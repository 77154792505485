/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Pagination from '@mui/material/Pagination';
import Icons from '../../../../assets/icons';
import colors from '../../../../constants';
import CommonList from '../../../../components/common';
import Styles, { GlobalStyles, Typography } from '../../../../styles';
import { useGetResourcesQuery } from '../../../../store/services/resourcesApi';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {
  StyledTable,
  TableWrapper,
  ResourcesWrap,
  ButtonsContainer,
  StyledTableContainer,
  PrimaryButton,
  SecondaryButton,
  HeaderBtnWrapper,
  EmptyTitleWrapper,
} from './resources.s';
import { useAppTheme } from '../../../../hooks';

interface IResoursesProps {}

export const Resources: FC<IResoursesProps> = () => {
  const { theme } = useAppTheme();
  const [t, i18n] = useTranslation('global');
  const [buttonState, setButtonState] = useState(true);
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [rowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { data: resourcesData, isLoading, refetch } = useGetResourcesQuery({ lang: i18n.language });

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => setPage(newPage);

  const filteredResources = resourcesData || [];

  const halfFilteredResources = buttonState ? filteredResources.slice(0, rowsPerPage) : [];

  useEffect(() => {
    setLoadingPlans(isLoading);
  }, [isLoading]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <ResourcesWrap>
      <Styles.Row gap={16}>
        <Styles.Row>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('resources.header.resources')}
          </Typography.h4>
        </Styles.Row>
        <Styles.Row>
          <CommonList.Container>
            <ButtonsContainer>
              <Styles.Row wrap={{ xs: 'wrap', lg: 'nowrap' }} gap={16} content={'space-between'}>
                <HeaderBtnWrapper
                  wrap={'nowrap'}
                  gap={6}
                  content={{ xs: 'space-between', sm: 'flex-start' }}>
                  <PrimaryButton
                    onClick={() => setButtonState(true)}
                    variant='outlined'
                    buttonState={buttonState}>
                    {t('resources.button.total')}
                  </PrimaryButton>
                  <SecondaryButton
                    onClick={() => setButtonState(false)}
                    variant='outlined'
                    buttonState={buttonState}>
                    {t('resources.button.payed')}
                  </SecondaryButton>
                </HeaderBtnWrapper>
              </Styles.Row>
            </ButtonsContainer>
            {loadingPlans ? (
              <EmptyTitleWrapper content={'center'} align_items={'center'}>
                <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('plans.main.loadingTitle')}
                </Typography.h4>
              </EmptyTitleWrapper>
            ) : (
              <>
                {halfFilteredResources.length > 0 ? (
                  <Styles.Row>
                    <TableWrapper>
                      <StyledTableContainer>
                        <StyledTable stickyHeader aria-label='sticky table'>
                          <TableHead>
                            <TableRow>
                              <GlobalStyles.IdTableCell align='center'>
                                <GlobalStyles.TableHeaderTitle content={'center'}>
                                  {t('resources.table.number')}
                                </GlobalStyles.TableHeaderTitle>
                              </GlobalStyles.IdTableCell>
                              <TableCell align='center'>
                                <GlobalStyles.TableHeaderTitle
                                  width='170px'
                                  align_items={'center'}
                                  content={'space-between'}
                                  wrap={'nowrap'}>
                                  {t('resources.table.name')}
                                </GlobalStyles.TableHeaderTitle>
                              </TableCell>
                              <TableCell align='center'>{t('resources.table.necessary')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredResources.map((row: any, index: number) => (
                              <TableRow key={index}>
                                <TableCell align='center'>
                                  {(page - 1) * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell align='left'>{row?.name}</TableCell>
                                <TableCell align='center'>
                                  <Link to={`/resource-type/${row?.id}/`}>
                                    <Icons.Eye
                                      color={
                                        theme === 'dark'
                                          ? colors['grey-300']
                                          : colors['typography-body']
                                      }
                                    />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </StyledTable>
                      </StyledTableContainer>
                    </TableWrapper>
                    {/* <Styles.Row content={'flex-end'}>
                      <Pagination
                        count={Math.ceil(resourcesData?.count / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color='primary'
                        shape='rounded'
                        style={{ marginTop: '20px' }}
                      />
                    </Styles.Row> */}
                  </Styles.Row>
                ) : (
                  <EmptyTitleWrapper content={'center'} align_items={'center'}>
                    <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t('resources.header.emptyTable')}
                    </Typography.h4>
                  </EmptyTitleWrapper>
                )}
              </>
            )}
          </CommonList.Container>
        </Styles.Row>
      </Styles.Row>
    </ResourcesWrap>
  );
};
