import styled from 'styled-components';
import Styles from '../../../styles';
import { Table, TableContainer } from '@mui/material';
import colors from '../../../constants';

export const NewPlanWrap = styled.div`
  width: 100%;
`;

export const AddPlanBody = styled(Styles.Row)`
  && {
    padding: 24px;
  }
`;

export const StepsWrap = styled(Styles.Row)`
  && {
    flex-direction: column;
    padding: 0px 24px 24px 24px;
    border-bottom: 1px solid var(--extra-divider);
  }
`;

export const StepsTextWrap = styled(Styles.Row)`
  && {
    flex-direction: column;
    @media (max-width: 576px) {
      display: none;
    }
  }
`;

export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;

export const StepContentWrap = styled(Styles.Row)<{ planStep: boolean; index: number }>`
  && {
    height: 40px;
    width: ${({ index }) => (index === 0 ? '45px' : '40px')};
    border-radius: 6px;
    background-color: ${({ planStep, index }) =>
      planStep
        ? index === 0
          ? 'var(--primary-500)'
          : 'var(--secondary-500)'
        : index === 0
          ? 'var(--secondary-500)'
          : 'var(--primary-500)'};
  }
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors['grey-300']};
  }
`;
