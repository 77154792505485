import { PLAN, TOPIC } from '../URLs';
import { api } from './api';

export const plansApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlans: builder.query<any, { page?: number; lang: string }>({
      query: ({ page = 1, lang }: { page?: number; lang: string }) => ({
        url: `${PLAN}?page=${page}&limit=10`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'accept-language': lang,
        },
      }),
      providesTags: ['getPlans'],
    }),
    addPlans: builder.mutation({
      query: (body) => ({
        url: PLAN,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getPlans'],
    }),
    addPlansMedia: builder.mutation({
      query: ({ id, formData }: { id: number; formData: FormData }) => ({
        url: `${PLAN}${id}/set-media/`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      }),
      invalidatesTags: ['getPlans'],
    }),
    deletePlan: builder.mutation({
      query: (id: number) => ({
        url: `${PLAN}${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      invalidatesTags: ['getPlans'],
    }),
    getPlanById: builder.query<any, { id: number; lang: string }>({
      query: ({ id, lang }) => ({
        url: `${PLAN}?id=${id}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getPlans'],
    }),
    deletePlanById: builder.mutation<any, number>({
      query: (id) => ({
        url: `${TOPIC}?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getPlans'],
    }),
    getRelatedPlanById: builder.query({
      query: (id: number) => ({
        url: `${PLAN}${id}/related-plans/`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'getRelatedPlanById', id }],
    }),
    getPlansByQuarter: builder.query<any, number>({
      query: (id) => ({
        url: `${PLAN}?quarter=${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: ['getPlansByQuarter'],
    }),
    getPlansByGrade: builder.query<any, number>({
      query: (id) => ({
        url: `${PLAN}?classes=${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: ['getPlansByGrade'],
    }),
    getPlansByScience: builder.query<any, number>({
      query: (id) => ({
        url: `${PLAN}?science=${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: ['getPlansByScience'],
    }),
  }),
});

export const {
  useGetPlansQuery,
  useAddPlansMutation,
  useAddPlansMediaMutation,
  useDeletePlanMutation,
  useGetPlanByIdQuery,
  useGetRelatedPlanByIdQuery,
  useGetPlansByGradeQuery,
  useGetPlansByQuarterQuery,
  useGetPlansByScienceQuery,
  useDeletePlanByIdMutation,
} = plansApi;
