import styled from 'styled-components';
import Styles from '../../../styles';
import { Button, MenuItem, Select, Table, TableCell, TableContainer } from '@mui/material';
import colors from '../../../constants';

export const ResourcesWrap = styled.div`
  width: 100%;
`;

export const ButtonsContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
  }
`;

export const IdTableCell = styled(TableCell)`
  && {
    max-width: 40px;
  }
`;



export const NewResourcesContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
  }
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    &.Mui-selected {
      color: ${colors['white']} !important;
    }
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 192px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
  }
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 192px;
    gap: 8px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors['grey-300']};
  }
`;
