import { FC } from 'react';
import { TestsWrap } from './tests.s';

interface ITests {}

export const Tests: FC<ITests> = () => {
  return (
    <TestsWrap>
      <div>man testlar page man</div>
    </TestsWrap>
  );
};
