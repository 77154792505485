export const lessonHours = [
  {
    value: '1-soat',
    label: 'lessonHours.firstHour',
  },
  { value: '2-soat', label: 'lessonHours.secondHour' },
  {
    value: '3-soat',
    label: 'lessonHours.thirdHour',
  },
  {
    value: '4-soat',
    label: 'lessonHours.fourthHour',
  },
];
