import { FC } from "react";
import { IIconProps } from "./interfaces/icon-interface";

export const MathFunction: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color,
}) => {
  return (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M14 10H15C16 10 16 11 17.016 13.527C18 16 18 17 19 17H20" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 10H15C16 10 16 11 17.016 13.527C18 16 18 17 19 17H20" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13 17C14.5 17 16 15 17 13.5C18 12 19.5 10 21 10" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13 17C14.5 17 16 15 17 13.5C18 12 19.5 10 21 10" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3 19C3 20.5 3.5 21 5 21C6.5 21 7 17 8 12C9 7 9.5 3 11 3C12.5 3 13 3.5 13 5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3 19C3 20.5 3.5 21 5 21C6.5 21 7 17 8 12C9 7 9.5 3 11 3C12.5 3 13 3.5 13 5" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 12H11" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 12H11" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};