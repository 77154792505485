import { Button, TextField } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../../../../constants';

export const NewPasswordFormWrapper = styled.div`
  width: 400px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PrimaryButton = styled(Button)`
  && {
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const SecondaryButton = styled(Button)`
  && {
    background-color: ${colors['opacity-primary40']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;
