import styled, { css } from 'styled-components';
import Styles from '../../../styles';

export const FooterWrap = styled.footer`
  && {
    width: 100%;
    padding-bottom: 16px;
  }
`;

export const FooterMockWrap = styled(Styles.Row)<{fs: string}>`
  && {
    margin-right: ${({fs}) => Number(fs) > 18 ? '10px' : '0px'};
  }
`;
