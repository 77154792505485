import { MODERATOR_RESOURCES, RESOURCE, RESOURCE_TYPES } from '../URLs';
import { api } from './api';

export const resourcesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getResources: builder.query<any, { lang: string }>({
      query: ({ lang }) => ({
        url: `${RESOURCE_TYPES}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getResources'],
    }),
    getModeratorResources: builder.query<any, void>({
      query: () => ({
        url: MODERATOR_RESOURCES,
        method: 'GET',
      }),
      providesTags: ['getModeratorResources'],
    }),
    addResource: builder.mutation({
      query: (body) => ({
        url: RESOURCE,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body,
      }),
      invalidatesTags: ['getResources'],
    }),
    getResourceTypes: builder.query<any, { id: string; lang: string }>({
      query: ({ id, lang }) => ({
        url: `${RESOURCE}?type=${id}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getResourceTypes'],
    }),
    getResource: builder.query<any, { id: string; lang: string }>({
      query: ({ id, lang }) => ({
        url: `${RESOURCE}${id}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getResource'],
    }),
  }),
});

export const {
  useAddResourceMutation,
  useGetResourcesQuery,
  useGetResourceTypesQuery,
  useGetResourceQuery,
  useGetModeratorResourcesQuery,
} = resourcesApi;
