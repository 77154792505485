/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Styles, { Typography } from '../../../../../../../../styles';
import { Button, HeaderWrap } from './header.s';
import { Day } from '../../../../../../../../interfaces/lesson';

interface IHeader {
  theme: string;
  day: any[] | null;
  date: string;
  slot: number;
  setSlot: (slot: 1 | 2) => void;
  active: boolean;
  dayOfWeek: string;
}

export const Header: FC<IHeader> = ({ theme, day, date, slot, setSlot, active, dayOfWeek }) => {
  const { t } = useTranslation('global');
  const parts = date.split(' ');
  const monthName = parts[0];
  const days = parts[1];

  return (
    <HeaderWrap theme={theme} align_items={'center'} content={'space-between'}>
      <Styles.Row width='auto' direction={'column'} gap={2}>
        <Typography.h5
          color={active ? 'primary-500' : theme === 'dark' ? 'grey-200' : 'typography-body'}>
          {t(dayOfWeek)}
        </Typography.h5>
        <Typography.paragraph color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
          {days} - {t(`${monthName}`)}
        </Typography.paragraph>
      </Styles.Row>
      <Styles.Row width='auto' gap={16}>
        <Button
          color={slot === 1 ? 'extra-white' : 'primary-500'}
          active={slot === 1}
          onClick={() => setSlot(1)}>
          <Typography.paragraph color={slot === 1 ? 'extra-white' : 'primary-500'}>
            1 - {t('mainPage.mainTable.button.first')}
          </Typography.paragraph>
        </Button>
        <Button
          color={slot === 2 ? 'extra-white' : 'primary-500'}
          active={slot === 2}
          onClick={() => setSlot(2)}>
          <Typography.paragraph color={slot === 2 ? 'extra-white' : 'primary-500'}>
            2 - {t('mainPage.mainTable.button.second')}
          </Typography.paragraph>
        </Button>
      </Styles.Row>
    </HeaderWrap>
  );
};
