import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { api } from './services/api';
import { cartSlice } from './slices';
import { authApi } from './services/authApi';
import authSlice from './slices/authSlice';
import { classApi } from './services/classApi';
import { daysOffApi } from './services/daysOffApi';
import { mediaApi } from './services/mediaApi';
import { moderatorApi } from './services/moderatorApi';
import { updatePasswordApi } from './services/updatePasswordApi';
import planSlice from './slices/planSlice';
import { resourcesApi } from './services/resourcesApi';
import { plansApi } from './services/plansApi';
import { userScheduleApi } from './services/userScheduleApi';
import { quaterApi } from './services/quatersApi';
import scheduleSlice from './slices/scheduleSlice';
import { questionApi } from './services/questionApi';
import { searchApi } from './services/search';
import { changeRoleApi } from './services/changeRole';
import { orderApi } from './services/order';
import { uploadApi } from './services/uploadApi';
import { weekApi } from './services/weekApi';
import { moderatorPlansApi } from './services/moderatorPlansApi';
import { NotificationApi } from './services/notificationApi';

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: {
      auth: authSlice,
      [api.reducerPath]: api.reducer,
      [authApi.reducerPath]: authApi.reducer,
      [classApi.reducerPath]: classApi.reducer,
      [daysOffApi.reducerPath]: daysOffApi.reducer,
      [mediaApi.reducerPath]: mediaApi.reducer,
      [moderatorApi.reducerPath]: moderatorApi.reducer,
      [updatePasswordApi.reducerPath]: updatePasswordApi.reducer,
      [resourcesApi.reducerPath]: resourcesApi.reducer,
      [plansApi.reducerPath]: plansApi.reducer,
      [quaterApi.reducerPath]: quaterApi.reducer,
      [userScheduleApi.reducerPath]: userScheduleApi.reducer,
      [questionApi.reducerPath]: questionApi.reducer,
      [searchApi.reducerPath]: searchApi.reducer,
      [changeRoleApi.reducerPath]: changeRoleApi.reducer,
      [orderApi.reducerPath]: orderApi.reducer,
      [uploadApi.reducerPath]: uploadApi.reducer,
      [weekApi.reducerPath]: weekApi.reducer,
      [moderatorPlansApi.reducerPath]: moderatorPlansApi.reducer,
      [NotificationApi.reducerPath]: NotificationApi.reducer,
      schedule: scheduleSlice,
      cart: cartSlice,
      plan: planSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    ...options,
  });

export const store = createStore();
export type RootState = ReturnType<typeof store.getState>;
