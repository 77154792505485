import styled from 'styled-components';
import Styles from '../../../../styles';

export const MainWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TypographyWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

