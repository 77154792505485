import { QUARTER, QUESTION } from '../URLs';
import { api } from './api';

export const questionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getQuestions: builder.query<any, void>({
      query: () => ({
        url: QUESTION,
        method: 'GET',
      }),
      providesTags: ['getQuestions'],
    }),
    addQuestion: builder.mutation<any, FormData>({
        query: (body) => ({
          url: QUESTION,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['addQuestion'],

      }),
  }),
});

export const { useGetQuestionsQuery, useAddQuestionMutation } = questionApi;
