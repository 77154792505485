/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import {
  AddOrderWrap,
  Bar,
  ContentWrap,
  SecondaryButton,
  StyledMenu,
  StyledSelect,
} from './add-order.s';
import { useTranslation } from 'react-i18next';
import Styles, { Typography } from '../../../styles';
import { Button } from '@mui/material';
import colors from '../../../constants';
import * as Yup from 'yup';
import { Common } from '../../../components';
import { useAppTheme } from '../../../hooks';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  useGetAllScienceByClassQuery,
  useGetAllScienceTypeQuery,
} from '../../../store/services/scienceApi';
import { useAddOrderMutation } from '../../../store/services/order';
import { useGetAllClassGroupQuery } from '../../../store/services/classApi';

interface IPaymentProps {}

export const AddOrder: FC<IPaymentProps> = () => {
  const [t, i18n] = useTranslation('global');
  const { theme } = useAppTheme();
  const [classGrp, setClassGrp] = useState(0);
  const [scienceGrp, setScienceGrp] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const { data: classGroupData } = useGetAllClassGroupQuery(i18n.language);
  const { data: scienceData } = useGetAllScienceByClassQuery({
    id: classGrp || Number(localStorage.getItem('class_group')),
    lang: i18n.language,
  });
  const [scienceInfo, setScienceInfo] = useState(0);
  const { data: scienceTypesData } = useGetAllScienceTypeQuery({
    class_group: classGrp,
    science: scienceInfo,
    lang: i18n.language,
  });
  const [addOrder, { isLoading }] = useAddOrderMutation();

  const formik = useFormik({
    initialValues: {
      science: 0,
      class: 0,
      types: [],
    },
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append('science', values.science.toString());
        formData.append('class_type', values.class.toString());
        values.types.forEach((item: string, index: number) => {
          formData.append(`types`, item);
        });
        await addOrder(formData).unwrap();
        setSnackbarMessage('payment.order.add');
        setSnackbarOpen(true);
        if (!isLoading) {
          setTimeout(() => {
            navigate('/payment');
          }, 500);
        }
      } catch (error: any) {
        setSnackbarMessage(error.data.detail);
        setSnackbarOpen(true);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('types', []);
  }, [scienceInfo]);

  return (
    <AddOrderWrap onSubmit={formik.handleSubmit}>
      <Common.Container>
        <ContentWrap gap={16}>
          <Styles.Row>
            <Typography.h5 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {t('payment.order.addOrder')}
            </Typography.h5>
          </Styles.Row>
          <Styles.Row
            content={'space-between'}
            gap={16}
            wrap={'nowrap'}
            direction={{ xs: 'column', sm: 'row' }}>
            <Styles.Column size={6} difference={0} gap={4}>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('auth.userReg.inputs.class_group')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </Styles.Row>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <StyledSelect
                  value={formik.values.class}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setClassGrp(event.target.value as number);
                  }}
                  displayEmpty
                  renderValue={(selected: any) => {
                    if (selected === 0) {
                      return <>{t('auth.userReg.inputs.placeholder')}</>;
                    }

                    const selectedClassGroup = classGroupData?.results.find(
                      (group: any) => group.id === selected
                    );
                    return selectedClassGroup ? selectedClassGroup.name : '';
                  }}
                  name='class'
                  error={formik.touched.class && Boolean(formik.errors.class)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '500px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {classGroupData?.results &&
                    classGroupData?.results.map((classType: any, index: number) => (
                      <StyledMenu key={index} value={classType.id}>
                        {classType.name}
                      </StyledMenu>
                    ))}
                </StyledSelect>
                {formik.touched.class && formik.errors.class && (
                  <Typography.paragraphSmall color='danger-600'>
                    {t(formik.errors.class)}
                  </Typography.paragraphSmall>
                )}
              </Styles.Row>
            </Styles.Column>
            <Styles.Column size={6} difference={0} gap={4}>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('auth.userReg.inputs.lesson')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </Styles.Row>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <StyledSelect
                  value={formik.values.science}
                  disabled={classGrp === 0}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setScienceGrp(event.target.value as number);
                  }}
                  renderValue={(selected: any) => {
                    if (formik.values.class === 0) {
                      return <>{t('auth.userReg.inputs.sciencePlaceholder')}</>;
                    }

                    const findScienceName = (id: number) => {
                      const science = scienceData?.results.find((item: any) => item.id === id);
                      return science ? science.name : '';
                    };

                    return selected ? (
                      findScienceName(selected)
                    ) : (
                      <>{t('auth.userReg.inputs.placeholder')}</>
                    );
                  }}
                  displayEmpty
                  name='science'
                  error={formik.touched.science && Boolean(formik.errors.science)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '500px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {scienceData?.results &&
                    scienceData?.results.map((science: any, index: number) => (
                      <StyledMenu
                        key={index}
                        value={science.id}
                        onClick={() => setScienceInfo(science.id)}>
                        {science.name}
                      </StyledMenu>
                    ))}
                </StyledSelect>
                {formik.touched.science && formik.errors.science && (
                  <Typography.paragraphSmall color='danger-600'>
                    {t(formik.errors.science)}
                  </Typography.paragraphSmall>
                )}
              </Styles.Row>
            </Styles.Column>
            <Styles.Column size={6} difference={0} gap={4}>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('auth.userReg.inputs.grade')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </Styles.Row>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <StyledSelect
                  value={formik.values.types}
                  onChange={formik.handleChange}
                  renderValue={(selected: any) => {
                    if (formik.values.science === 0) {
                      return <>{t('auth.userReg.inputs.scienceGrpPlaceholder')}</>;
                    } else {
                      const getSelectedNames = () => {
                        console.log(selected)
                        return selected
                          .map((id: number) => {
                            const science = scienceTypesData?.results.find(
                              (item: any) => item.id === id
                            );
                            return science ? science.name : '';
                          })
                          .filter((name: string) => name !== '')
                          .join(', ');
                      };
                      return selected.length === 0 ? (
                        <>{t('auth.userReg.inputs.placeholder')}</>
                      ) : (
                        getSelectedNames()
                      );
                    }
                  }}
                  displayEmpty
                  disabled={scienceGrp === 0}
                  name='types'
                  multiple
                  error={formik.touched.types && Boolean(formik.errors.types)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      width: '100px !important',
                      height: '500px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {scienceTypesData &&
                    scienceTypesData?.results.map((scienceInfo: any, index: number) => (
                      <StyledMenu key={index} value={scienceInfo.id}>
                        {scienceInfo.name}
                      </StyledMenu>
                    ))}
                </StyledSelect>
                {formik.touched.types && formik.errors.types && (
                  <Typography.paragraphSmall color='danger-600'>
                    {/* {t(formik.errors.types)} */}
                  </Typography.paragraphSmall>
                )}
              </Styles.Row>
            </Styles.Column>
          </Styles.Row>
          <Styles.Row gap={16} content={{ xs: 'space-between', sm: 'flex-end' }}>
            <SecondaryButton
              onClick={() => navigate('/payment')}
              variant='contained'
              sx={{
                backgroundColor: colors['primary-500'],
                textTransform: 'capitalize',
              }}>
              <Typography.h6 color={theme === 'dark' ? 'white' : 'primary-500'}>
                {t('auth.userReg.cancelBtn')}
              </Typography.h6>
            </SecondaryButton>
            <Button
              type='submit'
              disabled={isLoading || formik.values.types.length === 0}
              variant='contained'
              sx={{
                backgroundColor: colors['primary-500'],
                textTransform: 'capitalize',
              }}>
              <Typography.h6 color='white'>{t('payment.order.addBtn')}</Typography.h6>
            </Button>
          </Styles.Row>
        </ContentWrap>
      </Common.Container>
      <Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </AddOrderWrap>
  );
};
