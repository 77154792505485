import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const MoonStars: FC<IIconProps> = ({ className, width = 25, height = 24, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.5 2.99994C12.632 2.99994 12.763 2.99994 12.893 2.99994C10.2421 5.46311 9.72802 9.46779 11.6707 12.5207C13.6134 15.5736 17.4589 16.804 20.813 15.4459C19.0803 19.6151 14.5268 21.8552 10.1666 20.6836C5.80643 19.512 2.98907 15.2912 3.57943 10.8151C4.16978 6.33903 7.98513 2.99303 12.5 2.99194V2.99994Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.5 2.99994C12.632 2.99994 12.763 2.99994 12.893 2.99994C10.2421 5.46311 9.72802 9.46779 11.6707 12.5207C13.6134 15.5736 17.4589 16.804 20.813 15.4459C19.0803 19.6151 14.5268 21.8552 10.1666 20.6836C5.80643 19.512 2.98907 15.2912 3.57943 10.8151C4.16978 6.33903 7.98513 2.99303 12.5 2.99194V2.99994Z'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.5 4C17.5 5.10457 18.3954 6 19.5 6C18.3954 6 17.5 6.89543 17.5 8C17.5 6.89543 16.6046 6 15.5 6C16.6046 6 17.5 5.10457 17.5 4'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.5 4C17.5 5.10457 18.3954 6 19.5 6C18.3954 6 17.5 6.89543 17.5 8C17.5 6.89543 16.6046 6 15.5 6C16.6046 6 17.5 5.10457 17.5 4'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19.5 10.25C19.0858 10.25 18.75 10.5858 18.75 11C18.75 11.4142 19.0858 11.75 19.5 11.75V10.25ZM21.5 11.75C21.9142 11.75 22.25 11.4142 22.25 11C22.25 10.5858 21.9142 10.25 21.5 10.25V11.75ZM21.25 10C21.25 9.58579 20.9142 9.25 20.5 9.25C20.0858 9.25 19.75 9.58579 19.75 10H21.25ZM19.75 12C19.75 12.4142 20.0858 12.75 20.5 12.75C20.9142 12.75 21.25 12.4142 21.25 12H19.75ZM19.5 11.75H21.5V10.25H19.5V11.75ZM19.75 10V12H21.25V10H19.75Z'
        fill={color}
      />
      <path
        d='M19.5 10.25C19.0858 10.25 18.75 10.5858 18.75 11C18.75 11.4142 19.0858 11.75 19.5 11.75V10.25ZM21.5 11.75C21.9142 11.75 22.25 11.4142 22.25 11C22.25 10.5858 21.9142 10.25 21.5 10.25V11.75ZM21.25 10C21.25 9.58579 20.9142 9.25 20.5 9.25C20.0858 9.25 19.75 9.58579 19.75 10H21.25ZM19.75 12C19.75 12.4142 20.0858 12.75 20.5 12.75C20.9142 12.75 21.25 12.4142 21.25 12H19.75ZM19.5 11.75H21.5V10.25H19.5V11.75ZM19.75 10V12H21.25V10H19.75Z'
        fill='white'
        fill-opacity='0.1'
      />
    </svg>
  );
};
