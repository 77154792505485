/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import { AddPlanBody, AddPlanWrap } from './add-plan.s';
import Styles, { Typography } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Icons from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import colors from '../../../constants';

interface IAddPlanProps {}

export const AddPlan: FC<IAddPlanProps> = ({}) => {
  const [t] = useTranslation('global');
  const navigate = useNavigate();

  return (
    <AddPlanWrap>
      <Styles.Row gap={26}>
        <Styles.Row gap={4}>
          <Typography.h4 color='typography-muted'>{t('plans.main.title')} /</Typography.h4>
          <Typography.h4 color='typography-body'>Matematika</Typography.h4>
        </Styles.Row>
        <Styles.Row gap={28}>
          <Styles.Row gap={8}>
            <Styles.Row>
              <Typography.paragraph color='typography-body'>
                {t('plans.viewItem.header.title.lesson')}:
              </Typography.paragraph>
            </Styles.Row>
            <Styles.Row>
              <Typography.paragraph color='typography-body'>
                {t('plans.viewItem.header.title.grade')}:
              </Typography.paragraph>
            </Styles.Row>
            <Styles.Row>
              <Typography.paragraph color='typography-body'>
                {t('plans.viewItem.header.title.quater')}:
              </Typography.paragraph>
            </Styles.Row>
            <Styles.Row>
              <Typography.paragraph color='typography-body'>
                {t('plans.viewItem.header.title.theme')}:
              </Typography.paragraph>
            </Styles.Row>
            <Styles.Row>
              <Typography.paragraph color='typography-body'>
                {t('plans.viewItem.header.title.themeNumber')}:
              </Typography.paragraph>
            </Styles.Row>
          </Styles.Row>
          <AddPlanBody gap={8}>
            <Styles.Row content={'center'}>
              <Typography.h4 color='typography-body'>
                {t('plans.viewItem.body.title.primary')}
              </Typography.h4>
            </Styles.Row>
            <Styles.Row content={'center'}>
              <Typography.paragraph color='typography-muted'>
                {t('plans.viewItem.body.title.secondary')}
              </Typography.paragraph>
            </Styles.Row>
            <Styles.Row content={'center'}>
              <Button
                onClick={() => navigate('/new-plan')}
                variant='contained'
                sx={{
                  gap: '8px',
                  width: '192px',
                  backgroundColor: colors['primary-500'],
                  textTransform: 'capitalize',
                  '@media (max-width: 768px)': {
                    width: '150px',
                  },
                }}>
                <Typography.h6 color='white'>{t('plans.viewItem.body.button')}</Typography.h6>
                <Icons.Plus />
              </Button>
            </Styles.Row>
          </AddPlanBody>
        </Styles.Row>
      </Styles.Row>
    </AddPlanWrap>
  );
};
