import styled from 'styled-components';
import Styles, { Typography } from '../../../../../styles';
import colors from '../../../../../constants';

export const AnsweredQuestionWrapper = styled(Styles.Row)`
  height: 100vh;
`;

export const ImageUserWrapper = styled(Styles.Row)`
  height: 38px;
  max-width: 38px;
  background-color: ${colors['primary-500']};
  border-radius: 50px;
`;

export const ImageAdminWrapper = styled(Styles.Row)`
  height: 32px;
  max-width: 32px;
`;


export const Image = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 100px;
`;

export const StyledUserTypogarphy = styled(Typography.paragraph)`
    background-color: ${colors['primary-500']};
    padding: 10px 16px;
    border-radius: 6px 0px 6px 6px;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

export const StyledUserParagraphSm = styled(Typography.paragraphSmall)`
    display: flex;
    align-content: center;
    gap: 6px;
`;

export const StyledAdminTypogarphy = styled(Typography.paragraph)`
    padding: 10px 16px;
    border-radius: 0px 6px 6px 6px;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: ${colors['white']};
`;