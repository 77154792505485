/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { FC } from 'react';
import {
  Line,
  PlanInfoWrapper,
  MainResourceViewWrap,
  StyledTable,
  StyledTableContainer,
  TableContentWrap,
} from './main-resource-view.s';
import Styles, { Typography } from '../../../../styles';
import { useTranslation } from 'react-i18next';
import { Common } from '../../../../components';
import { Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import colors from '../../../../constants';
import Icons from '../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { fullNameGenerator } from '../../../../helpers/fullNameGenerator';
import { formatBytes } from '../../../../helpers/formatBytes';
import { useGetMediaByIdQuery } from '../../../../store/services/mediaApi';
import { formatUpdatedDate } from '../../../../helpers/formatUpdatedDate';

interface IMainResourceViewProps {}

export const MainResourceView: FC<IMainResourceViewProps> = (props) => {
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const url = window.location.href;
  const parts = url.split('/');
  const id = parts[parts.length - 1];
  const { data: resource } = useGetMediaByIdQuery({ id, lang: i18n.language });

  return (
    <MainResourceViewWrap>
      <Styles.Row gap={16}>
        <Styles.Row gap={4}>
          <Typography.h4 color='typography-body'>{resource?.name}</Typography.h4>
        </Styles.Row>
        <Common.Container>
          <PlanInfoWrapper wrap={{ xs: 'wrap', sm: 'nowrap' }} gap={16}>
            <Styles.Row direction={'column'} gap={16}>
              <Typography.h4 color='typography-body'>{resource?.name}</Typography.h4>
              <Typography.paragraph color='typography-body'>
                {resource?.description}
              </Typography.paragraph>
              <Line />
              <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                <Icons.School color={colors['typography-body']} />
                <Typography.paragraph color='typography-body'>
                  Sinf:{resource?._class.name}
                </Typography.paragraph>
              </Styles.Row>
              <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                <Icons.Comet color={colors['typography-body']} />
                <Typography.paragraph color='typography-body'>Fan:</Typography.paragraph>
                <Typography.paragraphBold color='typography-body'>
                  {resource?.science.name}
                </Typography.paragraphBold>
              </Styles.Row>
              <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                <Icons.MathFunction color={colors['typography-body']} />
                <Typography.paragraph color='typography-body'>
                  Mavzu: {resource?.topic?.name}
                </Typography.paragraph>
              </Styles.Row>
              <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                <Icons.EmptyUser color={colors['typography-body']} />
                <Typography.paragraph color='typography-body'>Muallif:</Typography.paragraph>
                <Typography.paragraphBold color='typography-body'>
                  {fullNameGenerator(resource?.user?.first_name, resource?.user?.last_name)}
                </Typography.paragraphBold>
              </Styles.Row>
              <Styles.Row gap={8} align_items={'center'}>
                <Icons.RotateClockwise color={colors['typography-body']} />
                <Typography.paragraph color='typography-body'>
                  So'nggi yangilanish: {formatUpdatedDate(resource ? resource.updated_at : '')}
                </Typography.paragraph>
              </Styles.Row>
            </Styles.Row>
          </PlanInfoWrapper>
        </Common.Container>
        <Common.Container>
          <TableContentWrap>
            <StyledTableContainer>
              <StyledTable aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>{t('resources.add.table.content.number')}</TableCell>
                    <TableCell align='left'>
                      <Styles.Row align_items={'center'} content={'space-between'} wrap={'nowrap'}>
                        {t('resources.add.table.content.name')}
                        <Button>
                          <Icons.Arrow />
                        </Button>
                      </Styles.Row>
                    </TableCell>
                    <TableCell align='left'>
                      <Styles.Row>{t('resources.add.table.content.type')}</Styles.Row>
                    </TableCell>
                    <TableCell align='left'>
                      <Styles.Row>{t('resources.add.table.content.size')}</Styles.Row>
                    </TableCell>
                    <TableCell align='center'>
                      {t('resources.add.table.content.download')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell align='center'>{resource?.id}</TableCell>
                    <TableCell align='left'>{resource?.name}</TableCell>
                    <TableCell align='left'>{resource?.type?.name}</TableCell>
                    <TableCell align='left'>{formatBytes(resource?.size)}</TableCell>
                    <TableCell align='center'>
                      <Styles.Row content={'center'}>
                        <Button href={resource?.file} download={resource?.name}>
                          <Icons.Download color={colors['typography-body']} />
                        </Button>
                      </Styles.Row>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </TableContentWrap>
        </Common.Container>
      </Styles.Row>
    </MainResourceViewWrap>
  );
};
