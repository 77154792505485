/* eslint-disable react/jsx-pascal-case */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  SidebarWrap,
  SidebarHeader,
  MenuBlock,
  LinkBlock,
  DotBtn,
  CloseBtn,
  SidebarButton,
  StyledH4,
  Img,
  SidebarMain,
  BurgerMenu,
} from './sidebar.s';
import Styles, { Typography } from '../../../styles';
import Icons from '../../../assets/icons';
import { RouteLinkProps } from '../../../interfaces/global-interface';
import colors from '../../../constants';
import { useMediaQuery } from '@mui/material';
import { Logout } from '../../../pages/user/logout';
import useCartStore from '../../../store/slices/setCart';

interface ISidebarProps {
  theme: string;
  menuStatus: boolean;
  setMenuStatus: (status: boolean) => void;
  menus: RouteLinkProps[];
  open: boolean;
  setOpen: (status: boolean) => void;
}

export const Sidebar: FC<ISidebarProps> = ({
  menuStatus,
  setMenuStatus,
  menus,
  open,
  setOpen,
  theme,
}) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [fontSize, setFontSize] = useState(localStorage.getItem('fontSize') || '16');
  const [t] = useTranslation('global');
  const location = useLocation();
  const { clearCart } = useCartStore();
  const matches = useMediaQuery('(max-width:768px)');
  const visibleMenus = menus.filter((menu) => menu.name && menu.icon);
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (localStorage.getItem('fontSize')) {
      setFontSize(localStorage.getItem('fontSize') || '16');
    }
  }, [localStorage.getItem('fontSize')]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        if (matches) {
          setOpen(false);
  
        }
      }
    };

    if (matches) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [matches, setOpen]);

  return (
    <BurgerMenu open={open}>
      <SidebarMain menuStatus={menuStatus}></SidebarMain>
      <SidebarWrap ref={sidebarRef} theme={theme} menuStatus={menuStatus} menuOpen={open}>
        <SidebarHeader isOpen={menuStatus}>
          {menuStatus ? (
            <StyledH4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {<Img src='/images/LogoV2.png' />} Classcom
            </StyledH4>
          ) : (
            ''
          )}
          <DotBtn onClick={() => setMenuStatus(!menuStatus)}>
            <Icons.CircleDot color={theme === 'dark' ? colors['grey-400'] : colors['extra-dark']} />
          </DotBtn>
          <CloseBtn onClick={() => setOpen(!open)}>
            <Icons.X color={theme === 'dark' ? colors['grey-400'] : colors['extra-dark']} />
          </CloseBtn>
        </SidebarHeader>

        <Styles.Row>
          {visibleMenus.map((menu) => {
            return (
              <MenuBlock
                menuStatus={menuStatus}
                fontSize={fontSize}
                key={menu.path}
                direction={'column'}
                align_items={'flex-start'}>
                {/* {menuStatus && menu.blockTitle && (
                  <Caption color='grey-500'>{t(`${menu.blockTitle}`)}</Caption>
                )} */}
                <LinkBlock
                  fontSize={fontSize}
                  to={menu.path}
                  active={menuStatus}
                  onClick={() => {
                    clearCart();
                    setOpen(!open);
                  }}
                  bg={
                    location.pathname === menu.path ||
                    menu.paths?.some((path) =>
                      location.pathname.startsWith(path.replace(':id', ''))
                    )
                  }>
                  {menu.icon && (
                    <menu.icon
                      width={24}
                      height={24}
                      stroke={
                        location.pathname === menu.path ||
                        menu.paths?.some((path) =>
                          location.pathname.startsWith(path.replace(':id', ''))
                        )
                          ? colors.white
                          : theme === 'dark'
                          ? colors['grey-400']
                          : colors['extra-dark']
                      }
                    />
                  )}
                  {menuStatus && (
                    <Typography.paragraph
                      color={
                        location.pathname === menu.path ||
                        menu.paths?.some((path) =>
                          location.pathname.startsWith(path.replace(':id', ''))
                        )
                          ? 'extra-white'
                          : theme === 'dark'
                          ? 'grey-400'
                          : 'extra-dark'
                      }>
                      {t(`${menu.name}`)}
                    </Typography.paragraph>
                  )}
                </LinkBlock>
              </MenuBlock>
            );
          })}
          <MenuBlock
            menuStatus={menuStatus}
            fontSize={fontSize}
            direction={'column'}
            align_items={'flex-start'}
            onClick={() => setModalStatus(true)}>
            <SidebarButton fontSize={fontSize} menuStatus={menuStatus}>
              <Icons.Logout
                width={24}
                height={24}
                stroke={theme === 'dark' ? colors['grey-400'] : colors['extra-dark']}
              />
              {menuStatus && (
                <Typography.paragraph color={theme === 'dark' ? 'grey-400' : 'extra-dark'}>
                  {t('sidebar.items.logout')}
                </Typography.paragraph>
              )}
            </SidebarButton>
          </MenuBlock>
          <Logout modalStatus={modalStatus} setModalStatus={setModalStatus} />
        </Styles.Row>
      </SidebarWrap>
    </BurgerMenu>
  );
};
