import styles from './style.module.scss';

export function Spin({
  size,
  type = 'normal',
}: {
  size?: string;
  type?: 'small' | 'normal' | 'large';
}) {
  return (
    <span
      className={`${styles.loader} ${styles[type]}`}
      style={{
        width: size,
        height: size,
      }}
    />
  );
}
