import { AuthSate } from '../../interfaces/auth-interface';
import {
  AUTH_CONFIRM,
  AUTH_ME,
  AUTH_ME_UPDATE,
  AUTH_REGISTER,
  AUTH_REGISTER_MODERATOR,
  AUTH_TOKEN,
  DISTRICTS,
  REGION,
  SCHOOL_TYPE,
} from '../URLs';
import { api } from './api';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, { phone: string; password: string; lang: string }>({
      query: (body) => {
        return {
          url: AUTH_TOKEN,
          method: 'POST',
          body,
          headers: {
            'accept-language': body.lang,
          },
        };
      },
      invalidatesTags: ['login'],
    }),
    getMe: builder.query<AuthSate, null>({
      query: () => ({
        url: AUTH_ME,
        method: 'GET',
      }),
      providesTags: ['getMe', 'login'],
    }),
    register: builder.mutation<any, { phone: string }>({
      query: (body) => {
        return {
          url: AUTH_REGISTER,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['register'],
    }),
    moderatorRegister: builder.mutation<any, { formData: FormData; lang: string }>({
      query: ({ formData, lang }) => {
        return {
          url: AUTH_REGISTER_MODERATOR,
          method: 'POST',
          body: formData,
          headers: {
            'accept-language': lang,
          },
        };
      },
      invalidatesTags: ['moderatorRegister'],
    }),
    userRegister: builder.mutation<any, { formData: FormData; lang: string }>({
      query: ({ formData, lang }) => {
        return {
          url: AUTH_REGISTER,
          method: 'POST',
          body: formData,
          headers: {
            'accept-language': lang,
          },
        };
      },
      invalidatesTags: ['userRegister'],
    }),
    getRegion: builder.query<any, string>({
      query: (lang) => ({
        url: REGION,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getRegion'],
    }),
    getSchoolType: builder.query<any, {educationType: string, lang: string}>({
      query: ({educationType, lang}) => ({
        url: `${SCHOOL_TYPE}${educationType}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getSchoolType'],
    }),
    getDistrict: builder.query<any, {region_id: number, lang: string}>({
      query: ({region_id, lang}) => ({
        url: DISTRICTS + `${region_id}/`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getDistrict'],
    }),
    authConfirm: builder.mutation<any, { phone: string; code: number; lang: string }>({
      query: (body) => {
        return {
          url: AUTH_CONFIRM,
          method: 'POST',
          body,
          headers: {
            'accept-language': body.lang,
          },
        };
      },
      invalidatesTags: ['authConfirm'],
    }),
    authUpdateMe: builder.mutation<any, FormData>({
      query: (body) => {
        return {
          url: AUTH_ME_UPDATE,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['authUpdateMe'],
    }),
  }),
});

export const {
  useLoginMutation,
  useGetMeQuery,
  useModeratorRegisterMutation,
  useUserRegisterMutation,
  useGetRegionQuery,
  useGetDistrictQuery,
  useAuthConfirmMutation,
  useAuthUpdateMeMutation,
  useGetSchoolTypeQuery,
} = authApi;
