/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { VerificationForm } from '../../../../components/Verification-form';
import * as Yup from 'yup';
import { useAuthConfirmMutation } from '../../../../../../store/services/authApi';
import { setRefToken } from '../../../../../../helpers/token';
import { useTranslation } from 'react-i18next';

interface IUserVerificationFormProps {}

const validationSchema = Yup.object().shape({
  code: Yup.string().required('auth.passVer.errors.code.required'),
});

export const UserVerificationForm: FC<IUserVerificationFormProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation('global');

  const [authConfirm, { isError, isLoading }] = useAuthConfirmMutation();

  const handleSubmit = async (code: string, phoneNumber: string) => {
    try {
      const response = await authConfirm({
        phone: phoneNumber,
        code: Number(code),
        lang: i18n.language,
      }).unwrap();
      localStorage.setItem('waiting_accessToken', response.token.access);
      setRefToken(response.token.refresh);
      localStorage.removeItem('moderator-2');
      localStorage.removeItem('moderator-1');
      navigate('/author/m-waiting');
    } catch (error: any) {
      throw error;
    }
  };

  return (
    <VerificationForm
      initialValues={{ code: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      back={'/author/register-multisteps'}
      onSuccess={() => navigate('/author/m-waiting')}
      onError={(error: any) => console.log(error)}
    />
  );
};
