import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const Point: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color,
  ...props
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle
        cx='12.5'
        cy='12'
        r='4'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='12.5'
        cy='12'
        r='4'
        stroke={color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
