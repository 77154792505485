import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const Menu2: FC<IIconProps> = ({ className, width = 25, height = 24 }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.5 6H20.5'
        stroke='#4B465C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.5 6H20.5'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.5 12H20.5'
        stroke='#4B465C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.5 12H20.5'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.5 18H20.5'
        stroke='#4B465C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.5 18H20.5'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
