import styled from 'styled-components';

export const ResetPasswordVerificationWrap = styled.div`
  && {
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 20px;
    flex-wrap: nowrap;
    @media (min-width: 768px) {
      padding: 0px;
    }
  }
`;

export const Img = styled.img`
  && {
    width: 80%;
    height: 100%;
    object-fit: contain;
    &:hover {
      width: 85%;
    }
    @media (max-width: 1200px) {
      width: 70%;
      &:hover {
        width: 75%;
      }
    }
  }
`;
