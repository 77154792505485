import { Button, MenuItem, Select, TextField } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../../../constants';

export const FirstFormWrap = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    };
    @media(max-width: 576px){
      width: 100%;
    };
  }
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    &.Mui-selected {
      color: ${colors['white']} !important;
    }
    &:focus {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important;
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important;
    }
  }
`;


export const StyledBigTextField = styled(TextField)`
  && {
    color: ${colors['secondary-500']};
    input {
      height: 84px;
    }
    .MuiOutlinedInput-root {
      height: 84px;
      align-items: flex-end;
    }
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 192px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    };
    @media(max-width: 576px){
      width: 100%;
    }
  }
`;

export const PrimaryIconBtn = styled(Button)`
  && {
    width: auto;
    height: 45px;
    background-color: ${colors['primary-500']};
    @media(max-width: 1000px){
      min-width: 44px;
      padding: 6px 10px
    };
  }
`;

export const SecondaryIconBtn = styled(Button)`
  && {
    width: auto;
    height: 45px;
    background-color: ${colors['opacity-primary32']};
    &:hover {
      background-color: ${colors['grey-200']};
    }
    @media(max-width: 1000px){
      min-width: 44px;
      padding: 6px 10px
    };
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 192px;
    gap: 8px;
    height: 38px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const PrimaryBigButton = styled(Button)`
  && {
    width: 192px;
    height: 38px;
    gap: 8px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const SecondaryButton = styled(Button)`
  && {
    width: 192px;
    height: 38px;
    gap: 8px;
    background-color: ${colors['opacity-primary32']};
    &:hover {
      background-color: ${colors['grey-200']};
    }
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;