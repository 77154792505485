import { Box, Table, TableContainer } from "@mui/material";
import styled from "styled-components";
import Styles, { Typography } from "../../../../styles";
import colors from "../../../../constants";

export const ResourceViewWrapper = styled.div`
  width: 100%;
`;

export const StyledTable = styled(Table)`
  min-width: 650px;
  width: 100%;
`;

export const StyledBox = styled(Box)<{ selectedImage: string }>`
  && {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
    background-image: ${({ selectedImage }) => `url(${selectedImage})`};
  }
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const ResourceBannerWrapper = styled(Styles.Row)`
  && {
    background-color: #a8aaae29;
    border-radius: 6px;
    width: 250px;
    height: 200px;
    @media (max-width:576px){
      width:100%
    }
  }
`;

export const Line = styled(Styles.Row)`
  && {
    height: 1px;
    background-color: ${colors["grey-300"]}
  }
`;

export const ResourceInfoWrapper = styled(Styles.Row)`
  && {
    padding: 0px 10px;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors["grey-300"]};
    max-width: 100%;
    overflow-x: auto;
    
  }
`;

export const StyledParagraph = styled(Typography.paragraph)`
  && {
    @media(max-width: 576px){
      text-align: center;
    }
  }
`;



export const StyledH4 = styled(Typography.h4)`
  && {
    @media(max-width: 576px){
      text-align: center;
    }
  }
`;

export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;