/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import { MainWrap, TypographyWrapper } from './main.s';
import Styles, { GlobalStyles, Typography } from '../../../../styles';
import { TodayDate, getCurrentDate } from '../../../../lib/getDay';
import { Diary, Header } from './components';
import { useTranslation } from 'react-i18next';
import { useAppTheme } from '../../../../hooks';
import { useCreateScheduleMutation } from '../../../../store/services/userScheduleApi';

interface IMainProps {}

interface Schedule {
  id: number;
  class: number;
  classType: string;
  subject: number;
  subjectType: number;
  classGroup: string;
  startTime: string;
  endTime: string;
  shift: string;
  lessonTime: string;
  days: string;
}

export const SampleCreate: FC<IMainProps> = () => {
  const { theme } = useAppTheme();
  const [t] = useTranslation('global');
  const [scheduleFormat, setScheduleFormat] = useState('3');
  const [sampleName, setSampleName] = useState<string>('');
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [scheduleDay, setScheduleDay] = useState<any | null>(null);
  const [createSheduleSample, { isLoading }] = useCreateScheduleMutation();
  const today = TodayDate();
  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const [deleteState, setDeleteState] = useState(false);

  const getDaySchedules = (day: string) => schedules.filter((schedule) => schedule.days === day);
  const getShiftSchedules = (shift: string, day: string) =>
    getDaySchedules(day).filter((schedule) => schedule.shift === shift);

  const daysToShow = daysOfWeek.map((day, index) => ({
    id: index + 1,
    schedule: {
      MORNING: getShiftSchedules('MORNING', day),
      EVENING: getShiftSchedules('EVENING', day),
    },
  }));

  const isHoliday = false;
  const isSunday = today.weekday === 0;

  const handleSaveSample = async (name: string) => {
    try {
      const transformedSchedules = schedules.map((schedule) => ({
        class_group: schedule.classGroup.toString(),
        class_type: schedule.classType.toString(),
        classes: schedule.class.toString(),
        end_time: schedule.endTime,
        lesson_time: schedule.lessonTime,
        science: schedule.subject.toString(),
        science_type: schedule.subjectType.toString(),
        start_time: schedule.startTime,
        shift: schedule.shift,
        weekday: schedule.days,
      }));

      await createSheduleSample({
        name,
        schedules: transformedSchedules,
      }).unwrap();

      setSnackbarMessage('mainPage.snackbar.sampleAdded');
      setSnackbarOpen(true);
      setSchedules([]);
      setSampleName('');
    } catch (error) {
      console.error('Error saving schedule:', error);
      setSnackbarMessage('mainPage.snackbar.sampleErr');
      setSnackbarOpen(true);
    }
  };

  return (
    <MainWrap>
      <Header
        sampleName={sampleName}
        setSampleName={setSampleName}
        deleteState={deleteState}
        setDeleteState={setDeleteState}
        theme={theme}
        setScheduleDay={setScheduleDay}
        setScheduleFormat={setScheduleFormat}
        onSave={handleSaveSample}
      />
      <Styles.Row gap={16}>
        {isHoliday || isSunday ? (
          <TypographyWrapper align_content={'center'} content={'center'}>
            <Typography.h4 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
              {t('mainPage.holiday')}
            </Typography.h4>
          </TypographyWrapper>
        ) : (
          <Styles.Row gap={16}>
            {daysToShow.map((day, index) => {
              const currentDate = getCurrentDate(index);
              const date = `${currentDate.month} ${currentDate.day.toString().padStart(2, '0')} ${
                currentDate.year
              }`;
              const dateByIndex = `${currentDate.year}-${
                currentDate.monthIndex + 1
              }-${currentDate.day.toString().padStart(2, '0')}`;
              return (
                <Styles.Column key={index} size={{ xs: 12, lg: 6 }} difference={8}>
                  <Diary
                    deleteState={deleteState}
                    theme={theme}
                    todayWeekday={today.weekday}
                    weekday={currentDate.weekday}
                    slot1={day.schedule.MORNING || []}
                    slot2={day.schedule.EVENING || []}
                    date={date}
                    dateByIndex={dateByIndex}
                    updateSchedules={(updatedSchedules: any) => setSchedules(updatedSchedules)}
                  />
                </Styles.Column>
              );
            })}
          </Styles.Row>
        )}
      </Styles.Row>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </MainWrap>
  );
};
