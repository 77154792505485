import styled from 'styled-components';
import Styles, { Typography } from '../../../../../../../../styles';
import colors from '../../../../../../../../constants';
import { Button } from '@mui/material';
import { motion } from 'framer-motion';

const wrapperStart = {
  hidden: { opacity: 0, },
  visible: { opacity: 1, transition: { duration: 0.6, ease: "circOut" } },
};

const firstInputs = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'circOut' } },
};

const secondInputs = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'circOut' } },
};

export const RatingFormWrap = styled(motion.div).attrs(() => ({
  variants: wrapperStart,
  initial: 'hidden',
  animate: 'visible',
}))`
  && {
    width: 100%;
  }
`;

export const FirstRows = styled(motion(Styles.Row)).attrs(() => ({
  variants: firstInputs,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const SecondRows = styled(motion(Styles.Row)).attrs(() => ({
  variants: secondInputs,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const ErrorTypography = styled(Typography.paragraph)`
  && {
    text-align: center;
  }
`;

export const StepsWrap = styled.div`
  && {
    width: 100%;
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
`;

export const IconWrapper = styled(Styles.Row)<{ index: number }>`
  && {
    background-color: ${(props) =>
      props.index === 1 ? colors['primary-500'] : colors['secondary-200']};
    border-radius: 6px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 192px;
    gap: 8px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const SecondaryButton = styled(Button)`
  && {
    width: 192px;
    gap: 8px;
    background-color: ${colors['opacity-primary40']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;
