import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const Upload: FC<IIconProps> = ({ className, width = 24, height = 24, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 29 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.14478 19.8334V22.1667C5.14478 23.4554 6.18944 24.5 7.47811 24.5H21.4781C22.7668 24.5 23.8114 23.4554 23.8114 22.1667V19.8334'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.14478 19.8334V22.1667C5.14478 23.4554 6.18944 24.5 7.47811 24.5H21.4781C22.7668 24.5 23.8114 23.4554 23.8114 22.1667V19.8334'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.64478 10.5L14.4781 4.66663L20.3114 10.5'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.64478 10.5L14.4781 4.66663L20.3114 10.5'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.4781 4.66663V18.6666'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.4781 4.66663V18.6666'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
