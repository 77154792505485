import styled from 'styled-components';
import Styles from '../../../styles';

export const AddPlanWrap = styled.div`
  width: 100%;
`;

export const AddPlanBody = styled(Styles.Row)`
  && {
    justify-content: center;
    align-content: center;
    height: 300px;
  }
`;
