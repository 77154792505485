import { QUARTER } from '../URLs';
import { api } from './api';

export const quarterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllQuarters: builder.query<any, void>({
      query: () => ({
        url: QUARTER,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: ['getAllQuarters'],
    }),
  }),
});

export const { useGetAllQuartersQuery } = quarterApi;
