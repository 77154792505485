import { Button, Snackbar, TextField } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../../../constants';
import { PatternFormat } from 'react-number-format';
import { motion } from 'framer-motion';
import Styles from '../../../../../styles';

const wrapperStart = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.6, ease: 'circOut' } },
};

const wrapperTitle = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'circOut' } },
};

const wrapperPhone = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.7, ease: 'circOut' } },
};

const wrapperPassword = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'circOut' } },
};

const wrapperBtn = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.9, ease: 'circOut' } },
};

const wrapperLink = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'circOut' } },
};

const wrapperHover = {
  initial: { scale: 1 },
  hover: { scale: 1.05 },
};

export const LoginFormWrap = styled(motion.div).attrs(() => ({
  variants: wrapperStart,
  initial: 'hidden',
  animate: 'visible',
}))`
  width: 400px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RowTitleWrapper = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperTitle,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const RowPhoneWrapper = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperPhone,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const RowPasswordWrapper = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperPassword,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const BtnWrapper = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperBtn,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const LinkWrapper = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperLink,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const InputWrapper = styled(motion(Styles.Column)).attrs(() => ({
  variants: wrapperTitle,
  initial: 'initial',
  whileHover: 'hover',
}))``;

export const PrimaryButton = styled(Button)`
  && {
    background-color: ${colors['primary-500']};
    display: flex;
    gap: 10px;
  }
`;
