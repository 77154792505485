import styled from 'styled-components';
import Styles from '../../../../styles';

export const ModeratorWaitingWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  @media (max-width: 768px) {
    padding: 18px;
  }
`;

export const TitleWrap = styled(Styles.Row)`
  && {
    width: 570px;
    @media (max-width: 768px) {
      width: 300px;
    }
  }
`;

export const ContentWrap = styled(Styles.Row)`
  && {
    height: 80vh;
  }
`;

