import styled from 'styled-components';
import Styles from '../../../../styles';

export const RegisterMultistepsWrap = styled.div`
  && {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: nowrap;
  }
`;

export const MultistepsContentWrap = styled(Styles.Row)`
  && {
    min-height: 100vh;
    @media (max-width: 992px) {
      justify-content: center;
    }
  }
`;

export const Img = styled.img`
  && {
    object-fit: contain;
    width: 80%;
    &:hover {
      width: 85%;
    }
    @media (max-width: 1200px) {
      width: 70%;
      &:hover {
        width: 75%;
      }
    }
  }
`;
