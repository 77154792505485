import { Box } from '@mui/material';
import styled from 'styled-components';
import { Typography } from '../../styles';


export const StyledBox = styled(Box)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: transparent;
    padding: 30px;
    min-width: 30%;
    border-radius: 4px;
  }
`;

