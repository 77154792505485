import { TOPIC } from '../URLs';
import { api } from './api';

export const topicsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllTopic: builder.query<any, void>({
      query: () => ({
        url: TOPIC,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: ['getAllTopic'],
    }),
    getTopicByParams: builder.query<any, { classNum: number; science: number; quarter: number }>({
      query: ({ classNum, science, quarter }) => ({
        url: `${TOPIC}?_class=${classNum}&science=${science}&quarter=${quarter}`,
        method: 'GET',
      }),
      providesTags: ['getTopicByParams'],
    }),
    getTopicCalculationByParams: builder.query<
      any,
      {
        science: number | null;
        classes: number | null;
        week: number | null;
        schedule: number | null;
      }
    >({
      query: ({ science, classes, week, schedule }) => ({
        url: `algorithm/?science=${science}&classes=${classes}&week=${week}&schedule_id=${schedule}`,
        method: 'GET',
      }),
      providesTags: ['getTopicCalculationByParams'],
    }),
  }),
});

export const {
  useGetAllTopicQuery,
  useGetTopicByParamsQuery,
  useGetTopicCalculationByParamsQuery,
} = topicsApi;
