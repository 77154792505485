/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Styles, { GlobalStyles, Typography } from '../../../../../styles';
import {
  FirstFormWrap,
  PrimaryButton,
  SecondaryButton,
  StyledMenu,
  StyledSelect,
} from './first-form.s';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  useGetModeratorClassGrpQuery,
  useGetModeratorClassQuery,
  useGetModeratorLangQuery,
  useGetModeratorQuarterQuery,
  useGetModeratorScienceQuery,
  useGetModeratorScienceTypeQuery,
} from '../../../../../store/services/moderatorPlansApi';
import { useAddPlansMutation } from '../../../../../store/services/plansApi';

interface IFirstFormProps {
  theme: string;
}

interface IFormData {
  quarter: string;
  classes: string;
  science: string;
  classesGrp: string;
  scienceTypes: string;
  lang: string;
}

export const FirstForm: FC<IFirstFormProps> = ({ theme }) => {
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const { data: quarterData } = useGetModeratorQuarterQuery(i18n.language);
  const { data: langData } = useGetModeratorLangQuery(i18n.language);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { data: classData } = useGetModeratorClassQuery(i18n.language);
  const { data: classGrpData } = useGetModeratorClassGrpQuery(i18n.language);
  const { data: scienceData } = useGetModeratorScienceQuery(i18n.language);
  const { data: scienceTypeData } = useGetModeratorScienceTypeQuery(i18n.language);
  const [addPlan, { isLoading }] = useAddPlansMutation();
  // const russianOnlyRegex = /^[а-яА-Я0-9\s]*$/;

  const initialValues: IFormData = {
    lang: '',
    quarter: '',
    classes: '',
    classesGrp: '',
    science: '',
    scienceTypes: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      lang: Yup.string().required('plans.viewAddItem.body.errors.global'),
      quarter: Yup.string().required('plans.viewAddItem.body.errors.global'),
      classes: Yup.string().required('plans.viewAddItem.body.errors.global'),
      classesGrp: Yup.string().required('plans.viewAddItem.body.errors.global'),
      science: Yup.string().required('plans.viewAddItem.body.errors.global'),
      scienceTypes: Yup.string().required('plans.viewAddItem.body.errors.global'),
    }),
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append('language', values.lang);
        formData.append('quarter', values.quarter);
        formData.append('classes', values.classes);
        formData.append('class_group', values.classesGrp);
        formData.append('science', values.science);
        formData.append('science_types', values.scienceTypes);

        await addPlan(formData).unwrap();

        setSnackbarMessage('mainPage.snackbar.sampleAdded');
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate('/plans');
        }, 2000);
      } catch (error: any) {
        console.log('error', error);
        setSnackbarMessage('mainPage.snackbar.sampleErr');
        setSnackbarOpen(true);
      }
    },
    validateOnChange: true,
  });

  return (
    <FirstFormWrap onSubmit={formik.handleSubmit}>
      <Styles.Row gap={16}>
        <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
          <Styles.Column size={6} difference={0} gap={4}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall
                color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                as={'label'}
                htmlFor='quarter value'>
                {t('plans.viewAddItem.body.input.lang')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }} direction={'column'}>
              <StyledSelect
                displayEmpty
                disabled={langData?.length === 0}
                renderValue={(value) => {
                  const selectedLang = langData?.find((lang: any) => lang.language === value);
                  return selectedLang ? (
                    selectedLang.language
                  ) : (
                    <>{t('auth.userReg.inputs.placeholder')}</>
                  );
                }}
                id='lang value'
                name='lang'
                value={formik.values.lang}
                onChange={(e) => formik.setFieldValue('lang', e.target.value)}
                error={Boolean(formik.touched.lang) && Boolean(formik.errors.lang)}
                MenuProps={{
                  disableScrollLock: true,
                  sx: {
                    height: '280px',
                    '& .MuiPaper-root': {
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#bdbdbd transparent',
                    },
                  },
                }}>
                {langData?.map((lang: { language: any; id: number }) => (
                  <StyledMenu key={lang?.id} value={lang?.language}>
                    {lang?.language}
                  </StyledMenu>
                ))}
              </StyledSelect>
              {langData?.length === 0 && (
                <Typography.paragraph color='danger-500'>
                  {t('plans.viewAddItem.body.errors.empty')}
                </Typography.paragraph>
              )}
              {formik.touched.lang && formik.errors.lang && (
                <Typography.paragraph color='danger-500'>
                  {t(formik.errors.lang)}
                </Typography.paragraph>
              )}
            </Styles.Row>
          </Styles.Column>

          <Styles.Column size={6} difference={0} gap={4}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('plans.viewAddItem.body.input.quater')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <StyledSelect
                displayEmpty
                disabled={quarterData?.length === 0}
                renderValue={(value) => {
                  const selectedQuarter = quarterData?.find((quarter: any) => quarter.id === value);
                  return selectedQuarter ? (
                    selectedQuarter.choices
                  ) : (
                    <>{t('auth.userReg.inputs.placeholder')}</>
                  );
                }}
                name='quarter'
                value={formik.values.quarter}
                onChange={(e) => formik.setFieldValue('quarter', e.target.value)}
                MenuProps={{
                  disableScrollLock: true,
                  sx: {
                    height: '280px',
                    '& .MuiPaper-root': {
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#bdbdbd transparent',
                    },
                  },
                }}>
                {quarterData?.map((quarter: { choices: number; id: number }) => (
                  <StyledMenu key={quarter?.id} value={quarter?.id}>
                    {quarter?.choices}
                  </StyledMenu>
                ))}
              </StyledSelect>
            </Styles.Row>
            {quarterData?.length === 0 && (
              <Typography.paragraph color='danger-500'>
                {t('plans.viewAddItem.body.errors.empty')}
              </Typography.paragraph>
            )}
            {formik.touched.quarter && formik.errors.quarter && (
              <Typography.paragraph color='danger-500'>
                {t(formik.errors.quarter)}
              </Typography.paragraph>
            )}
          </Styles.Column>
        </Styles.Row>

        <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
          <Styles.Column size={6} difference={0} gap={4}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('plans.viewAddItem.body.input.grade')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <StyledSelect
                displayEmpty
                disabled={classData?.length === 0}
                renderValue={(value) => {
                  const selectedClass = classData?.find((c: any) => c.id === value);
                  return selectedClass ? (
                    selectedClass.name
                  ) : (
                    <>{t('auth.userReg.inputs.placeholder')}</>
                  );
                }}
                name='classes'
                value={formik.values.classes}
                onChange={(e) => formik.setFieldValue('classes', e.target.value)}
                MenuProps={{
                  disableScrollLock: true,
                  sx: {
                    height: '280px',
                    '& .MuiPaper-root': {
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#bdbdbd transparent',
                    },
                  },
                }}>
                {classData?.map((classes: { name: string; id: number }) => (
                  <StyledMenu key={classes?.id} value={classes?.id}>
                    {classes?.name}
                  </StyledMenu>
                ))}
              </StyledSelect>
            </Styles.Row>
            {classData?.length === 0 && (
              <Typography.paragraph color='danger-500'>
                {t('plans.viewAddItem.body.errors.empty')}
              </Typography.paragraph>
            )}
            {formik.touched.classes && formik.errors.classes && (
              <Typography.paragraph color='danger-500'>
                {t(formik.errors.classes)}
              </Typography.paragraph>
            )}
          </Styles.Column>

          <Styles.Column size={6} difference={0} gap={4}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('plans.viewAddItem.body.input.gradeType')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <StyledSelect
                displayEmpty
                disabled={classGrpData?.length === 0}
                renderValue={(value) => {
                  const selectedClassGrp = classGrpData?.find((cg: any) => cg.id === value);
                  return selectedClassGrp ? (
                    selectedClassGrp.name
                  ) : (
                    <>{t('auth.userReg.inputs.placeholder')}</>
                  );
                }}
                name='classesGrp'
                value={formik.values.classesGrp}
                onChange={(e) => formik.setFieldValue('classesGrp', e.target.value)}
                MenuProps={{
                  disableScrollLock: true,
                  sx: {
                    height: '280px',
                    '& .MuiPaper-root': {
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#bdbdbd transparent',
                    },
                  },
                }}>
                {classGrpData?.map((classesGrp: { name: string; id: number }) => (
                  <StyledMenu key={classesGrp?.id} value={classesGrp?.id}>
                    {classesGrp?.name}
                  </StyledMenu>
                ))}
              </StyledSelect>
            </Styles.Row>
            {classGrpData?.length === 0 && (
              <Typography.paragraph color='danger-500'>
                {t('plans.viewAddItem.body.errors.empty')}
              </Typography.paragraph>
            )}
            {formik.touched.classesGrp && formik.errors.classesGrp && (
              <Typography.paragraph color='danger-500'>
                {t(formik.errors.classesGrp)}
              </Typography.paragraph>
            )}
          </Styles.Column>
        </Styles.Row>

        <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
          <Styles.Column size={6} difference={0} gap={4}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('plans.viewAddItem.body.input.lesson')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <StyledSelect
                displayEmpty
                disabled={scienceData?.length === 0}
                renderValue={(value) => {
                  const selectedScience = scienceData?.find((s: any) => s.id === value);
                  return selectedScience ? (
                    selectedScience.name
                  ) : (
                    <>{t('auth.userReg.inputs.placeholder')}</>
                  );
                }}
                name='science'
                value={formik.values.science}
                onChange={(e) => formik.setFieldValue('science', e.target.value)}
                MenuProps={{
                  disableScrollLock: true,
                  sx: {
                    height: '280px',
                    '& .MuiPaper-root': {
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#bdbdbd transparent',
                    },
                  },
                }}>
                {scienceData?.map((science: { name: string; id: number }) => (
                  <StyledMenu key={science?.id} value={science?.id}>
                    {science?.name}
                  </StyledMenu>
                ))}
              </StyledSelect>
            </Styles.Row>
            {scienceData?.length === 0 && (
              <Typography.paragraph color='danger-500'>
                {t('plans.viewAddItem.body.errors.empty')}
              </Typography.paragraph>
            )}
            {formik.touched.science && formik.errors.science && (
              <Typography.paragraph color='danger-500'>
                {t(formik.errors.science)}
              </Typography.paragraph>
            )}
          </Styles.Column>
          <Styles.Column size={6} difference={0} gap={4}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('plans.viewAddItem.body.input.lessonType')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <StyledSelect
                displayEmpty
                renderValue={(value) => {
                  const selectedScienceType = scienceTypeData?.find((st: any) => st.id === value);
                  return selectedScienceType ? (
                    selectedScienceType.name
                  ) : (
                    <>{t('auth.userReg.inputs.placeholder')}</>
                  );
                }}
                name='scienceTypes'
                disabled={scienceTypeData?.length === 0}
                value={formik.values.scienceTypes}
                onChange={(e) => formik.setFieldValue('scienceTypes', e.target.value)}
                MenuProps={{
                  disableScrollLock: true,
                  sx: {
                    height: '280px',
                    '& .MuiPaper-root': {
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#bdbdbd transparent',
                    },
                  },
                }}>
                {scienceTypeData?.map((scienceType: { name: string; id: number }) => (
                  <StyledMenu key={scienceType?.id} value={scienceType?.id}>
                    {scienceType?.name}
                  </StyledMenu>
                ))}
              </StyledSelect>
            </Styles.Row>
            {scienceTypeData?.length === 0 && (
              <Typography.paragraph color='danger-500'>
                {t('plans.viewAddItem.body.errors.empty')}
              </Typography.paragraph>
            )}
            {formik.touched.scienceTypes && formik.errors.scienceTypes && (
              <Typography.paragraph color='danger-500'>
                {t(formik.errors.scienceTypes)}
              </Typography.paragraph>
            )}
          </Styles.Column>
        </Styles.Row>
        <Styles.Row gap={16} content={'space-between'} wrap={'nowrap'}>
          <SecondaryButton
            variant='contained'
            onClick={() => navigate('/plans')}
            disabled={isLoading}>
            <Typography.h6 color='secondary-500'>
              {t('plans.viewAddItem.body.button.previous')}
            </Typography.h6>
          </SecondaryButton>

          <PrimaryButton type='submit' variant='contained' disabled={isLoading}>
            <Typography.h6 color='white'>{t('plans.viewAddItem.body.button.next')}</Typography.h6>
          </PrimaryButton>
        </Styles.Row>
        <Styles.Row content={'flex-end'} gap={16}></Styles.Row>
      </Styles.Row>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </FirstFormWrap>
  );
};
