import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const SmartHome: FC<IIconProps> = ({
  className,
  width = 25,
  height = 24,
  color = colors.white,
  ...props
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19.9563 8.71001L14.6233 4.56201C13.6605 3.81299 12.3121 3.81299 11.3493 4.56201L6.01533 8.71001C5.36573 9.21518 4.98594 9.99211 4.98633 10.815V18.015C4.98633 19.1196 5.88176 20.015 6.98633 20.015H18.9863C20.0909 20.015 20.9863 19.1196 20.9863 18.015V10.815C20.9863 9.99201 20.6063 9.21501 19.9563 8.71001'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.9565 14.9999C14.7465 16.3329 11.1645 16.3329 8.95654 14.9999'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
