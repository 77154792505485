import styled from 'styled-components';
import { Button, Select, Table, TableContainer } from '@mui/material';
import Styles from '../../../../styles';
import colors from '../../../../constants';

export const ResourcesWrap = styled.div`
  width: 100%;
`;

export const ButtonsContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
    @media(max-width: 576px){
      padding: 0px;
    }
  }
`;

export const SelectsWrapper = styled(Styles.Row)`
  && {
    @media(max-width: 576px){
      padding: 5px;
    }
  }
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const NewResourcesContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
    
  }
`;

export const HeaderBtnWrapper = styled(Styles.Row)`
  && {
    padding: 5px;
    
  }
`;

export const PrimaryButton = styled(Button)<{ buttonState: boolean }>`
  && {
    border-radius: 6px;
    border-color: ${props => props.buttonState  ? colors['primary-400'] : colors['secondary-400']};
    text-transform: none;
    color: ${props => props.buttonState  ? colors['primary-400'] : colors['secondary-400']};
  }
  &&:hover {
    color: ${colors['primary-400']};
  }
`;

export const SecondaryButton = styled(Button)<{ buttonState: boolean }>`
  && {
    border-radius: 6px;
    border-color: ${props => props.buttonState  ? colors['secondary-400'] : colors['primary-400']};
    text-transform: none;
    color: ${props => props.buttonState  ? colors['secondary-400'] : colors['primary-400']};

  }
  &&:hover {
    color: ${colors['primary-400']};
  }
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors['grey-300']};
  }
`;

export const StyledSelect = styled(Select)`
  && {
    @media(max-width: 576px){
      width: 80px;
    }
    .MuiSelect-select {
      border: none
    }
  }
`;