import { FC } from 'react';
import { Img, UserVerificationWrap } from './user-verification.s';
import Styles from '../../../../styles';
import AuthComp from '../../components';
import { UserVerificationForm } from './components';

interface IUserVerificationProps {}

export const UserVerification: FC<IUserVerificationProps> = (props) => {
  return (
    <UserVerificationWrap>
      <AuthComp.LogInLeftContent>
        <Img src='/images/auth-illustration/confirmPageImage.png' alt='two' />
      </AuthComp.LogInLeftContent>
      <Styles.Row content={'center'}>
        <UserVerificationForm />
      </Styles.Row>
    </UserVerificationWrap>
  );
};
