import { CLASS, CLASS_GROUP, CLASS_TYPE } from '../URLs';
import { api } from './api';

export const classApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllClass: builder.query<any, void>({
      query: () => ({
        url: CLASS,
        method: 'GET',
      }),
      providesTags: ['getAllClass'],
    }),
    getClassGroup: builder.query<any, string>({
      query: (lang) => ({
        url: CLASS_GROUP,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getClassGroup'],
    }),
    getAllClassGroup: builder.query<any, string>({
      query: (lang) => ({
        url: CLASS_GROUP,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getAllClassGroup'],
    }),
    getClassType: builder.query<any, string>({
      query: (lang) => ({
        url: CLASS_TYPE,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getClassType'],
    }),
  }),
});

export const { useGetAllClassQuery, useGetClassGroupQuery, useGetClassTypeQuery, useGetAllClassGroupQuery } = classApi;
