/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import {
  FormWrap,
  NavbarFlag,
  StyledButton,
  StyledMenu,
  StyledSelect,
  ThemeFormWrap,
  UserButtons,
  UserFormInput,
} from './theme-form.s';
import Styles, { Typography } from '../../../../../styles';
import { useTranslation } from 'react-i18next';
import { Button, SelectChangeEvent } from '@mui/material';
import Mock from '../../../../../mock';
import colors from '../../../../../constants';
import Icons from '../../../../../assets/icons';
import { useAppTheme } from '../../../../../hooks';

interface IThemeFormProps {
  setFormStatus: (status: string) => void;
}

interface IFormData {
  select1: string;
  select2: string;
}

export const ThemeForm: FC<IThemeFormProps> = ({ setFormStatus }) => {
  const { theme, setTheme } = useAppTheme();
  const [t, i18n] = useTranslation('global');
  const [activeButton, setActiveButton] = useState<number>(
    Number(localStorage.getItem('fontSize')) || 16
  );
  const [formData, setFormData] = useState<IFormData>({
    select1: i18n.language,
    select2: localStorage.getItem('theme') || 'light',
  });

  const handleButtonClick = (itemSize: number) => {
    setActiveButton(itemSize);
  };

  const handleLangChange = (event: SelectChangeEvent<unknown>) => {
    const selectedLanguage = event.target.value as string;
    setFormData({ ...formData, select1: selectedLanguage });
  };

  const handleThemeChange = (event: SelectChangeEvent<unknown>) => {
    const selectedTheme = event.target.value as string;
    setFormData({ ...formData, select2: selectedTheme });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    i18n.changeLanguage(formData.select1);
    localStorage.setItem('lang', formData.select1);
    localStorage.setItem('theme', formData.select2);
    window.dispatchEvent(new Event('storage'));
    localStorage.setItem('fontSize', activeButton.toString());
    document.documentElement.style.setProperty('--font-size', `${activeButton}px`);
  };

  const handleReset = () => {
    setFormData({
      select1: i18n.language,
      select2: localStorage.getItem('theme') || 'light',
    });
    setActiveButton(Number(localStorage.getItem('fontSize')) || 16);
  };

  useEffect(() => {
    setFormData({ ...formData, select1: i18n.language });
  }, [i18n.language]);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedTheme = localStorage.getItem('theme');
      if (updatedTheme) {
        setTheme(updatedTheme);
        setFormData({ ...formData, select2: updatedTheme });
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <ThemeFormWrap onSubmit={handleSubmit}>
      <Styles.Row>
        <FormWrap gap={16}>
          <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', md: 'row' }}>
            <Styles.Row>
              <UserFormInput>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('settings.inputs.language')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </UserFormInput>
              <UserFormInput>
                <StyledSelect
                  value={formData.select1}
                  onChange={handleLangChange}
                  displayEmpty
                  name='select1'
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '280px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  <StyledMenu value='uz'>
                    {' '}
                    <NavbarFlag src={'/images/flags/uz.png'} alt='flag' />
                    {t('settings.selectData.lang.uz')}
                  </StyledMenu>
                  <StyledMenu value='ru'>
                    {' '}
                    <NavbarFlag src={'/images/flags/ru.png'} alt='flag' />
                    {t('settings.selectData.lang.ru')}
                  </StyledMenu>
                </StyledSelect>
              </UserFormInput>
            </Styles.Row>
            <Styles.Row>
              <UserFormInput>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('settings.inputs.theme')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </UserFormInput>
              <UserFormInput>
                <StyledSelect
                  value={formData.select2}
                  onChange={handleThemeChange}
                  displayEmpty
                  name='select3'
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '280px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  <StyledMenu value='light'>
                    {' '}
                    <Icons.Sun color={colors['grey-500']} /> {t('settings.selectData.theme.light')}
                  </StyledMenu>
                  <StyledMenu value='dark'>
                    {' '}
                    <Icons.MoonStars color={colors['grey-500']} />{' '}
                    {t('settings.selectData.theme.dark')}
                  </StyledMenu>
                </StyledSelect>
              </UserFormInput>
            </Styles.Row>
          </Styles.Row>
          <Styles.Row gap={4}>
            <UserFormInput>
              <Typography.paragraphSmall color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('settings.inputs.size')}
              </Typography.paragraphSmall>
            </UserFormInput>
            <Styles.Row gap={10} wrap={'nowrap'} content={{ xs: 'space-around', md: 'flex-start' }}>
              {Mock.Settings.fontSize.map((item) => (
                <StyledButton
                  onClick={() => handleButtonClick(item.size)}
                  variant='contained'
                  activeButton={activeButton}
                  item={item.size}>
                  <Typography.paragraphSmallSemiBold
                    color={activeButton === item.size ? 'white' : 'typography-muted'}>
                    {item.size}
                  </Typography.paragraphSmallSemiBold>
                </StyledButton>
              ))}
            </Styles.Row>
          </Styles.Row>
        </FormWrap>
        <UserButtons gap={16} wrap={'nowrap'}>
          <Styles.Row width='130px'>
            <Button
              variant='text'
              onClick={handleReset}
              sx={{
                width: '150px',
                padding: '10px',
                backgroundColor: `${colors['opacity-primary16']}`,
              }}>
              <Typography.paragraphSmallSemiBold color='primary-400'>
                {t('settings.buttons.cancel')}
              </Typography.paragraphSmallSemiBold>
            </Button>
          </Styles.Row>
          <Styles.Row width='130px'>
            <Button
              type='submit'
              variant='contained'
              sx={{
                width: '150px',
                padding: '10px',
              }}>
              <Typography.paragraphSmallSemiBold color='white'>
                {t('settings.buttons.save')}
              </Typography.paragraphSmallSemiBold>
            </Button>
          </Styles.Row>
        </UserButtons>
      </Styles.Row>
    </ThemeFormWrap>
  );
};
