import { Table, TableCell, TableContainer } from '@mui/material';
import styled from 'styled-components';
import Styles from '../../../../../styles';
import colors from '../../../../../constants';

export const DownloadHistoryWrap = styled.div`
  width: 100%;
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
    width: 100%;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableCell = styled(TableCell)`
  min-width: 250px;
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors['grey-300']};
    max-width: 100%;
    overflow-x: auto;
  }
`;
export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;
