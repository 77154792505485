/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@mui/material';
import { FormContainer, Grid, Button, StyledMenu } from './addLessonModal.s';
import { DesktopTimePicker, LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  clearSchedule,
  selectSchedule,
} from '../../../../../../../../../../store/slices/scheduleSlice';
import { useGetQuaterQuery } from '../../../../../../../../../../store/services/quatersApi';
import {
  useUpdateScheduleMutation,
  useCreateScheduleMutation,
  useGetSchedulesQuery,
} from '../../../../../../../../../../store/services/userScheduleApi';
import mock from '../../../../../../../../../../mock';
import colors from '../../../../../../../../../../constants';
import CommonList from '../../../../../../../../../../components/common';
import Styles, { Typography } from '../../../../../../../../../../styles';
import { useGetAllScienceQuery } from '../../../../../../../../../../store/services/scienceApi';

interface IAddLessonModal {
  theme: string;
  modalStatus: boolean;
  quarter: number;
  lesson: number;
  setModalStatus: (status: boolean) => void;
}

interface IFormInputValues {
  shift: string;
  subject: number;
  class: number;
  days: string;
  startTime: Dayjs;
  endTime: Dayjs;
  lessonTime: string;
}

const lessons = [
  { id: '1', value: 1 },
  { id: '2', value: 2 },
  { id: '3', value: 3 },
  { id: '4', value: 4 },
  { id: '5', value: 5 },
  { id: '6', value: 6 },
];

export const AddLessonModal: FC<IAddLessonModal> = ({
  lesson,
  quarter,
  modalStatus,
  setModalStatus,
  theme,
}) => {
  const [t, i18n] = useTranslation('global');
  const dispatch = useDispatch();
  const { data: quaterList } = useGetQuaterQuery();
  // const { refetch: refetchSchedules } = useGetSchedulesQuery();
  const ourQuater = quaterList?.results.filter((item: any) => item.choices === quarter);
  const [createSchedule] = useCreateScheduleMutation();
  const [updateSchedule] = useUpdateScheduleMutation();
  const { data: scienceData } = useGetAllScienceQuery(i18n.language);
  const { lesson_time, start_time, end_time, shift, weekday, classes, science } =
    useSelector(selectSchedule);

  const addLessonValidationSchema = Yup.object().shape({
    shift: Yup.string().required(`${t('shift required')}`),
    subject: Yup.number().required(`${t('users.main.addLesson.errors.subject')}`),
    class: Yup.number().required(`${t('class required')}`),
    days: Yup.string().required(`${t('days required')}`),
    lessonTime: Yup.string().required(`${t('lessonTime required')}`),
  });

  const initialValues: IFormInputValues = {
    shift: '',
    subject: 0,
    class: 0,
    days: '',
    startTime: dayjs('06:00', 'HH:mm'),
    endTime: dayjs('07:45', 'HH:mm'),
    lessonTime: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addLessonValidationSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append('science', values.subject.toString());
        formData.append('classes', values.class.toString());
        formData.append('weekday', values.days);
        formData.append('quarter', ourQuater?.[0].id.toString());
        formData.append('start_time', values.startTime.format('HH:mm'));
        formData.append('end_time', values.endTime.format('HH:mm'));
        formData.append('lesson_time', values.lessonTime);
        formData.append('shift', values.shift);
        // if (lesson === 0) {
        //   // const response = await createSchedule(formData);
        //   if (response) {
        //     // await refetchSchedules();
        //   }
        // } else {
        //   const response = await updateSchedule({
        //     schedule_id: lesson,
        //     formData: formData,
        //   });
        //   if (response) {
        //     // await refetchSchedules();
        //   }
        // }
        setModalStatus(false);
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      shift: shift || 'MORNING',
      subject: science || 0,
      class: classes || 0,
      days: weekday || '',
      startTime: start_time ? dayjs(start_time, 'HH:mm') : dayjs('06:00', 'HH:mm'),
      endTime: end_time ? dayjs(end_time, 'HH:mm') : dayjs('07:45', 'HH:mm'),
      lessonTime: lesson_time || '',
    }));
  }, [shift, science, classes, weekday, start_time, end_time, lesson_time]);

  useEffect(() => {
    if (!modalStatus) {
      const timeoutId = setTimeout(() => {
        dispatch(clearSchedule());
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [modalStatus, dispatch]);

  return (
    <CommonList.Modal modalStatus={modalStatus} setModalStatus={setModalStatus}>
      <Styles.Row direction={'column'} gap={20}>
        <Styles.Row direction={'column'} gap={12}>
          <Typography.h3 align='center' color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {shift ? t('mainPage.mainModal.editTitle') : t('mainPage.mainModal.title')}
          </Typography.h3>
          <Typography.paragraph
            align='center'
            color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {shift ? t('mainPage.mainModal.editDescription') : t('mainPage.mainModal.description')}
          </Typography.paragraph>
        </Styles.Row>
        <FormContainer onSubmit={formik.handleSubmit}>
          <Styles.Row direction={'column'} gap={16}>
            {/* Shift Select */}
            <Styles.Row  direction={'column'} align_items={'flex-start'} gap={4}>
              <Typography.labelSm color={theme === 'dark' ? 'grey-300' : 'typography-body'} as={'label'} htmlFor='lesson subject' subColor='danger-500'>
                {t('mainPage.mainModal.inputs.shift')}
                <span>*</span>
              </Typography.labelSm>
              <Select
                id='lesson shift'
                name='shift'
                value={formik.values.shift}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.shift) && Boolean(formik.touched.shift)}
                fullWidth
                MenuProps={{
                  disableScrollLock: true,
                  sx: {
                    height: '200px',
                    width: '250px',
                    '& .MuiPaper-root': {
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#bdbdbd transparent',
                    },
                  },
                }}>
                <StyledMenu value={'MORNING'}>{t('mainPage.mainModal.inputs.firstShift')}</StyledMenu>
                <StyledMenu value={'EVENING'}>{t('mainPage.mainModal.inputs.secondShift')}</StyledMenu>
              </Select>
              {formik.errors.shift && formik.touched.shift ? (
                <Typography.paragraphSmall color='danger-600'>
                  {formik.errors.shift}
                </Typography.paragraphSmall>
              ) : (
                ''
              )}
            </Styles.Row>
            {/* Subject Select */}
            <Styles.Row direction={'column'} align_items={'flex-start'} gap={4}>
              <Typography.labelSm color={theme === 'dark' ? 'grey-300' : 'typography-body'}  as={'label'} htmlFor='lesson subject' subColor='danger-500'>
                {t('mainPage.mainModal.inputs.lesson')}
                <span>*</span>
              </Typography.labelSm>
              <Select
                id='lesson subject'
                name='subject'
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.subject) && Boolean(formik.touched.subject)}
                fullWidth
                MenuProps={{
                  disableScrollLock: true,
                  sx: {
                    height: '200px',
                    width: '250px',
                    '& .MuiPaper-root': {
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#bdbdbd transparent',
                    },
                  },
                }}>
                {scienceData?.results &&
                  scienceData?.results.map((science: any, index: number) => (
                    <StyledMenu key={index} value={science.id}>
                      {science.name}
                    </StyledMenu>
                  ))}
              </Select>
              {formik.errors.subject && formik.touched.subject ? (
                <Typography.paragraphSmall color='danger-600'>
                  {formik.errors.subject}
                </Typography.paragraphSmall>
              ) : (
                ''
              )}
            </Styles.Row>
            {/* Days Select */}
            <Styles.Row direction={'column'} align_items={'flex-start'} gap={4}>
              <Typography.labelSm color={theme === 'dark' ? 'grey-300' : 'typography-body'} as={'label'} htmlFor='lesson days' subColor='danger-500'>
                {t('mainPage.mainModal.inputs.days')}
                <span>*</span>
              </Typography.labelSm>
              <Select
                id='lesson days'
                name='days'
                value={formik.values.days}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.days) && Boolean(formik.touched.days)}
                fullWidth
                MenuProps={{
                  disableScrollLock: true,
                  sx: {
                    height: '200px',
                    width: '250px',
                    '& .MuiPaper-root': {
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#bdbdbd transparent',
                    },
                  },
                }}>
                {mock.days
                  .filter((day) => day.value !== 'Yakshanba')
                  .map((day) => (
                    <StyledMenu key={day.value} value={day.value}>
                      {t(`${day.label}`)}
                    </StyledMenu>
                  ))}
              </Select>
              {formik.errors.days && formik.touched.days ? (
                <Typography.paragraphSmall color='danger-600'>
                  {formik.errors.days}
                </Typography.paragraphSmall>
              ) : (
                ''
              )}
              <Typography.labelSm color={theme === 'dark' ? 'grey-300' : 'typography-body'}>{t('mainPage.mainModal.inputs.daysDesc')}</Typography.labelSm>
            </Styles.Row>
            {/* Time Inputs */}
            <Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Styles.Row direction={'column'} align_items={'flex-start'} gap={10}>
                  <Typography.labelSm
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                    as={'label'}
                    htmlFor='Lesson start time'
                    subColor='danger-500'>
                    {t('mainPage.mainModal.inputs.startTime')}
                    <span>*</span>
                  </Typography.labelSm>
                  <DesktopTimePicker
                    reduceAnimations
                    value={formik.values.startTime}
                    ampm={false}
                    onChange={(newValue) => {
                      formik.setValues({
                        ...formik.values,
                        startTime:
                          newValue instanceof dayjs && newValue.isValid()
                            ? newValue
                            : dayjs('06:00', 'HH:mm'),
                      });
                    }}
                    slotProps={{
                      layout: {
                        sx: {
                          '& .MuiMultiSectionDigitalClockSection-root::after': {
                            height: '0 !important',
                          },
                        },
                      },
                      popper: {
                        sx: {
                          transition: 'none !important',
                        },
                      },
                      desktopTransition: {
                        style: {
                          transition: 'none !important',
                        },
                      },
                    }}
                  />
                </Styles.Row>
                <Styles.Row direction={'column'} align_items={'flex-start'} gap={10}>
                  <Typography.labelSm color={theme === 'dark' ? 'grey-300' : 'typography-body'} as={'label'} htmlFor='Lesson end time' subColor='danger-500'>
                    {t('mainPage.mainModal.inputs.endTime')}
                    <span>*</span>
                  </Typography.labelSm>
                  <DesktopTimePicker
                    reduceAnimations
                    value={formik.values.endTime}
                    ampm={false}
                    onChange={(newValue) => {
                      formik.setValues({
                        ...formik.values,
                        endTime:
                          newValue instanceof dayjs && newValue.isValid()
                            ? newValue
                            : dayjs('07:45', 'HH:mm'),
                      });
                    }}
                    slotProps={{
                      layout: {
                        sx: {
                          '& .MuiMultiSectionDigitalClockSection-root::after': {
                            height: '0 !important',
                          },
                        },
                      },
                      popper: {
                        sx: {
                          transition: 'none !important',
                        },
                      },
                      desktopTransition: {
                        style: {
                          transition: 'none !important',
                        },
                      },
                    }}
                  />
                </Styles.Row>
              </LocalizationProvider>
            </Grid>
            {/* Lesson hours & Class */}
            <Styles.Row wrap={'nowrap'} gap={8}>
              <Styles.Row direction={'column'}>
                <Typography.labelSm color={theme === 'dark' ? 'grey-300' : 'typography-body'} as={'label'} htmlFor='lesson hours' subColor='danger-500'>
                  {t('mainPage.mainModal.inputs.lessonHour')}
                  <span>*</span>
                </Typography.labelSm>
                <Select
                  id='lesson lessonTime'
                  name='lessonTime'
                  value={formik.values.lessonTime}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.lessonTime) && Boolean(formik.touched.lessonTime)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '200px',
                      width: '250px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}
                  fullWidth>
                  {lessons.map((lesson, index) => (
                    <StyledMenu value={lesson.id} key={index}>
                      {lesson.value}
                    </StyledMenu>
                  ))}
                </Select>
              </Styles.Row>
              <Styles.Row direction={'column'}>
                <Typography.labelSm color={theme === 'dark' ? 'grey-300' : 'typography-body'} as={'label'} htmlFor='lesson hours' subColor='danger-500'>
                  {t('mainPage.mainModal.inputs.class')}
                  <span>*</span>
                </Typography.labelSm>
                <Select
                  id='lesson class'
                  name='class'
                  value={formik.values.class}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.class) && Boolean(formik.touched.class)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '200px',
                      width: '250px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}
                  fullWidth>
                  {mock.classes.map((classItem) => (
                    <StyledMenu key={classItem.value} value={classItem.value}>
                      {t(`${classItem.label}`)}
                    </StyledMenu>
                  ))}
                </Select>
              </Styles.Row>
            </Styles.Row>
            <Styles.Row width='auto' style={{ marginBottom: '20px' }} content={'flex-end'} gap={10}>
              <Button
                color='primary-500'
                bg={colors['opacity-primary40']}
                type='button'
                onClick={() => setModalStatus(false)}>
                {t('mainPage.mainModal.inputs.btnCancel')}
              </Button>
              <Button type='submit' color='extra-white' bg={colors['primary-500']}>
                {t('mainPage.mainModal.inputs.btnSave')}
              </Button>
            </Styles.Row>
          </Styles.Row>
        </FormContainer>
      </Styles.Row>
    </CommonList.Modal>
  );
};
