import { CHANGE_ROLE } from '../URLs';
import { api } from './api';

export const changeRoleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changeRole: builder.mutation<any, FormData>({
      query: (body) => ({
        url: CHANGE_ROLE,
        method: 'POST',
        body
      }),
      invalidatesTags: ['changeRole'],
    }),
  }),
});

export const { useChangeRoleMutation } = changeRoleApi;
