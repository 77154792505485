/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../../styles';
import { Day } from '../../../../../../interfaces/lesson';
import { DiaryWrap, Bottom } from './diary.s';
import { Header, TableContent } from './components';


interface IDiary {
  theme: string;
  day: any[] | null;
  weekId: number;
  date: any;
  quarter: number;
  slot1: any[];
  slot2: any[];
  weekday: number;
  todayWeekday: number;
  dateByIndex: string;
}

export const Diary: FC<IDiary> = ({ theme, day, date, slot1, slot2, quarter, weekday, todayWeekday, dateByIndex, weekId }) => {
  const { t } = useTranslation('global');
  const [slot, setSlot] = useState<1 | 2>(1);

  const daysOfWeek = [
    'mainPage.days.monday',
    'mainPage.days.tuesday',
    'mainPage.days.wednesday',
    'mainPage.days.thursday',
    'mainPage.days.friday',
    'mainPage.days.saturday',
  ];

  const currentDayOfWeek = daysOfWeek[weekday - 1];

  return (
    <DiaryWrap active={weekday === todayWeekday} direction={'column'}>
      <Header theme={theme} active={weekday === todayWeekday} dayOfWeek={currentDayOfWeek} day={day} date={date} slot={slot} setSlot={setSlot} />

      <TableContent weekId={weekId} theme={theme} dateByIndex={dateByIndex} day={day} quarter={quarter} slot={slot} lessons={slot === 1 ? slot1 : slot2} />

      <Bottom theme={theme}>
        <Typography.paragraphSmall color={theme === 'dark' ? 'grey-400' : 'typography-muted'}>
          {t('mainPage.mainTable.header.total')}: {slot === 1 ? slot1.length : slot2.length}{' '}
          {t('mainPage.mainTable.header.time')}
        </Typography.paragraphSmall>
      </Bottom>
    </DiaryWrap>
  );
};
