import { FC, useState } from 'react';
import {
  AddPaymentWrap,
  AddPlanBody,
  StepContentWrap,
  StepsTextWrap,
  StepsWrap,
} from './add-payment.s';
import Styles, { Typography } from '../../../styles';
import CommonList from '../../../components/common';
import mock from '../../../mock';
import Icons from '../../../assets/icons';
import { FirstForm, SecondForm } from './components';
import { useTranslation } from 'react-i18next';

interface IAddPaymentProps {}

export const AddPayment: FC<IAddPaymentProps> = (props) => {
  const [planStep, setPlanStep] = useState(true);
  const [t] = useTranslation('global');

  return (
    <AddPaymentWrap>
      <Styles.Row gap={26}>
        <Styles.Row>
          <Typography.h4 color='typography-body'>
            {t('plans.payment.addPayment.header')}
          </Typography.h4>
        </Styles.Row>
        <Styles.Row>
          <CommonList.Container>
            <StepsWrap>
              <Styles.Row gap={16} wrap={'nowrap'}>
                {mock.Steps.newPayment.map((plan, index) => (
                  <Styles.Row gap={16} width='200' wrap={'nowrap'}>
                    <Styles.Row width='200' gap={8}>
                      <StepContentWrap
                        planStep={planStep}
                        index={index}
                        content={'center'}
                        align_items={'center'}
                      >
                        <Typography.h6 color='white'>{plan.content}</Typography.h6>
                      </StepContentWrap>
                      <StepsTextWrap>
                        <Typography.h6 color='typography-body'>{t(plan.header)}</Typography.h6>
                        <Typography.paragraphSmall color='typography-muted'>
                          {t(plan.title)}
                        </Typography.paragraphSmall>
                      </StepsTextWrap>
                    </Styles.Row>
                    {plan.chevron && (
                      <Styles.Row width='30' align_items={'center'}>
                        <Icons.ChevronRight />
                      </Styles.Row>
                    )}
                  </Styles.Row>
                ))}
              </Styles.Row>
            </StepsWrap>
            <AddPlanBody gap={26}>
              <Styles.Row gap={6} direction={'column'}>
                <Typography.h5 color='typography-body'>
                  {planStep
                    ? t('plans.payment.addPayment.body.first.info')
                    : t('plans.payment.addPayment.body.second.info')}
                </Typography.h5>
                <Typography.paragraph color='typography-muted'>
                  {planStep
                    ? t('plans.payment.addPayment.body.first.title')
                    : t('plans.payment.addPayment.body.second.title')}
                </Typography.paragraph>
              </Styles.Row>
              {planStep ? (
                <FirstForm planStep={planStep} setPlanStep={setPlanStep} />
              ) : (
                <SecondForm planStep={planStep} setPlanStep={setPlanStep} />
              )}
            </AddPlanBody>
          </CommonList.Container>
        </Styles.Row>
      </Styles.Row>
    </AddPaymentWrap>
  );
};
