import styled from "styled-components";
import { Menu } from "@mui/material";
import Styles, { Typography } from "../../../../styles";

export const ProfileContainer = styled(Styles.Row)`
  align-items: center;
  gap: 12px;
  position: relative;
  cursor: pointer;
`;

export const NavbarAva = styled.img`
  width: 38px;
  height: 38px;
  display: block;
  border-radius: 100px;
  background-color: #629bf8;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ImageWrapper = styled(Styles.Row)`
  height: 40px;
  max-width: 40px;
`;

export const StyledMenu = styled(Menu)`
  && {
    .MuiPaper-root {
      width: 200px;
    }
  }
`;

export const UserFullName = styled(Typography.paragraphSmallBold)`
  && {
    clear: both;
    overflow: hidden;
    white-space: nowrap;
  }
`