import { NOTIFICATION } from '../URLs';
import { api } from './api';

export const NotificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    notification: builder.query<any, number>({
      query: (count) => {
        return {
          url: `${NOTIFICATION}?page=1&page_size=${count}`,
          method: 'GET',
        };
      },
      providesTags: ['getAllNotification'],
    }),
    notificationReaded: builder.mutation<any, {id: number, body: FormData}>({
      query: ({id, body}) => {
        return {
          url: `${NOTIFICATION}${id}/`,
          method: 'PATCH',
          body
        };
      },
      invalidatesTags: ['getAllNotification'],
    }),
  }),
});

export const { useNotificationQuery, useNotificationReadedMutation } = NotificationApi;
