import { useSelector } from 'react-redux';
import { authRoute, moderatorRoute, userRoute } from '../routes';
import { RootState } from '../store/store';
import { Roles } from '../types/roles';

export const useRoutes = () => {
  const role = useSelector((state: RootState) => state.auth.role);

  switch (role) {
    case Roles.AUTH:
      return { role: Roles.AUTH, routes: authRoute };
    case Roles.USER:
      return { role: Roles.USER, routes: userRoute };
    case Roles.MODERATOR:
      return { role: Roles.MODERATOR, routes: moderatorRoute };
    default:
      return { role: Roles.AUTH, routes: authRoute };
  }
};
