export const getToken = () => {
  const token = localStorage.getItem("accessToken");
  const result = token ? localStorage.getItem("accessToken") : null;
  return result;
};

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem("accessToken", accessToken);
};

export const setRefToken = (refToken: string) => {
  localStorage.setItem("refreshToken", refToken);
};

export const removeToken = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};
