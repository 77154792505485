import { Button, Snackbar } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../../../../constants';
import { PatternFormat } from 'react-number-format';
import Styles, { Typography } from '../../../../../../styles';
import { motion } from 'framer-motion';

const wrapperStart = {
  hidden: { opacity: 0, },
  visible: { opacity: 1, transition: { duration: 0.6, ease: "circOut" } },
};

const wrapperPhone = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "circOut" } },
};

const wrapperBtn = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "circOut" } },
};


export const ResetPasswordFormWrap = styled(motion.div).attrs(() => ({
  variants: wrapperStart,
  initial: 'hidden',
  animate: 'visible',
}))`
  width: 400px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PhoneWrapper = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperPhone,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const BtnWrapper = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperBtn,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const StyledTypographyParagraph = styled(Typography.paragraph)`
  && {
    text-align: left;
    @media (max-width: 576px) {
      text-align: center;
    }
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 192px;
    padding: 6px 13px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const SecondaryButton = styled(Button)`
  && {
    width: 192px;
    background-color: ${colors['opacity-primary40']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const StyledPhoneFormat = styled(PatternFormat)<{
  errorColor?: boolean;
}>`
  padding: 10px 14px;
  width: 192px;
  color: ${colors['typography-muted']};
  border: 1px solid
    ${(props) => (props.errorColor ? colors['danger-600'] : colors['extra-divider'])};
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  &:focus {
    color: black;
    border-color: ${colors['opacity-primary']};
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
