import { WEEKS } from '../URLs';
import { api } from './api';

export const weekApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWeeks: builder.query<any, number | null>({
      query: (id) => ({
        url: `${WEEKS}${id}/`,
        method: 'GET',
      }),
      providesTags: ['getWeeks'],
    }),
  }),
});

export const { useGetWeeksQuery } = weekApi;
