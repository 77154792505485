import styled, { css } from "styled-components";
import { Typography } from "../../../styles";

const media = {
  mobile: (...args: Parameters<typeof css>) => css`
    @media (max-width: 768px) {
      ${css(...args)}
    }
  `,
};

export const NavbarWrapper = styled.nav`
  && {
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgba(59, 130, 246, 0.1);
    border-radius: 0px 0px 20px 20px;
    position: sticky;
    top: 0;
    z-index: 10;
    &.scrolled {
      background-color: rgb(180, 209, 255);
      border-radius: 5px;

      ${media.mobile`
      background-color: rgba(59, 130, 246, 0.1);
      border-radius: 0;
    `}
    }
    ${media.mobile`
    position: static;
    z-index: 10;
  `}
  }
`;

export const Img = styled.img`
  && {
    width: 25px;
  }
`

export const NavbarWrap = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 40px 150px;
    position: relative;
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 1000px) {
      padding: 40px 80px;
    }
    &.scrolled {
      padding: 25px 120px;
    }
  }
`;

export const StyledP = styled(Typography.paragraph)`
  text-decoration: none;
  font-weight: 500;
  transition: transform 0.3s ease-in-out;
  transition: font-weight 0.3s ease-in-out;

  &:hover {
    transform: translateY(-0.5px);
    font-weight: 700;
  }
`;

export const BurgerMenuWrap = styled.div`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 40px;
    /* justify-content: space-between; */
    @media (min-width: 769px) {
      display: none;
    }
    @media (max-width: 768px) {
      padding: 25px;
    }
  }
`;

export const BurgerMenu = styled.div<{open:boolean}>`
  && {
    position: fixed;
    top: 0;
    left: 0;
    width: ${({ open }) => (open ? "100%" : "0")};
    height: ${({ open }) => (open ? "100%" : "0")};
    transition: opacity 0.3s ease; /* Анимация только для opacity */
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    opacity: ${({ open }) => (open ? "1" : "0")};
    justify-content: center;
    z-index: 100;
    display: flex; 
  }
`;

export const Menu = styled.div<{open:boolean}>`
  && {
    position: fixed;
    top: 0;
    left: ${({ open }) => (open ? "0" : "-300px")};
    width: 250px;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease, opacity 0.3s ease;
    z-index: 101;
    opacity: ${({ open }) => (open ? "1" : "0")};

    display: flex;
    flex-direction: column;
    padding: 20px;
  }
`;

export const ListWrapper = styled.div`
  && {

  }
`;

export const MenuWrapper = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const BtnWrap = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const ListItemsWrap = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const LogoWrapper = styled.div`
  && {
    text-align: center;
    z-index: 10;
  }
`;

export const FlagWrapper = styled.div`
  && {
    display: flex;
    z-index: 10;
  }
`;

export const StyledUl = styled.ul`
  && {
    display: flex;
    gap: 16px;
  }
`;
