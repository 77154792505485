import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const Check: FC<IIconProps> = ({ className, width = 9, height = 9, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 9 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.875 4.5L3.75 6.375L7.5 2.625'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
