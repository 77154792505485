import { motion } from 'framer-motion';
import styled from 'styled-components';

const wrapperStart = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.7, ease: 'circOut' } },
};

export const LeftContentWrap = styled(motion.div).attrs(() => ({
  variants: wrapperStart,
  initial: 'hidden',
  animate: 'visible',
}))`
  && {
    width: 100%;
    height: 100vh;
    border-radius: 20px;

    display: flex;
    align-items: end;
    justify-content: center;
    @media (max-width: 992px) {
      display: none;
    }
    @media (min-height: 1200px) {
      align-items: center;
    }
  }
`;
