import styled from 'styled-components';
import Styles, { Typography } from '../../../../../../../../styles';
import colors from '../../../../../../../../constants';

export const HeaderWrap = styled(Styles.Row)<{theme: string}>`
  background-color: ${({theme}) => theme === 'dark' ? colors['dark-theme-secondary'] : ''};
  padding: 24px 24px 16px 24px;
`;

export const Button = styled(Typography.buttonSmall)<{ active?: boolean }>`
  padding: 6px 14px;
  border-radius: 6px;

  ${({ active }) =>
    active
      ? `background-color: ${colors['primary-500']}; border: none;`
      : `background: transparent; border: 1px solid ${colors['primary-500']}; color: ${colors['primary-500']};`};
`;
