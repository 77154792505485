export const resourcesMock = [
  {
    id: 1,
    title: 'Lorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    type: 'normativ hujjat',
    author: 'John Doe',
    grade: 8,
    image: '/images/mock/plan-view-mock.png',
    lastUpdate: '2021-10-10',
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 2,
    title: 'Lorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    type: 'normativ hujjat',
    author: 'John Doe',
    grade: 8,
  },
  {
    id: 3,
    title: 'Lorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    type: 'normativ hujjat',
    author: 'John Doe',
    grade: 8,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 4,
    title: 'Consectetur adipiscing elit',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    type: 'sinfdan tashqari',
    author: 'John Doe',
    grade: 9,
    resources: [{ id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' }],
  },
  {
    id: 5,
    title: 'Consectetur adipiscing elit',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    type: 'sinfdan tashqari',
    author: 'John Doe',
    grade: 9,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
      { id: 544934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 6,
    title: 'Sed do eiusmod tempor incididunt',
    description: 'Ut labore et dolore magna aliqua',
    type: 'sinfdan tashqari',
    author: 'John Doe',
    grade: 10,
    resources: [{ id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' }],
  },
  {
    id: 7,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: 'sinfdan tashqari',
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 8,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: 'sinfdan tashqari',
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 9,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: 'Olimpiada uchun materiallar',
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 10,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: 'Olimpiada uchun materiallar',
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 11,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: 'Olimpiada uchun materiallar',
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 12,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: "O'quv-uslubiy komplekslar",
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 13,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: "O'quv-uslubiy komplekslar",
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 14,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: "O'quv-uslubiy komplekslar",
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 15,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: "O'quv-uslubiy komplekslar",
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 16,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: "O'quv-uslubiy komplekslar",
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 17,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: 'sinfdan tashqari',
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 18,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: 'sinfdan tashqari',
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 19,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: 'Malaka oshirish',
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 20,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: 'Malaka oshirish',
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
  {
    id: 21,
    title: 'Ut labore et dolore magna aliqua',
    description: 'Ut labore et dolore magna aliqua',
    type: 'Sinfdan tashqari',
    author: 'John Doe',
    grade: 11,
    resources: [
      { id: 4934, name: 'Darslik', type: 'audio/mp3', size: '3.5 MB' },
      { id: 44934, name: 'Darslik emas', type: 'audio/mp3', size: '3.5 MB' },
    ],
  },
];
