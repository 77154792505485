import { FC } from 'react';
import { Img, MultistepsContentWrap, RegisterMultistepsWrap } from './register-multisteps.s';
import AuthComp from '../../components';
import { RegisterMultistepsForm } from './components';

interface IRegisterMultistepsProps {}

export const RegisterMultisteps: FC<IRegisterMultistepsProps> = (props) => {
  return (
    <RegisterMultistepsWrap>
      <AuthComp.LogInLeftContent>
        <Img src='/images/auth-illustration/loginImage.png' alt='moderator' />
      </AuthComp.LogInLeftContent>
      <MultistepsContentWrap>
        <RegisterMultistepsForm />
      </MultistepsContentWrap>
    </RegisterMultistepsWrap>
  );
};
