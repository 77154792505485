import { Lesson, WeekCurriculum } from '../interfaces/lesson';

const diaryTypes = [
  {value: '1', label: 'mainPage.mainHeader.select.day'},
  {value: '2', label: 'mainPage.mainHeader.select.half'},
  {value: '3', label: 'mainPage.mainHeader.select.week'},
]

const lessons1: Lesson[] = [
  {
    subject: 'Matematika',
    grade: '5-sinf',
    start: '08:00',
    end: '09:00',
  },
  {},
  {
    subject: 'Ona tili',
    grade: '5-sinf',
    start: '09:00',
    end: '10:00',
  },
  {},
  {
    subject: 'Matematika',
    grade: '5-sinf',
    start: '10:00',
    end: '11:00',
  },
  {
    subject: 'Ona tili',
    grade: '5-sinf',
    start: '11:00',
    end: '12:00',
  },
];
const lessons2: Lesson[] = [
  {
    subject: 'Matematika',
    grade: '5-sinf',
    start: '13:00',
    end: '14:00',
  },
  {},
  {},
  {},
  {
    subject: 'Matematika',
    grade: '5-sinf',
    start: '16:00',
    end: '17:00',
  },
  {
    subject: 'Ona tili',
    grade: '5-sinf',
    start: '17:00',
    end: '18:00',
  },
];

const weekCurriculum: WeekCurriculum[] = [
  {
    day: 'monday',
    date: 'Aprel 15, 2021',
    slot1: lessons1,
    slot2: lessons2,
  },
  {
    day: 'tuesday',
    date: 'Aprel 16, 2021',
    slot1: lessons1,
    slot2: lessons2,
  },
  {
    day: 'wednesday',
    date: 'Aprel 17, 2021',
    slot1: lessons1,
    slot2: lessons2,
  },
  {
    day: 'thursday',
    date: 'Aprel 18, 2021',
    slot1: lessons1,
    slot2: lessons2,
  },
  {
    day: 'friday',
    date: 'Aprel 19, 2021',
    slot1: lessons1,
    slot2: lessons2,
  },
  {
    day: 'saturday',
    date: 'Aprel 20, 2021',
    slot1: lessons1,
    slot2: lessons2,
  },
];

export const usersHomeData = {
  diaryTypes,
  weekCurriculum,
};
