import { FC } from 'react';
import { ResetPasswordWrap } from './reset-password.s';
import AuthComp from '../../components';
import Styles, { GlobalStyles } from '../../../../styles';
import { ResetPasswordForm } from './components';

interface IResetPasswordProps {}

export const ResetPassword: FC<IResetPasswordProps> = (props) => {
  return (
    <ResetPasswordWrap>
      <AuthComp.LogInLeftContent>
        <GlobalStyles.Img src='/images/auth-illustration/loginImage.png' alt='login' />
      </AuthComp.LogInLeftContent>
      <Styles.Row content={'center'}>
        <ResetPasswordForm />
      </Styles.Row>
    </ResetPasswordWrap>
  );
};
