import { LeftContent } from './left-content';
import { LeftContentModerator } from './left-content-moderator';
import { LogInLeftContent } from './logIn-left-content';
import { VerificationForm } from './Verification-form/VerificationForm';


const AuthComp = {
  LeftContent,
  LeftContentModerator,
  LogInLeftContent,
  VerificationForm
};

export default AuthComp;
