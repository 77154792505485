import styled from 'styled-components';

export const NewPasswordWrap = styled.div`
  && {
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 20px;
    flex-wrap: nowrap;
    @media (min-width: 768px) {
      padding: 0px;
    }
  }
`;