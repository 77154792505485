import { CREATE_SCHEDULE, GET_SCHEDULE_DAY, SCHEDULE, SCHEDULE_CHOICE } from '../URLs';
import { api } from './api';

export const userScheduleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSchedules: builder.query<any, { quarter: number | null; week: number | null; lang: string }>({
      query: ({ quarter, week, lang }) => ({
        url: `${SCHEDULE_CHOICE}?quarter=${quarter}&week=${week}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
        skipCache: true,
      }),
      providesTags: ['Schedule'],
    }),
    getScheduleByDate: builder.query<any, { date: string; lang: string }>({
      query: ({ date, lang }) => ({
        url: `${SCHEDULE_CHOICE}?date=${date}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
        skipCache: true,
      }),
      providesTags: ['Schedule'],
    }),
    getAllSchedules: builder.query<any, void>({
      query: () => ({
        url: SCHEDULE,
        method: 'GET',
      }),
      providesTags: ['getAllSchedules'],
    }),
    getScheduleById: builder.query<any, number | null>({
      query: (id) => ({
        url: `${SCHEDULE}${id}`,
        method: 'GET',
      }),
      providesTags: ['Schedule'],
    }),
    connectSchedule: builder.mutation<any, { formData: FormData; week: string }>({
      query: ({ formData, week }) => ({
        url: `${SCHEDULE_CHOICE}${week}/`,
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['connectSchedule'],
    }),
    createSchedule: builder.mutation<any, { name: string; schedules: any[] }>({
      query: (data) => ({
        url: CREATE_SCHEDULE,
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: [{ type: 'Schedule', id: 'LIST' }],
    }),
    getSchedule: builder.query<any, number>({
      query: (schedule_id) => ({
        url: SCHEDULE + `${schedule_id}/`,
        method: 'GET',
      }),

      providesTags: (result, error, id) => [{ type: 'Schedule', id }],
    }),
    getScheduleDay: builder.mutation<any, FormData>({
      query: (body) => ({
        url: GET_SCHEDULE_DAY,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) => [{ type: 'Schedule' }],
    }),
    updateSchedule: builder.mutation<any, { schedule_id: number; formData: FormData }>({
      query: ({ schedule_id, formData }) => ({
        url: `${CREATE_SCHEDULE}${schedule_id}/`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: (result, error, { schedule_id }) => [{ type: 'Schedule', id: schedule_id }],
    }),
    deleteSchedule: builder.mutation({
      query: (schedule_id) => ({
        url: SCHEDULE + `${schedule_id}/`,
        method: 'DELETE',
      }),

      invalidatesTags: (result, error, id) => [{ type: 'Schedule', id }],
    }),
  }),
});

export const {
  useGetSchedulesQuery,
  useCreateScheduleMutation,
  useGetScheduleQuery,
  useUpdateScheduleMutation,
  useDeleteScheduleMutation,
  useGetScheduleDayMutation,
  useGetAllSchedulesQuery,
  useConnectScheduleMutation,
  useGetScheduleByIdQuery,
  useGetScheduleByDateQuery,
} = userScheduleApi;
