import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const Pencil: FC<IIconProps> = ({ className, width = 22, height = 22, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.66675 18.3343H7.33341L16.9584 8.70926C17.9709 7.69674 17.9709 6.05512 16.9584 5.04259C15.9459 4.03007 14.3043 4.03007 13.2917 5.04259L3.66675 14.6676V18.3343'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3.66675 18.3343H7.33341L16.9584 8.70926C17.9709 7.69674 17.9709 6.05512 16.9584 5.04259C15.9459 4.03007 14.3043 4.03007 13.2917 5.04259L3.66675 14.6676V18.3343'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.375 5.95898L16.0417 9.62565'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.375 5.95898L16.0417 9.62565'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
