/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import {
  EmptyTitleWrapper,
  PaymnetHistoryWrap,
  ScienceChip,
  StyledTable,
  StyledTableCell,
  StyledTableContainer,
  TableContentWrap,
} from './uploadHistory.s';
import { useTranslation } from 'react-i18next';
import Styles, { Typography } from '../../../../../styles';
import { Pagination, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useAppTheme } from '../../../../../hooks';
import { useUploadMediaHistoryQuery } from '../../../../../store/services/uploadApi';
import { formatBytes } from '../../../../../helpers/formatBytes';
import { formatUpdatedDate } from '../../../../../helpers/formatUpdatedDate';

interface IUploadHistory {}

export const UploadHistory: FC<IUploadHistory> = () => {
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const { theme } = useAppTheme();
  const [t] = useTranslation('global');
  const {
    data: uploadData,
    refetch: uploadHistoryRefetch,
    isLoading,
  } = useUploadMediaHistoryQuery({page: page, limit: rowsPerPage});

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    uploadHistoryRefetch();
  }, []);

  return (
    <PaymnetHistoryWrap>
      {isLoading ? (
        <EmptyTitleWrapper content={'center'} align_items={'center'}>
          <Typography.h4 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
            {t('plans.main.loadingTitle')}
          </Typography.h4>
        </EmptyTitleWrapper>
      ) : (
        <>
          {uploadData?.results?.media_files?.length > 0 ? (
            <Styles.Row>
              <TableContentWrap>
                <Typography.h4 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                  {t('history.uploadTable.countTitle')}: {uploadData.results.total_media_count}
                </Typography.h4>
              </TableContentWrap>
              <TableContentWrap>
                <StyledTableContainer>
                  <StyledTable aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>{t('history.paymentTable.number')}</TableCell>
                        <StyledTableCell align='center'>{t('history.uploadTable.name')}</StyledTableCell>
                        <StyledTableCell align='center'>{t('history.uploadTable.createDate')}</StyledTableCell>
                        <StyledTableCell align='center'>{t('history.uploadTable.size')}</StyledTableCell>
                        <TableCell align='center'>{t('history.uploadTable.total')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uploadData?.results?.media_files?.map((media: any, index: number) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { bmedia: 0 },
                          }}>
                          <TableCell align='center'>{index + 1}</TableCell>
                          <TableCell align='center'>
                            <ScienceChip label={media.name} variant='outlined' color='primary' />
                          </TableCell>
                          <TableCell align='center'>{formatUpdatedDate(media.created_at)}</TableCell>
                          <TableCell align='center'>{formatBytes(media.size)}</TableCell>
                          <TableCell align='center'>{media.count}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              </TableContentWrap>
              <Styles.Row content={'flex-end'}>
                <Pagination
                  count={Math.ceil(uploadData?.count / rowsPerPage)}
                  page={page}
                  onChange={handleChangePage}
                  color='primary'
                  shape='rounded'
                  style={{ marginTop: '20px' }}
                />
              </Styles.Row>
            </Styles.Row>
          ) : (
            <EmptyTitleWrapper content={'center'} align_items={'center'}>
              <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('Yuklanganlar yo`q')}
              </Typography.h4>
            </EmptyTitleWrapper>
          )}
        </>
      )}
    </PaymnetHistoryWrap>
  );
};
