import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lang } from './lang';
import {
  BtnWrap,
  BurgerMenu,
  BurgerMenuWrap,
  FlagWrapper,
  Img,
  ListItemsWrap,
  ListWrapper,
  LogoWrapper,
  Menu,
  MenuWrapper,
  NavbarWrap,
  NavbarWrapper as StyledNavbarWrapper,
  StyledP,
  StyledUl,
} from './authNavbar.s';
import Icons from '../../../assets/icons';
import Styles from '../../../styles';

interface IAuthNavbarProps {
  language: string;
  setLanguage: (lang: string) => void;
}

export const AuthNavBar: FC<IAuthNavbarProps> = ({ language, setLanguage }) => {
  const [scrolled, setScrolled] = useState(false);
  const [t] = useTranslation('global');
  const [menuStatus, setMenuStatus] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const burgerBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside: EventListener = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        burgerBtnRef.current &&
        !burgerBtnRef.current.contains(event.target as Node)
      ) {
        setMenuStatus(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleMenuOpen = () => {
    setMenuStatus(true);
  };

  const handleInsideClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY === 0;
      setScrolled(!isTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StyledNavbarWrapper className={scrolled ? 'scrolled' : ''}>
      <NavbarWrap className={scrolled ? 'scrolled' : ''}>
        <LogoWrapper>
          <Img src='/images/LogoV2.png' />
        </LogoWrapper>
        <ListWrapper>
          <StyledUl>
            <li>
              <a href='https://classcom.uz/'>
                <StyledP>{t('auth.authNavbar.main')}</StyledP>
              </a>
            </li>
          </StyledUl>
        </ListWrapper>
        <FlagWrapper>
          <Lang lang={language} setLanguage={setLanguage} />
        </FlagWrapper>
      </NavbarWrap>
      <BurgerMenuWrap>
        {menuStatus && <div onClick={() => setMenuStatus(false)} />}
        <Img src='/images/LogoV2.png' />
        <Styles.Row content={'flex-end'}>
          <button ref={burgerBtnRef} onClick={handleMenuOpen}>
            <Icons.Menu2 />
          </button>
        </Styles.Row>

        <BurgerMenu open={menuStatus}>
          <Menu ref={menuRef} open={menuStatus} onClick={handleInsideClick}>
            <MenuWrapper>
              <BtnWrap>
                <Lang lang={language} setLanguage={setLanguage} />
                <button onClick={() => setMenuStatus(false)}>
                  <Icons.X />
                </button>
              </BtnWrap>
              <ListItemsWrap>
                <li>
                  <a href='https://classcom.uz/'>
                    <StyledP>{t('auth.authNavbar.main')}</StyledP>
                  </a>
                </li>
              </ListItemsWrap>
            </MenuWrapper>
          </Menu>
        </BurgerMenu>
      </BurgerMenuWrap>
    </StyledNavbarWrapper>
  );
};
