export const plansMainData = [
  {
    id: 4444,
    semester: 'I',
    grade: 9,
    subject: 'Matematika',
    state: 'Active',
    topics: [
      { id: 52, title: 'Lorem ipsum doler psok hit ker', duration: '1 soat' },
      { id: 53, title: 'Lorem ipsum doler psok hit ker', duration: '1 soat' },
      { id: 54, title: 'Lorem ipsum doler psok hit ker', duration: '1 soat' },
      {
        id: 1,
        image: '/images/mock/plan-view-mock.png',
        title: 'Lorem lorem lorem ipsum',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio quam enim fugit ad eaque est, iste, officia dolore sunt molestiae repudiandae in, consequatur commodi tempora similique inventore accusamus porro! Quasi accusamus mollitia, in at magnam quia asperiores aperiam unde facilis consequatur commodi natus velit cupiditate repudiandae ut odit corrupti. Voluptatem vel maiores quae, eveniet accusantium eius provident! Consectetur quos hic aliquid assumenda aliquam porro ipsum tenetur asperiores commodi ducimus quis amet deserunt odio itaque similique, soluta unde voluptate voluptatem quas optio sapiente! Omnis magnam molestias, illo nobis quod nihil, repudiandae in, suscipit doloribus quisquam excepturi. Adipisci saepe veritatis quas debitis.',
        grade: 9,
        subject: 'Mateamtika',
        topic: '2ni 2ga qoshish',
        duration: '1 soat',
        author: 'Oybekjon',
        lastUpdate: '12.12.2021',
        resources: [
          {
            id: 1,
            name: 'Email-LTD-2021-00-00-FileName.mp3',
            type: 'audio/mp3',
            size: '2.8 MB',
          },
          {
            id: 2,
            name: 'NIC Asia Limited.mp4',
            type: 'video/mp4',
            size: '8 MB',
          },
          { id: 3, name: '000021-LAK.mp3', type: 'audio/mp3', size: '4.1 MB' },
          {
            id: 4,
            name: 'Abstergo Ltd.mp3',
            type: 'audio/mp3',
            size: '2.1 MB',
          },
        ],
      },
    ],
  },
  {
    id: 4445,
    semester: 'III',
    grade: 5,
    subject: 'Botanika',
    state: 'Inactive',
  },
  {
    id: 4446,
    semester: 'II',
    grade: 11,
    subject: 'Matematika',
    state: 'Active',
  },
  {
    id: 4447,
    semester: 'II',
    grade: 5,
    subject: 'Matematika',
    state: 'Active',
  },
  { id: 4448, semester: 'IV', grade: 4, subject: 'Adabiyot', state: 'Active' },
  {
    id: 4449,
    semester: 'II',
    grade: 3,
    subject: 'Matematika',
    state: 'Inactive',
  },
  { id: 4450, semester: 'I', grade: 1, subject: 'Matematika', state: 'Active' },
  { id: 4451, semester: 'III', grade: 8, subject: 'Ona tili', state: 'Active' },
  { id: 4452, semester: 'IV', grade: 8, subject: 'Rus Tili', state: 'Active' },
];
