import styled from 'styled-components';
import colors from '../../../constants';

export const RichEditorWrapper = styled.div<{ theme: string }>`
  && {
    background: ${({theme}) => theme === 'dark' ? colors['dark-theme-secondary'] : '#fff'};
    border: 1px solid #ddd;
    font-family: 'Georgia', serif;
    font-size: 14px;
    padding: 15px;
    width: 100%;
  }
`;
