import { FC, useEffect, useRef, useState } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';
import { useAppTheme } from '../../hooks';

export const Loader: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color = colors.black,
  ...props
}) => {
  const { theme } = useAppTheme();
  const defaultColor = theme === 'dark' ? colors['dark-theme-primary'] : colors['grey-200'];
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const paths = [
    'M12.5 6V3',
    'M12.5 6V3',
    'M16.75 7.74998L18.9 5.59998',
    'M16.75 7.74998L18.9 5.59998',
    'M18.5 12H21.5',
    'M18.5 12H21.5',
    'M16.75 16.25L18.9 18.4',
    'M16.75 16.25L18.9 18.4',
    'M12.5 18V21',
    'M12.5 18V21',
    'M8.24998 16.25L6.09998 18.4',
    'M8.24998 16.25L6.09998 18.4',
    'M6.5 12H3.5',
    'M6.5 12H3.5',
    'M8.24998 7.74998L6.09998 5.59998',
    'M8.24998 7.74998L6.09998 5.59998',
  ];

  useEffect(() => {
    const updateIndex = () => {
      setActiveIndex(prevIndex => (prevIndex + 1) % paths.length);
    };

    intervalRef.current = setInterval(updateIndex, 50);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [paths.length]);

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      {paths.map((d, index) => {
        const isActive = (index >= activeIndex && index < activeIndex + 3) || (activeIndex + 3 >= paths.length && index < (activeIndex + 3) % paths.length);
        return (
          <path
            key={index}
            d={d}
            stroke={isActive ? color : defaultColor} // Основной цвет для активных элементов, цвет по умолчанию для остальных
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        );
      })}
    </svg>
  );
};