import styled from 'styled-components';
import Styles from '../../../../styles';
import colors from '../../../../constants';
import { Button, MenuItem, Select, Snackbar, Table, TableContainer } from '@mui/material';

export const AddOrderWrap = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentWrap = styled(Styles.Row)`
  padding: 0px 24px;
`;

export const ButtonContentWrap = styled(Styles.Row)`
  padding: 12px;
`;

export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;

export const ButtonIconWrap = styled(Styles.Row)`
  height: 46px;
  background-color: white;
`;

export const ActiveIconWrap = styled(Styles.Row)`
  height: 14px;
  border-radius: 15px;
  background-color: #3b82f6;
`;

export const SelectContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
  }
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
    width: 100%;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid rgba(67, 73, 104, 1);
    max-width: 100%;
    overflow-x: auto;
  }
`;

export const OrderWrapper = styled(Styles.Row)`
  && {
    position: relative;
    flex-direction: column;
    border-radius: 5px;
    background-color: ${colors['opacity-secondary32']};
    border: 1px solid ${colors['primary-500']};
    padding: 15px;
    gap: 8px;
  }
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const OrderDelete = styled(Button)`
  && {
    position: absolute;
    top: -15px;
    right: -20px;
    padding: 5px;
    min-width: 20px;
    height: 25px;
  }
`;

export const OrderConfirm = styled(Button)`
  && {
    position: absolute;
    background-color: ${colors['primary-500']};
    top: 15px;
    right: -20px;
    padding: 5px;
    min-width: 20px;
    height: 25px;
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 100%;
    overflow: auto;
    color: ${colors['secondary-500']};
    @media (max-width: 576px) {
      width: 100%;
    }
    
  }
`;

export const Bar = styled(Snackbar)`
  & .MuiSnackbarContent-root {
    background-color: ${colors.white};
    color: ${colors.black};
    display: flex;
    justify-content: center;
  }
`;


export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    &.Mui-selected {
      color: ${colors['white']} !important;
    }
    &:focus {
      background-color: ${colors['primary-500']} !important; /* Цвет фокуса */
      color: ${colors['white']} !important; /* Цвет текста при фокусе */
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important; 
    }
  }
`;

export const SecondaryButton = styled(Button)`
  && {
    gap: 8px;
    
    background-color: ${colors['opacity-primary40']};
  }
`;