import styled from 'styled-components';
import Styles from '../../../../../../styles';
import colors from '../../../../../../constants';

export const DiaryWrap = styled(Styles.Row)<{ active?: boolean }>`
  border: ${({ active }) => (active ? `2px solid ${colors['primary-500']}` : 'none')};
  border-radius: 6px;
  height: 100%;
  background-color: ${colors['extra-white']};
  box-shadow: 0px 4px 18px 0px rgba(75, 70, 92, 0.1);
`;

export const Bottom = styled(Styles.Row)<{ theme: string }>`
  background-color: ${({ theme }) => (theme === 'dark' ? colors['dark-theme-secondary'] : '')};
  padding: 16px 24px 24px;
`;
