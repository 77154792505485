import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const Profile: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color = colors.white,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='12'
        cy='7'
        r='4'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='12'
        cy='7'
        r='4'
        stroke={props.stroke || color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6 21V19C6 16.7909 7.79086 15 10 15H14C16.2091 15 18 16.7909 18 19V21'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6 21V19C6 16.7909 7.79086 15 10 15H14C16.2091 15 18 16.7909 18 19V21'
        stroke={props.stroke || color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
