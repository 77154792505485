import styled from 'styled-components';

export const TermsWrap = styled.div`
  && {
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 0px 10px;
    justify-content: center;
    align-content: center;
    @media (min-width: 768px) {
      padding: 0px 100px;
    }
  }
`;
