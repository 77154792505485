/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TermsWrap } from './terms.s';
import Styles, { Typography } from '../../../styles';
import { Button, Typography as MuiTypo } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ITermsProps {}

export const Terms: FC<ITermsProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [t] = useTranslation('global');
  const queryParams = new URLSearchParams(location.search);
  const returnTo = queryParams.get('returnTo') || '/role-selection';

  const handleBack = () => {
    navigate(returnTo);
  };

  return (
    <TermsWrap>
      <Styles.Row gap={24} align_content={'center'}>
        <Styles.Row gap={24}>
          <Styles.Row>
            <Typography.h3 color='primary-500'>{t('auth.terms.title')}</Typography.h3>
          </Styles.Row>
          <Styles.Row>
            <Typography.paragraph color='typography-muted'>
              By using this Site, you agree to be bound by, and to comply with, these Terms and
              Conditions. If you do not agree to these Terms and Conditions, please do not use this
              site. PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or
              otherwise alter these Terms and Conditions at any time. Unless otherwise indicated,
              amendments will become effective immediately. Please review these Terms and Conditions
              periodically. Your continued use of the Site following the posting of changes and/or
              modifications will constitute your acceptance of the revised Terms and Conditions and
              the reasonableness of these standards for notice of changes. For your information,
              this page was last updated as of the date at the top of these terms and conditions.
            </Typography.paragraph>
          </Styles.Row>
        </Styles.Row>
        <Styles.Row
          content={'space-between'}
          wrap={'nowrap'}
          direction={{ xs: 'column', md: 'row' }}
          gap={24}>
          <Styles.Row gap={10} content={{ xs: 'space-between', md: 'flex-end' }}>
            <Button
              variant='text'
              onClick={handleBack}
              sx={{
                width: '130px',
                backgroundColor: '#3B82F629',
                textTransform: 'capitalize',
              }}>
              <MuiTypo
                sx={{
                  fontSize: '13px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#3B82F6',
                  textTransform: 'none',
                }}>
                {t('auth.terms.cancelBtn')}
              </MuiTypo>
            </Button>
          </Styles.Row>
        </Styles.Row>
      </Styles.Row>
    </TermsWrap>
  );
};
