import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const StairsUp: FC<IIconProps> = ({ className, width = 24, height = 24, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.5 20H8.5V16H12.5V12H16.5V8H20.5'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.5 20H8.5V16H12.5V12H16.5V8H20.5'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3.96967 10.4697C3.67678 10.7626 3.67678 11.2374 3.96967 11.5303C4.26256 11.8232 4.73744 11.8232 5.03033 11.5303L3.96967 10.4697ZM11.5 4H12.25C12.25 3.69665 12.0673 3.42318 11.787 3.30709C11.5068 3.191 11.1842 3.25517 10.9697 3.46967L11.5 4ZM10.75 8C10.75 8.41421 11.0858 8.75 11.5 8.75C11.9142 8.75 12.25 8.41421 12.25 8H10.75ZM7.5 3.25C7.08579 3.25 6.75 3.58579 6.75 4C6.75 4.41421 7.08579 4.75 7.5 4.75V3.25ZM11.5 4.75C11.9142 4.75 12.25 4.41421 12.25 4C12.25 3.58579 11.9142 3.25 11.5 3.25V4.75ZM5.03033 11.5303L12.0303 4.53033L10.9697 3.46967L3.96967 10.4697L5.03033 11.5303ZM10.75 4V8H12.25V4H10.75ZM7.5 4.75H11.5V3.25H7.5V4.75Z'
        fill={color}
      />
      <path
        d='M3.96967 10.4697C3.67678 10.7626 3.67678 11.2374 3.96967 11.5303C4.26256 11.8232 4.73744 11.8232 5.03033 11.5303L3.96967 10.4697ZM11.5 4H12.25C12.25 3.69665 12.0673 3.42318 11.787 3.30709C11.5068 3.191 11.1842 3.25517 10.9697 3.46967L11.5 4ZM10.75 8C10.75 8.41421 11.0858 8.75 11.5 8.75C11.9142 8.75 12.25 8.41421 12.25 8H10.75ZM7.5 3.25C7.08579 3.25 6.75 3.58579 6.75 4C6.75 4.41421 7.08579 4.75 7.5 4.75V3.25ZM11.5 4.75C11.9142 4.75 12.25 4.41421 12.25 4C12.25 3.58579 11.9142 3.25 11.5 3.25V4.75ZM5.03033 11.5303L12.0303 4.53033L10.9697 3.46967L3.96967 10.4697L5.03033 11.5303ZM10.75 4V8H12.25V4H10.75ZM7.5 4.75H11.5V3.25H7.5V4.75Z'
        fill='white'
        fill-opacity='0.1'
      />
    </svg>
  );
};
