import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../hooks';

interface IPlanState {
  quarter?: number | null;
  grade?: number | null;
  science?: number | null;
  theme?: number | null;
  name?: string;
  description?: string;
}

const initialState: IPlanState = {
  quarter: null,
  grade: null,
  science: null,
  theme: null,
  name: '',
  description: '',
};

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setNewPlan: (state, action: PayloadAction<IPlanState>) => {
      state.quarter = action.payload.quarter;
      state.grade = action.payload.grade;
      state.science = action.payload.science;
      state.theme = action.payload.theme;
      state.name = action.payload.name;
      state.description = action.payload.description;
    },
  },
});

export const { setNewPlan } = planSlice.actions;

export default planSlice.reducer;

export const selectPlan = (state: RootState) => state.plan;
