/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import { ContentWrap, ModeratorWaitingWrap, TitleWrap } from './moderator-waiting.s';
import Styles, { GlobalStyles, Typography } from '../../../../styles';
import { useNavigate } from 'react-router-dom';
import { Button, Typography as MuiTypo } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useDispatch } from 'react-redux';
import { setRole } from '../../../../store/slices/authSlice';
import { Roles } from '../../../../types/roles';
import { BASE_URL, MODERATOR } from '../../../../store/URLs';
import { useTranslation } from 'react-i18next';

interface IModeratorWaitingProps {}

export const ModeratorWaiting: FC<IModeratorWaitingProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation('global');
  const userState = useSelector((state: RootState) => state.auth);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const token = localStorage.getItem('waiting_accessToken');

  const refetch = async () => {
    try {
      const response = await fetch(BASE_URL + MODERATOR, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data?.[0]?.is_contracted === true) {
        localStorage.setItem('accessToken', '2');
        dispatch(
          setRole({
            access_token: localStorage.getItem('accessToken'),
            refresh_token: localStorage.getItem('refreshToken'),
            role: Roles.MODERATOR,
          })
        );
        navigate('/main');
      }
      else{
        setSnackbarMessage('auth.logIn.errors.phone.waiting');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <ModeratorWaitingWrap>
      <Styles.Row>
        <ContentWrap gap={44} content={'center'}>
          <Styles.Row content={'center'} gap={16} align_items={'center'} direction={'column'}>
            <Styles.Row content='center'>
              <Typography.h2 color='typography-body' align='center'>
                {t('auth.authorReg.waiting.title')}
              </Typography.h2>
            </Styles.Row>
            <TitleWrap content='center'>
              <Typography.h6 color='typography-muted' align='center'>
                {t('auth.authorReg.waiting.subTitle')}
              </Typography.h6>
            </TitleWrap>
            <Styles.Row content={'center'}>
              <Button
                onClick={refetch}
                variant='contained'
                sx={{
                  backgroundColor: '#3B82F6',
                  textTransform: 'capitalize',
                  width: '400px',
                  '@media (max-width: 768px)': {
                    width: '200px',
                  },
                }}>
                <MuiTypo
                  sx={{
                    fontSize: '13px',
                    fontWeight: '600',
                    lineHeight: '20px',
                    textTransform: 'none',
                  }}>
                  {t('auth.authorReg.waiting.btn')}
                </MuiTypo>
              </Button>
            </Styles.Row>
          </Styles.Row>
        </ContentWrap>
      </Styles.Row>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </ModeratorWaitingWrap>
  );
};
