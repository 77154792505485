import styled from 'styled-components';
import { Typography } from '../../../../../../../../../../styles';
import { MenuItem } from '@mui/material';
import colors from '../../../../../../../../../../constants';

export const FormContainer = styled.form`
  width: 100%;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

export const Button = styled(Typography.buttonDefault)<{ bg: string }>`
  padding: 10px 20px;
  border-radius: 6px;
  background-color: ${({ bg }) => bg};
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    &.Mui-selected {
      color: ${colors['white']} !important;
    }
    &:focus {
      background-color: ${colors['primary-500']} !important; 
      color: ${colors['white']} !important; 
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important; 
    }
  }
`;