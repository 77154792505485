import styled from 'styled-components';
import { Box } from '@mui/material';
import Styles from '../../../styles';
import colors from '../../../constants';

export const IconWrap = styled(Styles.Row)<{theme: string}>`
  && {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    padding: 6px;
    position: absolute;
    z-index: 999;
    top: -16px;
    right: -14px;
    box-shadow: ${({theme}) => theme === 'dark' ? '0px 2px 4px 0px rgba(15, 20, 34, 0.4)' : '0px 2px 4px 0px rgba(165, 163, 174, 0.3)'};
    background-color: ${({theme}) => theme === 'dark' ? colors['dark-theme-secondary'] : 'white'};
    cursor: pointer;
  }
`;

export const ChildrenWrap = styled(Box)`
  && {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 576px) {
      width: 300px;
    }
  }
`;

export const StyledModalBox = styled(Box)<{theme: string}>`
  &.MuiBox-root {
    display: flex;
    justify-content: center;
    width: 610px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    padding: 35px;
    background-color: ${({theme}) => theme === 'dark' ? colors['dark-theme-primary'] : 'white'};
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

    @media (max-width: 767px) {
      width: 340px;
      padding: 20px;
    }

    @media (max-height: 1000px) {
      max-height: 500px;
    }
  }
`;
