import { Spin } from './spin';
import CommonList from './common';
import { Layout } from './layout';

const Components = {
  Layout,
  Spin,
};

export default Components;

export const Common = CommonList;
