import { FC } from "react";
import { IIconProps } from "./interfaces/icon-interface";

export const School: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color,
}) => {
  return (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M22 9L12 5L2 9L12 13L22 9V15" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22 9L12 5L2 9L12 13L22 9V15" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 10.6V16C6 17.6568 8.68629 19 12 19C15.3137 19 18 17.6568 18 16V10.6" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 10.6V16C6 17.6568 8.68629 19 12 19C15.3137 19 18 17.6568 18 16V10.6" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};
