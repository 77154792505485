import { FC, ReactNode, useEffect, useState } from 'react';
import { ContainerWrap } from './container.s';

interface IContainerProps {
  children: ReactNode;
}

export const Container: FC<IContainerProps> = ({ children }) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedTheme = localStorage.getItem('theme');
      if (updatedTheme) {
        setTheme(updatedTheme);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return <ContainerWrap theme={theme}>{children}</ContainerWrap>;
};
