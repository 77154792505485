/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from 'react';
import Icons from '../../../assets/icons';
import { Common } from '../../../components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Styles, { GlobalStyles, Typography } from '../../../styles';
import { Pagination, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import {
  PlansWrap,
  StyledTable,
  TableWrapper,
  PrimaryButton,
  ButtonsContainer,
  StyledTableContainer,
  EmptyTitleWrapper,
  IconButton,
} from './scienceTopics.s';
import { useDeletePlanByIdMutation, useGetPlanByIdQuery } from '../../../store/services/plansApi';
import { AddPlan } from '../add-plan';
import { useAppTheme } from '../../../hooks';
import colors from '../../../constants';

export const ScienceTopics = () => {
  const { theme } = useAppTheme();
  const location = useLocation();
  const [science, setScience] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const [t, i18n] = useTranslation('global');
  const url = window.location.href;
  const parts = url.split('/');
  const number = parseInt(parts[parts.length - 2]);
  const [deleteTopic, { isLoading: deleteLoading }] = useDeletePlanByIdMutation();
  const { data: planData, isLoading: planLoading } = useGetPlanByIdQuery({
    id: number,
    lang: i18n.language,
  });
  

  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);

  const indexOfLastRow = page * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const response = planData?.results[0];

  const handleDelete = async (e: React.MouseEvent, topicId: number) => {
    e.stopPropagation();
    try {
      await deleteTopic(topicId).unwrap();
      setSnackbarMessage('mainPage.snackbar.topicDeleted');
      setSnackbarOpen(true);
    } catch (error: any) {
      setSnackbarMessage('mainPage.snackbar.sampleErr');
      setSnackbarOpen(true);
    }
  };

  const handleEdit = (e: React.MouseEvent, topicId: number) => {
    e.stopPropagation();
    navigate(`/edit-topic/${topicId}/`);
  };

  useEffect(() => {
    sessionStorage.setItem('previousUrl', location.pathname);
  }, [location]);

  return (
    <PlansWrap>
      {science ? (
        <>
          {planLoading ? (
            <Common.Container>
              <EmptyTitleWrapper content={'center'} align_items={'center'}>
                <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('plans.main.loadingTitle')}
                </Typography.h4>
              </EmptyTitleWrapper>
            </Common.Container>
          ) : (
            <Styles.Row>
              <Styles.Row gap={16}>
                <Styles.Row gap={6}>
                  <Typography.h4 color='typography-muted'>
                    {t('sidebar.items.plans')} /{' '}
                  </Typography.h4>
                  <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {response?.science?.name}
                  </Typography.h4>
                </Styles.Row>
                <Styles.Row>
                  <Styles.Row gap={8} style={{ marginBottom: '20px' }}>
                    <Styles.Row>
                      <Typography.paragraph
                        color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                        {t('plans.viewAddItem.body.input.gradeType')}: {response?.class_group?.name}
                      </Typography.paragraph>
                    </Styles.Row>
                    <Styles.Row>
                      <Typography.paragraph
                        color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                        {t('plans.viewItem.header.title.grade')}: {response?.classes?.name}
                      </Typography.paragraph>
                    </Styles.Row>
                    <Styles.Row>
                      <Typography.paragraph
                        color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                        {t('plans.view.table.select.quater')}: {response?.quarter?.choices}
                      </Typography.paragraph>
                    </Styles.Row>
                  </Styles.Row>

                  {response?.topic?.length === 0 ? (
                    <Common.Container>
                      <EmptyTitleWrapper
                        gap={4}
                        content={'center'}
                        align_items={'center'}
                        direction={'column'}>
                        <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                          {t('plans.viewItem.body.title.topicEmpty')}
                        </Typography.h4>
                        <PrimaryButton
                          onClick={() => navigate(`/new-topic/${number}/`)}
                          variant='contained'>
                          <Typography.h6 color='white'>{t('resources.button.add')}</Typography.h6>
                          <Icons.Plus />
                        </PrimaryButton>
                      </EmptyTitleWrapper>
                    </Common.Container>
                  ) : (
                    <Common.Container>
                      <ButtonsContainer>
                        <Styles.Row
                          gap={16}
                          wrap={'nowrap'}
                          align_items={'center'}
                          content={'space-between'}>
                          <Styles.Row>
                            <Typography.h4 color='typography-muted'>
                              {t('plans.view.table.header')}{' '}
                            </Typography.h4>
                          </Styles.Row>
                          <Styles.Row
                            content={'flex-end'}
                            wrap={{ xs: 'wrap', md: 'nowrap' }}
                            gap={10}>
                            <Styles.Row width='130px' content={'flex-end'}>
                              <PrimaryButton
                                onClick={() => navigate(`/new-topic/${number}/`)}
                                variant='contained'>
                                <Typography.h6 color='white'>
                                  {t('resources.button.add')}
                                </Typography.h6>
                                <Icons.Plus />
                              </PrimaryButton>
                            </Styles.Row>
                          </Styles.Row>
                        </Styles.Row>
                      </ButtonsContainer>
                      <Styles.Row>
                        <TableWrapper>
                          <StyledTableContainer>
                            <StyledTable stickyHeader aria-label='sticky table'>
                              <TableHead>
                                <TableRow>
                                  <TableCell align='center'>
                                    <GlobalStyles.TableHeaderTitle
                                      content={'center'}
                                      align_items={'center'}>
                                      {t('plans.view.table.title.number')}
                                    </GlobalStyles.TableHeaderTitle>
                                  </TableCell>
                                  <TableCell align='center' sx={{ width: '600px' }}>
                                    <GlobalStyles.TableHeaderTitle
                                      content={'center'}
                                      align_items={'center'}>
                                      {t('plans.view.table.title.name')}
                                    </GlobalStyles.TableHeaderTitle>
                                  </TableCell>
                                  <TableCell align='center' sx={{ width: '600px' }}>
                                    <GlobalStyles.TableHeaderTitle
                                      content={'center'}
                                      align_items={'center'}>
                                      {t('plans.view.table.title.week')}
                                    </GlobalStyles.TableHeaderTitle>
                                  </TableCell>
                                  <TableCell align='center'>
                                    <GlobalStyles.TableHeaderTitle
                                      content={'center'}
                                      align_items={'center'}>
                                      {t('plans.view.table.title.duration')}
                                    </GlobalStyles.TableHeaderTitle>
                                  </TableCell>
                                  <TableCell align='center'>
                                    <GlobalStyles.TableHeaderTitle
                                      content={'center'}
                                      align_items={'center'}>
                                      {t('')}
                                    </GlobalStyles.TableHeaderTitle>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {response?.topic?.map((item: any) => (
                                  <TableRow
                                    key={item?.id}
                                    onClick={() => navigate(`/plan-view/${item?.id}`)}
                                    sx={{
                                      '&:last-child td, &:last-child th': { border: 0 },
                                      cursor: 'pointer',
                                    }}>
                                    <TableCell align='center'>{item.sequence_number}</TableCell>
                                    <TableCell align='center'>{item.name}</TableCell>
                                    <TableCell align='center'>{item.weeks}</TableCell>
                                    <TableCell align='center'>{item.hours}</TableCell>
                                    <TableCell align='center'>
                                      <Styles.Row wrap={'nowrap'} content={'center'} gap={8}>
                                        <IconButton
                                          onClick={(e) => handleEdit(e, item.id)}
                                          variant='contained'>
                                          <Icons.Pencil color={colors.white} />
                                        </IconButton>
                                        <IconButton
                                          disabled={deleteLoading}
                                          onClick={(e) => handleDelete(e, item.id)}
                                          variant='contained'>
                                          <Icons.Trash color={colors.white} />
                                        </IconButton>
                                      </Styles.Row>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </StyledTable>
                          </StyledTableContainer>
                        </TableWrapper>

                        {/* <Styles.Row content={'flex-end'}>
                          <Pagination
                            count={Math.ceil(topicData?.count / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            color='primary'
                            shape='rounded'
                            style={{ marginTop: '20px' }}
                          />
                        </Styles.Row> */}
                      </Styles.Row>
                    </Common.Container>
                  )}
                </Styles.Row>
              </Styles.Row>
            </Styles.Row>
          )}
        </>
      ) : (
        <AddPlan />
      )}
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </PlansWrap>
  );
};
