/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../../../../../../../../assets/icons';
import colors from '../../../../../../../../../../constants';
import { fullNameGenerator } from '../../../../../../../../../../helpers/fullNameGenerator';
import Styles, { GlobalStyles, Typography } from '../../../../../../../../../../styles';
import {
  AccordionButton,
  AccordionResWrapper,
  AccordionWrapper,
  ResourcesButton,
  StyledButton,
} from './accordion.s';
import Grow from '@mui/material/Grow';
import { useAppTheme } from '../../../../../../../../../../hooks';
import { handleDownloadClick } from '../../../../../../../../../../helpers/fileDownload';

interface IAccodion {
  item: any;
}

type SetIsDownloading = (id: number | null) => void;

export const AccordionContainer: FC<IAccodion> = ({ item }) => {
  const [t, i18n] = useTranslation('global');
  const { theme } = useAppTheme();
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isDownloading, setIsDownloading] = useState<number | null>(null);
  const [resourcesView, setResourcesView] = useState(false);
  const [shouldRenderResources, setShouldRenderResources] = useState(false);
  const [activeDesc, setActiveDesc] = useState<string | null>(null);

  useEffect(() => {
    if (resourcesView) {
      setShouldRenderResources(true);
    }
  }, [resourcesView]);

  const handleToggleResources = () => {
    setResourcesView((prev) => !prev);
    setShouldRenderResources(false);
  };

  const getUniqueAuthors = () => {
    const authorsMap = new Map();
    item?.topic?.media.forEach((media: any) => {
      const author = media.user;
      if (author && !authorsMap.has(author.id)) {
        authorsMap.set(author.id, author);
      }
    });
    return Array.from(authorsMap.values());
  };

  const uniqueAuthors = getUniqueAuthors();

  const handleDescClick = (desc: string) => {
    setActiveDesc((prevDesc) => (prevDesc === desc ? null : desc));
  };

  const groupedMedia = item?.topic?.media?.reduce((acc: any, media: any) => {
    if (!acc[media.desc]) {
      acc[media.desc] = [];
    }
    acc[media.desc].push(media);
    return acc;
  }, {});

  const onDownloadClick = (id: number, name: string, setIsDownloading: SetIsDownloading) => {
    handleDownloadClick(id, name, i18n.language, setIsDownloading, setSnackbarMessage, setSnackbarOpen);
  };

  return (
    <AccordionWrapper gap={24}>
      <Styles.Row content={'center'} gap={4} align_items={'center'}>
        <Typography.h5 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
          {t('mainPage.resoursModal.topicTitle')}: {item?.topic?.name}
        </Typography.h5>
        <StyledButton onClick={() => navigate(`/plan-view/${item?.topic?.id}`)}>
          <Icons.Link color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']} />
        </StyledButton>
      </Styles.Row>
      <Styles.Row>
        <Typography.h5 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
          {t('mainPage.resoursModal.topicNum')}: {item?.topic?.sequence_number}
        </Typography.h5>
      </Styles.Row>
      <Styles.Row gap={8}>
        <Typography.h5 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
          {t('mainPage.resoursModal.author')}
        </Typography.h5>
        {uniqueAuthors.map((author) => (
          <AccordionButton theme={theme} key={author.id} fullWidth>
            <Styles.Row content={'space-between'} align_items={'center'}>
              <Typography.paragraph color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                {fullNameGenerator(author.first_name, author.last_name)}
              </Typography.paragraph>
            </Styles.Row>
          </AccordionButton>
        ))}
      </Styles.Row>

      <AccordionResWrapper gap={12}>
        <Typography.h5 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
          {t('mainPage.resoursModal.resources')}
        </Typography.h5>
        <AccordionButton
          theme={theme}
          state={resourcesView}
          fullWidth
          onClick={handleToggleResources}>
          <Styles.Row content={'space-between'} align_items={'center'}>
            <Typography.paragraph color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
              {t('mainPage.resoursModal.total')}
            </Typography.paragraph>
            {resourcesView ? (
              <Icons.ChevronDown
                color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
              />
            ) : (
              <Icons.ChevronRight
                color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
              />
            )}
          </Styles.Row>
        </AccordionButton>
        {resourcesView && (
          <Styles.Row gap={6}>
            {Object.keys(groupedMedia).map((desc) => (
              <>
                <AccordionButton
                  theme={theme}
                  key={desc}
                  fullWidth
                  state={activeDesc === desc}
                  onClick={() => handleDescClick(desc)}>
                  <Styles.Row content={'space-between'} align_items={'center'}>
                    <Typography.paragraph color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                      {desc}
                    </Typography.paragraph>
                    {activeDesc === desc ? (
                      <Icons.ChevronDown
                        color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
                      />
                    ) : (
                      <Icons.ChevronRight
                        color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
                      />
                    )}
                  </Styles.Row>
                </AccordionButton>
                {activeDesc === desc && (
                  <Styles.Row gap={6}>
                    {groupedMedia[desc].map((res: any, index: number) => (
                      <Grow key={res.id} in={shouldRenderResources} timeout={index * 200}>
                        <ResourcesButton
                          theme={theme}
                          fullWidth
                          style={{ display: shouldRenderResources ? 'flex' : 'none' }}>
                          <Styles.Row
                            wrap={'nowrap'}
                            content={'space-between'}
                            align_items={'center'}>
                            <Typography.paragraph
                              color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                              {res.name}
                            </Typography.paragraph>
                            <Styles.Row width='auto' content={'center'}>
                              {isDownloading !== res.id ? (
                                <Button
                                  onClick={() =>
                                    onDownloadClick(res?.id, res.name, setIsDownloading)
                                  }>
                                  <Icons.Download
                                    color={
                                      theme === 'dark'
                                        ? colors['grey-300']
                                        : colors['typography-body']
                                    }
                                  />
                                </Button>
                              ) : (
                                <Button disabled>
                                  <Icons.Loader
                                    color={
                                      theme === 'dark'
                                        ? colors['grey-100']
                                        : colors['typography-muted']
                                    }
                                  />
                                </Button>
                              )}
                            </Styles.Row>
                          </Styles.Row>
                        </ResourcesButton>
                      </Grow>
                    ))}
                  </Styles.Row>
                )}
              </>
            ))}
          </Styles.Row>
        )}
      </AccordionResWrapper>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </AccordionWrapper>
  );
};
