import mock from '../mock';

export default function getDay() {
  const today = new Date().getDay();
  const days = [
    'mainPage.days.sunday',
    'mainPage.days.monday',
    'mainPage.days.tuesday',
    'mainPage.days.wednesday',
    'mainPage.days.thursday',
    'mainPage.days.friday',
    'mainPage.days.saturday',
  ];

  return days[today];
}

export function daysInMonth(month: number, year: number): number {
  return new Date(year, month + 1, 0).getDate();
}

export function TodayDate() {
  const today = new Date();
  const monthIndex = today.getMonth();
  const monthNames = mock.Month.name;

  return {
    day: today.getDate(),
    month: monthNames[monthIndex],
    monthIndex,
    year: today.getFullYear(),
    weekday: today.getDay(),
  };
}

export function getCurrentDate(
  index: number = 0,
  userDate?: { day: number; year: number; monthIndex: number; weekday: number }
) {
  let today = new Date();
  // let today = new Date('2024-04-22');

  if (userDate) {
    today = new Date(userDate.year, userDate.monthIndex, userDate.day);
  }

  const currentDayOfWeek = today.getDay();
  const monday = new Date(today);
  monday.setDate(today.getDate() - currentDayOfWeek + 1);

  const targetDate = new Date(monday);
  targetDate.setDate(monday.getDate() + index);

  const day = targetDate.getDate();
  const monthIndex = targetDate.getMonth();
  const year = targetDate.getFullYear();
  const weekday = targetDate.getDay();
  const monthNames = mock.Month.name;

  return { day: day, month: monthNames[monthIndex], monthIndex, year, weekday };
}

export function getUpdatedDate(
  currentDate: Date,
  dayOffset: number
): { day: number; month: string; monthIndex: number; year: number; weekday: number } {
  const newDate = new Date(currentDate);
  newDate.setDate(newDate.getDate() + dayOffset);

  let day = newDate.getDate();
  let monthIndex = newDate.getMonth();
  let year = newDate.getFullYear();
  let currentDayName = newDate.getDay();

  if (monthIndex === 12) {
    monthIndex = 0;
    year++;
  } else if (monthIndex === -1) {
    monthIndex = 11;
    year--;
  }

  const monthNames = mock.Month.name;
  const month = monthNames[monthIndex];

  return { day, month, monthIndex, year, weekday: currentDayName };
}

export function extractTime(timeString: string) {
  const date = new Date(timeString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let monthIndex = date.getMonth();
  let day = date.getDate();
  const monthNames = mock.Month.name;
  const formattedHour = `${hours}:${minutes < 10 ? '0' + minutes : minutes} `;
  const formattedMonth = `${monthNames[monthIndex]}`;
  const formattedDay = ` ${day}`;

  return {hour: formattedHour, month: formattedMonth, day: formattedDay};
}

export function getTodaySheduleDate() {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  return formattedDate;
}
