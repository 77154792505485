import styled from 'styled-components';

export const AuthSuccessfulWrap = styled.div`
  width: 100%;
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  align-content: center;
  @media (max-width: 768px) {
    padding: 18px;
  }
`;
export const Img = styled.img`
  object-fit: contain;
`;
