import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const Edit: FC<IIconProps> = ({ className, width = 25, height = 24, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 15H6L13.875 7.12504C14.7034 6.29662 14.7034 4.95347 13.875 4.12504C13.0466 3.29662 11.7034 3.29662 10.875 4.12504L3 12V15'
        stroke={color || '#fff'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.125 4.875L13.125 7.875'
        stroke={color || '#fff'}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
