import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const Download: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color = colors.white,
  ...props
}) => {
  return (
    <svg
      className={className}
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.417 16.5001C19.1889 16.5001 20.6253 15.0636 20.6253 13.2917C20.6253 11.5198 19.1889 10.0834 17.417 10.0834H16.5003C16.8642 8.4623 16.2395 6.78963 14.8616 5.69547C13.4836 4.6013 11.5617 4.25187 9.81991 4.7788C8.07807 5.30573 6.7809 6.62896 6.41702 8.25006C4.4016 8.16933 2.60869 9.46524 2.13955 11.3418C1.67041 13.2184 2.65645 15.1499 4.49201 15.9501'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.417 16.5001C19.1889 16.5001 20.6253 15.0636 20.6253 13.2917C20.6253 11.5198 19.1889 10.0834 17.417 10.0834H16.5003C16.8642 8.4623 16.2395 6.78963 14.8616 5.69547C13.4836 4.6013 11.5617 4.25187 9.81991 4.7788C8.07807 5.30573 6.7809 6.62896 6.41702 8.25006C4.4016 8.16933 2.60869 9.46524 2.13955 11.3418C1.67041 13.2184 2.65645 15.1499 4.49201 15.9501'
        stroke={props.stroke || color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.0003 11.9166V20.1666'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.0003 11.9166V20.1666'
        stroke={props.stroke || color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.25 17.4166L11 20.1666L13.75 17.4166'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.25 17.4166L11 20.1666L13.75 17.4166'
        stroke={props.stroke || color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
