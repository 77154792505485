/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Styles, { GlobalStyles, Typography } from '../../../../../styles';
import {
  FirstFormWrap,
  PrimaryButton,
  SecondaryButton,
  StyledColumn,
  StyledTextField,
} from './first-form.s';
import { useFormik, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  useEditTopicMutation,
  useGetTopicByIdQuery,
} from '../../../../../store/services/moderatorPlansApi';

interface IFirstFormProps {
  theme: string;
}

interface IFormData {
  name: string;
  description: string;
  hours: string;
  weeks: string;
}

export const FirstForm: FC<IFirstFormProps> = ({ theme }) => {
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const url = window.location.href;
  const parts = url.split('/');
  const number = parseInt(parts[parts.length - 2]);
  const previousUrl = sessionStorage.getItem('previousUrl');
  const previousUrlId = previousUrl ? previousUrl.match(/\d+/) : null;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editTopic, { isLoading }] = useEditTopicMutation();
  const { data: topicData, isLoading: topicLoading } = useGetTopicByIdQuery({
    id: number,
    lang: i18n.language,
  });

  const initialValues: IFormData = {
    name: topicData?.name || '',
    description: topicData?.description || '',
    hours: topicData?.hours || '',
    weeks: topicData?.weeks || '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      topics: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('plans.viewAddItem.body.errors.global'),
          description: Yup.string().required('plans.viewAddItem.body.errors.global'),
          hours: Yup.string().required('plans.viewAddItem.body.errors.global'),
          weeks: Yup.string().required('plans.viewAddItem.body.errors.global'),
        })
      ),
    }),
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('description', values.description);
        formData.append('hours', values.hours);
        formData.append('weeks', values.weeks);
        formData.append('sequence_number', topicData.sequence_number);
        await editTopic({ id: number, body: formData }).unwrap();

        setSnackbarMessage('mainPage.snackbar.sampleEdited');
        setSnackbarOpen(true);
        setTimeout(() => {
          handleBack();
        }, 2000);
      } catch (error: any) {
        console.log('error', error);
        setSnackbarMessage('mainPage.snackbar.sampleErr');
        setSnackbarOpen(true);
      }
    },
    validateOnChange: true,
  });

  const handleBack = () => {
    if (previousUrlId && previousUrlId[0]) {
      navigate(`/science-topics/${previousUrlId[0]}/`);
    }
  };

  useEffect(() => {
    if (topicData) {
      formik.setValues({
        name: topicData.name || '',
        description: topicData.description || '',
        hours: topicData.hours || '',
        weeks: topicData.weeks || '',
      });
    }
  }, [topicData]);

  return (
    <FirstFormWrap onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Styles.Row wrap={{ xs: 'wrap', sm: 'nowrap' }} gap={16}>
          <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
            <Styles.Row gap={4}>
              <StyledColumn size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                    as={'label'}
                    subColor='danger-600'
                    htmlFor={`name`}>
                    {t('plans.viewAddItem.body.topicAdd.input.name')}
                    <span>*</span>
                  </Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }} direction={'column'}>
                  <StyledTextField
                    placeholder='...'
                    name={`name`}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </Styles.Row>
              </StyledColumn>

              <StyledColumn size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall
                    subColor='danger-600'
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewAddItem.body.topicAdd.input.duration')}
                    <span>*</span>
                  </Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledTextField
                    placeholder='...'
                    name={`hours`}
                    type='number'
                    value={formik.values.hours}
                    onChange={formik.handleChange}
                  />
                </Styles.Row>
              </StyledColumn>
            </Styles.Row>

            <Styles.Row gap={4}>
              <StyledColumn size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall
                    subColor='danger-600'
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewAddItem.body.topicAdd.input.description')}
                    <span>*</span>
                  </Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledTextField
                    placeholder='...'
                    name={`description`}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                </Styles.Row>
              </StyledColumn>

              <StyledColumn size={6} difference={0} gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall
                    subColor='danger-600'
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewAddItem.body.topicAdd.input.week')}
                    <span>*</span>
                  </Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <StyledTextField
                    placeholder='...'
                    name={`weeks`}
                    type='number'
                    value={formik.values.weeks}
                    onChange={formik.handleChange}
                  />
                </Styles.Row>
              </StyledColumn>
            </Styles.Row>
          </Styles.Row>
        </Styles.Row>

        <Styles.Row gap={16} content={'space-between'} wrap={'nowrap'}>
          <SecondaryButton variant='contained' onClick={() => handleBack()} disabled={isLoading}>
            <Typography.h6 color='secondary-500'>
              {t('plans.viewAddItem.body.button.previous')}
            </Typography.h6>
          </SecondaryButton>

          <PrimaryButton type='submit' variant='contained' disabled={isLoading}>
            <Typography.h6 color='white'>{t('plans.viewAddItem.body.button.save')}</Typography.h6>
          </PrimaryButton>
        </Styles.Row>
      </FormikProvider>

      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </FirstFormWrap>
  );
};
