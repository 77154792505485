/* eslint-disable react/jsx-pascal-case */
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Icons from '../../../assets/icons';
import { useGetResourcesQuery } from '../../../store/services/resourcesApi';
import Styles, { GlobalStyles, Typography } from '../../../styles';
import colors from '../../../constants';

import {
  StyledTable,
  TableWrapper,
  ResourcesWrap,
  PrimaryButton,
  ButtonsContainer,
  StyledTableContainer,
  EmptyTitleWrapper,
  IdTableCell,
} from './resources.s';
import CommonList from '../../../components/common';
import { useAppTheme } from '../../../hooks';
import { useSelector } from 'react-redux';

export const Resources: FC = () => {
  const { theme } = useAppTheme();
  const navigate = useNavigate();
  const [t, i18n] = useTranslation('global');
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const userState = useSelector((state: any) => state.auth);

  const { data: resourcesData, isLoading, refetch } = useGetResourcesQuery({ lang: i18n.language });

  const handleAdd = () => {
    navigate('/new-resources');
  };

  // const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
  //   setPage(newPage);
  // };

  const filteredResources = resourcesData || [];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <ResourcesWrap>
      <Styles.Row gap={16}>
        <Styles.Row>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('resources.header.resources')}
          </Typography.h4>
        </Styles.Row>
        <Styles.Row>
          <CommonList.Container>
            <ButtonsContainer>
              <Styles.Row wrap={'nowrap'} gap={16} content={'flex-end'}>
                {userState.resource_creatable && (
                  <PrimaryButton onClick={handleAdd} variant='contained'>
                    <Typography.h6 color='white'>{t('resources.button.add')}</Typography.h6>
                    <Icons.Plus />
                  </PrimaryButton>
                )}
              </Styles.Row>
            </ButtonsContainer>
            {isLoading ? (
              <EmptyTitleWrapper content={'center'} align_items={'center'}>
                <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('plans.main.loadingTitle')}
                </Typography.h4>
              </EmptyTitleWrapper>
            ) : (
              <Styles.Row>
                <TableWrapper>
                  <StyledTableContainer>
                    <StyledTable stickyHeader aria-label='sticky table'>
                      <TableHead>
                        <TableRow>
                          <IdTableCell align='center'>
                            <GlobalStyles.TableHeaderTitle content={'center'}>
                              {t('resources.table.number')}
                            </GlobalStyles.TableHeaderTitle>
                          </IdTableCell>
                          <TableCell align='center'>
                            <GlobalStyles.TableHeaderTitle
                              width='170px'
                              align_items={'center'}
                              content={'space-between'}
                              wrap={'nowrap'}>
                              {t('resources.table.name')}
                            </GlobalStyles.TableHeaderTitle>
                          </TableCell>
                          <TableCell align='center'>{t('resources.table.vision')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredResources.map((row: any, index: number) => (
                          <TableRow key={index}>
                            <IdTableCell align='center' >
                              {(page - 1) * rowsPerPage + index + 1}
                            </IdTableCell>
                            <TableCell align='left'>{row?.name}</TableCell>
                            <TableCell align='center'>
                              <Link to={`/resource-type/${row?.id}/`}>
                                <Icons.Eye
                                  color={
                                    theme === 'dark'
                                      ? colors['grey-300']
                                      : colors['typography-body']
                                  }
                                />
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </StyledTable>
                  </StyledTableContainer>
                </TableWrapper>
                {/* <Styles.Row content={'flex-end'}>
                  <Pagination
                    count={Math.ceil(resourcesData?.count / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color='primary'
                    shape='rounded'
                    style={{ marginTop: '20px' }}
                  />
                </Styles.Row> */}
              </Styles.Row>
            )}
          </CommonList.Container>
        </Styles.Row>
      </Styles.Row>
    </ResourcesWrap>
  );
};
