/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import { SettingsWrap, StyledButton, UserForm } from './settings.s';
import Styles, { Typography } from '../../../styles';
import Mock from '../../../mock';
import { useTranslation } from 'react-i18next';
import { AccountForm, ModeratorForm, ThemeForm } from './components';
import { useAppTheme } from '../../../hooks';

interface ISettingsProps {}

export const Settings: FC<ISettingsProps> = (props) => {
  const { theme } = useAppTheme();
  const [t] = useTranslation('global');
  const [formStatus, setFormStatus] = useState(() => {
    return localStorage.getItem('formStatus') || 'account';
  });

  useEffect(() => {
    localStorage.setItem('formStatus', formStatus);
  }, [formStatus]);

  return (
    <SettingsWrap>
      <Styles.Row gap={26}>
        <Styles.Row gap={6}>
          <Typography.h4 color='typography-muted'>{t('settings.title.main')} /</Typography.h4>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {formStatus === 'account' && t('settings.title.account')}
            {formStatus === 'theme' && t('settings.title.theme')}
            {formStatus === 'moderator' && t('settings.title.moderator')}
          </Typography.h4>
        </Styles.Row>

        <Styles.Row gap={4} wrap={'nowrap'}>
          {Mock.Settings.userBtns.map((button) => (
            <Styles.Row width='125px'>
              <StyledButton
                theme={theme}
                onClick={() => setFormStatus(button.formChange)}
                variant='contained'
                formStatus={formStatus}
                formChange={button.formChange}>
                <Styles.Row>
                  <button.icons width={20} height={20} />
                </Styles.Row>
                {t(button.label)}
              </StyledButton>
            </Styles.Row>
          ))}
        </Styles.Row>

        <UserForm theme={theme}>
          {formStatus === 'account' && <AccountForm theme={theme} setFormStatus={setFormStatus} />}
          {formStatus === 'theme' && <ThemeForm setFormStatus={setFormStatus} />}
          {formStatus === 'moderator' && <ModeratorForm theme={theme} setFormStatus={setFormStatus} />}
        </UserForm>
      </Styles.Row>
    </SettingsWrap>
  );
};
