import { MEDIA, MODERATOR, TOPIC } from '../URLs';
import { api } from './api';

export const moderatorPlansApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getModeratorClassGrp: builder.query<any, string>({
      query: (lang) => ({
        url: `${MODERATOR}class-groups/`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getModeratorClassGrp'],
    }),
    getModeratorClass: builder.query<any, string>({
      query: (lang) => ({
        url: `${MODERATOR}classes/`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getModeratorClass'],
    }),
    getModeratorScience: builder.query<any, string>({
      query: (lang) => ({
        url: `${MODERATOR}science/`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getModeratorScience'],
    }),
    getModeratorScienceType: builder.query<any, string>({
      query: (lang) => ({
        url: `${MODERATOR}science-type/`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getModeratorScienceType'],
    }),
    getModeratorQuarter: builder.query<any, string>({
      query: (lang) => ({
        url: `${MODERATOR}quarters/`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getModeratorQuarter'],
    }),
    getModeratorLang: builder.query<any, string>({
      query: (lang) => ({
        url: `${MODERATOR}languages/`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getModeratorLang'],
    }),
    addTopic: builder.mutation<any, { id: number, body: string  }>({
      query: ({ id, body }) => ({
        url: `${TOPIC}?plan_id=${id}`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['getPlans'],
    }),
    editTopic: builder.mutation<any, { id: number, body: FormData  }>({
      query: ({ id, body }) => ({
        url: `${TOPIC}?id=${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['getPlans'],
    }),
    getTopicById: builder.query<any, {id: number, lang: string}>({
      query: ({id, lang}) => ({
        url: `${TOPIC}?id=${id}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getPlans'],
    }),
    addResourceToTopic: builder.mutation<any, { id: number, body: FormData}>({
      query: ({ id, body }) => ({
        url: `${MEDIA}?topic_id=${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getTopicById'],
    }),
  }),
});

export const {
  useGetModeratorClassGrpQuery,
  useGetModeratorClassQuery,
  useGetModeratorLangQuery,
  useGetModeratorQuarterQuery,
  useGetModeratorScienceQuery,
  useGetModeratorScienceTypeQuery,
  useAddTopicMutation,
  useGetTopicByIdQuery,
  useAddResourceToTopicMutation,
  useEditTopicMutation,
} = moderatorPlansApi;
