/* eslint-disable react/jsx-pascal-case */
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  FileUploaderBox,
  FileUploaderImageWrap,
  FileUploaderInput,
  FileUploaderWrap,
  IconWrap,
  RequiredTextWrap,
} from './uploader.s';
import { FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import Styles, { GlobalStyles, Typography } from '../../../styles';
import { useTranslation } from 'react-i18next';

interface IUploaderProps {
  icon: ReactNode;
  title: any;
  requiredText: any;
  type: string;
  files: FileWithPath[];
  setFiles: (files: FileWithPath[]) => void;
}

export const Uploader: FC<IUploaderProps> = ({
  icon,
  title,
  requiredText,
  type,
  files,
  setFiles,
}) => {
  const [t] = useTranslation('global');
  const [rejection, setRejection] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length > 0) {
        setRejection(true);
        return;
      }

      setRejection(false);
      setFiles(acceptedFiles);
    },
    [setFiles, type]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      type === 'image'
        ? { 'image/*': ['.png', '.jpeg', '.jpg', '.svg',] }
        : {
            '.pdf, .doc, .docx, .txt, .audio,': [
              '.pdf',
              '.doc',
              '.docx',
              '.txt',
              '.mp3',
              '.png',
              '.jpeg',
              '.jpg',
              '.svg',
            ],
          },
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedTheme = localStorage.getItem('theme');
      if (updatedTheme) {
        setTheme(updatedTheme);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Styles.Row content={'center'}>
      <FileUploaderWrap {...getRootProps()}>
        <FileUploaderInput {...getInputProps()} />
        {files.length > 0 ? (
          <>
            {files.map((file, index) => (
              <div key={index}>
                <FileUploaderImageWrap>
                  {file.type.startsWith('image/') ? (
                    <Styles.Row content={'center'}>
                      <Typography.paragraph
                        color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                        {file.name.length > 24
                          ? `${file.name.slice(0, 20)}...${file.name.slice(-4)}`
                          : file.name}
                      </Typography.paragraph>
                    </Styles.Row>
                  ) : (
                    <Styles.Row content={'center'}>
                      <Typography.paragraph
                        color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                        {file.name.length > 24
                          ? `${file.name.slice(0, 20)}...${file.name.slice(-4)}`
                          : file.name}
                      </Typography.paragraph>
                    </Styles.Row>
                  )}
                </FileUploaderImageWrap>
                <Styles.Row align_items={'center'} content={'center'} direction={'column'}>
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('auth.authorReg.rating.uploader.edit')}
                  </Typography.paragraph>
                  {rejection && (
                    <GlobalStyles.ErrorTypography color='danger-500'>
                      {t('auth.authorReg.rating.uploader.wrong')}{' '}
                      {type === 'image' ? '(png, jpeg, jpg)' : '(pdf, doc, docx)'}{' '}
                      {t('auth.authorReg.rating.uploader.choose')}
                    </GlobalStyles.ErrorTypography>
                  )}
                </Styles.Row>
              </div>
            ))}
          </>
        ) : (
          <FileUploaderBox>
            <IconWrap>{icon}</IconWrap>
            <Styles.Row content={'center'}>
              <Typography.h5
                align='center'
                color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t(title)}
              </Typography.h5>
              <Typography.h5 color='danger-600'>*</Typography.h5>
            </Styles.Row>
            <RequiredTextWrap align_items={'center'} content={'center'} direction={'column'}>
              <Typography.paragraph color='typography-muted'>
                {t(requiredText)}
              </Typography.paragraph>
              {rejection && (
                <GlobalStyles.ErrorTypography color='danger-500'>
                  Fayl formati noto'g'ri!{' '}
                  {type === 'image' ? '(png, jpeg, jpg)' : '(pdf, doc, docx)'} fayl turini tanlang
                </GlobalStyles.ErrorTypography>
              )}
            </RequiredTextWrap>
          </FileUploaderBox>
        )}
      </FileUploaderWrap>
    </Styles.Row>
  );
};
