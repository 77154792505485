import styled from 'styled-components';
import Styles from '../../../../../styles';
import { Link } from 'react-router-dom';
import { MenuItem, Select, Snackbar } from '@mui/material';
import colors from '../../../../../constants';

export const ModeratorFormWrap = styled.form`
  width: 100%;
`;

export const FormWrap = styled(Styles.Row)`
  padding: 24px;
`;

export const UserButtons = styled(Styles.Row)`
  && {
    padding: 24px;
    justify-content: flex-end;
    @media (max-width: 576px) {
      justify-content: space-between;
    };
  }
`;

export const UserLink = styled(Link)`
  text-decoration: underline;
  color: #3b82f6;
`;

export const Bar = styled(Snackbar)`
  & .MuiSnackbarContent-root {
    background-color: ${colors.white};
    color: ${colors.black};
    display: flex;
    justify-content: center;
  }
`;

export const FileUploaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  border: 3px dashed #e9e9e9;
  padding: 6px;
  @media (min-width: 1024px) {
    padding: 24px;
  }
  @media (max-width: 768px) {
    width: 95%;
  }
  gap: 16px;
`;

export const FileUploaderInput = styled.input`
  cursor: pointer;
`;

export const FileUploaderImageWrap = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  padding: 20px;
  @media (min-width: 1024px) {
    padding: 40px;
  }
`;

export const FileUploaderImage = styled.img`
  height: 100px;
  width: 100%;
  border-radius: 24px;
  object-fit: contain;
  object-position: center;
`;

export const FileUploaderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 7px;
  height: 171px;
  gap: 10px;
`;

export const IconWrap = styled.div`
  width: 46px;
  height: 46px;
  background-color: #4b465c14;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  && {
    width: 192px;
    overflow: auto;
    color: ${colors['secondary-500']};
    @media (max-width: 576px) {
      width: 100%;
    }
    
  }
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    &.Mui-selected {
      color: ${colors['white']} !important;
    }
    &:focus {
      background-color: ${colors['primary-500']} !important; /* Цвет фокуса */
      color: ${colors['white']} !important; /* Цвет текста при фокусе */
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important; 
    }
  }
`;