import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const LockOpen: FC<IIconProps> = ({ className, width = 25, height = 24, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='5.5'
        y='11'
        width='14'
        height='10'
        rx='2'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <rect
        x='5.5'
        y='11'
        width='14'
        height='10'
        rx='2'
        stroke={color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='12.5'
        cy='16'
        r='1'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='12.5'
        cy='16'
        r='1'
        stroke={color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.5 11V6C8.5 3.79086 10.2909 2 12.5 2C14.7091 2 16.5 3.79086 16.5 6'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.5 11V6C8.5 3.79086 10.2909 2 12.5 2C14.7091 2 16.5 3.79086 16.5 6'
        stroke={color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
