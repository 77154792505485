import styled from 'styled-components';
import Styles from '../../../styles';
import { Button } from '@mui/material';
import colors from '../../../constants';

export const SettingsWrap = styled.div`
  width: 100%;
`;

export const UserForm = styled(Styles.Row)<{ theme: string }>`
  && {
    border-radius: 6px;
    background-color: ${(props) =>
      props.theme === 'light' ? colors['extra-white'] : colors['dark-theme-secondary']};
    box-shadow: 0px 4px 18px 0px #4b465c1a;
  }
`;

export const StyledButton = styled(Button)<{
  formStatus: string;
  formChange: string;
  theme: string;
}>`
  && {
    padding: 8px 20px;
    border-radius: 6px;
    gap: 8px;
    width: 125px;
    box-shadow: none;
    color: ${({ formStatus, formChange }) => (formStatus === formChange ? '#fff' : '#a8aaae')};
    background-color: ${({ formStatus, formChange, theme }) =>
      formStatus === formChange ? '#3b82f6' : theme === 'dark' ? colors['dark-theme-secondary'] : 'transparent'};
    text-transform: capitalize;
    @media (max-width: 768px) {
      width: 110px;
    }
    &:hover {
      color: #fff;
      box-shadow: none;
    }
    &:active {
      background-color: ${colors['primary-500']};
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
`;
