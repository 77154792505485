import { Custom } from '../assets/icons';

export const SelectUser = {
  card: [
    {
      icons: Custom.User,
      label: 'auth.roleSelection.user.title',
      title: 'auth.roleSelection.user.subTitle',
    },
    {
      icons: Custom.Lightbulb,
      label: 'auth.roleSelection.author.title',
      title: 'auth.roleSelection.author.subTitle',
    },
  ],
};
