import { UPLOAD_HISTORY } from '../URLs';
import { api } from './api';

export const uploadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadMediaHistory: builder.query<any, {page: number, limit: number}>({
      query: ({page , limit}) => {
        return {
          url: `${UPLOAD_HISTORY}?page=${page}&page_size=${limit}`,
          method: 'GET',
        };
      },
      providesTags: ['uploadHistory'],
    }),
  }),
});

export const { useUploadMediaHistoryQuery } = uploadApi;
