// BASE URL
export const BASE_URL = 'https://classcom.felixits.uz';

// AUTH
export const AUTH_CONFIRM = '/auth/confirm/';
export const AUTH_ME = '/auth/me/';
export const AUTH_REGISTER = '/auth/register/';
export const AUTH_ME_UPDATE = '/auth/me/update/';
export const AUTH_RESEND = '/auth/resend/';
export const AUTH_RESET_CONFIRM = '/auth/reset/confirm/';
export const AUTH_RESET_PASSWORD = '/auth/reset/password/';
export const AUTH_RESET_SET = '/auth/reset/set/';
export const AUTH_TOKEN = '/auth/token/';
export const AUTH_TOKEN_REFRESH = '/auth/token/refresh/';
export const AUTH_TOKEN_VERIFY = '/auth/token/verify/';
export const AUTH_CHANGE_PASSWORD = '/auth/change/password/';
export const AUTH_REGISTER_MODERATOR = '/auth/register/moderator/';
export const REGION = '/region/';
export const DISTRICTS = '/district/';
export const SCHOOL_TYPE = '/school-type/?institution=';

// OTHER
export const ANSWER = '/answer/';
export const CLASS = '/class/';
export const CLASS_GROUP = '/class-group/';
export const CLASS_TYPE = '/class-type/';
export const DAYS_OFF = '/days_off/';
export const DOWNLOAD_HISTORY = '/download/history/';
export const DOWNLOAD_MEDIA = '/download/media/';
export const DOWNLOAD_FILE = '/download/file/';
export const FEEDBACK = '/feedback/';
export const MEDIA = '/media/';
export const MODERATOR = '/moderator/';
export const NOTIFICATION = '/notification/';
export const PLAN = '/plan/';
export const RESOURCE = '/resource/';
export const RESOURCE_TYPES = '/types/resource-types';
export const MODERATOR_RESOURCES = '/moderator/resource-types';
export const SCHEDULE_CHOICE = '/schedule-choice/';
export const SCHEDULE = '/schedule/';
export const CREATE_SCHEDULE = '/schedule/';
export const GET_SCHEDULE_DAY = '/get-day-schedule/';
export const SCIENCE = '/science/';
export const SCIENCE_INFO = '/science-info/';
export const SCIENCE_GROUP = '/science/?science_grp';
export const SCIENCE_TYPE = '/science-types/?';
export const TOPIC = '/topic/';
export const QUARTER = '/quarters/';
export const WEEKS = '/weeks/?quarter=';
export const QUESTION = '/chats/';
export const SEARCH = '/search/';
export const CHANGE_ROLE = '/change-role/';
export const ORDERS = '/orders/';
export const UPLOAD_HISTORY = '/moderator/media/';
export const PAYMENT_CREATE = '/payment/create/';
