/* eslint-disable react/jsx-pascal-case */
import { FC, useState, MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { Typography } from '../../../../styles';
import { StyledMenu, NavbarFlag, StyledLangWrapper } from './lang.s';

interface ILang {
  lang: string;
  setLanguage: (lang: string) => void;
}

export const Lang: FC<ILang> = ({ lang, setLanguage }) => {
  const { i18n } = useTranslation('global');
  const [langAnchorEl, setLangAnchorEl] = useState<null | HTMLElement>(null);

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    setLanguage(lang);
  };

  const handleLangClick = (event: MouseEvent<HTMLElement>) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleLangClose = () => {
    setLangAnchorEl(null);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleLangClose);

    return () => {
      window.removeEventListener('scroll', handleLangClose);
    };
  }, []);

  return (
    <>
      <StyledLangWrapper
        align_items={'center'}
        gap={6}
        wrap={'nowrap'}
        onClick={handleLangClick}>
        <NavbarFlag
          src={lang === 'uz' ? '/images/flags/uz.png' : '/images/flags/ru.png'}
          alt='flag'
        />
        <Typography.paragraph>{lang === 'uz' ? "O'zbek" : 'Русский'}</Typography.paragraph>
      </StyledLangWrapper>
      <StyledMenu
        anchorEl={langAnchorEl}
        open={Boolean(langAnchorEl)}
        onClose={handleLangClose}
        disableScrollLock={true}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem
          onClick={() => {
            handleChangeLanguage('uz');
            handleLangClose();
          }}>
          <img src='/images/flags/uz.png' alt='flag' style={{ marginRight: '10px' }} />
          O'zbek
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleChangeLanguage('ru');
            handleLangClose();
          }}>
          <img src='/images/flags/ru.png' alt='flag' style={{ marginRight: '10px' }} />
          Русский
        </MenuItem>
      </StyledMenu>
    </>
  );
};
