import { useState, useEffect } from 'react';

export const useWebSocket = (url: string) => {
  const [messages, setMessages] = useState<any[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [ws, setWs] = useState<WebSocket | null>(null);

  useEffect(() => {
    const socket = new WebSocket(url);
    setWs(socket);

    socket.onopen = () => {
      setIsConnected(true);
      setError(null);

      if (socket.readyState === WebSocket.OPEN) {
        // socket.send('Salom, server!');
      } else {
      }
    };

    socket.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data);
        if(localStorage.getItem('messages') === '0'){
          setMessages([]);
        }
        setMessages((prevMessages) => [...prevMessages, parsedData]);
      } catch (e) {}
    };

    socket.onerror = (event) => {
      const errorMessage = (event as any).message || 'Unknown error';
      setError('WebSocket error: ' + errorMessage);
    };

    socket.onclose = (event) => {
      setIsConnected(false);
      setError(`WebSocket closed with code: ${event.code}, reason: ${event.reason}`);
    };

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [url]);

  return { messages, isConnected, error };
};
