/* eslint-disable react/jsx-pascal-case */
import { FC, MouseEvent, useEffect, useState, useCallback } from 'react';
import { TextField, Menu, MenuItem, Button } from '@mui/material';
import {
  MenuBtn,
  NavbarItemsWrapper,
  NavbarWrap,
  NotificationBtnWrapper,
  NotificationMsgWrapper,
  NotificationWrapper,
  StyledInputAdornment,
  StyledSearchInputWrapper,
} from './navbar.s';
import Styles, { Typography } from '../../../styles';
import Icons from '../../../assets/icons';
import { Profile } from './profile';
import { Lang } from './lang';
import { useTranslation } from 'react-i18next';
import { useSearchQuery } from '../../../store/services/search';
import colors from '../../../constants';
import { useWebSocket } from '../../../hooks';
import {
  useNotificationQuery,
  useNotificationReadedMutation,
} from '../../../store/services/notificationApi';
import { formatNotificationDate } from '../../../helpers/formatNotificationDate';

interface INavbarProps {
  language: string;
  theme: string;
  setTheme: (state: string) => void;
  setLanguage: (lang: string) => void;
  openSidebar: boolean;
  setSearchData: (state: any) => void;
  closeSidebar: () => void;
  searchInput: string;
  setSearchInput: (state: string) => void;
}

const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export const Navbar: FC<INavbarProps> = ({
  theme,
  setTheme,
  language,
  setLanguage,
  openSidebar,
  setSearchData,
  closeSidebar,
  searchInput,
  setSearchInput,
}) => {
  const [t] = useTranslation('global');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchParams, setSearchParams] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [notificationCount, setNotificationCount] = useState(5);
  const { data: searchData, refetch } = useSearchQuery(searchParams);
  const { messages } = useWebSocket(
    `wss://classcom.felixits.uz/ws/notifications/?token=${localStorage.getItem('accessToken')}`
  );
  const [notificationInfo, setNotificationInfo] = useState(
    Number(localStorage.getItem('messages')) | 0
  );
  const {
    data: notificationData,
    refetch: notificationRefetch,
    isFetching,
  } = useNotificationQuery(notificationCount);
  const [readed] = useNotificationReadedMutation();

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setTheme(localStorage.getItem('theme') || 'light');
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [setTheme]);

  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    localStorage.setItem('messages', '0');
    setNotificationInfo(0);
  };

  const handleMenuClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleMenuClose);

    return () => {
      window.removeEventListener('scroll', handleMenuClose);
    };
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchInput(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setIsLoading(true);
      setSearchParams(query);
      refetch().finally(() => setIsLoading(false));
    }, 1000),
    [refetch]
  );

  const handleChangeTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    window.dispatchEvent(new Event('storage'));
  };

  const handleReaded = async (id: number) => {
    try {
      const formData = new FormData();
      formData.append('is_read', 'true');
      await readed({ id, body: formData });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchInput === '') {
      setSearchData(null);
    } else if (searchData) {
      setSearchData(searchData);
    }
  }, [searchData, setSearchData, searchInput]);


  useEffect(() => {
    if (messages.length > 0) {
      messages.forEach((msg: any) => {
        if (Notification.permission === 'granted') {
          new Notification(t('navbar.notificationTitle'), {
            body: msg.message,
            icon: 'path/to/icon.png',
          });
        }
      });
      notificationRefetch();
      localStorage.setItem('messages', messages.length.toString());
      setNotificationInfo(Number(localStorage.getItem('messages')));
    }
  }, [messages]);

  return (
    <NavbarWrap theme={theme}>
      <Styles.Row gap={12} wrap='nowrap' content='space-between' align_items={'center'}>
        <StyledSearchInputWrapper>
          <TextField
            placeholder={t('navbar.search')}
            type='search'
            fullWidth
            value={searchInput}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <StyledInputAdornment position='start'>
                  {isLoading ? (
                    <Icons.Loader
                      color={theme === 'dark' ? colors['grey-200'] : colors['extra-dark']}
                    />
                  ) : (
                    <Icons.Search
                      color={theme === 'dark' ? colors['grey-500'] : colors['extra-dark']}
                    />
                  )}
                </StyledInputAdornment>
              ),
            }}
          />
        </StyledSearchInputWrapper>

        {/* Lang, Theme, Notifications, Profile */}
        <MenuBtn>
          <Styles.Row
            content='center'
            width='26px'
            align_items={'center'}
            style={{ marginRight: '12px', cursor: 'pointer' }}
            onClick={handleMenuClick}>
            <Icons.Bell
              color={theme === 'dark' ? colors['grey-500'] : colors['extra-dark']}
              notificationCount={notificationInfo}
            />
            <Menu
              disableScrollLock={true}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                style: {
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  maxHeight: 200,
                  maxWidth: 300,
                  minWidth: 300,
                  padding: 10,
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#bdbdbd transparent',
                },
              }}>
              <NotificationWrapper theme={theme}>
                <Typography.h5 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                  {t('navbar.notification')}
                </Typography.h5>
              </NotificationWrapper>
              {notificationData?.results?.map((msg: any, index: number) => (
                <MenuItem
                  style={{
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                  }}
                  sx={{
                    padding: '10px 7px !important',
                    marginBottom: '0px',
                  }}
                  onClick={() => handleReaded(msg.id)}
                  key={index}>
                  <NotificationMsgWrapper
                    align_items={'center'}
                    wrap={'nowrap'}
                    gap={12}
                    theme={theme}>
                    <Styles.Row width='auto'>
                      <Icons.Point
                        color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
                      />
                    </Styles.Row>
                    <Styles.Row direction={'column'} gap={2}>
                      <Typography.paragraph
                        color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                        {formatNotificationDate(msg.created_at)}
                      </Typography.paragraph>
                      <Typography.paragraph
                        color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                        {msg.message}
                      </Typography.paragraph>
                    </Styles.Row>
                  </NotificationMsgWrapper>
                </MenuItem>
              ))}
              <NotificationBtnWrapper content={'center'} theme={theme}>
                {isFetching ? (
                  <Icons.Loader
                    color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
                  />
                ) : (
                  <Button
                    disabled={notificationData?.count === notificationData?.results.length}
                    onClick={() => {
                      setNotificationCount(notificationCount + 5);
                    }}>
                    <Typography.h5
                      color={
                        theme === 'dark'
                          ? notificationData?.count === notificationData?.results.length
                            ? 'dark-theme-primary'
                            : 'grey-200'
                          : notificationData?.count === notificationData?.results.length
                          ? 'grey-100'
                          : 'typography-body'
                      }>
                      {t('navbar.notificationBtn')}
                    </Typography.h5>
                  </Button>
                )}
              </NotificationBtnWrapper>
            </Menu>
          </Styles.Row>
          <Styles.Row onClick={closeSidebar}>
            <Icons.Menu2 />
          </Styles.Row>
        </MenuBtn>
        <NavbarItemsWrapper>
          <Styles.Row wrap='nowrap' align_items='center' gap={16}>
            {/* Lang */}
            <Lang lang={language} setLanguage={setLanguage} />
            {/* Theme Toggle */}
            <Styles.Row content='center' width='26px' onClick={handleChangeTheme}>
              {theme === 'light' ? <Icons.Sun /> : <Icons.MoonStars color={colors['grey-500']} />}
            </Styles.Row>
            {/* Notifications */}
            <Styles.Row
              content='center'
              width='26px'
              align_items={'center'}
              style={{ marginRight: '12px', cursor: 'pointer' }}
              onClick={handleMenuClick}>
              <Icons.Bell
                color={theme === 'dark' ? colors['grey-500'] : colors['extra-dark']}
                notificationCount={notificationInfo}
              />
              <Menu
                disableScrollLock={true}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                  style: {
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    maxHeight: 200,
                    maxWidth: 300,
                    minWidth: 300,
                    padding: 10,
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#bdbdbd transparent',
                  },
                }}>
                <NotificationWrapper theme={theme}>
                  <Typography.h5 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                    {t('navbar.notification')}
                  </Typography.h5>
                </NotificationWrapper>
                {notificationData?.results?.map((msg: any, index: number) => (
                  <MenuItem
                    style={{
                      whiteSpace: 'normal',
                      overflowWrap: 'break-word',
                    }}
                    sx={{
                      padding: '10px 7px !important',
                      marginBottom: '0px',
                    }}
                    onClick={() => handleReaded(msg.id)}
                    key={index}>
                    <NotificationMsgWrapper
                      align_items={'center'}
                      wrap={'nowrap'}
                      gap={12}
                      theme={theme}>
                      <Styles.Row width='auto'>
                        <Icons.Point
                          color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
                        />
                      </Styles.Row>
                      <Styles.Row direction={'column'} gap={2}>
                        <Typography.paragraph
                          color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                          {formatNotificationDate(msg.created_at)}
                        </Typography.paragraph>
                        <Typography.paragraph
                          color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                          {msg.message}
                        </Typography.paragraph>
                      </Styles.Row>
                    </NotificationMsgWrapper>
                  </MenuItem>
                ))}
                <NotificationBtnWrapper content={'center'} theme={theme}>
                  {isFetching ? (
                    <Icons.Loader
                      color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
                    />
                  ) : (
                    <Button
                      disabled={notificationData?.count === notificationData?.results.length}
                      onClick={() => {
                        setNotificationCount(notificationCount + 5);
                      }}>
                      <Typography.h5
                        color={
                          theme === 'dark'
                            ? notificationData?.count === notificationData?.results.length
                              ? 'dark-theme-primary'
                              : 'grey-200'
                            : notificationData?.count === notificationData?.results.length
                            ? 'grey-100'
                            : 'typography-body'
                        }>
                        {t('navbar.notificationBtn')}
                      </Typography.h5>
                    </Button>
                  )}
                </NotificationBtnWrapper>
              </Menu>
            </Styles.Row>
          </Styles.Row>
          {/* Profile */}
          <Profile theme={theme} />
        </NavbarItemsWrapper>
      </Styles.Row>
    </NavbarWrap>
  );
};
