/* eslint-disable react/jsx-pascal-case */
import { FC, useState, useEffect, useMemo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Icons from '../../../../../../../../assets/icons';
import {
  Table,
  Btn,
  TableWrapper,
  StyledTableCell,
  StyledTableRow,
  BtnTableCell,
  ScienceTableCell,
} from './table.s';
import Styles, { Typography } from '../../../../../../../../styles';
import { AddLessonModal } from './components';
import { useTranslation } from 'react-i18next';

const titles = [
  { title: 'mainPage.mainTable.header.number', small: true },
  { title: 'mainPage.mainTable.header.lesson', small: false },
  { title: 'mainPage.mainTable.header.class', small: true },
  { title: 'mainPage.mainTable.header.classType', small: false },
  { title: 'mainPage.mainTable.header.start', small: true },
  { title: 'mainPage.mainTable.header.end', small: true },
];

interface ITableContainer {
  tableId: number;
  theme: string;
  lessons: any[];
  slot: any[];
  deleteState: boolean;
  dateByIndex: string;
  updateSchedules: (updatedSchedules: any) => void;
}

export const TableContent: FC<ITableContainer> = ({
  theme,
  tableId,
  slot,
  deleteState,
  lessons,
  dateByIndex,
  updateSchedules,
}) => {
  const [animation, setAnimation] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState('');
  const [t] = useTranslation('global');

  const rows = useMemo(() => {
    const tempRows = Array(6).fill(null);
    lessons.forEach((lesson) => {
      const timeIndex = parseInt(lesson.lessonTime, 10) - 1;
      if (timeIndex >= 0 && timeIndex < 6) {
        tempRows[timeIndex] = lesson;
      }
    });
    return tempRows.map((row) => row || {});
  }, [lessons]);

  const handleEditLessonClick = (lesson: any) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelectedLesson(lesson);
    setIsModalOpen(true);
  };

  const handleAddLessonClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    event.stopPropagation();
    setSelectedLesson(null);
    setSelectedRowIndex(index.toString());
    setIsModalOpen(true);
  };

  const handleDeleteLessonClick = (lesson: any) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    updateSchedules((prevSchedules: any) =>
      prevSchedules.filter((schedule: any) => schedule.id !== lesson.id)
    );
  };

  const saveLesson = (updatedLesson: any) => {
    updateSchedules((prevSchedules: any) => {
      const existingLessonIndex = prevSchedules.findIndex(
        (schedule: any) => schedule.id === updatedLesson.id
      );
      if (existingLessonIndex > -1) {
        return prevSchedules.map((schedule: any, index: number) =>
          index === existingLessonIndex ? updatedLesson : schedule
        );
      } else {
        return [...prevSchedules, updatedLesson];
      }
    });
  };

  useEffect(() => {
    setAnimation(false);
    setTimeout(() => {
      setAnimation(true);
    }, 200);
  }, [slot]);

  console.log();
  return (
    <TableWrapper theme={theme}>
      <Styles.Row>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {titles.map((title, index) => (
                  <StyledTableCell sx={{ maxWidth: title.small ? '110px' : '' }} key={index}>
                    <Typography.paragraphSmallSemiBold
                      color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                      {t(`${title.title}`)}
                    </Typography.paragraphSmallSemiBold>
                  </StyledTableCell>
                ))}
                <BtnTableCell></BtnTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index} active={row?.id} hover={true}>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  {row?.subjectTypeName === '' ? (
                    <StyledTableCell>
                      <Typography.h6 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                        s
                      </Typography.h6>
                    </StyledTableCell>
                  ) : (
                    <ScienceTableCell>
                      <Typography.h6 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                        {row?.subjectTypeName}
                      </Typography.h6>
                    </ScienceTableCell>
                  )}

                  <StyledTableCell>
                    <Typography.h6 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                      {row?.class ? (
                        <>
                          {row?.className || ''} ({row?.classGroupName || ''})
                        </>
                      ) : (
                        ''
                      )}
                    </Typography.h6>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography.h6 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                      {row?.classTypeName || ''}
                    </Typography.h6>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography.h6 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                      {row?.startTime || ''}
                    </Typography.h6>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography.h6 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                      {row?.endTime || ''}
                    </Typography.h6>
                  </StyledTableCell>
                  <StyledTableCell>
                    {row?.id ? (
                      <Styles.Row gap={8}>
                        <Btn onClick={(event) => handleEditLessonClick(row)(event)}>
                          <Icons.Edit />
                        </Btn>
                        {deleteState && (
                          <Btn onClick={(event) => handleDeleteLessonClick(row)(event)}>
                            <Icons.Trash color='white' />
                          </Btn>
                        )}
                      </Styles.Row>
                    ) : (
                      <Btn onClick={(event) => handleAddLessonClick(event, index)}>
                        <Icons.Plus />
                      </Btn>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Styles.Row>
      <AddLessonModal
        tableId={tableId}
        theme={theme}
        rowIndex={selectedRowIndex}
        modalStatus={isModalOpen}
        setModalStatus={setIsModalOpen}
        lesson={selectedLesson}
        saveLesson={saveLesson}
      />
    </TableWrapper>
  );
};
