import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const Puzzle: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color = colors.white,
  ...props
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.5 7H7.5C8.05228 7 8.5 6.55228 8.5 6V5C8.5 3.89543 9.39543 3 10.5 3C11.6046 3 12.5 3.89543 12.5 5V6C12.5 6.55228 12.9477 7 13.5 7H16.5C17.0523 7 17.5 7.44772 17.5 8V11C17.5 11.5523 17.9477 12 18.5 12H19.5C20.6046 12 21.5 12.8954 21.5 14C21.5 15.1046 20.6046 16 19.5 16H18.5C17.9477 16 17.5 16.4477 17.5 17V20C17.5 20.5523 17.0523 21 16.5 21H13.5C12.9477 21 12.5 20.5523 12.5 20V19C12.5 17.8954 11.6046 17 10.5 17C9.39543 17 8.5 17.8954 8.5 19V20C8.5 20.5523 8.05228 21 7.5 21H4.5C3.94772 21 3.5 20.5523 3.5 20V17C3.5 16.4477 3.94772 16 4.5 16H5.5C6.60457 16 7.5 15.1046 7.5 14C7.5 12.8954 6.60457 12 5.5 12H4.5C3.94772 12 3.5 11.5523 3.5 11V8C3.5 7.44772 3.94772 7 4.5 7'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.5 7H7.5C8.05228 7 8.5 6.55228 8.5 6V5C8.5 3.89543 9.39543 3 10.5 3C11.6046 3 12.5 3.89543 12.5 5V6C12.5 6.55228 12.9477 7 13.5 7H16.5C17.0523 7 17.5 7.44772 17.5 8V11C17.5 11.5523 17.9477 12 18.5 12H19.5C20.6046 12 21.5 12.8954 21.5 14C21.5 15.1046 20.6046 16 19.5 16H18.5C17.9477 16 17.5 16.4477 17.5 17V20C17.5 20.5523 17.0523 21 16.5 21H13.5C12.9477 21 12.5 20.5523 12.5 20V19C12.5 17.8954 11.6046 17 10.5 17C9.39543 17 8.5 17.8954 8.5 19V20C8.5 20.5523 8.05228 21 7.5 21H4.5C3.94772 21 3.5 20.5523 3.5 20V17C3.5 16.4477 3.94772 16 4.5 16H5.5C6.60457 16 7.5 15.1046 7.5 14C7.5 12.8954 6.60457 12 5.5 12H4.5C3.94772 12 3.5 11.5523 3.5 11V8C3.5 7.44772 3.94772 7 4.5 7'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
