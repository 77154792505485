import { useEffect, useState } from "react";

export const useMultiSelectControl = () => {
    const [openSelectId, setOpenSelectId] = useState<string | null>(null);
  
    useEffect(() => {
      const handleScroll = () => {
        if (openSelectId !== null) {
          setOpenSelectId(null);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [openSelectId]);
  
    const open = (id: string) => setOpenSelectId(id);
    const close = () => setOpenSelectId(null);
  
    return {
      openSelectId,
      open,
      close,
    };
  };
  