import styled from 'styled-components';
import Styles from '../../../../../../../../../../styles';
import { Box, Button } from '@mui/material';
import colors from '../../../../../../../../../../constants';

export const AccordionWrapper = styled(Styles.Row)``;

export const AccordionButton = styled(Button)<{ state?: boolean; theme: string }>`
  && {
    padding: 12px 16px;
    border-radius: 12px;
    margin: 0px 3px;
    border: ${(props) => (props.state ? '1px solid blue' : 'none')};
    background-color: ${(props) =>
      props.theme === 'light' ? colors['extra-white'] : colors['dark-theme-secondary']};
    box-shadow: ${(props) =>
      props.theme === 'light'
        ? '0px 0px 5px 1px rgba(165, 163, 174, 0.3)'
        : '0px 2px 4px 0px rgba(15, 20, 34, 0.4)'};
  }
`;

export const ResourcesButton = styled(Button)<{ theme: string }>`
  && {
    padding: 12px 16px;
    border-radius: 12px;
    height: 48px;
    margin: 0px 5px;
    transition: opacity 0.3s ease;
    background-color: ${(props) =>
      props.theme === 'light' ? colors['extra-white'] : colors['dark-theme-secondary']};
    box-shadow: ${(props) =>
      props.theme === 'light'
        ? '0px 0px 5px 1px rgba(165, 163, 174, 0.3)'
        : '0px 2px 4px 0px rgba(15, 20, 34, 0.4)'};
  }
`;

export const AccordionResWrapper = styled(Styles.Row)`
  && {
    margin-bottom: 3px;
  }
`;

export const StyledButton = styled(Button)`
  && {
    min-width: 0px;
  }
`;

export const StyledBox = styled(Box)`
  && {
    gap: 4px;
    margin: 0px 3px;
  }
`;
