/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect } from 'react';
import Styles from '../../../../../styles';
import {
  AnsweredQuestionWrapper,
  Image,
  ImageAdminWrapper,
  ImageUserWrapper,
  StyledAdminTypogarphy,
  StyledUserParagraphSm,
  StyledUserTypogarphy,
} from './answered-question.s';
import { useGetQuestionsQuery } from '../../../../../store/services/questionApi';
import Icons from '../../../../../assets/icons';
import colors from '../../../../../constants';
import { extractTime } from '../../../../../lib/getDay';
import { imageRender } from '../../../../../helpers/imageRender';
import { useTranslation } from 'react-i18next';

interface IAnsweredQuestions {
  message: boolean;
}

export const AnsweredQuestion: FC<IAnsweredQuestions> = ({ message }) => {
  const { data: getAnswer, refetch } = useGetQuestionsQuery();
  const reversedResults = getAnswer.slice().reverse();
  const [t] = useTranslation('global');

  useEffect(() => {
    refetch();
  }, [message]);

  return (
    <AnsweredQuestionWrapper gap={16} align_content={'flex-start'}>
      {reversedResults.map((res: any, index: number) => (
        <Styles.Row key={res.id} direction={'column'} gap={16} style={{ height: 'fit-content' }}>
          <Styles.Row wrap={'nowrap'} content={'flex-end'} gap={6}>
            <Styles.Row gap={6} align_items={'flex-end'} direction={'column'}>
              <StyledUserTypogarphy color='white'>{res.massage}</StyledUserTypogarphy>
              <StyledUserParagraphSm color='typography-muted'>
                {res.responsed ? (
                  <Icons.Check width={18} height={18} color={colors['success-500']} />
                ) : null}
                {(() => {
                  const { hour, month, day } = extractTime(res.time);
                  return (
                    <>
                      {hour}
                      {t(month)}
                      {day}
                    </>
                  );
                })()}
              </StyledUserParagraphSm>
            </Styles.Row>
            <ImageUserWrapper content={'center'} align_items={'center'}>
              <Image
                src={
                  res.user.avatar === null
                    ? '/images/navbar/Placeholder.png'
                    : imageRender(res.user.avatar)
                }
                alt='Avatar'
              />
            </ImageUserWrapper>
          </Styles.Row>
          {res.responsed && (
            <Styles.Row wrap={'nowrap'} gap={6}>
              <ImageAdminWrapper content={'center'} align_items={'center'}>
                <img src='/images/Avatar.png' alt='Avatar' />
              </ImageAdminWrapper>
              <Styles.Row gap={6} align_items={'flex-start'} direction={'column'}>
                <StyledAdminTypogarphy color='typography-muted'>
                  {res.response}
                </StyledAdminTypogarphy>
                <StyledUserParagraphSm color='typography-muted'>
                  {(() => {
                  const { hour, month, day } = extractTime(res.response_time);
                  return (
                    <>
                      {hour}
                      {t(month)}
                      {day}
                    </>
                  );
                })()}
                </StyledUserParagraphSm>
              </Styles.Row>
            </Styles.Row>
          )}
        </Styles.Row>
      ))}
    </AnsweredQuestionWrapper>
  );
};
