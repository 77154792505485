import styled from 'styled-components';
import Styles from '../../../../../styles';
import { Box, Button, MenuItem, Select, Snackbar, TextField } from '@mui/material';
import colors from '../../../../../constants';

export const AccountFormWrap = styled.div`
  width: 100%;
`;

export const UserInfo = styled(Styles.Row)<{theme: string}>`
  && {
    padding: 24px;
    border-bottom: ${({theme}) => theme === 'dark' ? '1px solid rgba(67, 73, 104, 1)' : '1px solid #dbdade'};
  };
`;

export const UserAvatar = styled(Styles.Row)`
  && {
    background-color: #a8aaae29;
    border-radius: 6px;
    width: 100px;
    height: 100px;
  }
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    &.Mui-selected {
      color: ${colors['white']} !important;
    }
    &:focus {
      background-color: ${colors['primary-500']} !important; /* Цвет фокуса */
      color: ${colors['white']} !important; /* Цвет текста при фокусе */
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important; 
    }
  }
`;

export const StyledRow = styled(Styles.Row)`
  && {
    padding: 24px;
  }
`;

export const UserForm = styled.form`
  width: 100%;
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 170px;
    gap: 8px;
    padding: 10px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const SecondaryButton = styled(Button)`
  && {
    width: 170px;
    gap: 8px;
    padding: 10px;
    background-color: ${colors['grey-200']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 192px;
    height: 43px;
    color: ${colors['secondary-500']};
    input {
      height: 9px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 192px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const StyledBox = styled(Box)<{ selectedImage: string }>`
  && {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
    background-image: ${({ selectedImage }) => `url(${selectedImage})`};
  }
`;

export const StyledUnknownBox = styled(Box)<{ avatar: string | null }>`
  && {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ avatar }) =>
      avatar === null ? colors['opacity-extra-dark16'] : 'transparent'};
    background-image: ${({ avatar }) => (avatar !== null ? `url(${avatar})` : 'none')};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const Bar = styled(Snackbar)`
  & .MuiSnackbarContent-root {
    background-color: ${colors.white};
    color: ${colors.black};
    display: flex;
    justify-content: center;
  }
`;
