import { FC } from 'react';
import { Img, ResetPasswordVerificationWrap } from './reset-password-verification.s';
import Styles from '../../../../styles';
import AuthComp from '../../components';
import { ResetPasswordVerificationForm } from './components';

interface IResetPasswordVerificationProps {}

export const ResetPasswordVerification: FC<IResetPasswordVerificationProps> = (props) => {
  return (
    <ResetPasswordVerificationWrap>
      <AuthComp.LogInLeftContent>
        <Img src='/images/auth-illustration/confirmPageImage.png' alt='two' />
      </AuthComp.LogInLeftContent>
      <Styles.Row content={'center'}>
        <ResetPasswordVerificationForm />
      </Styles.Row>
    </ResetPasswordVerificationWrap>
  );
};
