import AuthPages from './auth';
import ModeratorPages from './moderator';
import { PageNotFound } from './page-not-found';
import UserPages from './user';

const Pages = {
  AuthPages,
  UserPages,
  ModeratorPages,
  PageNotFound,
};

export default Pages;
