import { Chip, Table, TableCell, TableContainer } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../../../constants';
import Styles from '../../../../../styles';

export const PaymnetHistoryWrap = styled.div`
  width: 100%;
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
    width: 100%;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const ScienceChip = styled(Chip)`
  && {
    border: none;
    color: ${colors['primary-500']};
    background-color: ${colors['opacity-primary32']};
  }
`;

export const StyledTableCell = styled(TableCell)`
  min-width: 250px;
`;

export const StateChip = styled(Chip)<{status: boolean}>`
  && {
    border: none;
    color: ${({status}) => status ? colors['success-500'] : colors['danger-500']};
    background-color: ${({status}) => status ? colors['opacity-success32'] : colors['opacity-danger32']};
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors['grey-300']};
    max-width: 100%;
    overflow-x: auto;
  }
`;
export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;
