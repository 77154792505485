import { Button, MenuItem, Select, TextField } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../../../../../../constants';
import Styles from '../../../../../../../../styles';
import { PatternFormat } from 'react-number-format';
import { motion } from 'framer-motion';

const wrapperStart = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.6, ease: 'circOut' } },
};

const firstInputs = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.6, ease: 'circOut' } },
};

const secondInputs = {
  hidden: { opacity: 0, x: 20 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.7, ease: 'circOut' } },
};

const thirdInputs = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.8, ease: 'circOut' } },
};

const fourthInputs = {
  hidden: { opacity: 0, x: 20 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.9, ease: 'circOut' } },
};

const fifthInputs = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0, transition: { duration: 1, ease: 'circOut' } },
};

export const AccountFormWrap = styled(motion.div).attrs(() => ({
  variants: wrapperStart,
  initial: 'hidden',
  animate: 'visible',
}))`
  && {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FirstRows = styled(motion(Styles.Row)).attrs(() => ({
  variants: firstInputs,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const SecondRows = styled(motion(Styles.Row)).attrs(() => ({
  variants: secondInputs,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const ThirdRows = styled(motion(Styles.Row)).attrs(() => ({
  variants: thirdInputs,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const FourthRows = styled(motion(Styles.Row)).attrs(() => ({
  variants: fourthInputs,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const FifthRows = styled(motion(Styles.Row)).attrs(() => ({
  variants: fifthInputs,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const StepsWrap = styled.div`
  && {
    width: 100%;
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
`;

export const IconWrapper = styled(Styles.Row)<{ index: number }>`
  && {
    background-color: ${(props) =>
      props.index === 0 ? colors['primary-500'] : colors['secondary-200']};
    border-radius: 6px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 300px;
    border: ${colors['secondary-400']};
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
    @media (max-width: 992px) {
      width: 238px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
`;

export const StyledBigSelect = styled(Select)`
  && {
    width: 300px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
    @media (max-width: 992px) {
      width: 238px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
`;

export const StyledPhoneFormat = styled(PatternFormat)<{
  errorColor?: boolean;
}>`
  height: 45px;
  padding: 10px 14px;
  width: 300px;
  color: ${colors['typography-muted']};
  border: 1px solid
    ${(props) => (props.errorColor ? colors['danger-600'] : colors['secondary-400'])};
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  &:focus {
    color: black;
    border-color: ${colors['opacity-primary']};
  }
  @media (max-width: 992px) {
    width: 238px;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 300px;
    overflow: auto;
    color: ${colors['secondary-500']};
    @media (max-width: 992px) {
      width: 238px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    &.Mui-selected {
      color: ${colors['white']} !important;
    }
    &:focus {
      background-color: ${colors['primary-500']} !important; /* Цвет фокуса */
      color: ${colors['white']} !important; /* Цвет текста при фокусе */
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important;
    }
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 192px;
    gap: 8px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const SecondaryButton = styled(Button)`
  && {
    width: 192px;
    gap: 8px;
    background-color: ${colors['opacity-primary40']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;
