import { FC, ReactNode } from 'react';
import { TBody, TD, TDLessonSpan, TDStatenSpan, THead, TR, TableWrap } from './table.s';
import { Typography } from '../../../styles';

interface ITableHeader {
  label: string | number;
}

interface IBodyItem {
  [key: string]: ReactNode;
}
interface ITableProps {
  header: ITableHeader[];
  body: IBodyItem[];
  bodyKey: string[];
}

export const Table: FC<ITableProps> = ({ header, body, bodyKey }) => {
  return (
    <TableWrap>
      <THead>
        <TR>
          {header.map(({ label }) => (
            <TD key={label}>
              <Typography.paragraphSmallSemiBold color='typography-heading'>
                {label}
              </Typography.paragraphSmallSemiBold>
            </TD>
          ))}
        </TR>
      </THead>
      <TBody>
        {body.map((item, index) => (
          <TR key={index}>
            {bodyKey.map((subItem) => (
              <TD key={subItem}>
                {subItem === 'lesson' ? (
                  <TDLessonSpan>{item[subItem]}</TDLessonSpan>
                ) : subItem === 'state' || subItem === 'status' ? (
                  <TDStatenSpan state={item[subItem]}>{item[subItem]}</TDStatenSpan>
                ) : (
                  <Typography.paragraph color='typography-body'>
                    {item[subItem]}
                  </Typography.paragraph>
                )}
              </TD>
            ))}
          </TR>
        ))}
      </TBody>
    </TableWrap>
  );
};
