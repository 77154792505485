import { QUARTER } from '../URLs';
import { api } from './api';

export const quaterApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getQuater: builder.query<any, void>({
      query: () => ({
        url: QUARTER,
        method: 'GET',
      }),
      providesTags: ['quaters'],
    }),
  }),
});

export const { useGetQuaterQuery } = quaterApi;
