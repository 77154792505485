import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const Link: FC<IIconProps> = ({ className, width = 24, height = 24, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.5 14C11.1583 14.6719 12.0594 15.0505 13 15.0505C13.9406 15.0505 14.8417 14.6719 15.5 14L19.5 10C20.8807 8.6193 20.8807 6.38072 19.5 5.00001C18.1193 3.6193 15.8807 3.6193 14.5 5.00001L14 5.50001'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.5 14C11.1583 14.6719 12.0594 15.0505 13 15.0505C13.9406 15.0505 14.8417 14.6719 15.5 14L19.5 10C20.8807 8.6193 20.8807 6.38072 19.5 5.00001C18.1193 3.6193 15.8807 3.6193 14.5 5.00001L14 5.50001'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.4999 9.99997C13.8416 9.32809 12.9405 8.94946 11.9999 8.94946C11.0592 8.94946 10.1582 9.32809 9.49989 9.99997L5.49989 14C4.11918 15.3807 4.11918 17.6193 5.49989 19C6.8806 20.3807 9.11918 20.3807 10.4999 19L10.9999 18.5'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.4999 9.99997C13.8416 9.32809 12.9405 8.94946 11.9999 8.94946C11.0592 8.94946 10.1582 9.32809 9.49989 9.99997L5.49989 14C4.11918 15.3807 4.11918 17.6193 5.49989 19C6.8806 20.3807 9.11918 20.3807 10.4999 19L10.9999 18.5'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
