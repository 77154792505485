import { FC, useEffect, useState } from 'react';
import { RegisterMultistepsFormWrap } from './registerMultisteps-form.s.ts';
import Styles from '../../../../../../styles';
import { AccountForm, ContractForm, RatingForm } from './components/index';

interface IRegisterMultistepsFormProps {}

export const RegisterMultistepsForm: FC<IRegisterMultistepsFormProps> = (props) => {
  const [pageState, setPageState] = useState(() => {
    return localStorage.getItem('pageState') || 'account';
  });
  const [mainFile, setMainFile] = useState<File[] | null>(null);
  const [accountForm, setAccountForm] = useState(false);
  const [ratingForm, setRatingForm] = useState(false);

  useEffect(() => {
    localStorage.setItem('pageState', pageState);
  }, [pageState]);

  return (
    <RegisterMultistepsFormWrap>
      <Styles.Row>
        {pageState === 'account' && (
          <AccountForm
            pageState={pageState}
            setPageState={setPageState}
            setAccountForm={setAccountForm}
          />
        )}
        {pageState === 'rating' && (
          <RatingForm
            pageState={pageState}
            setPageState={setPageState}
            setMainFile={setMainFile}
            mainFile={mainFile}
            setRatingForm={setRatingForm}
          />
        )}
        {pageState === 'contract' && (
          <ContractForm
            accountForm={accountForm}
            ratingForm={ratingForm}
            pageState={pageState}
            setPageState={setPageState}
            mainFile={mainFile}
          />
        )}
      </Styles.Row>
    </RegisterMultistepsFormWrap>
  );
};
