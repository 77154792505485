/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectChangeEvent } from '@mui/material';
import { FormContainer, Grid, Button, StyledMenu } from './addLessonModal.s';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  clearSchedule,
  selectSchedule,
} from '../../../../../../../../../../store/slices/scheduleSlice';
import mock from '../../../../../../../../../../mock';
import colors from '../../../../../../../../../../constants';
import CommonList from '../../../../../../../../../../components/common';
import Styles, { Typography } from '../../../../../../../../../../styles';
import {
  useGetScienceByClassQuery,
  useGetScienceTypeQuery,
} from '../../../../../../../../../../store/services/scienceApi';
import {
  useGetAllClassQuery,
  useGetClassGroupQuery,
  useGetClassTypeQuery,
} from '../../../../../../../../../../store/services/classApi';

interface IAddLessonModal {
  theme: string;
  tableId: number;
  modalStatus: boolean;
  lesson: any;
  rowIndex: string;
  setModalStatus: (status: boolean) => void;
  saveLesson: (lesson: any) => void;
}

interface IFormInputValues {
  shift: string;
  subject: number;
  subjectName: string;
  subjectType: number;
  subjectTypeName: string;
  class: number;
  className: string;
  days: string;
  startTime: Dayjs;
  endTime: Dayjs;
  lessonTime: string;
  classType: number;
  classTypeName: string;
  classGroup: string;
  classGroupName: string;
}

const lessons = [
  { id: '1', value: 1 },
  { id: '2', value: 2 },
  { id: '3', value: 3 },
  { id: '4', value: 4 },
  { id: '5', value: 5 },
  { id: '6', value: 6 },
];

export const AddLessonModal: FC<IAddLessonModal> = ({
  lesson,
  modalStatus,
  rowIndex,
  setModalStatus,
  tableId,
  theme,
  saveLesson,
}) => {
  const [t, i18n] = useTranslation('global');
  const dispatch = useDispatch();
  const [classGrp, setClassGrp] = useState(0);
  const [scienceGrp, setScienceGrp] = useState(0);
  const { data: classGroupData } = useGetClassGroupQuery(i18n.language);
  const { data: classTypeData } = useGetClassTypeQuery(i18n.language);
  const { data: classData } = useGetAllClassQuery();
  const { data: scienceData } = useGetScienceByClassQuery({
    id: classGrp,
    lang: i18n.language,
  });
  const selectedSchedule = useSelector(selectSchedule);
  const { data: scienceTypesData } = useGetScienceTypeQuery({
    class_group: classGrp,
    science: scienceGrp,
    lang: i18n.language,
  });

  const addLessonValidationSchema = Yup.object().shape({
    shift: Yup.string().required(`${t('mainPage.mainModal.errors.shift')}`),
    class: Yup.number().min(1, `${t('mainPage.mainModal.errors.class')}`),
    subject: Yup.number().min(1, `${t('mainPage.mainModal.errors.subject')}`),
    classType: Yup.string().required(`${t('mainPage.mainModal.errors.classType')}`),
    classGroup: Yup.string().required(`${t('mainPage.mainModal.errors.classGroup')}`),
    days: Yup.string().required(`${t('mainPage.mainModal.errors.days')}`),
    lessonTime: Yup.string().required(`${t('mainPage.mainModal.errors.lessonTime')}`),
  });

  const initialValues: IFormInputValues = {
    shift: lesson?.shift || 'MORNING',
    subject: lesson?.subject || 0,
    subjectName: lesson?.subjectName || '',
    subjectType: lesson?.subject || 0,
    subjectTypeName: lesson?.subjectName || '',
    class: lesson?.class || 0,
    className: lesson?.className || '',
    days: lesson?.days || mock.days[tableId]?.value,
    classType: lesson?.classType || 0,
    classTypeName: lesson?.classTypeName || '',
    classGroup: lesson?.classGroup || '',
    classGroupName: lesson?.classGroupName || '',
    startTime: lesson?.startTime ? dayjs(lesson.startTime, 'HH:mm') : dayjs('06:00', 'HH:mm'),
    endTime: lesson?.endTime ? dayjs(lesson.endTime, 'HH:mm') : dayjs('07:45', 'HH:mm'),
    lessonTime: lesson?.lessonTime || lessons[Number(rowIndex)]?.id,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addLessonValidationSchema,
    onSubmit: (values) => {
      const newLesson = {
        id: lesson?.id || Date.now(),
        shift: values.shift,
        subject: values.subject,
        subjectName: values.subjectName === '' ? lesson?.subjectName : values.subjectName,
        subjectType: values.subjectType,
        subjectTypeName:
          values.subjectTypeName === '' ? lesson?.subjectTypeName : values.subjectTypeName,
        class: values.class,
        className: values.className === '' ? lesson?.className : values.className,
        classType: values.classType,
        classTypeName: values.classTypeName === '' ? lesson?.classTypeName : values.classTypeName,
        classGroup: values.classGroup,
        classGroupName:
          values.classGroupName === '' ? lesson?.classGroupName : values.classGroupName,
        days: values.days,
        startTime: values.startTime.format('HH:mm'),
        endTime: values.endTime.format('HH:mm'),
        lessonTime: values.lessonTime,
      };
      saveLesson(newLesson);
      setModalStatus(false);
    },
  });

  const handleSelectChange =
    (name: string, nameField: string, data: any) => (event: SelectChangeEvent<string | number>) => {
      const selectedId = event.target.value;
      if (name === 'classType') {
        setClassGrp(Number(event.target.value) as number);
      } else if (name === 'subject') {
        setScienceGrp(event.target.value as number);
      }
      const selectedItem = data.find((item: any) => item.id === selectedId) || { id: '', name: '' };
      formik.setFieldValue(name, selectedId);
      formik.setFieldValue(nameField, selectedItem.name);
    };

  useEffect(() => {
    if (lesson) {
      formik.setValues({
        ...formik.values,
        shift: lesson.shift || 'MORNING',
        subject: lesson.subject || 0,
        subjectType: lesson.subjectType || 0,
        class: lesson.class || 0,
        classType: lesson.classType || '',
        classGroup: lesson.classGroup || '',
        days: lesson.days || mock.days[tableId]?.value,
        startTime: lesson.startTime ? dayjs(lesson.startTime, 'HH:mm') : dayjs('06:00', 'HH:mm'),
        endTime: lesson.endTime ? dayjs(lesson.endTime, 'HH:mm') : dayjs('07:45', 'HH:mm'),
        lessonTime: lesson.lessonTime || lessons[Number(rowIndex)]?.id,
      });
    } else {
      formik.resetForm();
    }
  }, [lesson, modalStatus]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      lessonTime: lessons[Number(rowIndex)]?.id,
    });
  }, [rowIndex]);

  useEffect(() => {
    if (!modalStatus) {
      const timeoutId = setTimeout(() => {
        dispatch(clearSchedule());
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [modalStatus, dispatch]);

  return (
    <CommonList.Modal modalStatus={modalStatus} setModalStatus={setModalStatus}>
      <Styles.Row direction={'column'} gap={20}>
        <Styles.Row direction={'column'} gap={12}>
          <Typography.h3 align='center' color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {selectedSchedule.shift
              ? t('mainPage.mainModal.editTitle')
              : t('mainPage.mainModal.title')}
          </Typography.h3>
          <Typography.paragraph
            align='center'
            color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {selectedSchedule.shift
              ? t('mainPage.mainModal.editDescription')
              : t('mainPage.mainModal.description')}
          </Typography.paragraph>
        </Styles.Row>
        <FormContainer onSubmit={formik.handleSubmit}>
          <Styles.Row direction={'column'} gap={16}>
            {/* Shift Select */}
            <Styles.Row wrap={{ xs: 'wrap', md: 'nowrap' }} gap={8}>
              <Styles.Row direction={'column'}>
                <Typography.labelSm
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                  as={'label'}
                  htmlFor='lesson subject'
                  subColor='danger-500'>
                  {t('mainPage.mainModal.inputs.shift')}
                  <span>*</span>
                </Typography.labelSm>
                <Select
                  id='lesson shift'
                  name='shift'
                  value={formik.values.shift}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.shift) && Boolean(formik.touched.shift)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '200px',
                      width: '250px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  <StyledMenu value={'MORNING'}>
                    {t('mainPage.mainModal.inputs.firstShift')}
                  </StyledMenu>
                  <StyledMenu value={'EVENING'}>
                    {t('mainPage.mainModal.inputs.secondShift')}
                  </StyledMenu>
                </Select>
                {formik.errors.shift && formik.touched.shift ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.shift}
                  </Typography.paragraphSmall>
                ) : (
                  ''
                )}
              </Styles.Row>
              {/* ClassType Select */}
              <Styles.Row direction={'column'}>
                <Typography.labelSm
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                  as={'label'}
                  htmlFor='lesson days'
                  subColor='danger-500'>
                  {t('mainPage.mainModal.inputs.classType')}
                  <span>*</span>
                </Typography.labelSm>
                <Select
                  id='classType'
                  name='classType'
                  renderValue={(selected: any) => {
                    if (selected === 0) {
                      return <>{t('auth.userReg.inputs.placeholder')}</>;
                    }

                    const selectedClassGroup = classGroupData?.results.find(
                      (group: any) => group.id === selected
                    );
                    return selectedClassGroup ? selectedClassGroup.name : '';
                  }}
                  value={formik.values.classType}
                  onChange={handleSelectChange(
                    'classType',
                    'classTypeName',
                    classGroupData?.results || []
                  )}
                  sx={(theme) => ({
                    [theme.breakpoints.down(767)]: {
                      width: '300px',
                    },
                  })}
                  error={Boolean(formik.errors.classType) && Boolean(formik.touched.classType)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '200px',
                      width: '250px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {classGroupData?.results &&
                    classGroupData?.results.map((classGroup: any, index: number) => (
                      <StyledMenu key={index} value={classGroup.id}>
                        {classGroup.name}
                      </StyledMenu>
                    ))}
                </Select>
                {formik.errors.classType && formik.touched.classType ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.classType}
                  </Typography.paragraphSmall>
                ) : (
                  ''
                )}
              </Styles.Row>
            </Styles.Row>
            {/* Time Inputs */}
            <Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Styles.Row direction={'column'}>
                  <Typography.labelSm
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                    as={'label'}
                    htmlFor='Lesson start time'
                    subColor='danger-500'>
                    {t('mainPage.mainModal.inputs.startTime')}
                    <span>*</span>
                  </Typography.labelSm>
                  <DesktopTimePicker
                    reduceAnimations
                    value={formik.values.startTime}
                    ampm={false}
                    onChange={(newValue) => {
                      formik.setValues({
                        ...formik.values,
                        startTime:
                          newValue instanceof dayjs && newValue.isValid()
                            ? newValue
                            : dayjs('06:00', 'HH:mm'),
                      });
                    }}
                    slotProps={{
                      layout: {
                        sx: {
                          '& .MuiMultiSectionDigitalClockSection-root::after': {
                            height: '0 !important',
                          },
                        },
                      },
                      popper: {
                        sx: {
                          transition: 'none !important',
                        },
                      },
                      desktopTransition: {
                        style: {
                          transition: 'none !important',
                        },
                      },
                      textField: {
                        sx: {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(0, 0, 0, 0.23) !important',
                          },
                        },
                      },
                    }}
                  />
                </Styles.Row>
                <Styles.Row direction={'column'}>
                  <Typography.labelSm
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                    as={'label'}
                    htmlFor='Lesson end time'
                    subColor='danger-500'>
                    {t('mainPage.mainModal.inputs.endTime')}
                    <span>*</span>
                  </Typography.labelSm>
                  <DesktopTimePicker
                    reduceAnimations
                    value={formik.values.endTime}
                    ampm={false}
                    onChange={(newValue) => {
                      formik.setValues({
                        ...formik.values,
                        endTime:
                          newValue instanceof dayjs && newValue.isValid()
                            ? newValue
                            : dayjs('07:45', 'HH:mm'),
                      });
                    }}
                    slotProps={{
                      layout: {
                        sx: {
                          '& .MuiMultiSectionDigitalClockSection-root::after': {
                            height: '0 !important',
                          },
                        },
                      },
                      popper: {
                        sx: {
                          transition: 'none !important',
                        },
                      },
                      desktopTransition: {
                        style: {
                          transition: 'none !important',
                        },
                      },
                      textField: {
                        sx: {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(0, 0, 0, 0.23) !important',
                          },
                        },
                      },
                    }}
                  />
                </Styles.Row>
              </LocalizationProvider>
            </Grid>
            {/* Subject & Subject Type Select */}
            <Styles.Row wrap={{ xs: 'wrap', md: 'nowrap' }} gap={8}>
              <Styles.Row direction={'column'}>
                <Typography.labelSm
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                  as={'label'}
                  htmlFor='lesson subject'
                  subColor='danger-500'>
                  {t('mainPage.mainModal.inputs.lesson')}
                  <span>*</span>
                </Typography.labelSm>
                <Select
                  id='lesson subject'
                  name='subject'
                  disabled={classGrp === 0}
                  value={formik.values.subject}
                  renderValue={(selected: any) => {
                    if (formik.values.subject === 0) {
                      return <>{t('auth.userReg.inputs.sciencePlaceholder')}</>;
                    }

                    const findScienceName = (id: number) => {
                      const science = scienceData?.results.find((item: any) => item.id === id);
                      return science ? science.name : '';
                    };

                    return selected ? (
                      findScienceName(selected)
                    ) : (
                      <>{t('auth.userReg.inputs.placeholder')}</>
                    );
                  }}
                  onChange={handleSelectChange(
                    'subject',
                    'subjectName',
                    scienceData?.results || []
                  )}
                  sx={(theme) => ({
                    [theme.breakpoints.down(767)]: {
                      width: '300px',
                    },
                    [theme.breakpoints.up(767)]: {
                      width: '253px',
                    },
                  })}
                  error={Boolean(formik.errors.subject) && Boolean(formik.touched.subject)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '200px',
                      minWidth: 'auto',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {scienceData?.results &&
                    scienceData?.results.map((science: any, index: number) => (
                      <StyledMenu key={index} value={science.id}>
                        {science.name}
                      </StyledMenu>
                    ))}
                </Select>
                {formik.errors.subject && formik.touched.subject ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.subject}
                  </Typography.paragraphSmall>
                ) : (
                  ''
                )}
              </Styles.Row>

              <Styles.Row direction={'column'}>
                <Typography.labelSm
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                  as={'label'}
                  htmlFor='lesson subjectType'
                  subColor='danger-500'>
                  {t('mainPage.mainModal.inputs.lessonType')}
                  <span>*</span>
                </Typography.labelSm>
                <Select
                  id='lesson subjectType'
                  name='subjectType'
                  disabled={scienceGrp === 0}
                  value={formik.values.subjectType}
                  onChange={handleSelectChange(
                    'subjectType',
                    'subjectTypeName',
                    scienceTypesData?.results || []
                  )}
                  renderValue={(selected: any) => {
                    if (formik.values.subjectType === 0) {
                      return <>{t('auth.userReg.inputs.scienceGrpPlaceholder')}</>;
                    }

                    const findScienceName = (id: number) => {
                      const science = scienceTypesData?.results.find((item: any) => item.id === id);
                      return science ? science.name : '';
                    };

                    return selected ? (
                      findScienceName(selected)
                    ) : (
                      <>{t('auth.userReg.inputs.placeholder')}</>
                    );
                  }}
                  sx={(theme) => ({
                    [theme.breakpoints.down(768)]: {
                      width: '300px',
                    },
                    [theme.breakpoints.up(767)]: {
                      width: '253px',
                    },
                  })}
                  error={Boolean(formik.errors.subjectType) && Boolean(formik.touched.subjectType)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '200px',
                      width: '250px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {scienceTypesData?.results &&
                    scienceTypesData?.results.map((science: any, index: number) => (
                      <StyledMenu key={index} value={science.id}>
                        {science.name}
                      </StyledMenu>
                    ))}
                </Select>
                {formik.errors.subject && formik.touched.subject ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.subject}
                  </Typography.paragraphSmall>
                ) : (
                  ''
                )}
              </Styles.Row>
            </Styles.Row>
            {/* Days & Lesson hours Select */}
            <Styles.Row wrap={{ xs: 'wrap', md: 'nowrap' }} gap={8}>
              <Styles.Row direction={'column'}>
                <Typography.labelSm
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                  as={'label'}
                  htmlFor='lesson hours'
                  subColor='danger-500'>
                  {t('mainPage.mainModal.inputs.lessonHour')}
                  <span>*</span>
                </Typography.labelSm>
                <Select
                  id='lesson lessonTime'
                  name='lessonTime'
                  value={formik.values.lessonTime}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.lessonTime) && Boolean(formik.touched.lessonTime)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '200px',
                      width: '250px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {lessons
                    .filter((_, index) => index === Number(rowIndex))
                    .map((ls) => (
                      <StyledMenu key={ls.id} value={ls.id}>
                        {t(`${ls.value}`)}
                      </StyledMenu>
                    ))}
                </Select>
                {formik.errors.lessonTime && formik.touched.lessonTime ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.lessonTime}
                  </Typography.paragraphSmall>
                ) : (
                  ''
                )}
              </Styles.Row>
              <Styles.Row direction={'column'}>
                <Typography.labelSm
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                  as={'label'}
                  htmlFor='lesson days'
                  subColor='danger-500'>
                  {t('mainPage.mainModal.inputs.days')}
                  <span>*</span>
                </Typography.labelSm>
                <Select
                  id='lesson days'
                  name='days'
                  value={formik.values.days}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.days) && Boolean(formik.touched.days)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '200px',
                      width: '250px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {mock.days
                    .filter((_, index) => index === tableId)
                    .map((day) => (
                      <StyledMenu key={day.value} value={day.value}>
                        {t(`${day.label}`)}
                      </StyledMenu>
                    ))}
                </Select>
                {formik.errors.days && formik.touched.days ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.days}
                  </Typography.paragraphSmall>
                ) : (
                  ''
                )}
              </Styles.Row>
            </Styles.Row>

            {/* Class & Class Group  */}
            <Styles.Row wrap={{ xs: 'wrap', md: 'nowrap' }} gap={8}>
              <Styles.Row direction={'column'}>
                <Typography.labelSm
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                  as={'label'}
                  htmlFor='lesson hours'
                  subColor='danger-500'>
                  {t('mainPage.mainModal.inputs.class')}
                  <span>*</span>
                </Typography.labelSm>
                <Select
                  id='lesson class'
                  name='class'
                  value={formik.values.class}
                  onChange={handleSelectChange('class', 'className', classData?.results || [])}
                  error={Boolean(formik.errors.class) && Boolean(formik.touched.class)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '200px',
                      width: '250px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {classData?.results &&
                    classData?.results.map((classData: any, index: number) => (
                      <StyledMenu key={index} value={classData.id}>
                        {classData.name}
                      </StyledMenu>
                    ))}
                </Select>
                {formik.errors.class && formik.touched.class ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.class}
                  </Typography.paragraphSmall>
                ) : (
                  ''
                )}
              </Styles.Row>
              <Styles.Row direction={'column'}>
                <Typography.labelSm
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                  as={'label'}
                  htmlFor='lesson hours'
                  subColor='danger-500'>
                  {t('mainPage.mainModal.inputs.classGroup')}
                  <span>*</span>
                </Typography.labelSm>
                <Select
                  id='classGroup'
                  name='classGroup'
                  value={formik.values.classGroup}
                  onChange={handleSelectChange(
                    'classGroup',
                    'classGroupName',
                    classTypeData?.results || []
                  )}
                  error={Boolean(formik.errors.classGroup) && Boolean(formik.touched.classGroup)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '200px',
                      width: '250px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {classTypeData?.results &&
                    classTypeData?.results.map((classType: any, index: number) => (
                      <StyledMenu key={index} value={classType.id}>
                        {classType.name}
                      </StyledMenu>
                    ))}
                </Select>
                {formik.errors.classGroup && formik.touched.classGroup ? (
                  <Typography.paragraphSmall color='danger-600'>
                    {formik.errors.classGroup}
                  </Typography.paragraphSmall>
                ) : (
                  ''
                )}
              </Styles.Row>
            </Styles.Row>

            <Styles.Row
              width='auto'
              style={{ marginBottom: '20px' }}
              content={{ xs: 'space-between', md: 'flex-end' }}
              gap={10}>
              <Button
                color='primary-500'
                bg={colors['opacity-primary40']}
                type='button'
                onClick={() => setModalStatus(false)}>
                {t('mainPage.mainModal.inputs.btnCancel')}
              </Button>
              <Button type='submit' color='extra-white' bg={colors['primary-500']}>
                {t('mainPage.mainModal.inputs.btnSave')}
              </Button>
            </Styles.Row>
          </Styles.Row>
        </FormContainer>
      </Styles.Row>
    </CommonList.Modal>
  );
};
