export const classes = [
    { value: 1, label: '11-sinf' },
    { value: 2, label: '10-sinf' },
    { value: 3, label: '9-sinf' },
    { value: 4, label: '8-sinf' },
    { value: 5, label: '7-sinf' },
    { value: 6, label: '6-sinf' },
    { value: 7, label: '5-sinf' },
    { value: 8, label: '4-sinf' },
    { value: 9, label: '3-sinf' },
    { value: 10, label: '2-sinf' },
    { value: 11, label: '1-sinf' },
  ];
  