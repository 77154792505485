import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const Uzum: FC<IIconProps> = ({ className, width = 30, height = 30, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15Z'
        fill='#FFFF00'
      />
      <path
        d='M3.51666 13.8778C3.07362 11.3653 2.8521 10.1089 3.17186 9.06304C3.45313 8.14303 4.01844 7.3357 4.78676 6.75673C5.66022 6.09853 6.91651 5.87701 9.42912 5.43398L17.0192 4.09564C19.5318 3.6526 20.7881 3.43108 21.834 3.75084C22.754 4.03211 23.5613 4.59742 24.1403 5.36574C24.7985 6.2392 25.02 7.49549 25.463 10.0081L26.504 15.9116C26.947 18.4243 27.1686 19.6806 26.8488 20.7264C26.5675 21.6464 26.0022 22.4538 25.2339 23.0328C24.3604 23.6909 23.1041 23.9125 20.5915 24.3556L13.0015 25.6939C10.4889 26.1369 9.23258 26.3584 8.18669 26.0387C7.26669 25.7574 6.45934 25.1921 5.88038 24.4238C5.22218 23.5503 5.00065 22.294 4.55762 19.7814L3.51666 13.8778Z'
        fill='#7000FF'
      />
      <path
        d='M19.2787 10.3984C19.7313 10.4981 20.1692 10.5832 20.5989 10.6931C21.0555 10.8094 21.508 10.9506 21.9605 11.0771C22.0249 11.0958 22.0498 11.1208 22.0498 11.1934C22.0477 12.6069 22.0622 14.0226 22.0435 15.4362C22.0228 16.9556 21.5433 18.3296 20.6196 19.5376C19.5901 20.8848 18.2429 21.7524 16.5949 22.1469C15.9452 22.3025 15.283 22.3544 14.6167 22.315C13.033 22.2215 11.6298 21.6632 10.43 20.6274C9.19292 19.5626 8.41246 18.2217 8.09903 16.6151C8.00978 16.1585 7.97449 15.6956 7.97449 15.2306C7.97241 13.8918 7.97449 12.5551 7.97034 11.2163C7.97034 11.1269 7.9994 11.0958 8.08035 11.0709C8.94384 10.7948 9.82396 10.5769 10.7144 10.415C10.7227 10.4129 10.731 10.415 10.7517 10.4129C10.7517 10.4483 10.7517 10.4835 10.7517 10.5167C10.7517 12.1959 10.7455 13.8773 10.756 15.5565C10.7622 16.4034 10.8763 17.2399 11.1648 18.0432C11.3932 18.6804 11.7315 19.2492 12.2359 19.71C12.7092 20.1397 13.2634 20.4116 13.884 20.5444C14.762 20.7333 15.6379 20.7187 16.4994 20.4468C17.5165 20.1272 18.2222 19.4505 18.6747 18.4978C18.9176 17.9893 19.0629 17.4495 19.1542 16.8953C19.2559 16.2788 19.2808 15.6562 19.2808 15.0334C19.2808 13.5348 19.2808 12.0341 19.2808 10.5354C19.2787 10.4959 19.2787 10.4586 19.2787 10.3984Z'
        fill='white'
      />
      <path
        d='M16.2447 13.511C15.4165 13.511 14.5966 13.511 13.7726 13.511C13.7726 11.5806 13.7726 9.65228 13.7726 7.72603C14.0299 7.64508 15.7549 7.63885 16.2447 7.7198C16.2447 9.65022 16.2447 11.5806 16.2447 13.511Z'
        fill='white'
      />
      <path
        d='M19.2787 10.3984C19.7313 10.4981 20.1692 10.5832 20.5989 10.6931C21.0555 10.8094 21.508 10.9506 21.9605 11.0771C22.0249 11.0958 22.0498 11.1208 22.0498 11.1934C22.0477 12.6069 22.0622 14.0226 22.0435 15.4362C22.0228 16.9556 21.5433 18.3296 20.6196 19.5376C19.5901 20.8848 18.2429 21.7524 16.5949 22.1469C15.9452 22.3025 15.283 22.3544 14.6167 22.315C13.033 22.2215 11.6298 21.6632 10.43 20.6274C9.19292 19.5626 8.41246 18.2217 8.09903 16.6151C8.00978 16.1585 7.97449 15.6956 7.97449 15.2306C7.97241 13.8918 7.97449 12.5551 7.97034 11.2163C7.97034 11.1269 7.9994 11.0958 8.08035 11.0709C8.94384 10.7948 9.82396 10.5769 10.7144 10.415C10.7227 10.4129 10.731 10.415 10.7517 10.4129C10.7517 10.4483 10.7517 10.4835 10.7517 10.5167C10.7517 12.1959 10.7455 13.8773 10.756 15.5565C10.7622 16.4034 10.8763 17.2399 11.1648 18.0432C11.3932 18.6804 11.7315 19.2492 12.2359 19.71C12.7092 20.1397 13.2634 20.4116 13.884 20.5444C14.762 20.7333 15.6379 20.7187 16.4994 20.4468C17.5165 20.1272 18.2222 19.4505 18.6747 18.4978C18.9176 17.9893 19.0629 17.4495 19.1542 16.8953C19.2559 16.2788 19.2808 15.6562 19.2808 15.0334C19.2808 13.5348 19.2808 12.0341 19.2808 10.5354C19.2787 10.4959 19.2787 10.4586 19.2787 10.3984Z'
        fill='white'
      />
      <path
        d='M16.2447 13.511C15.4165 13.511 14.5966 13.511 13.7726 13.511C13.7726 11.5806 13.7726 9.65228 13.7726 7.72603C14.0299 7.64508 15.7549 7.63885 16.2447 7.7198C16.2447 9.65022 16.2447 11.5806 16.2447 13.511Z'
        fill='white'
      />
      <path
        d='M19.2787 10.3984C19.7313 10.4981 20.1692 10.5832 20.5989 10.6931C21.0555 10.8094 21.508 10.9506 21.9605 11.0771C22.0249 11.0958 22.0498 11.1208 22.0498 11.1934C22.0477 12.6069 22.0622 14.0226 22.0435 15.4362C22.0228 16.9556 21.5433 18.3296 20.6196 19.5376C19.5901 20.8848 18.2429 21.7524 16.5949 22.1469C15.9452 22.3025 15.283 22.3544 14.6167 22.315C13.033 22.2215 11.6298 21.6632 10.43 20.6274C9.19292 19.5626 8.41246 18.2217 8.09903 16.6151C8.00978 16.1585 7.97449 15.6956 7.97449 15.2306C7.97241 13.8918 7.97449 12.5551 7.97034 11.2163C7.97034 11.1269 7.9994 11.0958 8.08035 11.0709C8.94384 10.7948 9.82396 10.5769 10.7144 10.415C10.7227 10.4129 10.731 10.415 10.7517 10.4129C10.7517 10.4483 10.7517 10.4835 10.7517 10.5167C10.7517 12.1959 10.7455 13.8773 10.756 15.5565C10.7622 16.4034 10.8763 17.2399 11.1648 18.0432C11.3932 18.6804 11.7315 19.2492 12.2359 19.71C12.7092 20.1397 13.2634 20.4116 13.884 20.5444C14.762 20.7333 15.6379 20.7187 16.4994 20.4468C17.5165 20.1272 18.2222 19.4505 18.6747 18.4978C18.9176 17.9893 19.0629 17.4495 19.1542 16.8953C19.2559 16.2788 19.2808 15.6562 19.2808 15.0334C19.2808 13.5348 19.2808 12.0341 19.2808 10.5354C19.2787 10.4959 19.2787 10.4586 19.2787 10.3984Z'
        fill='white'
      />
      <path
        d='M16.2447 13.511C15.4165 13.511 14.5966 13.511 13.7726 13.511C13.7726 11.5806 13.7726 9.65228 13.7726 7.72603C14.0299 7.64508 15.7549 7.63885 16.2447 7.7198C16.2447 9.65022 16.2447 11.5806 16.2447 13.511Z'
        fill='white'
      />
      <path
        d='M19.2787 10.3984C19.7313 10.4981 20.1692 10.5832 20.5989 10.6931C21.0555 10.8094 21.508 10.9506 21.9605 11.0771C22.0249 11.0958 22.0498 11.1208 22.0498 11.1934C22.0477 12.6069 22.0622 14.0226 22.0435 15.4362C22.0228 16.9556 21.5433 18.3296 20.6196 19.5376C19.5901 20.8848 18.2429 21.7524 16.5949 22.1469C15.9452 22.3025 15.283 22.3544 14.6167 22.315C13.033 22.2215 11.6298 21.6632 10.43 20.6274C9.19292 19.5626 8.41246 18.2217 8.09903 16.6151C8.00978 16.1585 7.97449 15.6956 7.97449 15.2306C7.97241 13.8918 7.97449 12.5551 7.97034 11.2163C7.97034 11.1269 7.9994 11.0958 8.08035 11.0709C8.94384 10.7948 9.82396 10.5769 10.7144 10.415C10.7227 10.4129 10.731 10.415 10.7517 10.4129C10.7517 10.4483 10.7517 10.4835 10.7517 10.5167C10.7517 12.1959 10.7455 13.8773 10.756 15.5565C10.7622 16.4034 10.8763 17.2399 11.1648 18.0432C11.3932 18.6804 11.7315 19.2492 12.2359 19.71C12.7092 20.1397 13.2634 20.4116 13.884 20.5444C14.762 20.7333 15.6379 20.7187 16.4994 20.4468C17.5165 20.1272 18.2222 19.4505 18.6747 18.4978C18.9176 17.9893 19.0629 17.4495 19.1542 16.8953C19.2559 16.2788 19.2808 15.6562 19.2808 15.0334C19.2808 13.5348 19.2808 12.0341 19.2808 10.5354C19.2787 10.4959 19.2787 10.4586 19.2787 10.3984Z'
        fill='white'
      />
      <path
        d='M16.2447 13.511C15.4165 13.511 14.5966 13.511 13.7726 13.511C13.7726 11.5806 13.7726 9.65228 13.7726 7.72603C14.0299 7.64508 15.7549 7.63885 16.2447 7.7198C16.2447 9.65022 16.2447 11.5806 16.2447 13.511Z'
        fill='white'
      />
    </svg>
  );
};
<svg
  width='30'
  height='30'
  viewBox='0 0 30 30'
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
></svg>;
