import { DOWNLOAD_MEDIA, DOWNLOAD_FILE, DOWNLOAD_HISTORY } from '../URLs';
import { api } from './api';

export const downloadsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    downloadMedia: builder.query({
      query: (id: number | null) => {
        return {
          url: `${DOWNLOAD_MEDIA}${id}/`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          },
        };
      },
      providesTags: ['downloads'],
    }),
    downloadHistory: builder.query<any, {page: number, limit: number}>({
      query: ({page, limit}) => {
        return {
          url: `${DOWNLOAD_HISTORY}?page=${page}&page_size=${limit}`,
          method: 'GET',
        };
      },
      providesTags: ['downloads'],
    }),
    downloadFile: builder.query<any, string>({
      query: (token: string) => {
        return {
          url: `${DOWNLOAD_FILE}${token}/`,
          method: 'GET',
        };
      },
      providesTags: ['downloads'],
    }),
  }),
});

export const { useDownloadMediaQuery, useDownloadFileQuery, useDownloadHistoryQuery } = downloadsApi;
