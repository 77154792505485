import styled from 'styled-components';
import { InputAdornment } from '@mui/material';
import colors from '../../../constants';
import Styles from '../../../styles';

export const NavbarWrap = styled.nav<{ theme: string }>`
  width: 100%;
  height: 62px;
  box-shadow: ${(props) =>
    props.theme === 'light'
      ? '0px 2px 4px 0px rgba(165, 163, 174, 0.3)'
      : '0px 2px 4px 0px rgba(15, 20, 34, 0.4)'};
  background-color: ${(props) => (props.theme === 'light' ? '#ffffff' : 'rgba(47, 51, 73, 1)')};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 12px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const StyledInputAdornment = styled(InputAdornment)`
  cursor: pointer;
`;

export const NavbarItemsWrapper = styled.div`
  display: flex;
  height: 40px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledSearchInputWrapper = styled.div`
  flex: 1;

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  &.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: black;
  }

  .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root {
    height: 40px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const MenuBtn = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const NotificationWrapper = styled(Styles.Row)<{ theme: string }>`
  padding: 14px;
  border-bottom: ${(props) =>
    props.theme === 'dark'
      ? `0.1px solid ${colors['grey-50']}`
      : `0.1px solid ${colors['grey-200']}`};
`;

export const NotificationBtnWrapper = styled(Styles.Row)<{ theme: string }>`
  padding: 14px;
`;

export const NotificationMsgWrapper = styled(Styles.Row)<{ theme: string }>`
  padding-bottom: 14px;
  border-bottom: ${(props) =>
    props.theme === 'dark'
      ? `1px solid ${colors['grey-50']}`
      : `1px solid ${colors['grey-200']}`};
`;
