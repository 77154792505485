import Icons from '../assets/icons';
import { RouteLinkProps } from '../interfaces/global-interface';
import Pages from '../pages';

const AuthPages = Pages.AuthPages;
const UserPages = Pages.UserPages;
const ModeratorPages = Pages.ModeratorPages;

export const authRoute: RouteLinkProps[] = [
  {
    component: AuthPages.Login,
    path: '/login',
  },
  {
    component: AuthPages.Terms,
    path: '/terms',
  },
  {
    component: AuthPages.ResetPassword,
    path: '/reset-password',
  },
  {
    component: AuthPages.ResetPasswordVerification,
    path: '/author/reset-password-verification',
  },
  {
    component: AuthPages.NewPassword,
    path: '/author/new-password',
  },
  {
    component: AuthPages.SelectUser,
    path: '/author/select-user',
  },
  {
    component: AuthPages.UserRegister,
    path: '/author/user-register',
  },
  {
    component: AuthPages.UserVerification,
    path: '/author/user-verification',
  },
  {
    component: AuthPages.RegisterMultisteps,
    path: '/author/register-multisteps',
  },
  {
    component: AuthPages.ModeratorVerification,
    path: '/author/moderator-verification',
  },
  {
    component: AuthPages.UAuthSuccessful,
    path: '/author/u-success',
  },
  {
    component: AuthPages.MAuthSuccessful,
    path: '/author/m-success',
  },
  {
    component: AuthPages.ModeratorWaiting,
    path: '/author/m-waiting',
  },
];

export const userRoute: RouteLinkProps[] = [
  {
    component: UserPages.Main,
    path: '/main',
    name: 'sidebar.items.home',
    icon: Icons.SmartHome,
    paths: ['/main', '/main-author-resources', '/sample-create', '/sample-connect'],
  },
  {
    component: UserPages.SampleCreate,
    path: '/sample-create',
  },
  {
    component: UserPages.SampleConnect,
    path: '/sample-connect',
  },
  {
    component: UserPages.MainAuthorResources,
    path: '/main-author-resources/:id',
  },
  {
    component: UserPages.MainResourceView,
    path: '/main-resource/:id',
  },
  {
    component: UserPages.Resources,
    path: '/resources',
    name: 'sidebar.items.resources',
    icon: Icons.Book2,
    paths: ['/resources', '/resource-view/:id', '/resource-type/:id'],
  },
  {
    component: UserPages.Plans,
    path: '/plans',
    name: 'sidebar.items.plans',
    icon: Icons.Calendar,
    paths: ['/plans', '/subject-topics/:id', '/plan-view/:id', '/plan-resource-view/:id'],
  },
  {
    component: UserPages.Payment,
    name: 'sidebar.items.payment',
    icon: Icons.CurrencyDolar,
    path: '/payment',
    paths: ['/payment', '/add-order'],
  },
  {
    component: UserPages.Settings,
    path: '/settings',
    name: 'sidebar.items.settings',
    icon: Icons.Settings,
  },
  {
    component: UserPages.History,
    path: '/history',
    name: 'sidebar.items.history',
    icon: Icons.History,
  },
  {
    blockTitle: 'sidebar.blockTitle.necessary',
    component: UserPages.SendQuestions,
    path: '/help',
    name: 'sidebar.items.help',
    icon: Icons.Help,
  },
  {
    component: UserPages.AddOrder,
    path: '/add-order',
  },

  {
    component: UserPages.SubjectView,
    path: '/subject-topics/:id',
  },
  {
    component: UserPages.PlansView,
    path: '/plan-view/:id',
  },
  {
    component: UserPages.PlansResourceView,
    path: '/plan-resource-view/:id',
  },
  {
    component: UserPages.ResourcesView,
    path: '/resource-view/:id',
  },
  {
    component: UserPages.ResourcesType,
    path: '/resource-type/:id',
  },
  {
    component: UserPages.Terms,
    path: '/terms',
  },
];

export const moderatorRoute: RouteLinkProps[] = [
  {
    component: UserPages.Main,
    path: '/main',
    name: 'sidebar.items.home',
    icon: Icons.SmartHome,
    paths: ['/main', '/main-author-resources', '/sample-create', '/sample-connect'],
  },
  {
    component: UserPages.SampleCreate,
    path: '/sample-create',
  },
  {
    component: UserPages.SampleConnect,
    path: '/sample-connect',
  },
  {
    component: UserPages.MainAuthorResources,
    path: '/main-author-resources/:id',
  },
  {
    component: UserPages.MainResourceView,
    path: '/main-resource/:id',
  },
  {
    component: ModeratorPages.Resources,
    path: '/resources',
    name: 'sidebar.items.resources',
    icon: Icons.Book2,
    paths: ['/resources', '/new-resources', '/resource-view/:id', '/resource-type/:id'],
  },
  {
    component: ModeratorPages.Plans,
    path: '/plans',
    name: 'sidebar.items.plans',
    icon: Icons.Calendar,
    paths: [
      '/plans',
      '/new-plan',
      '/science-topics/:id',
      '/plan-view/:id',
      '/plan-resource-view/:id',
      '/new-topic/:id',
      '/edit-topic/:id',
      '/topic-resource/:id',
    ],
  },
  {
    blockTitle: 'sidebar.blockTitle.settings',
    component: ModeratorPages.Settings,
    path: '/settings',
    name: 'sidebar.items.settings',
    icon: Icons.Settings,
  },
  {
    component: ModeratorPages.History,
    path: '/history',
    name: 'sidebar.items.history',
    icon: Icons.History,
  },
  {
    blockTitle: 'sidebar.blockTitle.necessary',
    component: UserPages.SendQuestions,
    path: '/help',
    name: 'sidebar.items.help',
    icon: Icons.Help,
  },
  {
    component: ModeratorPages.AddPayment,
    path: '/add-payment',
  },
  {
    component: ModeratorPages.PlansResourceView,
    path: '/plan-resource-view/:id',
  },
  {
    component: ModeratorPages.ScienceTopics,
    path: '/science-topics/:id/',
  },
  {
    component: ModeratorPages.NewTopic,
    path: '/new-topic/:id/',
  },
  {
    component: ModeratorPages.EditTopic,
    path: '/edit-topic/:id/',
  },
  {
    component: ModeratorPages.TopicResource,
    path: '/topic-resource/:id/',
  },
  {
    component: ModeratorPages.NewResourse,
    path: '/new-resources',
    name: 'sidebar.items.newResources',
  },
  {
    component: ModeratorPages.AddPlan,
    path: '/add-plan',
  },
  {
    component: ModeratorPages.NewPlan,
    path: '/new-plan',
  },
  {
    component: ModeratorPages.PlansView,
    path: '/plan-view/:id',
  },
  {
    component: ModeratorPages.ResourcesView,
    path: '/resource-view/:id',
  },
  {
    component: ModeratorPages.ResourcesType,
    path: '/resource-type/:id',
  },
  {
    component: UserPages.Terms,
    path: '/terms',
  },
];
