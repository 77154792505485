import styled from 'styled-components';
import Styles from '../../../../../styles';

export const EmptySendQuestionWrapper = styled(Styles.Row)`
    height: calc(100vh - 400px);
`;

export const Image = styled.img`
    width: 303px;
    height: auto;
    object-fit: cover;
`;