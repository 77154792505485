import Icons from '../assets/icons';

export const Payment = {
  method: [
    {
      label: 'payment.main.method.second.header',
      icons: Icons.Uzcard,
      number: 'payment.main.method.second.number',
      name: 'payment.main.method.second.name',
    },
  ],
};
