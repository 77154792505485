import Icons from '../assets/icons';

export const Steps = {
  registration: [
    {
      label: 'auth.userReg.steps.accountTitle',
      title: 'auth.userReg.steps.accountSubTitle',
      icons: Icons.SmartHome,
      navigate: "account",
      chevron: true,
    },
    {
      label: 'auth.userReg.steps.starTitle',
      title: 'auth.userReg.steps.starSubTitle',
      navigate: "rating",
      icons: Icons.Stars,
      chevron: true,
    },
    {
      label: 'auth.userReg.steps.contractTitle',
      title: 'auth.userReg.steps.contractSubTitle',
      navigate: "contract",
      icons: Icons.FileText,
    },
  ],
  newPlan: [
    {
      header: 'plans.viewAddItem.steps.info',
      title: 'plans.viewAddItem.steps.info',
      content: 1,
      active: true,
      chevron: true,
    },
    {
      header: 'plans.viewAddItem.steps.resurs',
      title: 'plans.viewAddItem.steps.resursTitle',
      content: 2,
      active: false,
      chevron: false,
    },
  ],
  newPayment: [
    {
      header: 'plans.payment.addPayment.steps.first.info',
      title: 'plans.payment.addPayment.steps.first.title',
      content: 1,
      active: true,
      chevron: true,
    },
    {
      header: 'plans.payment.addPayment.steps.second.info',
      title: 'plans.payment.addPayment.steps.second.title',
      content: 2,
      active: false,
      chevron: false,
    },
  ],
};
