/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useRef, useState } from 'react';
import {
  ChatWrapper,
  ImageWrapper,
  QuestionsWrapper,
  StyledButton,
  StyledForm,
} from './send-questions.s';
import { TextField } from '@mui/material';
import Styles, { Typography } from '../../../styles';
import { AnsweredQuestion, EmptySendQuestion } from './components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAddQuestionMutation, useGetQuestionsQuery } from '../../../store/services/questionApi';
import { Custom } from '../../../assets/icons';
import colors from '../../../constants';
import { useAppTheme } from '../../../hooks';

interface ISendQuestions {}

export const SendQuestions: FC<ISendQuestions> = () => {
  const { theme } = useAppTheme();
  const [message, setMessage] = useState(false);
  const chatWrapperRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation('global');
  const [sendQuestion] = useAddQuestionMutation();
  const { data: getAnswer, refetch } = useGetQuestionsQuery();

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().required('Iltimos matin kiriting!'),
    }),
    validateOnChange: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        formData.append('massage', values.message);
        const response = await sendQuestion(formData);
        if (response) {
          setMessage(!message);
          resetForm();
        }
      } catch (error) {
        console.error('Submission error:', error);
      }
    },
  });

  const scrollToBottom = () => {
    if (getAnswer?.length > 5) {
      if (chatWrapperRef.current) {
        chatWrapperRef.current.scrollTop = chatWrapperRef.current.scrollHeight;
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };

    fetchData();
    scrollToBottom();
  }, [message, getAnswer]);

  scrollToBottom();

  return (
    <QuestionsWrapper>
      <StyledForm onSubmit={formik.handleSubmit}>
        <Styles.Row gap={16}>
          <Styles.Row wrap={'nowrap'} gap={12}>
            <ImageWrapper>
              <Custom.User width={'100%'} height={'100%'} color={theme === 'dark' ? colors['grey-400'] : undefined}/>
            </ImageWrapper>
            <Styles.Row direction={'column'}>
              <Typography.h6 color={ theme === 'dark' ? 'grey-200' : 'opacity-extra-questionText'}>{t('Administrator')}</Typography.h6>
              <Typography.paragraph color='typography-muted'>
                {t('sendQuestion.adminTitle')}
              </Typography.paragraph>
            </Styles.Row>
          </Styles.Row>
          <ChatWrapper theme={theme} ref={chatWrapperRef}>
            {getAnswer?.length > 0 ? <AnsweredQuestion message={message} /> : <EmptySendQuestion theme={theme} />}
          </ChatWrapper>
          <Styles.Row wrap={'nowrap'} gap={16}>
            <Styles.Row>
              <TextField
                name='message'
                id='message'
                placeholder={t('sendQuestion.placeholder')}
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.message}
              />
              {formik.errors.message && formik.touched.message ? (
                <Typography.paragraphSmall color='danger-600'>
                  {formik.errors.message}
                </Typography.paragraphSmall>
              ) : (
                ''
              )}
            </Styles.Row>
            <StyledButton variant='contained' type='submit'>
              {t('sendQuestion.button')}
            </StyledButton>
          </Styles.Row>
        </Styles.Row>
      </StyledForm>
    </QuestionsWrapper>
  );
};
