import { FC } from 'react';
import Icons from '../assets/icons';
import { IIconProps } from '../assets/icons/interfaces/icon-interface';

interface IMenu {
  [key: string]: { icon: FC<IIconProps>; label: string; link: string }[];
}

export const Menu: IMenu = {
  main: [
    {
      icon: Icons.SmartHome,
      label: 'main.home',
      link: '/main',
    },
    {
      icon: Icons.Book2,
      label: 'main.resources',
      link: '/resources',
    },
    {
      icon: Icons.Calendar,
      label: 'main.plans',
      link: '/plans',
    },
    {
      icon: Icons.Puzzle,
      label: 'main.tests',
      link: '/tests',
    },
  ],

  services: [
    {
      icon: Icons.CurrencyDolar,
      label: 'services.payment',
      link: '/payment',
    },
  ],

  settings: [
    {
      icon: Icons.Settings,
      label: 'settings.settings',
      link: '/settings',
    },
    {
      icon: Icons.History,
      label: 'settings.history',
      link: '/history',
    },
  ],

  necessary: [
    {
      icon: Icons.Help,
      label: 'necessary.question',
      link: '/help',
    },
    // {
    //   icon: Icons.PhoneCall,
    //   label: "necessary.call",
    //   link: "/call",
    // },
    // {
    //   icon: Icons.Logout,
    //   label: "necessary.logout",
    //   link: "/logout",
    // },
  ],
};
