/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import { HistoryWrap } from './history.s';
import Styles, { Typography } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Icons from '../../../assets/icons';
import colors from '../../../constants';
import { DownloadHistory, PaymentHistory } from './components';
import CommonList from '../../../components/common';
import { useAppTheme } from '../../../hooks';

interface IHistory {}

export const History: FC<IHistory> = () => {
  const {theme} = useAppTheme();
  const [historyState, setHistoryState] = useState(true);
  const [t] = useTranslation('global');
  
  return (
    <HistoryWrap>
      <Styles.Row gap={26}>
        <Styles.Row gap={4}>
          <Typography.h4 color='typography-muted'>{t('history.main.title')} /</Typography.h4>
          <Typography.h4 color={theme === 'dark' ? 'typography-placeholder' : 'typography-body'}>
            {historyState ? t('history.main.paymentTitle') : t('history.main.downloadTitle')}
          </Typography.h4>
        </Styles.Row>
        <Styles.Row wrap={'nowrap'} gap={4}>
          <Button
            variant='contained'
            startIcon={
              <Icons.CurrencyDolar
                color={historyState ? colors.white : colors['typography-muted']}
              />
            }
            onClick={() => setHistoryState(true)}
            sx={{
              boxShadow: 'none',
              backgroundColor: historyState ? colors['primary-500'] : theme === 'dark' ? colors['dark-theme-secondary'] : colors.white,
            }}
          >
            <Typography.paragraphSemiBold color={historyState ? 'white' : 'typography-muted'}>
              {t("history.main.paymentBtn")}
            </Typography.paragraphSemiBold>
          </Button>
          <Button
            variant='contained'
            startIcon={
              <Icons.Download color={historyState ? colors['typography-muted'] : colors.white} />
            }
            onClick={() => setHistoryState(false)}
            sx={{
              boxShadow: 'none',
              backgroundColor: historyState ? theme === 'dark' ? colors['dark-theme-secondary'] : colors.white : colors['primary-500'],
            }}
          >
            <Typography.paragraphSemiBold color={historyState ? 'typography-muted' : 'white'}>
            {t("history.main.downloadBtn")}
            </Typography.paragraphSemiBold>
          </Button>
        </Styles.Row>
        <Styles.Row>
          <CommonList.Container>
            {historyState ? <PaymentHistory /> : <DownloadHistory />}
          </CommonList.Container>
        </Styles.Row>
      </Styles.Row>
    </HistoryWrap>
  );
};
