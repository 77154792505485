import styled, { keyframes } from 'styled-components';
import { Table as MuiTable, TableCell, TableContainer, TableRow } from '@mui/material';
import colors from '../../../../../../../../constants';
import Styles, { Typography } from '../../../../../../../../styles';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(0); 
  }
  to {
    opacity: 1;
    transform: translateY(0); 
  }
`;

export const Table = styled(MuiTable)(({ theme }) => ({
  borderCollapse: 'collapse',
  '& tr': {
    border: `1px solid ${colors['extra-divider']}`,
  },
}));

export const Btn = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: ${colors['primary-500']};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableWrapper = styled.div<{theme: string}>`
  width: 100%;
  overflow-x: auto;
  border-top: ${({ theme }) => theme === 'dark' ? 'none' : '1px solid rgba(224, 224, 224, 1)'};
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
  }
`;

export const TableWrap = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors['grey-300']};
  }
`;

export const StyledTableRow = styled(TableRow)<{ active: boolean }>`
  && {
    background-color: ${(props) => (props.active ? 'white' : colors['grey-200'])};
    & .MuiTableCell-root {
      background-color: ${(props) => (props.active ? 'white' : colors['grey-200'])};
      border-bottom: 1px solid ${colors['grey-300']};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    /* animation: ${fadeIn} 0.5s ease-out; */
    height: 63px;
  }
`;

export const StyledTypographyParagraph = styled(Typography.paragraph)<{animate: boolean}>`
  && {
    opacity: ${(props) => (props.animate ? 1 : 0)};
    animation: ${fadeIn} 0.5s ease-out;
  }
`;

export const StyledTypographyParagraphSm = styled(Typography.paragraphSemiBold)<{animate: boolean}>`
  && {
    opacity: ${(props) => (props.animate ? 1 : 0)};
    animation: ${fadeIn} 0.5s ease-out;
  }
`;