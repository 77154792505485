import styled, { css } from 'styled-components';
import colors, { TColors } from '../../constants';

export type TTypographyProps = {
  color?: TColors;
  align?: 'center' | 'end' | 'justify' | 'left' | 'right' | 'start';
  subColor?: TColors;
};

const subColorStyle = css<TTypographyProps>`
  && {
    span {
      ${({ subColor }) => {
        return subColor?.substring(0, 8) === 'gradient'
          ? css`
              background: ${colors[subColor]};
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            `
          : css`
              color: ${subColor ? colors[subColor] : 'var(--grey-900, #101010)'};
            `;
      }}
    }
  }
`;

const globalStyle = css<TTypographyProps>`
  ${({ color }) => {
    return color?.substring(0, 8) === 'gradient'
      ? css`
          background: ${colors[color]};
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        `
      : css`
          color: ${color ? colors[color] : 'var(--grey-900, #101010)'};
        `;
  }}
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
  ${({ subColor }) => subColor && subColorStyle}
  font-family:  'Mulish', sans-serif;
  font-style: normal;
  text-transform: none;
`;

/* -------------------- display text style -------------------- */
export const display1 = styled.h1<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 4rem; /* 64px / 16px */
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 5.25rem; /* 84px / 16px */
  }
`;

export const display2 = styled.h1<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 3.5rem; /* 56px / 16px */
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 4.875rem; /* 78px / 16px */
  }
`;

export const display3 = styled.h1<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 3rem; /* 48px / 16px */
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 4.25rem; /* 68px / 16px */
  }
`;

export const display4 = styled.h1<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 2rem; /* 32px / 16px */
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 2.875rem; /* 46px / 16px */
  }
`;

/* -------------------- Heading text style -------------------- */
export const h1 = styled.h1<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 2.375rem; /* 38px / 16px */
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 3.25rem; /* 52px / 16px */
  }
`;

export const h2 = styled.h2<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 2rem; /* 32px / 16px */
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 2.75rem; /* 44px / 16px */
  }
`;

export const h3 = styled.h3<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 1.625rem; /* 26px / 16px */
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 2.25rem; /* 36px / 16px */
  }
`;

export const h4 = styled.h4<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 1.375rem; /* 22px / 16px */
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 1.875rem; /* 30px / 16px */
  }
`;

export const h5 = styled.h5<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 1.125rem; /* 18px / 16px */
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 1.5rem; /* 24px / 16px */
  }
`;

export const h6 = styled.h6<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.9375rem; /* 15px / 16px */
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 1.3125rem; /* 21px / 16px */
  }
`;

/* -------------------- Paragraph text style -------------------- */
export const paragraphLead = styled.p<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 1.125rem; /* 18px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.75rem; /* 28px / 16px */
  }
`;

export const paragraph = styled.p<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.9375rem; /* 15px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.375rem; /* 22px / 16px */
  }
`;

export const paragraphMedium = styled.p<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.9375rem; /* 15px / 16px */
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 1.375rem; /* 22px / 16px */
  }
`;

export const paragraphSemiBold = styled.p<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.9375rem; /* 15px / 16px */
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 1.375rem; /* 22px / 16px */
  }
`;

export const paragraphBold = styled.p<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.9375rem; /* 15px / 16px */
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 1.375rem; /* 22px / 16px */
  }
`;

export const paragraphSmall = styled.p<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.8125rem; /* 13px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.25rem; /* 20px / 16px */
  }
`;

export const paragraphSmallSemiBold = styled.p<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.8125rem; /* 13px / 16px */
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 0.875rem; /* 14px / 16px */
  }
`;

export const paragraphSmallBold = styled.p<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.8125rem; /* 13px / 16px */
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 1.25rem; /* 20px / 16px */
  }
`;

/* -------------------- Extra text style -------------------- */
export const deleteText = styled.del<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.9375rem; /* 15px / 16px */
    text-decoration: line-through;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.5rem; /* 24px / 16px */
  }
`;

export const italicized = styled.i<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.9375rem; /* 15px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.5rem; /* 24px / 16px */
    text-transform: lowercase;
  }
`;

export const underline = styled.u<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 1rem; /* 16px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.5rem; /* 24px / 16px */
  }
`;

export const caption = styled.caption<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.6875rem; /* 11px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 0.875rem; /* 14px / 16px */
  }
`;

/* -------------------- button text style -------------------- */
export const buttonLG = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 1.0625rem; /* 17px / 16px */
    font-weight: 500;
    letter-spacing: 0.026875rem; /* 0.43px / 16px */
    line-height: 1.375rem; /* 22px / 16px */
  }
`;

export const buttonDefault = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.9375rem; /* 15px / 16px */
    font-weight: 500;
    letter-spacing: 0.026875rem; /* 0.43px / 16px */
  }
`;

export const buttonSmall = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.8125rem; /* 13px / 16px */
    font-weight: 500;
    letter-spacing: 0.026875rem; /* 0.43px / 16px */
    line-height: 1rem; /* 16px / 16px */
  }
`;

/* -------------------- label text style -------------------- */
export const labelLg = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 1.125rem; /* 18px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
  }
`;

export const labelDefault = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.9375rem; /* 15px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
  }
`;

export const labelSm = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.8125rem; /* 13px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
  }
`;

/* -------------------- input text style -------------------- */
export const inputLg = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 1.125rem; /* 18px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
  }
`;

export const inputDefault = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.9375rem; /* 15px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
  }
`;

export const inputSm = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.8125rem; /* 13px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
  }
`;

/* -------------------- placeholder text style -------------------- */
export const placeholderLg = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 1.125rem; /* 18px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.375rem; /* 22px / 16px */
  }
`;

export const placeholderDefault = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.9375rem; /* 15px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.5rem; /* 24px / 16px */
  }
`;

export const placeholderSm = styled.button<TTypographyProps>`
  && {
    ${globalStyle}

    font-size: 0.8125rem; /* 13px / 16px */
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.3125rem; /* 21px / 16px */
  }
`;
