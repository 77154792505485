import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const Stars: FC<IIconProps> = ({ className, width = 25, height = 24, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.771 19.817L16.599 20.955C16.4675 21.0234 16.3087 21.0118 16.1885 20.9251C16.0684 20.8384 16.0074 20.6913 16.031 20.545L16.446 18.134L14.689 16.427C14.582 16.3235 14.5433 16.1681 14.5895 16.0266C14.6357 15.8851 14.7585 15.7824 14.906 15.762L17.334 15.41L18.42 13.217C18.4862 13.0837 18.6222 12.9995 18.771 12.9995C18.9197 12.9995 19.0557 13.0837 19.122 13.217L20.208 15.41L22.636 15.762C22.7829 15.7831 22.9051 15.8859 22.9512 16.027C22.9972 16.1682 22.9592 16.3232 22.853 16.427L21.096 18.134L21.51 20.544C21.5353 20.6906 21.475 20.8389 21.3545 20.9262C21.2341 21.0135 21.0744 21.0247 20.943 20.955L18.771 19.817V19.817Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.771 19.817L16.599 20.955C16.4675 21.0234 16.3087 21.0118 16.1885 20.9251C16.0684 20.8384 16.0074 20.6913 16.031 20.545L16.446 18.134L14.689 16.427C14.582 16.3235 14.5433 16.1681 14.5895 16.0266C14.6357 15.8851 14.7585 15.7824 14.906 15.762L17.334 15.41L18.42 13.217C18.4862 13.0837 18.6222 12.9995 18.771 12.9995C18.9197 12.9995 19.0557 13.0837 19.122 13.217L20.208 15.41L22.636 15.762C22.7829 15.7831 22.9051 15.8859 22.9512 16.027C22.9972 16.1682 22.9592 16.3232 22.853 16.427L21.096 18.134L21.51 20.544C21.5353 20.6906 21.475 20.8389 21.3545 20.9262C21.2341 21.0135 21.0744 21.0247 20.943 20.955L18.771 19.817V19.817Z'
        stroke='white'
        stroke-opacity='0.2'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.17134 19.817L4.99934 20.955C4.86789 21.0234 4.70909 21.0118 4.58893 20.9251C4.46878 20.8384 4.4078 20.6913 4.43134 20.545L4.84634 18.134L3.08934 16.427C2.98234 16.3235 2.94374 16.1681 2.98991 16.0266C3.03607 15.8851 3.15892 15.7824 3.30634 15.762L5.73434 15.41L6.82034 13.217C6.88659 13.0837 7.02255 12.9995 7.17134 12.9995C7.32013 12.9995 7.4561 13.0837 7.52234 13.217L8.60834 15.41L11.0363 15.762C11.1833 15.7831 11.3055 15.8859 11.3516 16.027C11.3976 16.1682 11.3595 16.3232 11.2533 16.427L9.49634 18.134L9.91034 20.544C9.93569 20.6906 9.87541 20.8389 9.75493 20.9262C9.63446 21.0135 9.4748 21.0247 9.34334 20.955L7.17134 19.817V19.817Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.17134 19.817L4.99934 20.955C4.86789 21.0234 4.70909 21.0118 4.58893 20.9251C4.46878 20.8384 4.4078 20.6913 4.43134 20.545L4.84634 18.134L3.08934 16.427C2.98234 16.3235 2.94374 16.1681 2.98991 16.0266C3.03607 15.8851 3.15892 15.7824 3.30634 15.762L5.73434 15.41L6.82034 13.217C6.88659 13.0837 7.02255 12.9995 7.17134 12.9995C7.32013 12.9995 7.4561 13.0837 7.52234 13.217L8.60834 15.41L11.0363 15.762C11.1833 15.7831 11.3055 15.8859 11.3516 16.027C11.3976 16.1682 11.3595 16.3232 11.2533 16.427L9.49634 18.134L9.91034 20.544C9.93569 20.6906 9.87541 20.8389 9.75493 20.9262C9.63446 21.0135 9.4748 21.0247 9.34334 20.955L7.17134 19.817V19.817Z'
        stroke='white'
        stroke-opacity='0.2'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.9711 9.81698L10.7991 10.955C10.6677 11.0234 10.5089 11.0118 10.3887 10.9251C10.2686 10.8384 10.2076 10.6913 10.2311 10.545L10.6461 8.13398L8.88915 6.42698C8.78215 6.32353 8.74354 6.16811 8.78971 6.02662C8.83588 5.88514 8.95873 5.78239 9.10615 5.76198L11.5341 5.40998L12.6201 3.21698C12.6864 3.08375 12.8224 2.99951 12.9711 2.99951C13.1199 2.99951 13.2559 3.08375 13.3221 3.21698L14.4081 5.40998L16.8361 5.76198C16.9831 5.78312 17.1053 5.88587 17.1514 6.02702C17.1974 6.16816 17.1594 6.32322 17.0531 6.42698L15.2961 8.13398L15.7101 10.544C15.7355 10.6906 15.6752 10.8389 15.5547 10.9262C15.4343 11.0135 15.2746 11.0247 15.1431 10.955L12.9711 9.81698V9.81698Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.9711 9.81698L10.7991 10.955C10.6677 11.0234 10.5089 11.0118 10.3887 10.9251C10.2686 10.8384 10.2076 10.6913 10.2311 10.545L10.6461 8.13398L8.88915 6.42698C8.78215 6.32353 8.74354 6.16811 8.78971 6.02662C8.83588 5.88514 8.95873 5.78239 9.10615 5.76198L11.5341 5.40998L12.6201 3.21698C12.6864 3.08375 12.8224 2.99951 12.9711 2.99951C13.1199 2.99951 13.2559 3.08375 13.3221 3.21698L14.4081 5.40998L16.8361 5.76198C16.9831 5.78312 17.1053 5.88587 17.1514 6.02702C17.1974 6.16816 17.1594 6.32322 17.0531 6.42698L15.2961 8.13398L15.7101 10.544C15.7355 10.6906 15.6752 10.8389 15.5547 10.9262C15.4343 11.0135 15.2746 11.0247 15.1431 10.955L12.9711 9.81698V9.81698Z'
        stroke='white'
        stroke-opacity='0.2'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
