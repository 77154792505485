import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../../constants';
import Styles from '../../../styles';
import { Typography } from '../../../styles';

interface SidebarWrapProps {
  menuStatus: boolean;
  menuOpen: boolean;
  theme: string;
}

export const SidebarMain = styled.div<{ menuStatus: boolean }>`
  && {
    min-width: ${({ menuStatus }) => (menuStatus ? '260px' : '84px')};
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const SidebarWrap = styled.aside<SidebarWrapProps>`
  width: ${({ menuStatus }) => (menuStatus ? '260px' : '84px')};
  height: 100vh;
  z-index: 1000;
  position: fixed;
  flex-shrink: 0;
  white-space: nowrap;
  box-shadow: ${({ theme }) =>
    theme === 'light'
      ? '0px 2px 4px 0px rgba(165, 163, 174, 0.3)'
      : '0px 2px 4px 0px rgba(15, 20, 34, 0.4)'};
  background-color: ${({ theme }) =>
    theme === 'light' ? colors['extra-white'] : colors['dark-theme-secondary']};
  transition: width 0.3s;
  overflow-x: hidden;

  @media (max-width: 768px) {
    transform: ${({ menuOpen }) => (menuOpen ? 'translateX(0)' : 'translateX(-100%)')};
    opacity: ${({ menuOpen }) => (menuOpen ? '1' : '0')};
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
`;

export const BurgerMenu = styled.div<{ open: boolean }>`
  && {
    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      left: 0;
      width: ${({ open }) => (open ? '100%' : '0')};
      height: ${({ open }) => (open ? '100%' : '0')};
      transition: opacity 0.3s ease; /* Анимация только для opacity */
      background-color: rgba(0, 0, 0, 0.5);
      align-items: center;
      opacity: ${({ open }) => (open ? '1' : '0')};
      justify-content: center;
      z-index: 100;
      display: flex;
    }
  }
`;

export const Img = styled.img`
  && {
    width: 25px;
  }
`;

export const SidebarHeader = styled.div<{ isOpen?: boolean }>`
  padding: 20px 18px;
  display: flex;
  align-items: center;
  justify-content: ${({ isOpen }) => (isOpen ? 'space-between' : 'center')};
`;

export const MenuBlock = styled(Styles.Row)<{ fontSize: string; menuStatus: boolean }>`
  padding: ${({ fontSize, menuStatus }) =>
    menuStatus === false ? '0px 14px' : fontSize === '20' ? '0px 5px' : '0px 14px'};
`;

export const Caption = styled(Typography.caption)`
  padding-left: 20px;
  margin-top: 20px;
`;

export const LinkBlock = styled(NavLink)<{ bg?: boolean; active?: boolean; fontSize: string }>`
  padding: ${({ fontSize }) =>
    fontSize === '20' ? '12px 5px' : fontSize === '18' ? '12px 12px' : '12px 20px'};
  width: 100%;
  border-radius: 6px;
  transition: none;
  background: ${({ bg }) => (bg ? colors['gradient-menu'] : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: ${({ active }) => (active ? '' : 'center')};
  gap: 10px;

  svg {
    flex-shrink: 0;
  }
`;

export const SidebarButton = styled.button<{ fontSize: string; menuStatus: boolean }>`
  padding: ${({ fontSize, menuStatus }) =>
    menuStatus === false
      ? '12px 18px'
      : fontSize === '20'
      ? '12px 8px'
      : fontSize === '18'
      ? '12px 20px 12px 15px'
      : '12px 20px 12px 22px'};
  width: 100%;
  border-radius: 6px;
  transition: none;
  background: transparent;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    flex-shrink: 0;
  }
`;

export const DotBtn = styled.button`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledH4 = styled(Typography.h4)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const SidebarLink = styled.a`
  display: flex;
  gap: 10px;
`;

export const CloseBtn = styled.button`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
