import { MAuthSuccessful, ModeratorVerification, ModeratorWaiting, RegisterMultisteps } from './Author-auth';
import { Login } from './Login-auth';
import { NewPassword, ResetPassword, ResetPasswordVerification } from './Password-auth';
import { SelectUser } from './Select-user-auth';
import { Terms } from './Terms-auth';
import { UAuthSuccessful, UserRegister, UserVerification } from './User-auth';



const AuthPages = {
  Login,
  ResetPassword,
  ResetPasswordVerification,
  NewPassword,
  SelectUser,
  RegisterMultisteps,
  Terms,
  ModeratorVerification,
  UAuthSuccessful,
  MAuthSuccessful,
  ModeratorWaiting,
  UserVerification,
  UserRegister,
};


export default AuthPages;
