import { FC, ReactNode } from 'react';
import { LeftContentWrap } from './left-content.s';

interface ILeftContentProps {
  children: ReactNode;
}

export const LeftContent: FC<ILeftContentProps> = ({ children }) => {
  return <LeftContentWrap>{children}</LeftContentWrap>;
};
