/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import { AuthSuccessfulWrap, Img } from './auth-successful.s';
import Styles, { Typography } from '../../../../styles';
import { Button, Typography as MuiTypo } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IAuthSuccessfulProps {}

export const UAuthSuccessful: FC<IAuthSuccessfulProps> = (props) => {
  const navigate = useNavigate();

  return (
    <AuthSuccessfulWrap>
      <Styles.Row>
        <Styles.Row gap={44} content={'center'}>
          <Styles.Row gap={4} align_items={'center'} direction={'column'}>
            <Styles.Row content='center'>
              <Typography.h2 color='typography-body' align='center'>
                Muvaffaqiyatli
              </Typography.h2>
            </Styles.Row>
            <Styles.Row content='center'>
              <Typography.h6 color='typography-muted' align='center'>
                Muallif sifatida ro`yhatdan o`tish muvaffaqiyatli amalga oshdi!
              </Typography.h6>
            </Styles.Row>
          </Styles.Row>
          <Styles.Row content={'center'}>
            <Img src='/images/auth-illustration/verify-illustration.png' alt='successful' />
          </Styles.Row>
          <Styles.Row content={'center'}>
            <Button
              onClick={() => navigate('/main')}
              variant='contained'
              sx={{
                backgroundColor: '#3B82F6',
                textTransform: 'capitalize',
                width: '400px',
                '@media (max-width: 768px)': {
                  width: '200px',
                },
              }}
            >
              <MuiTypo
                sx={{
                  fontSize: '13px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  textTransform: 'none',
                }}
              >
                Yahshi
              </MuiTypo>
            </Button>
          </Styles.Row>
        </Styles.Row>
      </Styles.Row>
    </AuthSuccessfulWrap>
  );
};
