import { FC } from 'react';

export const Logo: FC = () => {
  return (
    <svg
      width='30.000000'
      height='28.275879'
      viewBox='0 0 30 28.2759'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <desc>Created with Pixso.</desc>
      <defs>
        <clipPath id='clip2012_153'>
          <rect
            id='Logo'
            rx='-0.500000'
            width='29.000000'
            height='27.275862'
            transform='translate(0.500000 0.500000)'
            fill='white'
            fill-opacity='0'
          />
        </clipPath>
      </defs>
      <rect
        id='Logo'
        rx='-0.500000'
        width='29.000000'
        height='27.275862'
        transform='translate(0.500000 0.500000)'
        fill='#FFFFFF'
        fill-opacity='0'
      />
      <g clip-path='url(#clip2012_153)'>
        <path
          id='Vector'
          d='M14.13 0C14.13 0.85 14.13 1.7 14.13 2.58C13.73 2.62 13.34 2.65 12.93 2.69C10.54 2.96 8.58 3.81 6.72 5.34C6.54 5.47 6.36 5.6 6.17 5.74C4.35 7.47 2.9 10.1 2.73 12.65C2.65 15.34 2.65 15.34 3.27 17.93C3.34 18.14 3.34 18.14 3.41 18.35C3.84 19.58 4.55 20.53 5.34 21.55C5.47 21.73 5.6 21.91 5.74 22.1C7.47 23.91 10.1 25.37 12.65 25.54C15.34 25.62 15.34 25.62 17.93 25C18.07 24.95 18.21 24.9 18.35 24.86C19.58 24.43 20.53 23.72 21.55 22.93C21.73 22.79 21.91 22.66 22.1 22.53C23.3 21.38 24.17 19.96 24.82 18.44C24.9 18.27 24.9 18.27 24.98 18.09C25.46 16.82 25.54 15.47 25.68 14.13C26.54 14.13 27.39 14.13 28.27 14.13C28.45 17.75 27.09 21.12 24.69 23.82C22.23 26.46 18.87 28.18 15.23 28.33C13.14 28.36 11.29 28.26 9.31 27.58C9.12 27.52 8.94 27.46 8.76 27.4C5.49 26.18 2.67 23.5 1.21 20.34C-0.28 16.83 -0.56 12.94 0.68 9.31C0.75 9.12 0.81 8.94 0.87 8.76C2.09 5.49 4.77 2.67 7.93 1.21C10 0.33 11.88 -0.06 14.13 0Z'
          fill='#4190B0'
          fill-opacity='1.000000'
          fill-rule='nonzero'
        />
        <path
          id='Vector'
          d='M28.1 0.51C28.61 0.75 29.01 1.02 29.43 1.4C29.54 1.49 29.65 1.58 29.76 1.68C29.83 1.75 29.91 1.82 30 1.89C29.76 2.59 29.45 3.02 28.95 3.55C28.25 4.3 27.6 5.08 26.96 5.87C26.52 6.41 26.07 6.94 25.62 7.47C25.5 7.61 25.5 7.61 25.38 7.76C25.06 8.14 24.73 8.52 24.41 8.9C23.59 9.86 22.78 10.82 21.99 11.79C21.39 12.53 20.78 13.25 20.17 13.96C19.29 14.98 18.43 16.02 17.58 17.06C17.29 17.42 17 17.78 16.71 18.13C16.58 18.29 16.45 18.45 16.31 18.61C16.22 18.73 16.13 18.84 16.03 18.96C15.25 18.81 14.79 18.36 14.21 17.84C13.99 17.65 13.77 17.46 13.56 17.27C13.39 17.13 13.39 17.13 13.23 16.98C12.82 16.63 12.4 16.28 11.98 15.94C11.13 15.25 10.3 14.54 9.47 13.83C8.84 13.29 8.22 12.76 7.58 12.24C7.84 11.44 8.25 10.95 8.79 10.34C9.45 10.62 9.96 11.07 10.5 11.54C10.7 11.71 10.9 11.88 11.1 12.05C11.2 12.14 11.31 12.22 11.41 12.32C13.33 13.97 13.33 13.97 15.34 15.51C16.62 14.28 17.77 12.92 18.88 11.54C19.36 10.96 19.85 10.39 20.34 9.82C20.95 9.11 21.56 8.4 22.15 7.67C22.74 6.94 23.35 6.22 23.96 5.51C24.84 4.49 25.7 3.45 26.55 2.41C26.84 2.05 27.13 1.7 27.42 1.34C27.55 1.18 27.68 1.02 27.81 0.86C27.91 0.75 28 0.63 28.1 0.51Z'
          fill='#FF2C2C'
          fill-opacity='1.000000'
          fill-rule='nonzero'
        />
      </g>
    </svg>
  );
};
