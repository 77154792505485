/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setRole } from '../../../../../../store/slices/authSlice';
import { VerificationForm } from '../../../../components/Verification-form';
import * as Yup from 'yup';
import { useAuthConfirmMutation } from '../../../../../../store/services/authApi';
import { Roles } from '../../../../../../types/roles';
import { useTranslation } from 'react-i18next';

interface IUserVerificationFormProps {}

const validationSchema = Yup.object().shape({
  code: Yup.string().required('auth.passVer.errors.code.required'),
});

export const UserVerificationForm: FC<IUserVerificationFormProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [authConfirm, { isError, isLoading }] = useAuthConfirmMutation();
  const [t, i18n] = useTranslation('global');

  const handleSubmit = async (code: string, phoneNumber: string) => {
    try {
      const response = await authConfirm({
        phone: phoneNumber,
        code: Number(code),
        lang: i18n.language,
      }).unwrap();
      localStorage.setItem('accessToken', response.token.access);
        localStorage.setItem('refreshToken', response.token.refresh);
        dispatch(
          setRole({
            access_token: localStorage.getItem('accessToken'),
            refresh_token: localStorage.getItem('refreshToken'),
            role: Roles.USER,
          })
        );
      navigate('/payment');
    } catch (error: any) {
      throw error;
    }
  };

  return (
    <VerificationForm
      back={'/author/user-register'}
      initialValues={{ code: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onSuccess={() => navigate('/payment')}
      onError={(error: any) => console.log(error)}
    />
  );
};
