import styled from 'styled-components';
import Styles, { Typography } from '../../../styles';
import { Button } from '@mui/material';
import colors from '../../../constants';

export const QuestionsWrapper = styled.div`
  width: 100%;
`;

export const StyledForm = styled.form`
  width: 100%;
  position: relative;
`;

export const StyledButton = styled(Button)`
  height: 45px;
`;

export const ImageWrapper = styled(Styles.Row)`
  height: 40px;
  max-width: 40px;
`;

export const ChatWrapper = styled(Styles.Row)<{theme: string}>`
  overflow: auto;
  padding: 15px;
  border-radius: 10px;
  background-color: ${({theme}) => theme === 'dark' ? colors['dark-theme-secondary'] : colors['secondary-100']};
  scrollbar-width: none;
  max-height: calc(100vh - 300px);
  -ms-overflow-style: none; 
  ::-webkit-scrollbar {
    display: none; 
  }
`;