import { DAYS_OFF } from '../URLs';
import { api } from './api';

export const daysOffApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDaysOff: builder.query({
      query: () => ({
        url: DAYS_OFF,
        method: 'GET',
      }),
      providesTags: ['daysOff'],
    }),
  }),
});

export const { useGetDaysOffQuery } = daysOffApi;
