import { MODERATOR } from '../URLs';
import { api } from './api';

export const moderatorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getModerators: builder.query<any, { token: string | null }>({
      query: (token) => ({
        url: MODERATOR,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ['moderator'],
    }),
  }),
});

export const { useGetModeratorsQuery } = moderatorApi;
