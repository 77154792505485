import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../hooks';

interface IInitialState {
  count: number;
}
const initialState: IInitialState = {
  count: 0,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    countPlus: (state) => {
      state.count = state.count + 1;
    },
    countMinus: (state, action: any) => {
      state.count = state.count - 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { countPlus, countMinus } = cartSlice.actions;

export default cartSlice.reducer;

export const selectCarts = (state: RootState) => state.cart;
