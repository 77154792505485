/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { FC } from 'react';
import {
  Line,
  PlanBannerWrapper,
  PlanInfoWrapper,
  MainAuthorResourcesWrap,
  StyledBox,
  StyledTable,
  StyledTableContainer,
  TableContentWrap,
} from './main-author-resources.s';
import Styles, { Typography } from '../../../../styles';
import { useTranslation } from 'react-i18next';
import { Common } from '../../../../components';
import { Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import colors from '../../../../constants';
import Icons from '../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { useGetResourceTypesQuery } from '../../../../store/services/resourcesApi';
import { fullNameGenerator } from '../../../../helpers/fullNameGenerator';
import { formatBytes } from '../../../../helpers/formatBytes';

interface IMainAuthorResourcesProps {}

export const MainAuthorResources: FC<IMainAuthorResourcesProps> = (props) => {
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const url = window.location.href;
  const parts = url.split('/');
  const id = parts[parts.length - 1];
  const { data: userResources } = useGetResourceTypesQuery({ id: id, lang: i18n.language });

  return (
    <MainAuthorResourcesWrap>
      <Styles.Row gap={16}>
        <Styles.Row gap={4}>
          <Typography.h4 color='typography-body'>{userResources?.name}</Typography.h4>
        </Styles.Row>
        <Common.Container>
          <PlanInfoWrapper wrap={{ xs: 'wrap', sm: 'nowrap' }} gap={16}>
            <PlanBannerWrapper>
              <StyledBox selectedImage={userResources?.banner} />
            </PlanBannerWrapper>
            <Styles.Row direction={'column'} gap={16}>
              <Typography.h4 color='typography-body'>{userResources?.name}</Typography.h4>
              <Typography.paragraph color='typography-body'>
                {userResources?.description}
              </Typography.paragraph>
              <Line />
              <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                <Icons.School color={colors['typography-body']} />
                <Typography.paragraph color='typography-body'>Sinf:</Typography.paragraph>
                <Typography.paragraphBold color='typography-body'>
                  {userResources?.classes?.name}
                </Typography.paragraphBold>
              </Styles.Row>
              <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                <Icons.Comet color={colors['typography-body']} />
                <Typography.paragraph color='typography-body'>Fan:</Typography.paragraph>
                <Typography.paragraphBold color='typography-body'>
                  {userResources?.classes?.name}
                </Typography.paragraphBold>
              </Styles.Row>
              <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                <Icons.MathFunction color={colors['typography-body']} />
                <Typography.paragraph color='typography-body'>Mavzu:</Typography.paragraph>
                <Typography.paragraphBold color='typography-body'>
                  {userResources?.topic?.name}
                </Typography.paragraphBold>
              </Styles.Row>
              <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                <Icons.EmptyUser color={colors['typography-body']} />
                <Typography.paragraph color='typography-body'>Muallif:</Typography.paragraph>
                <Typography.paragraphBold color='typography-body'>
                  {fullNameGenerator(
                    userResources?.user?.first_name,
                    userResources?.user?.last_name
                  )}
                </Typography.paragraphBold>
              </Styles.Row>
              <Styles.Row gap={8} align_items={'center'}>
                <Icons.RotateClockwise color={colors['typography-body']} />
                <Typography.paragraph color='typography-body'>
                  So'nggi yangilanish:
                </Typography.paragraph>
                <Typography.paragraph color='typography-body'>1</Typography.paragraph>
              </Styles.Row>
            </Styles.Row>
          </PlanInfoWrapper>
        </Common.Container>
        <Common.Container>
          <TableContentWrap>
            <StyledTableContainer>
              <StyledTable aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>{t('resources.add.table.content.number')}</TableCell>
                    <TableCell align='left'>
                      <Styles.Row align_items={'center'} content={'space-between'} wrap={'nowrap'}>
                        {t('resources.add.table.content.name')}
                        <Button>
                          <Icons.Arrow />
                        </Button>
                      </Styles.Row>
                    </TableCell>
                    <TableCell align='left'>
                      <Styles.Row>{t('resources.add.table.content.type')}</Styles.Row>
                    </TableCell>
                    <TableCell align='left'>
                      <Styles.Row>{t('resources.add.table.content.size')}</Styles.Row>
                    </TableCell>
                    <TableCell align='center'>
                      {t('resources.add.table.content.download')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userResources?.media?.map((res: any, index: number) => (
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell align='center'>{index + 1}</TableCell>
                      <TableCell align='left'>{res?.name}</TableCell>
                      <TableCell align='left'>{userResources?.type?.name}</TableCell>
                      <TableCell align='left'>{formatBytes(res?.size)}</TableCell>
                      <TableCell align='center'>
                        <Styles.Row>
                          <Button onClick={() => navigate(`/main-resource/${res?.id}`)}>
                            <Icons.Eye color={colors['typography-body']} />
                          </Button>
                          <Button href={res?.file} download={res?.name}>
                            <Icons.Download color={colors['typography-body']} />
                          </Button>
                        </Styles.Row>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </TableContentWrap>
        </Common.Container>
      </Styles.Row>
    </MainAuthorResourcesWrap>
  );
};
