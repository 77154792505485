import styled from 'styled-components';
import { Menu } from '@mui/material';
import Styles from '../../../../styles';

export const NavbarFlag = styled.img`
  width: 25px;
  height: 24px;
  border-radius: 100px;
  cursor: pointer;

  /* @media (max-width: 768px) {
    display: none;
  } */
`;

export const StyledMenu = styled(Menu)`
  && {
    width: 100%;
    /* .MuiPaper-root {
      width: 200px;
    } */

    img {
      width: 24px;
      height: 24px;
    }
  }
`;

export const StyledLangWrapper = styled(Styles.Row)`
  && {
    cursor: pointer;
  }
`;

