/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from 'react';
import Icons from '../../../assets/icons';
import { Common } from '../../../components';
import {
  Button,
  Pagination,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Styles, { GlobalStyles, Typography } from '../../../styles';
import { DeleteModal } from '../../../components/deleteModal';
import {
  PlansWrap,
  StyledSelect,
  PrimaryButton,
  ButtonsContainer,
  TableWrapper,
  StyledTableContainer,
  StyledTable,
  SelectWrapper,
  ScienceChip,
  StateChip,
  EmptyTitleWrapper,
  StyledMenu,
} from './plans.s';
import {
  useDeletePlanMutation,
  useGetPlansByGradeQuery,
  useGetPlansByQuarterQuery,
  useGetPlansByScienceQuery,
  useGetPlansQuery,
} from '../../../store/services/plansApi';
import { toast } from 'react-toastify';
import colors from '../../../constants';
import { useGetAllQuartersQuery } from '../../../store/services/quarterApi';
import { useGetAllScienceQuery } from '../../../store/services/scienceApi';
import { useGetAllClassQuery } from '../../../store/services/classApi';
import { useAppTheme } from '../../../hooks';
import { useSelector } from 'react-redux';

export const Plans = () => {
  const { theme } = useAppTheme();
  const [selectedQuarterId, setSelectedQuarterId] = useState(0);
  const [selectedClassId, setSelectedClassId] = useState(0);
  const [selectedScienceId, setSelectedScienceId] = useState(0);
  const navigate = useNavigate();
  const [t, i18n] = useTranslation('global');
  const [page, setPage] = useState(1);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const rowsPerPage = 10;
  const [deleteData, setDeleteData] = useState<number | any>(null);
  const {
    data,
    refetch,
    isLoading: plansLoading,
  } = useGetPlansQuery({ page, lang: i18n.language });
  const [deletePlan, { isLoading: deleteLoad }] = useDeletePlanMutation();
  const { data: quarters } = useGetAllQuartersQuery();
  const { data: sciences } = useGetAllScienceQuery(i18n.language);
  const { data: classes } = useGetAllClassQuery();
  const [loadingPlans, setLoadingPlans] = useState(false);
  const {
    data: plansByQuarter,
    refetch: quarterRefetch,
    isLoading: quarterLoading,
  } = useGetPlansByQuarterQuery(selectedQuarterId);
  const {
    data: plansByGrade,
    refetch: gradeRefetch,
    isLoading: gradeLoading,
  } = useGetPlansByGradeQuery(selectedClassId);
  const {
    data: plansByScience,
    refetch: scienceRefetch,
    isLoading: scienceLoading,
  } = useGetPlansByScienceQuery(selectedScienceId);
  const userState = useSelector((state: any) => state.auth);

  const handleDelete = async (id: number) => {
    try {
      await deletePlan(id).then(() => {
        toast.success(t('table.addPlans_deleted'));
        setDeleteStatus(false);
        refetch();
      });
    } catch (error) {
      console.error('Failed to delete the plan:', error);
    }
  };

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    if (selectedQuarterId) {
      quarterRefetch();
    } else if (selectedClassId) {
      gradeRefetch();
    } else if (selectedScienceId) {
      scienceRefetch();
    }
  };
  const handleQuarterChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedQuarterId(event.target.value as number);
    setSelectedClassId(0);
    setSelectedScienceId(0);
  };

  const handleClassChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedClassId(event.target.value as number);
    setSelectedQuarterId(0);
    setSelectedScienceId(0);
  };

  const handleScienceChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedScienceId(event.target.value as number);
    setSelectedQuarterId(0);
    setSelectedClassId(0);
  };

  const displayPlans = () => {
    if (selectedQuarterId) {
      return plansByQuarter?.results || [];
    } else if (selectedClassId) {
      return plansByGrade?.results || [];
    } else if (selectedScienceId) {
      return plansByScience?.results || [];
    } else {
      return data?.results || [];
    }
  };

  const displayedResources = displayPlans();

  useEffect(() => {
    setLoadingPlans(plansLoading || quarterLoading || gradeLoading || scienceLoading);
  }, [plansLoading, quarterLoading, gradeLoading, scienceLoading]);

  return (
    <PlansWrap>
      <Styles.Row gap={16}>
        <Styles.Row>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('plans.main.title')}
          </Typography.h4>
        </Styles.Row>
        <Styles.Row>
          <Common.Container>
            <ButtonsContainer>
              <Styles.Row gap={16} content={'space-between'}>
                <Styles.Row wrap={'nowrap'} width='400px' gap={6}>
                  <SelectWrapper>
                    <StyledSelect
                      defaultValue={0}
                      onChange={handleScienceChange}
                      value={selectedScienceId as number}
                      name='planLesson'
                      MenuProps={{
                        disableScrollLock: true,
                        sx: {
                          height: '280px',
                          '& .MuiPaper-root': {
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#bdbdbd transparent',
                          },
                        },
                      }}>
                      <StyledMenu value={0}>{t('plans.main.table.select.lesson')}</StyledMenu>
                      {sciences?.results.map((item: any) => (
                        <StyledMenu value={item.id}>{item.name}</StyledMenu>
                      ))}
                    </StyledSelect>
                  </SelectWrapper>
                  <SelectWrapper>
                    <StyledSelect
                      defaultValue={0}
                      value={selectedClassId}
                      onChange={handleClassChange}
                      name='planGrade'
                      MenuProps={{
                        disableScrollLock: true,
                        sx: {
                          height: '280px',
                          '& .MuiPaper-root': {
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#bdbdbd transparent',
                          },
                        },
                      }}>
                      <StyledMenu value={0}>{t('plans.main.table.select.grade')}</StyledMenu>
                      {classes?.results.map((item: any) => (
                        <StyledMenu value={item.id}>{item.name}</StyledMenu>
                      ))}
                    </StyledSelect>
                  </SelectWrapper>
                  <SelectWrapper>
                    <StyledSelect
                      defaultValue={0}
                      value={selectedQuarterId}
                      onChange={handleQuarterChange}
                      name='planQuarter'
                      MenuProps={{
                        disableScrollLock: true,
                        sx: {
                          height: '280px',
                          '& .MuiPaper-root': {
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#bdbdbd transparent',
                          },
                        },
                      }}>
                      <StyledMenu value={0}>{t('plans.main.table.select.quater')}</StyledMenu>
                      {quarters?.results.map((item: any) => (
                        <StyledMenu value={item.id}>{item.choices}</StyledMenu>
                      ))}
                    </StyledSelect>
                  </SelectWrapper>
                </Styles.Row>
                {userState.plan_creatable && (
                  <Styles.Row style={{ height: '43px' }} width='130px' content={'flex-end'}>
                    <PrimaryButton onClick={() => navigate('/new-plan')} variant='contained'>
                      <Typography.h6 color='white'>{t('resources.button.add')}</Typography.h6>
                      <Icons.Plus />
                    </PrimaryButton>
                  </Styles.Row>
                )}
              </Styles.Row>
            </ButtonsContainer>

            {loadingPlans ? (
              <EmptyTitleWrapper content={'center'} align_items={'center'}>
                <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('plans.main.loadingTitle')}
                </Typography.h4>
              </EmptyTitleWrapper>
            ) : (
              <>
                {displayedResources.length > 0 ? (
                  <Styles.Row>
                    <TableWrapper>
                      <StyledTableContainer>
                        <StyledTable stickyHeader aria-label='sticky table'>
                          <TableHead>
                            <TableRow>
                              <TableCell align='center'>
                                <GlobalStyles.TableHeaderTitle width='90px'>
                                  {t('plans.main.table.title.number')}
                                </GlobalStyles.TableHeaderTitle>
                              </TableCell>
                              <TableCell align='center'>
                                <GlobalStyles.TableHeaderTitle
                                  width='170px'
                                  align_items={'center'}
                                  content={'space-between'}
                                  wrap={'nowrap'}>
                                  {t('plans.main.table.title.quater')}
                                </GlobalStyles.TableHeaderTitle>
                              </TableCell>
                              <TableCell align='center'>
                                <GlobalStyles.TableHeaderTitle
                                  width='170px'
                                  align_items={'center'}
                                  content={'space-between'}
                                  wrap={'nowrap'}>
                                  {t('plans.main.table.title.grade')}
                                </GlobalStyles.TableHeaderTitle>
                              </TableCell>
                              <TableCell align='left'>
                                <GlobalStyles.TableHeaderTitle
                                  width='170px'
                                  align_items={'center'}
                                  content={'space-between'}
                                  wrap={'nowrap'}>
                                  {t('plans.main.table.title.lesson')}
                                </GlobalStyles.TableHeaderTitle>
                              </TableCell>
                              <TableCell align='center'>{t('resources.table.vision')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {displayedResources.map((row: any, index: number) => (
                              <TableRow
                                key={index}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                }}>
                                <TableCell align='left'>
                                  {(page - 1) * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell align='left'>{row?.quarter.choices}</TableCell>
                                <TableCell align='left'>{row?.classes.name}</TableCell>
                                <TableCell align='left'>
                                  <ScienceChip
                                    label={row?.science.name}
                                    variant='outlined'
                                    color='primary'
                                  />
                                </TableCell>
                                <TableCell align='center'>
                                  <Styles.Row gap={2} wrap={'nowrap'}>
                                    <Button onClick={() => navigate(`/science-topics/${row?.id}/`)}>
                                      <Icons.Eye
                                        color={
                                          theme === 'dark'
                                            ? colors['grey-200']
                                            : colors['typography-body']
                                        }
                                      />
                                    </Button>
                                    {row?.is_author && (
                                      <>
                                        <Button
                                          onClick={() => {
                                            setDeleteData(row.id);
                                            setDeleteStatus(true);
                                          }}>
                                          <Icons.Trash
                                            color={
                                              theme === 'dark'
                                                ? colors['grey-200']
                                                : colors['typography-body']
                                            }
                                          />
                                        </Button>
                                      </>
                                    )}
                                  </Styles.Row>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </StyledTable>
                      </StyledTableContainer>
                    </TableWrapper>
                    <Styles.Row content={'flex-end'}>
                      <Pagination
                        count={Math.ceil(data?.count / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color='primary'
                        shape='rounded'
                        style={{ marginTop: '20px' }}
                      />
                    </Styles.Row>
                    {deleteStatus && (
                      <DeleteModal
                        open={deleteStatus}
                        loading={deleteLoad}
                        click={() => handleDelete(deleteData)}
                        handleCancel={() => setDeleteStatus(false)}
                      />
                    )}
                  </Styles.Row>
                ) : (
                  <EmptyTitleWrapper content={'center'} align_items={'center'}>
                    <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t('plans.main.emptyTitle')}
                    </Typography.h4>
                  </EmptyTitleWrapper>
                )}
              </>
            )}
          </Common.Container>
        </Styles.Row>
      </Styles.Row>
    </PlansWrap>
  );
};
