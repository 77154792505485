import styled from 'styled-components';
import Styles, { Typography } from '../../../../../../styles';
import colors from '../../../../../../constants';
import { MenuItem, TextField } from '@mui/material';

export const ArrowBtn = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: ${colors['primary-500']};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 200px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    };
    @media(max-width: 576px){
      width: 100%;
    };
  }
`;

export const BtnWrapper = styled(Styles.Row)`
  && {
    width: auto;

    @media(max-width: 576px){
      width: 100%;
    };
  }
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    &.Mui-selected {
      color: ${colors['white']} !important;
    }
    &:focus {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important;
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important;
    }
  }
`;

export const NavBtn = styled(Typography.buttonDefault)<{
  bg: string;
  br?: boolean;
  theme: string;
}>`
  padding: 10px 20px;
  height: 45px;
  border-radius: 6px;
  border: ${({ theme, br }) =>
    br && theme === 'dark'
      ? `1px solid rgba(67, 73, 104, 1)`
      : br
      ? `1px solid ${colors['secondary-500']}`
      : 'none'};
  background-color: ${({ bg }) => bg};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:disabled {
    color: ${({ theme }) =>
      theme === 'dark' ? `rgba(102, 112, 133, 1)` : `1px solid ${colors['secondary-500']}`};
    cursor: not-allowed;
  }
`;


export const TitleBtn = styled(Typography.buttonDefault)<{
  bg: string;
  br?: boolean;
  theme: string;
}>`
  padding: 10px 20px;
  height: 45px;
  border-radius: 6px;
  border: ${({ theme, br }) =>
    br && theme === 'dark'
      ? `1px solid rgba(67, 73, 104, 1)`
      : br
      ? `1px solid ${colors['secondary-500']}`
      : 'none'};
  background-color: ${({ bg }) => bg};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width: 475px) {
    width: 100%;
  }

  &:disabled {
    color: ${({ theme }) =>
      theme === 'dark' ? `rgba(102, 112, 133, 1)` : `1px solid ${colors['secondary-500']}`};
    cursor: not-allowed;
  }
`;