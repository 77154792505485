import styled from 'styled-components';

export const LeftContentWrap = styled.div`
  && {
    width: 100%;
    height: 100vh;
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 992px) {
      display: none;
    }
  }
`;
