import { AUTH_RESEND, AUTH_RESET_CONFIRM, AUTH_RESET_PASSWORD, AUTH_RESET_SET } from '../URLs';
import { api } from './api';

export const updatePasswordApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetPasswrod: builder.mutation<any, { phone: string, lang: string }>({
      query: (body) => {
        return {
          url: AUTH_RESET_PASSWORD,
          method: 'POST',
          body,
          headers: {
            'accept-language': body.lang,
          },
        };
      },
      invalidatesTags: ['resetPasswrod'],
    }),
    confirmPhone: builder.mutation<any, { phone: string; code: number; lang: string }>({
      query: (body) => {
        return {
          url: AUTH_RESET_CONFIRM,
          method: 'POST',
          body,
          headers: {
            'accept-language': body.lang,
          },
        };
      },
      invalidatesTags: ['confirmPhone'],
    }),
    resendPasswrod: builder.mutation<any, { phone: string; lang: string }>({
      query: (body) => {
        return {
          url: AUTH_RESEND,
          method: 'POST',
          body,
          headers: {
            'accept-language': body.lang,
          },
        };
      },
      invalidatesTags: ['resendPassword'],
    }),
    resetSetPasswrod: builder.mutation<any, { password: string; token: string }>({
      query: (body) => {
        return {
          url: AUTH_RESET_SET,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['resetSetPasswrod'],
    }),
  }),
});

export const {
  useConfirmPhoneMutation,
  useResetPasswrodMutation,
  useResendPasswrodMutation,
  useResetSetPasswrodMutation,
} = updatePasswordApi;
