import { Resources, ResourcesView, ResourcesType } from './resources-page';
import { Plans, PlansView, SubjectView, PlansResourceView } from './plans-page';
import { Tests } from './tests';
import { Payment } from './payment';
import { History } from './history';
import { Settings } from './settings';
import { Logout } from './logout';
import { SendQuestions } from './send-questions';
import { Terms } from './terms';
import { AddOrder } from './order-add';
import { Main, MainAuthorResources, MainResourceView, SampleConnect, SampleCreate } from './main-page';



const UserPages = {
  Main,
  Resources,
  ResourcesView,
  Plans,
  PlansView,
  SubjectView,
  PlansResourceView,
  Tests,
  Payment,
  SendQuestions,
  History,
  Settings,
  Logout,
  MainAuthorResources,
  MainResourceView,
  Terms,
  AddOrder,
  ResourcesType,
  SampleCreate,
  SampleConnect,
};

export default UserPages;
