import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const Help: FC<IIconProps> = ({
  className,
  width = 25,
  height = 24,
  color = colors.white,
  ...props
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='12.5'
        cy='12'
        r='9'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='12.5'
        cy='12'
        r='9'
        stroke={props.stroke || color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.5 17V17.01'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.5 17V17.01'
        stroke={props.stroke || color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.5 13.5C12.4622 12.8331 12.8699 12.2215 13.5 12C14.5307 11.6058 15.2003 10.604 15.1704 9.50088C15.1404 8.39779 14.4174 7.43378 13.3668 7.09616C12.3162 6.75853 11.167 7.12085 10.5 7.99996'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.5 13.5C12.4622 12.8331 12.8699 12.2215 13.5 12C14.5307 11.6058 15.2003 10.604 15.1704 9.50088C15.1404 8.39779 14.4174 7.43378 13.3668 7.09616C12.3162 6.75853 11.167 7.12085 10.5 7.99996'
        stroke={props.stroke || color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
