/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import {
  BtnWrap,
  EmptyTitleWrapper,
  Line,
  PlanInfoWrapper,
  PlansViewWrap,
  PrimaryButton,
  StyledTable,
  StyledTableContainer,
  TableContentWrap,
} from './plans-view.s';
import Styles, { GlobalStyles, Typography } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { Common } from '../../../components';
import { Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import colors from '../../../constants';
import Icons from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { useGetPlanByIdQuery } from '../../../store/services/plansApi';
import { formatBytes } from '../../../helpers/formatBytes';
import { useAppTheme } from '../../../hooks';
import { handleDownloadClick } from '../../../helpers/fileDownload';
import { useGetTopicByIdQuery } from '../../../store/services/moderatorPlansApi';
import { formatUpdatedDate } from '../../../helpers/formatUpdatedDate';
import { fullNameGenerator } from '../../../helpers/fullNameGenerator';

interface IPlansViewProps {}

type SetIsDownloading = (id: number | null) => void;

export const PlansView: FC<IPlansViewProps> = (props) => {
  const { theme } = useAppTheme();
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const [isDownloading, setIsDownloading] = useState<number | null>(null);
  const parts = currentUrl.split('/');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const id = parts[parts.length - 1];
  const { data: planData, isLoading } = useGetPlanByIdQuery({
    id: Number(id),
    lang: i18n.language,
  });

  const {
    data: topicData,
    isLoading: topicLoading,
    refetch,
  } = useGetTopicByIdQuery({
    id: Number(id),
    lang: i18n.language,
  });

  const onDownloadClick = (id: number, name: string, setIsDownloading: SetIsDownloading) => {
    handleDownloadClick(
      id,
      name,
      i18n.language,
      setIsDownloading,
      setSnackbarMessage,
      setSnackbarOpen
    );
  };

  const getUniqueAuthors = (media: Array<any>) => {
    const authorsMap = new Map<number, string>();
    media.forEach((item) => {
      const { user } = item;
      if (user && user.first_name && user.last_name) {
        const fullName = `${user.first_name} ${user.last_name}`;
        authorsMap.set(user.id, fullName);
      }
    });
    return Array.from(authorsMap.values());
  };

  const uniqueAuthors = getUniqueAuthors(topicData?.media || []);
  const displayAuthorNames =
    uniqueAuthors.length > 1 ? uniqueAuthors.join(', ') : uniqueAuthors[0] || '';

  useEffect(() => {
    refetch();
  }, []);

  return (
    <PlansViewWrap>
      {isLoading ? (
        <Common.Container>
          <EmptyTitleWrapper content={'center'} align_items={'center'}>
            <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {t('plans.main.loadingTitle')}
            </Typography.h4>
          </EmptyTitleWrapper>
        </Common.Container>
      ) : (
        <Styles.Row gap={16}>
          <Styles.Row gap={4}>
            <Typography.h4 color='typography-muted'>{t('plans.main.title')} /</Typography.h4>
            <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {topicData?.name}
            </Typography.h4>
          </Styles.Row>
          <Common.Container>
            <PlanInfoWrapper wrap={{ xs: 'wrap', sm: 'nowrap' }} gap={16}>
              <Styles.Row direction={'column'} gap={16}>
                <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {topicData?.description}
                </Typography.paragraph>
                <Line />
                <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                  <Icons.MathFunction
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewItem.header.title.theme')}:
                  </Typography.paragraph>
                  <Typography.paragraphBold
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {topicData?.name}
                  </Typography.paragraphBold>
                </Styles.Row>
                <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                  <Icons.Clock
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewItem.header.title.duration')}:
                  </Typography.paragraph>
                  <Typography.paragraphBold
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {topicData?.hours}
                  </Typography.paragraphBold>
                </Styles.Row>
                <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                  <Icons.EmptyUser
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewItem.header.title.author')}:
                  </Typography.paragraph>
                  <Typography.paragraphBold
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {displayAuthorNames}
                  </Typography.paragraphBold>
                </Styles.Row>
                <Styles.Row gap={8} align_items={'center'}>
                  <Icons.RotateClockwise
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('plans.viewItem.header.title.updated')}:
                  </Typography.paragraph>
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {formatUpdatedDate(topicData?.created_at)}
                  </Typography.paragraph>
                </Styles.Row>
              </Styles.Row>
            </PlanInfoWrapper>
          </Common.Container>

          {topicData?.media?.length === 0 ? (
            <Common.Container>
              <EmptyTitleWrapper
                gap={4}
                content={'center'}
                align_items={'center'}
                direction={'column'}>
                <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('plans.viewItem.body.title.resEmpty')}
                </Typography.h4>
                {topicData?.media_creatable && (
                  <PrimaryButton
                    onClick={() => navigate(`/topic-resource/${id}/`)}
                    variant='contained'>
                    <Typography.h6 color='white'>{t('resources.button.add')}</Typography.h6>
                    <Icons.Plus />
                  </PrimaryButton>
                )}
              </EmptyTitleWrapper>
            </Common.Container>
          ) : (
            <Common.Container>
              <BtnWrap content={'space-between'} align_items={'center'}>
                <Typography.h4 color='typography-muted'>
                  {t('plans.view.table.headerRes')}{' '}
                </Typography.h4>
                <PrimaryButton
                  onClick={() => navigate(`/topic-resource/${id}/`)}
                  variant='contained'>
                  <Typography.h6 color='white'>{t('resources.button.add')}</Typography.h6>
                  <Icons.Plus />
                </PrimaryButton>
              </BtnWrap>
              <TableContentWrap>
                <StyledTableContainer>
                  <StyledTable aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>
                          {t('resources.add.table.content.number')}
                        </TableCell>
                        <TableCell align='left'>
                          <GlobalStyles.TableHeaderTitle
                            align_items={'center'}
                            content={'space-between'}
                            wrap={'nowrap'}>
                            {t('resources.add.table.content.name')}
                          </GlobalStyles.TableHeaderTitle>
                        </TableCell>
                        <TableCell align='left'>
                          <GlobalStyles.TableHeaderTitle>
                            {t('resources.add.table.content.resourceType')}
                          </GlobalStyles.TableHeaderTitle>
                        </TableCell>
                        <TableCell align='left'>
                          <GlobalStyles.TableHeaderTitle>
                            {t('resources.add.table.content.author')}
                          </GlobalStyles.TableHeaderTitle>
                        </TableCell>
                        <TableCell align='left'>
                          <GlobalStyles.TableHeaderTitle>
                            {t('resources.add.table.content.size')}
                          </GlobalStyles.TableHeaderTitle>
                        </TableCell>
                        <TableCell align='center'>
                          {t('resources.add.table.content.download')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {topicData?.media?.map((topic: any, index: number) => (
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}>
                          <TableCell align='center'>{index + 1}</TableCell>
                          <TableCell align='left'>{topic?.name}</TableCell>
                          <TableCell align='left'>{topic?.desc}</TableCell>
                          <TableCell align='left'>
                            {fullNameGenerator(topic?.user?.first_name, topic?.user?.last_name)}
                          </TableCell>
                          <TableCell align='left'>{formatBytes(topic?.size)}</TableCell>
                          <TableCell align='center'>
                            <Styles.Row content={'center'}>
                              {/* <Button onClick={() => navigate(`/plan-resource-view/${topic.id}`)}>
                                <Icons.Eye
                                  color={
                                    theme === 'dark'
                                      ? colors['grey-300']
                                      : colors['typography-body']
                                  }
                                />
                              </Button> */}
                              {isDownloading !== topic.id ? (
                                <Button
                                  onClick={() =>
                                    onDownloadClick(topic?.id, topic.name, setIsDownloading)
                                  }>
                                  <Icons.Download
                                    color={
                                      theme === 'dark'
                                        ? colors['grey-300']
                                        : colors['typography-body']
                                    }
                                  />
                                </Button>
                              ) : (
                                <Button disabled>
                                  <Icons.Loader
                                    color={
                                      theme === 'dark'
                                        ? colors['grey-100']
                                        : colors['typography-muted']
                                    }
                                  />
                                </Button>
                              )}
                            </Styles.Row>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              </TableContentWrap>
            </Common.Container>
          )}
        </Styles.Row>
      )}
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </PlansViewWrap>
  );
};
