/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { stateToHTML } from 'draft-js-export-html';
import {
  EmptyTitleWrapper,
  NewResourcesWrap,
  PrimaryButton,
  StyledMenu,
  StyledSelect,
  StyledTable,
  StyledTableContainer,
  StyledTextField,
  TableContentWrap,
} from './new-resource.s';
import { useTranslation } from 'react-i18next';
import Styles, { Typography } from '../../../styles';
import { NewResourcesContainer } from '../resources/resources.s';
import { Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Icons from '../../../assets/icons';
import { Common } from '../../../components';
import colors from '../../../constants';
import { FormikErrors, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  useAddResourceMutation,
  useGetModeratorResourcesQuery,
} from '../../../store/services/resourcesApi';
import { FileWithPath } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { useAppTheme } from '../../../hooks';
import mock from '../../../mock';
import CommonList from '../../../components/common';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

interface INewResourseProps {}

interface INewResourcesInfo {
  number: number;
  name: string;
  type: string;
  size: string;
}

export const NewResourse: FC<INewResourseProps> = () => {
  const { theme } = useAppTheme();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [t] = useTranslation('global');
  const { data: moderatorResourcesData } = useGetModeratorResourcesQuery();
  const [createResource, { isLoading }] = useAddResourceMutation();
  const navigate = useNavigate();
  const DegreeMock = mock.Degree;
  const [files, setFiles] = useState<FileWithPath[]>([]);

  const bytesToMegabytes = (bytes: number) => {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
  };

  interface IFormInputValues {
    resourceName: string;
    resourceType: string;
    resourceCategory: string;
    resourceDescription: string;
    resourceSource: string;
    resourceDegree: string;
    imageFiles: FileWithPath[];
    otherFiles: FileWithPath[];
  }

  const initialValues: IFormInputValues = {
    resourceName: '',
    resourceCategory: '',
    resourceType: '',
    resourceDescription: JSON.stringify(
      convertToRaw(EditorState.createEmpty().getCurrentContent())
    ),
    resourceSource: '',
    resourceDegree: '',
    imageFiles: [],
    otherFiles: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      resourceName: Yup.string().required('plans.viewAddItem.body.errors.global'),
      resourceCategory: Yup.string().required('plans.viewAddItem.body.errors.global'),
      resourceDescription: Yup.string().required('plans.viewAddItem.body.errors.global'),
      imageFiles: Yup.array().min(1, 'plans.viewAddItem.body.errors.global'),
      otherFiles: Yup.array().min(1, 'plans.viewAddItem.body.errors.global'),
    }),
    validateOnChange: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        const editorState = EditorState.createWithContent(
          convertFromRaw(JSON.parse(values.resourceDescription))
        );
        const contentState = editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);

        const formData = new FormData();
        formData.append('type', values.resourceCategory);
        formData.append('subtype', values.resourceType);
        formData.append('name', values.resourceName);
        formData.append('description', htmlContent);
        formData.append('source', values.resourceSource);
        formData.append('degree', values.resourceDegree);
        formData.append('banner', values.imageFiles[0]);
        values.otherFiles.forEach((file) => {
          formData.append('media_files', file);
        });

        await createResource(formData).then((res) => {
          if (res.data) {
            resetForm();
            navigate('/resources');
            toast.success(t('table.addResource_success'));
          }
        });
      } catch (error: any) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    const resourceDescription = formik.values.resourceDescription;
    if (resourceDescription && typeof resourceDescription === 'string') {
      try {
        if (resourceDescription.trim() !== '') {
          const rawContent = JSON.parse(resourceDescription);
          const contentState = convertFromRaw(rawContent);
          setEditorState(EditorState.createWithContent(contentState));
        }
      } catch (e) {
        console.error('Failed to parse resourceDescription', e);
      }
    }
  }, [formik.values.resourceDescription]);

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);
  };

  const handleEditorBlur = (state: EditorState) => {
    const rawContent = convertToRaw(state.getCurrentContent());
    const jsonString = JSON.stringify(rawContent);
    formik.setFieldValue('resourceDescription', jsonString);
  };

  const renderError = (error: string | string[] | FormikErrors<FileWithPath>[]) => {
    if (typeof error === 'string') {
      return error;
    } else if (Array.isArray(error)) {
      return error.join(', ');
    } else {
      return 'Invalid error format';
    }
  };

  const imageRow = formik.values.imageFiles.map((file, index): INewResourcesInfo => {
    return {
      number: index + 1,
      name: file.name,
      type: file.type,
      size: `${bytesToMegabytes(file.size)} MB`,
    };
  });

  const otherRow = formik.values.otherFiles.map((file, index): INewResourcesInfo => {
    return {
      number: index + 1,
      name: file.name,
      type: file.type,
      size: `${bytesToMegabytes(file.size)} MB`,
    };
  });

  const handleFileChange = (newFiles: FileWithPath[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    formik.setValues({
      ...formik.values,
      otherFiles: [...files, ...newFiles],
    });
  };

  const selectedId = formik.values.resourceCategory;
  const resource = moderatorResourcesData?.find((resource: any) => resource.id === selectedId);
  const isSelectDisabled = resource ? resource.items.length <= 0 : false;

  return (
    <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
      <NewResourcesWrap>
        {isLoading ? (
          <Styles.Row gap={16}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('resources.add.header.addingTitle')}
              </Typography.h4>
            </Styles.Row>
            <Common.Container>
              <EmptyTitleWrapper content={'center'} align_items={'center'}>
                <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('plans.main.loadingTitle')}
                </Typography.h4>
              </EmptyTitleWrapper>
            </Common.Container>
          </Styles.Row>
        ) : (
          <Styles.Row gap={16}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('resources.add.header.title')}
              </Typography.h4>
            </Styles.Row>
            <Styles.Row>
              <Common.Container>
                <NewResourcesContainer gap={26}>
                  <Styles.Row gap={6} direction={'column'}>
                    <Typography.h5 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t('resources.add.body.title.main')}
                    </Typography.h5>
                    <Typography.paragraph color='typography-muted'>
                      {t('resources.add.body.title.secondary')}
                    </Typography.paragraph>
                  </Styles.Row>
                  <Styles.Row gap={16}>
                    <Styles.Row gap={4} wrap={{ xs: 'wrap', sm: 'nowrap' }}>
                      <Styles.Row gap={4}>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <Typography.paragraphSmall
                            color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                            {t('resources.add.body.inputs.title.name')}
                          </Typography.paragraphSmall>
                          <Typography.paragraphSmall color='danger-600'>
                            *
                          </Typography.paragraphSmall>
                        </Styles.Row>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <StyledTextField
                            placeholder=''
                            value={formik.values.resourceName}
                            onChange={(e) => formik.setFieldValue('resourceName', e.target.value)}
                            name='resourceName'
                          />
                        </Styles.Row>
                        {formik.touched.resourceName && formik.errors.resourceName && (
                          <Typography.paragraph color='danger-500'>
                            {t(formik.errors.resourceName)}
                          </Typography.paragraph>
                        )}
                      </Styles.Row>
                      <Styles.Row gap={4}>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <Typography.paragraphSmall
                            color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                            {t('resources.add.body.inputs.title.category')}
                          </Typography.paragraphSmall>
                          <Typography.paragraphSmall color='danger-600'>
                            *
                          </Typography.paragraphSmall>
                        </Styles.Row>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <StyledSelect
                            value={formik.values.resourceCategory}
                            onChange={(e) =>
                              formik.setFieldValue('resourceCategory', e.target.value)
                            }
                            name='resourceCategory'
                            displayEmpty
                            renderValue={(value) => {
                              const selectedResCat = moderatorResourcesData?.find(
                                (cg: any) => cg.id === value
                              );
                              return selectedResCat ? (
                                selectedResCat.name
                              ) : (
                                <>{t('auth.userReg.inputs.placeholder')}</>
                              );
                            }}
                            MenuProps={{
                              disableScrollLock: true,
                              sx: {
                                height: '280px',
                                '& .MuiPaper-root': {
                                  overflowY: 'auto',
                                  scrollbarWidth: 'thin',
                                  scrollbarColor: '#bdbdbd transparent',
                                },
                              },
                            }}>
                            {moderatorResourcesData?.map((res: any, index: number) => (
                              <StyledMenu value={res.id}>{res.name}</StyledMenu>
                            ))}
                          </StyledSelect>
                        </Styles.Row>
                        {formik.touched.resourceCategory && formik.errors.resourceCategory && (
                          <Typography.paragraph color='danger-500'>
                            {t(formik.errors.resourceCategory)}
                          </Typography.paragraph>
                        )}
                      </Styles.Row>
                    </Styles.Row>
                    <Styles.Row gap={4} wrap={{ xs: 'wrap', sm: 'nowrap' }}>
                      <Styles.Row gap={4}>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <Typography.paragraphSmall
                            color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                            {t('resources.add.body.inputs.title.type')}
                          </Typography.paragraphSmall>
                        </Styles.Row>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <StyledSelect
                            value={formik.values.resourceType}
                            onChange={(e) => {
                              formik.setFieldValue('resourceType', e.target.value);
                            }}
                            name='resourceType'
                            disabled={formik.values.resourceCategory === '' || isSelectDisabled}
                            displayEmpty
                            renderValue={(value) => {
                              const selectedResType = moderatorResourcesData
                                ?.flatMap((res: any) => res.items)
                                .find((item: any) => item.id === value);
                              return selectedResType ? (
                                selectedResType.name
                              ) : isSelectDisabled ? (
                                <>{t('plans.viewAddItem.body.errors.resTypeglobal')}</>
                              ) : (
                                <>{t('auth.userReg.inputs.placeholder')}</>
                              );
                            }}
                            MenuProps={{
                              disableScrollLock: true,
                              sx: {
                                height: '280px',
                                '& .MuiPaper-root': {
                                  overflowY: 'auto',
                                  scrollbarWidth: 'thin',
                                  scrollbarColor: '#bdbdbd transparent',
                                },
                              },
                            }}>
                            {moderatorResourcesData
                              ?.filter((res: any) => res.id === formik.values.resourceCategory)
                              .flatMap((res: any) =>
                                res.items && res.items.length > 0
                                  ? res.items.map((item: any) => (
                                      <StyledMenu key={item.id} value={item.id}>
                                        {item.name}
                                      </StyledMenu>
                                    ))
                                  : []
                              )}
                          </StyledSelect>
                        </Styles.Row>
                        {formik.touched.resourceType && formik.errors.resourceType && (
                          <Typography.paragraph color='danger-500'>
                            {t(formik.errors.resourceType)}
                          </Typography.paragraph>
                        )}
                      </Styles.Row>
                      <Styles.Row gap={4}>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <Typography.paragraphSmall
                            color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                            {t('resources.add.body.inputs.title.source')}
                          </Typography.paragraphSmall>
                          <Typography.paragraphSmall color='danger-600'>
                            *
                          </Typography.paragraphSmall>
                        </Styles.Row>
                        <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                          <StyledTextField
                            placeholder=''
                            value={formik.values.resourceSource}
                            onChange={(e) => formik.setFieldValue('resourceSource', e.target.value)}
                            name='resourceSource'
                          />
                        </Styles.Row>
                        {formik.touched.resourceSource && formik.errors.resourceSource && (
                          <Typography.paragraph color='danger-500'>
                            {t(formik.errors.resourceSource)}
                          </Typography.paragraph>
                        )}
                      </Styles.Row>
                    </Styles.Row>
                    <Styles.Row gap={4}>
                      <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                        <Typography.paragraphSmall
                          color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                          {t('resources.add.body.inputs.title.degree')}
                        </Typography.paragraphSmall>
                      </Styles.Row>
                      <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                        <StyledSelect
                          value={formik.values.resourceDegree}
                          onChange={(e) => {
                            formik.setFieldValue('resourceDegree', e.target.value);
                          }}
                          name='resourceDegree'
                          displayEmpty
                          renderValue={(value) => {
                            const selectedResDegree = DegreeMock?.find(
                              (cg: any) => cg.value === value
                            );
                            return selectedResDegree ? (
                              <>{t(selectedResDegree.label)}</>
                            ) : (
                              <>{t('auth.userReg.inputs.placeholder')}</>
                            );
                          }}
                          MenuProps={{
                            disableScrollLock: true,
                            sx: {
                              height: '280px',
                              '& .MuiPaper-root': {
                                overflowY: 'auto',
                                scrollbarWidth: 'thin',
                                scrollbarColor: '#bdbdbd transparent',
                              },
                            },
                          }}>
                          {DegreeMock.map((item) => (
                            <StyledMenu key={item.value} value={item.value}>
                              {t(item.label)}
                            </StyledMenu>
                          ))}
                        </StyledSelect>
                      </Styles.Row>
                      {formik.touched.resourceType && formik.errors.resourceType && (
                        <Typography.paragraph color='danger-500'>
                          {t(formik.errors.resourceType)}
                        </Typography.paragraph>
                      )}
                    </Styles.Row>
                    <Styles.Row gap={4}>
                      <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                        <Typography.paragraphSmall
                          color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                          {t('resources.add.body.inputs.title.about')}
                        </Typography.paragraphSmall>
                        <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                      </Styles.Row>
                      <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                        <CommonList.RichTextEditor
                          editorState={editorState}
                          onBlur={handleEditorBlur}
                          onChange={handleEditorChange}
                        />
                      </Styles.Row>
                    </Styles.Row>

                    <Styles.Row>
                      <Common.Uploader
                        icon={
                          <Icons.Upload
                            color={
                              theme === 'dark' ? colors['grey-200'] : colors['typography-body']
                            }
                            width={29}
                            height={28}
                          />
                        }
                        type='image'
                        title={t('resources.add.body.inputs.placeholder.image.main')}
                        requiredText={t('resources.add.body.inputs.placeholder.image.secondary')}
                        files={formik.values.imageFiles}
                        setFiles={(files) =>
                          formik.setValues({
                            ...formik.values,
                            imageFiles: files,
                          })
                        }
                      />
                      {formik.touched.imageFiles && formik.errors.imageFiles && (
                        <Typography.paragraph color='danger-500'>
                          {t(renderError(formik.errors.imageFiles))}
                        </Typography.paragraph>
                      )}
                    </Styles.Row>
                    <Styles.Row>
                      <Common.Uploader
                        icon={
                          <Icons.FileText
                            color={
                              theme === 'dark' ? colors['grey-200'] : colors['typography-body']
                            }
                            width={29}
                            height={28}
                          />
                        }
                        type='file'
                        title={t('resources.add.body.inputs.placeholder.file.main')}
                        requiredText={t('resources.add.body.inputs.placeholder.file.secondary')}
                        files={formik.values.otherFiles}
                        // setFiles={(files) =>
                        //   formik.setValues({
                        //     ...formik.values,
                        //     otherFiles: files,
                        //   })
                        // }
                        setFiles={handleFileChange}
                      />
                      {formik.touched.otherFiles && formik.errors.otherFiles && (
                        <Typography.paragraph color='danger-500'>
                          {t(renderError(formik.errors.otherFiles))}
                        </Typography.paragraph>
                      )}
                    </Styles.Row>
                  </Styles.Row>
                </NewResourcesContainer>
              </Common.Container>
            </Styles.Row>
            {imageRow.length > 0 || otherRow.length > 0 ? (
              <Styles.Row>
                <Common.Container>
                  <TableContentWrap>
                    <StyledTableContainer>
                      <StyledTable aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell align='center'>
                              {t('resources.add.table.content.number')}
                            </TableCell>
                            <TableCell align='center'>
                              {t('resources.add.table.content.name')}
                            </TableCell>
                            <TableCell align='center'>
                              {t('resources.add.table.content.type')}
                            </TableCell>
                            <TableCell align='center'>
                              {t('resources.add.table.content.size')}
                            </TableCell>
                            <TableCell align='center'>{t('resources.table.necessary')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {imageRow.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                              }}>
                              <TableCell align='center'>{row.number}</TableCell>
                              <TableCell align='center'>{row.name}</TableCell>
                              <TableCell align='center'>{row.type}</TableCell>
                              <TableCell align='center'>{row.size}</TableCell>
                              <TableCell align='center'>
                                <Button
                                  onClick={() => {
                                    formik.setValues({
                                      ...formik.values,
                                      imageFiles: [],
                                    });
                                  }}>
                                  <Icons.Trash
                                    color={
                                      theme === 'dark'
                                        ? colors['grey-300']
                                        : colors['typography-body']
                                    }
                                  />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                          {otherRow.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                              }}>
                              <TableCell align='center'>{row.number}</TableCell>
                              <TableCell align='center'>{row.name}</TableCell>
                              <TableCell align='center'>{row.type}</TableCell>
                              <TableCell align='center'>{row.size}</TableCell>
                              <TableCell align='center'>
                                <Button
                                  onClick={() => {
                                    formik.setValues({
                                      ...formik.values,
                                      otherFiles: [],
                                    });
                                  }}>
                                  <Icons.Trash
                                    color={
                                      theme === 'dark'
                                        ? colors['grey-300']
                                        : colors['typography-body']
                                    }
                                  />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </StyledTable>
                    </StyledTableContainer>
                  </TableContentWrap>
                </Common.Container>
              </Styles.Row>
            ) : (
              <></>
            )}
            <Styles.Row content={'flex-end'}>
              <PrimaryButton type='submit' variant='contained'>
                <Typography.h6 color='white'>{t('resources.add.table.button')}</Typography.h6>
              </PrimaryButton>
            </Styles.Row>
          </Styles.Row>
        )}
      </NewResourcesWrap>
    </form>
  );
};
