import styled from 'styled-components';
import { Row } from './flexbox';
import { Snackbar, TableCell, TextField } from '@mui/material';
import colors from '../../constants';
import { paragraph } from './typography.s';
import { PatternFormat } from 'react-number-format';


export const TableHeaderTitle = styled(Row)`
  && {
   transition: none;
  }
`;

export const Bar = styled(Snackbar)`
  & .MuiSnackbarContent-root {
    background-color: ${colors.white};
    color: ${colors.black};
    justify-content: center;
    text-align: center;
  }
`;

export const ErrorTypography = styled(paragraph)`
  && {
    text-align: center;
  }
`;

export const Img = styled.img`
  && {
    object-fit: contain;
    width: 80%;
    &:hover {
      width: 85%;
    }
    @media (max-width: 1200px) {
      width: 70%;
      &:hover {
        width: 75%;
      }
    }
  }
`;


export const StyledTextField = styled(TextField)`
  && {
    width: 192px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
`;

export const StyledPhoneFormat = styled(PatternFormat)`
  padding: 7px 14px;
  width: 192px;
  color: ${colors['secondary-500']};
  border: 1px solid ${colors['grey-400']};
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  @media (max-width: 576px) {
    width: 100%;
  }
  &:focus {
    color: black;
    border-width: 0.1rem;
    border-color: ${colors['opacity-primary']};
  }
`;


export const IdTableCell = styled(TableCell)`
  && {
    max-width: 40px;
  }
`;
