import { SendQuestions } from '../user/send-questions';
import { AddPayment } from './add-payment';
import { AddPlan } from './add-plan';
import { EditTopic } from './edit-topic';
import { History } from './history';
import { NewPlan } from './new-plan';
import { NewResourse } from './new-resource';
import { NewTopic } from './new-topic';
import { Payment } from './payment';
import { PlansResourceView } from './plan-resource-view';
import { Plans } from './plans';
import { PlansView } from './plans-view';
import { Resources } from './resources';
import { ResourcesType } from './resources-type';
import { ResourcesView } from './resources-view';
import { ScienceTopics } from './scienceTopics';
import { Settings } from './settings';
import { TopicResource } from './topic-resource';


const ModeratorPages = {
  Resources,
  NewResourse,
  Plans,
  PlansResourceView,
  ScienceTopics,
  AddPlan,
  NewPlan,
  PlansView,
  Payment,
  SendQuestions,
  AddPayment,
  ResourcesView,
  Settings,
  History,
  ResourcesType,
  NewTopic,
  TopicResource,
  EditTopic
};

export default ModeratorPages;
