import styled from 'styled-components';
import Styles, { Typography } from '../../../../../styles';
import colors from '../../../../../constants';
import { Button, Select, Snackbar, Table, TableContainer } from '@mui/material';

export const PaymentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PaymentHeaderWrap = styled(Styles.Row)`
  padding: 0px 24px;
`;

export const ClassWrap = styled(Styles.Row)<{lang: string}>`
  width: ${(props) => props.lang === 'ru' ? '100px !important' : '65px !important'};
`;

export const ButtonContentWrap = styled(Styles.Row)`
  padding: 12px;
`;

export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;

export const ButtonIconWrap = styled(Styles.Row)`
  height: 46px;
  background-color: white;
`;

export const Bar = styled(Snackbar)`
  & .MuiSnackbarContent-root {
    background-color: ${colors.white};
    color: ${colors.black};
    display: flex;
    justify-content: center;
  }
`;

export const ActiveIconWrap = styled(Styles.Row)`
  height: 14px;
  border-radius: 15px;
  background-color: #3b82f6;
`;

export const SelectContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 150px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
  }
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
    width: 100%;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid rgba(67, 73, 104, 1);
    max-width: 100%;
    overflow-x: auto;
  }
`;

export const OrderWrapper = styled(Styles.Row)`
  && {
    position: relative;
    flex-direction: column;
    border-radius: 5px;
    background-color: ${colors['opacity-secondary32']};
    border: 1px solid ${colors['primary-500']};
    padding: 15px;
    gap: 8px;
  }
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const OrderDelete = styled(Button)`
  && {
    position: absolute;
    top: -15px;
    right: -20px;
    padding: 5px;
    min-width: 20px;
    height: 25px;
  }
`;

export const OrderConfirm = styled(Button)`
  && {
    background-color: ${colors['primary-500']};
  }
`;
