import { FC } from 'react';
import { LoginWrap } from './login.s';
import AuthComp from '../components';
import Styles, { GlobalStyles } from '../../../styles';
import { LoginForm } from './components';

interface ILoginProps {}

export const Login: FC<ILoginProps> = (props) => {
  localStorage.removeItem('week');
  localStorage.removeItem('quarter');

  return (
    <LoginWrap>
      <AuthComp.LogInLeftContent>
        <GlobalStyles.Img src='/images/auth-illustration/loginImage.png' alt='login' />
      </AuthComp.LogInLeftContent>
      <Styles.Row content={'center'}>
        <LoginForm />
      </Styles.Row>
    </LoginWrap>
  );
};
