import styled from 'styled-components';
import Styles from '../../../../styles';
import { Box, Table, TableContainer } from '@mui/material';
import colors from '../../../../constants';

export const PlansViewWrap = styled.div`
  width: 100%;
`;

export const ButtonsContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
  }
`;

export const StyledTable = styled(Table)`
  min-width: 650px;
  width: 100%;
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors["grey-300"]};
    max-width: 100%;
    overflow-x: auto; 
  }
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;

export const PlanBannerWrapper = styled(Styles.Row)`
  && {
    background-color: #a8aaae29;
    border-radius: 6px;
    width: 300px;
    height: 300px;
  }
`;

export const PlanInfoWrapper = styled(Styles.Row)`
  && {
    padding: 0px 10px;
  }
`;

export const StyledBox = styled(Box)<{ selectedImage: string }>`
  && {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-size: cover;
    background-position: center;
    background-image: ${({ selectedImage }) => `url(${selectedImage})`};
  }
`;

export const Line = styled(Styles.Row)`
  && {
    height: 1px;
    background-color: ${colors["grey-300"]}
  }
`;