/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import {
  AddOrderWrap,
  Bar,
  ContentWrap,
  SecondaryButton,
  StyledMenu,
  StyledSelect,
} from './sample-connect.s';

import { useTranslation } from 'react-i18next';
import Styles, { Typography } from '../../../../styles';
import { Button } from '@mui/material';
import colors from '../../../../constants';
import * as Yup from 'yup';
import { Common } from '../../../../components';
import { useAppTheme } from '../../../../hooks';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useAddOrderMutation } from '../../../../store/services/order';
import { useGetAllQuartersQuery } from '../../../../store/services/quarterApi';
import { useGetWeeksQuery } from '../../../../store/services/weekApi';
import {
  useConnectScheduleMutation,
  useGetAllSchedulesQuery,
  useGetScheduleByIdQuery,
  useGetSchedulesQuery,
} from '../../../../store/services/userScheduleApi';
import { getCurrentDate, TodayDate } from '../../../../lib/getDay';
import { Diary } from './components';

interface ISampleConnectProps {}

export const SampleConnect: FC<ISampleConnectProps> = () => {
  const [t, i18n] = useTranslation('global');
  const { theme } = useAppTheme();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [scheduleQuarter, setScheduleQuarter] = useState<number | null>(null);
  const [scheduleWeek, setScheduleWeek] = useState<number | null>(null);
  const shouldFetch = scheduleQuarter !== null && scheduleWeek !== null;
  const [scheduleId, setScheduleId] = useState<number | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const { data: quarterData } = useGetAllQuartersQuery();
  const { data: weekData } = useGetWeeksQuery(scheduleQuarter, { skip: scheduleQuarter === null });
  const { data: shedulesData } = useGetAllSchedulesQuery();
  const [connectSample, { isLoading: connectLoading }] = useConnectScheduleMutation();
  const { data: scheduleByIdData } = useGetScheduleByIdQuery(scheduleId, {
    skip: scheduleId === null,
  });
  const { data: scheduleIdData } = useGetSchedulesQuery(
    { quarter: scheduleQuarter, week: scheduleWeek, lang: i18n.language },
    { skip: !shouldFetch }
  );
  const today = TodayDate();

  const formik = useFormik({
    initialValues: {
      quarter: '',
      week: '',
      sample: '',
    },
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append('quarter', values.quarter);
        formData.append('schedule_template', values.sample);
        formData.append('week', values.week);
        await connectSample({
          formData,
          week: scheduleIdData?.results[0]?.id,
        }).unwrap();
        setSnackbarMessage('mainPage.snackbar.sampleConnect');
        setSnackbarOpen(true);
      } catch (error: any) {
        console.log(error);
        setSnackbarMessage('mainPage.snackbar.sampleErr');
        setSnackbarOpen(true);
      }
    },
  });

  const dayIndexMapping: Record<string, number> = {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
  };

  let daysToShow: any[][] = Array(6)
    .fill(null)
    .map(() => []);

  const fillDaysToShow = (schedules: any[]) => {
    schedules.forEach((item: any) => {
      const index = dayIndexMapping[item.weekday];
      if (index >= 0 && index < daysToShow.length) {
        daysToShow[index].push(item);
      }
    });
  };

  fillDaysToShow(scheduleByIdData?.schedules || []);

  return (
    <AddOrderWrap onSubmit={formik.handleSubmit}>
      <Common.Container>
        <ContentWrap gap={16}>
          <Styles.Row>
            <Typography.h5 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {t('mainPage.sample.connectTitle')}
            </Typography.h5>
          </Styles.Row>
          <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
            <Styles.Column size={6} difference={0} gap={4}>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('mainPage.sample.select.quater')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </Styles.Row>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <StyledSelect
                  value={formik.values.quarter}
                  onChange={formik.handleChange}
                  displayEmpty
                  name='quarter'
                  renderValue={(selected: any) => {
                    if (selected === '') {
                      return <>{t('auth.userReg.inputs.placeholder')}</>;
                    }

                    const selectedQuarter = quarterData?.results.find(
                      (group: any) => group.id === selected
                    );
                    return selectedQuarter ? selectedQuarter.choices : '';
                  }}
                  error={formik.touched.quarter && Boolean(formik.errors.quarter)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '500px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {quarterData?.results &&
                    quarterData?.results.map((quarter: any, index: number) => (
                      <StyledMenu
                        key={index}
                        value={quarter.id}
                        onClick={() => setScheduleQuarter(quarter.id)}>
                        {quarter.choices}
                      </StyledMenu>
                    ))}
                </StyledSelect>
                {formik.touched.quarter && formik.errors.quarter && (
                  <Typography.paragraphSmall color='danger-600'>
                    {t(formik.errors.quarter)}
                  </Typography.paragraphSmall>
                )}
              </Styles.Row>
            </Styles.Column>
            <Styles.Column size={6} difference={0} gap={4}>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('mainPage.sample.select.week')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </Styles.Row>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <StyledSelect
                  value={formik.values.week}
                  onChange={formik.handleChange}
                  disabled={scheduleQuarter === null}
                  displayEmpty
                  name='week'
                  error={formik.touched.week && Boolean(formik.errors.week)}
                  renderValue={(selected: any) => {
                    if (formik.values.quarter === '') {
                      return <>{t('auth.userReg.inputs.weekPlaceholder')}</>;
                    }

                    const findWeekName = (id: number) => {
                      const selectedQuarter = weekData?.find((group: any) => group.id === id);
                      const weekCount = selectedQuarter ? selectedQuarter.week_count : '';
                      const weekLabel = t('mainPage.sample.select.week');
                      return weekCount ? `${weekCount} - ${weekLabel}` : '';
                    };

                    return selected ? (
                      findWeekName(selected)
                    ) : (
                      <>{t('auth.userReg.inputs.placeholder')}</>
                    );
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '500px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {weekData &&
                    weekData?.map((week: any, index: number) => (
                      <StyledMenu
                        onClick={() => setScheduleWeek(week.id)}
                        key={index}
                        value={week.id}>
                        {week.week_count} - {t('mainPage.sample.select.week')}
                      </StyledMenu>
                    ))}
                </StyledSelect>
                {formik.touched.week && formik.errors.week && (
                  <Typography.paragraphSmall color='danger-600'>
                    {t(formik.errors.week)}
                  </Typography.paragraphSmall>
                )}
              </Styles.Row>
            </Styles.Column>
            <Styles.Column size={6} difference={0} gap={4}>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <Typography.paragraphSmall
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('mainPage.sample.select.sample')}
                </Typography.paragraphSmall>
                <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
              </Styles.Row>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <StyledSelect
                  value={formik.values.sample}
                  onChange={formik.handleChange}
                  renderValue={(selected: any) => {
                    if (formik.values.week === '') {
                      return <>{t('auth.userReg.inputs.schedulePlaceholder')}</>;
                    }

                    const findScheduleName = (id: number) => {
                      const selectedQuarter = scheduleByIdData;
                      const weekCount = selectedQuarter ? selectedQuarter.name : '';
                      console.log(selectedQuarter);
                      return weekCount;
                    };

                    return selected ? (
                      findScheduleName(selected)
                    ) : (
                      <>{t('auth.userReg.inputs.placeholder')}</>
                    );
                  }}
                  displayEmpty
                  disabled={formik.values.week === ''}
                  name='sample'
                  error={formik.touched.sample && Boolean(formik.errors.sample)}
                  MenuProps={{
                    disableScrollLock: true,
                    sx: {
                      height: '500px',
                      '& .MuiPaper-root': {
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#bdbdbd transparent',
                      },
                    },
                  }}>
                  {shedulesData &&
                    shedulesData?.results.map((schedule: any, index: number) => (
                      <StyledMenu
                        onClick={() => setScheduleId(schedule.id)}
                        key={index}
                        value={schedule.id}>
                        {schedule.name}
                      </StyledMenu>
                    ))}
                </StyledSelect>
                {formik.touched.sample && formik.errors.sample && (
                  <Typography.paragraphSmall color='danger-600'>
                    {t(formik.errors.sample)}
                  </Typography.paragraphSmall>
                )}
              </Styles.Row>
            </Styles.Column>
          </Styles.Row>
          <Styles.Row gap={16} content={{ xs: 'space-between', sm: 'flex-end' }}>
            <SecondaryButton
              onClick={() => navigate('/sample-create')}
              variant='contained'
              sx={{
                backgroundColor: colors['primary-500'],
                textTransform: 'capitalize',
              }}>
              <Typography.h6 color={theme === 'dark' ? 'white' : 'primary-500'}>
                {t('auth.userReg.cancelBtn')}
              </Typography.h6>
            </SecondaryButton>
            <Button
              type='submit'
              disabled={connectLoading || formik.values.sample === ''}
              variant='contained'
              sx={{
                backgroundColor: colors['primary-500'],
                textTransform: 'capitalize',
              }}>
              <Typography.h6 color='white'>{t('mainPage.sample.select.btn')}</Typography.h6>
            </Button>
          </Styles.Row>
        </ContentWrap>
      </Common.Container>
      {scheduleByIdData === undefined ? (
        <></>
      ) : (
        <Styles.Row gap={16}>
          {daysToShow.map((day: any, index: number) => {
            const currentDate = getCurrentDate(index);
            const date = `${currentDate.month} ${currentDate.day.toString().padStart(2, '0')} ${
              currentDate.year
            }`;
            const dateByIndex = `${currentDate.year}-${currentDate.monthIndex + 1}-${currentDate.day
              .toString()
              .padStart(2, '0')}`;
            return (
              <Styles.Column key={index} size={{ xs: 12, lg: 6 }} difference={8}>
                <Diary
                  theme={theme}
                  todayWeekday={today.weekday}
                  weekday={currentDate.weekday}
                  quarter={0}
                  day={day?.id || null}
                  slot2={day.filter((item: any) => item.shift === 'EVENING')}
                  slot1={day.filter((item: any) => item.shift === 'MORNING')}
                  date={date}
                  dateByIndex={dateByIndex}
                />
              </Styles.Column>
            );
          })}
        </Styles.Row>
      )}
      <Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </AddOrderWrap>
  );
};
