import { FC } from "react";
import { IIconProps } from "./interfaces/icon-interface";

export const RotateClockwise: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color,
}) => {
  return (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8.71985 3.85432C8.33562 4.00904 8.14956 4.44595 8.30429 4.83018C8.45901 5.21441 8.89592 5.40046 9.28015 5.24574L8.71985 3.85432ZM14.7198 18.7543C14.3356 18.909 14.1496 19.346 14.3043 19.7302C14.459 20.1144 14.8959 20.3005 15.2802 20.1457L14.7198 18.7543ZM15.75 15C15.75 14.5858 15.4142 14.25 15 14.25C14.5858 14.25 14.25 14.5858 14.25 15H15.75ZM15 20H14.25C14.25 20.4142 14.5858 20.75 15 20.75V20ZM20 20.75C20.4142 20.75 20.75 20.4142 20.75 20C20.75 19.5858 20.4142 19.25 20 19.25V20.75ZM9.28015 5.24574C13.0104 3.74361 17.2522 5.54989 18.7543 9.28018L20.1457 8.71988C18.3341 4.22112 13.2186 2.04274 8.71985 3.85432L9.28015 5.24574ZM18.7543 9.28018C20.2564 13.0105 18.4501 17.2522 14.7198 18.7543L15.2802 20.1457C19.7789 18.3342 21.9573 13.2186 20.1457 8.71988L18.7543 9.28018ZM14.25 15V20H15.75V15H14.25ZM15 20.75H20V19.25H15V20.75Z" fill={color}/>
        <path d="M8.71985 3.85432C8.33562 4.00904 8.14956 4.44595 8.30429 4.83018C8.45901 5.21441 8.89592 5.40046 9.28015 5.24574L8.71985 3.85432ZM14.7198 18.7543C14.3356 18.909 14.1496 19.346 14.3043 19.7302C14.459 20.1144 14.8959 20.3005 15.2802 20.1457L14.7198 18.7543ZM15.75 15C15.75 14.5858 15.4142 14.25 15 14.25C14.5858 14.25 14.25 14.5858 14.25 15H15.75ZM15 20H14.25C14.25 20.4142 14.5858 20.75 15 20.75V20ZM20 20.75C20.4142 20.75 20.75 20.4142 20.75 20C20.75 19.5858 20.4142 19.25 20 19.25V20.75ZM9.28015 5.24574C13.0104 3.74361 17.2522 5.54989 18.7543 9.28018L20.1457 8.71988C18.3341 4.22112 13.2186 2.04274 8.71985 3.85432L9.28015 5.24574ZM18.7543 9.28018C20.2564 13.0105 18.4501 17.2522 14.7198 18.7543L15.2802 20.1457C19.7789 18.3342 21.9573 13.2186 20.1457 8.71988L18.7543 9.28018ZM14.25 15V20H15.75V15H14.25ZM15 20.75H20V19.25H15V20.75Z" fill="white" fill-opacity="0.1"/>
        <path d="M5.62988 7.16004V7.17004" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.62988 7.16004V7.17004" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.06006 11V11.01" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.06006 11V11.01" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.62988 15.1V15.11" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.62988 15.1V15.11" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.16016 18.37V18.38" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.16016 18.37V18.38" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 19.9399V19.9499" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 19.9399V19.9499" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};
