/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import {
  DownloadHistoryWrap,
  EmptyTitleWrapper,
  StyledTable,
  StyledTableCell,
  StyledTableContainer,
  TableContentWrap,
} from './downloadHistory.s';
import { useTranslation } from 'react-i18next';
import Styles, { Typography } from '../../../../../styles';
import { Pagination, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useDownloadHistoryQuery } from '../../../../../store/services/downloadsApi';
import { useAppTheme } from '../../../../../hooks';
import { formatBytes } from '../../../../../helpers/formatBytes';

interface IDownloadHistory {}

export const DownloadHistory: FC<IDownloadHistory> = () => {
  const [t] = useTranslation('global');
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const { theme } = useAppTheme();
  const { data: downloadData, isLoading, refetch } = useDownloadHistoryQuery({page: page, limit: rowsPerPage});

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <DownloadHistoryWrap>
      {isLoading ? (
        <EmptyTitleWrapper content={'center'} align_items={'center'}>
          <Typography.h4 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
            {t('plans.main.loadingTitle')}
          </Typography.h4>
        </EmptyTitleWrapper>
      ) : (
        <>
          {downloadData?.results?.length > 0 ? (
            <Styles.Row>
              <TableContentWrap>
                <Typography.h4 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                  {t('history.downloadTable.countTitle')}: {downloadData?.count}
                </Typography.h4>
              </TableContentWrap>
              <TableContentWrap>
                <StyledTableContainer>
                  <StyledTable aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>{t('history.paymentTable.number')}</TableCell>
                        <StyledTableCell align='center'>
                          {t('history.uploadTable.name')}
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          {t('history.downloadTable.createDate')}
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          {t('history.uploadTable.size')}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {downloadData?.results?.map((media: any, index: number) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { bmedia: 0 },
                          }}>
                          <TableCell align='center'>{index + 1}</TableCell>
                          <TableCell align='center'>{media.media_name}</TableCell>
                          <TableCell align='center'>{media.download_date}</TableCell>
                          <TableCell align='center'>{formatBytes(media.media_size)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              </TableContentWrap>
              <Styles.Row content={'flex-end'}>
                <Pagination
                  count={Math.ceil(downloadData?.count / rowsPerPage)}
                  page={page}
                  onChange={handleChangePage}
                  color='primary'
                  shape='rounded'
                  style={{ marginTop: '20px' }}
                />
              </Styles.Row>
            </Styles.Row>
          ) : (
            <EmptyTitleWrapper content={'center'} align_items={'center'}>
              <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('Yuklanganlar yo`q')}
              </Typography.h4>
            </EmptyTitleWrapper>
          )}
        </>
      )}
    </DownloadHistoryWrap>
  );
};
