import { ORDERS, PAYMENT_CREATE } from '../URLs';
import { api } from './api';

export const orderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<any, void>({
      query: () => ({
        url: ORDERS,
        method: 'GET',
      }),
      providesTags: ['getOrders'],
    }),
    deleteOrder: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `${ORDERS}${id}`,
        method: `DELETE`,
      }),
      invalidatesTags: ['deleteOrder'],
    }),
    addOrder: builder.mutation<any, FormData>({
      query: (body) => ({
        url: ORDERS,
        method: `POST`,
        body,
      }),
      invalidatesTags: ['addOrder'],
    }),
    paymentCreate: builder.mutation<any, FormData>({
      query: (body) => ({
        url: PAYMENT_CREATE,
        method: `POST`,
        body
      }),
      invalidatesTags: ['paymentCreate'],
    }),
  }),
});

export const { useGetOrdersQuery, useDeleteOrderMutation, useAddOrderMutation, usePaymentCreateMutation } = orderApi;
