import styled from 'styled-components';
import Styles from '../../styles';

export const LayoutWrap = styled(Styles.Row)`
  min-height: 100vh;
  /* background: #f8f7fa; */
  position: relative;
  @media(max-width: 1400px){
    background: none;
  }
`;

export const ContentWrapper = styled(Styles.Row)<{role: string}>`
  padding: ${props => props.role === "auth" ? '0px' : '16px 20px' };
`;

export const BurgerIconWrapper = styled.div`
  position: absolute;
  top: 35px;
  left: 40px;
`;

export const BurgerMenuBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: white;
  z-index: 1000;
  box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.3);
  border-radius: 10px;
`;
