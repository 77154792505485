import { FC } from 'react';
import { NewPasswordWrap } from './new-password.s';
import { NewPasswordForm } from './components';
import Styles, { GlobalStyles } from '../../../../styles';
import AuthComp from '../../components';

interface INewPasswordProps {}

export const NewPassword: FC<INewPasswordProps> = (props) => {
  return (
    <NewPasswordWrap>
      <AuthComp.LogInLeftContent>
        <GlobalStyles.Img src='/images/auth-illustration/loginImage.png' alt='illustration' />
      </AuthComp.LogInLeftContent>
      <Styles.Row content={'center'}>
        <NewPasswordForm />
      </Styles.Row>
    </NewPasswordWrap>
  );
};
