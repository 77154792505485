import { SEARCH } from '../URLs';
import { api } from './api';

export const searchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query<any, string>({
        query: (queryParam) => ({
        url: `${SEARCH}?query=${encodeURIComponent(queryParam)}`,
        method: 'GET',
      }),
      providesTags: ['search'],
    }),
  }),
});

export const { useSearchQuery } = searchApi;
