import styled from 'styled-components';
import colors from '../../../constants';
import { ReactNode } from 'react';

export const TableWrap = styled.table`
  width: 100%;
  border-right: 0;
  border-left: 0;
  border: 0.01rem solid #dbdade;
`;
export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const TR = styled.tr``;

export const TH = styled.th``;

export const TD = styled.td`
  padding: 15px 20px;
`;

export const TDLessonSpan = styled.span`
  color: ${colors['primary-500']};
  background-color: ${colors['opacity-primary32']};
  padding: 5px 10px;
  border-radius: 4px;
`;

export const TDStatenSpan = styled.span<{ state: ReactNode }>`
  color: ${(props) =>
    props.state === 'Aktiv' || props.state === 'Hisoblangan'
      ? colors['success-500']
      : colors['danger-500']};
  background-color: ${(props) =>
    props.state === 'Aktiv' || props.state === 'Hisoblanmagan'
      ? colors['opacity-success32']
      : colors['opacity-success32']};
  padding: 5px 10px;
  border-radius: 4px;
`;
