/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import {
  MotionEndTitle,
  MotionSelect,
  MotionSpan,
  MotionStartTitle,
  MotionTypographyH4,
  PrimaryButton,
  SelectUserWrap,
  SelectWrap,
  TypographyTitle,
} from './select-user.s';
import Styles, { Typography } from '../../../styles';
import { useNavigate } from 'react-router-dom';
import mock from '../../../mock';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

interface ISelectUserProps {}

export const SelectUser: FC<ISelectUserProps> = (props) => {
  const [t] = useTranslation('global');
  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('province');
  localStorage.removeItem('city');
  localStorage.removeItem('class_group');
  localStorage.removeItem('institutionType');
  localStorage.removeItem('institutionNumber');
  localStorage.removeItem('science_types');
  localStorage.removeItem('phoneNumber');
  localStorage.removeItem('school');
  localStorage.removeItem('pageState');
  localStorage.removeItem('science');
  localStorage.removeItem('specialty');
  localStorage.removeItem('week');
  localStorage.removeItem('quarter');
  localStorage.removeItem('selectedOption');
  sessionStorage.clear();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const navigate = useNavigate();
  const handleSelect = (index: number) => {
    if (index === 0) {
      navigate('/author/user-register');
    } else {
      navigate('/author/register-multisteps');
    }
  };

  return (
    <SelectUserWrap>
      <Styles.Row content={'center'} direction={'column'} gap={32}>
        <Styles.Row content={'center'}>
          <MotionStartTitle ref={ref} inView={inView}>
            <MotionTypographyH4 color='typography-body'>
              {t('auth.roleSelection.title')}
            </MotionTypographyH4>
          </MotionStartTitle>
        </Styles.Row>
        <Styles.Row content={'center'} gap={26}>
          {mock.SelectUser.card.map((item, index) => (
            <MotionSelect
              key={index}
              index={index}
              ref={ref}
              inView={inView}
              transition={{ delay: index * 0.5 }}>
              <SelectWrap>
                <Styles.Row gap={6}>
                  <Styles.Row content={'center'}>
                    <item.icons width={60} height={60} />
                  </Styles.Row>
                  <Styles.Row content={'center'}>
                    <Typography.h5 color='typography-body'>{t(item.label)}</Typography.h5>
                  </Styles.Row>
                  <Styles.Row content={'center'}>
                    <TypographyTitle index={index} align='center' color='typography-body'>
                      {t(item.title)}
                    </TypographyTitle>
                  </Styles.Row>
                </Styles.Row>
                <Styles.Row content={'center'}>
                  <PrimaryButton variant='text' onClick={() => handleSelect(index)}>
                    <Typography.paragraph color='primary-500'>
                      {t('auth.roleSelection.btn')}
                    </Typography.paragraph>
                  </PrimaryButton>
                </Styles.Row>
              </SelectWrap>
            </MotionSelect>
          ))}
        </Styles.Row>
        <Styles.Row content={'center'}>
          <Typography.paragraph subColor='primary-500'>
            <MotionEndTitle ref={ref} inView={inView}>
              {t('auth.roleSelection.logInTitle')}{' '}
              <MotionSpan>
                <Link to='/login'>{t('auth.roleSelection.logInLink')}</Link>
              </MotionSpan>
            </MotionEndTitle>
          </Typography.paragraph>
        </Styles.Row>
      </Styles.Row>
    </SelectUserWrap>
  );
};
