import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const Add: FC<IIconProps> = ({
  className,
  width = 25,
  height = 24,
  color = colors['extra-white'],
  ...props
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9 3.75V14.25'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3.75 9H14.25'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
