/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import Styles, { Typography } from '../../../../../styles';
import { EmptySendQuestionWrapper, Image } from './empty-send-question.s';
import { useTranslation } from 'react-i18next';

interface IEmptySendQuestions {
  theme: string;
}

export const EmptySendQuestion: FC<IEmptySendQuestions> = ({theme}) => {
    const [t] = useTranslation('Global');

  return (
    <EmptySendQuestionWrapper>
      <Styles.Row content={'center'} align_items={'center'} direction={'column'} gap={12}>
        <Image src='/images/Frame 8.png' alt='empty'/>
        <Typography.h6 color={ theme === 'dark' ? 'grey-200' : 'typography-body'}>
            {t('Xabarlar mavjud emas!')}
        </Typography.h6>
      </Styles.Row>
    </EmptySendQuestionWrapper>
  );
};
