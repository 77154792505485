/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../../../styles';
import { Day } from '../../../../../../interfaces/lesson';
import { DiaryWrap, Bottom } from './diary.s';
import { Header, TableContent } from './components';

interface IDiary {
  deleteState: boolean;
  theme: string;
  date: any;
  slot1: any[];
  slot2: any[];
  weekday: number;
  todayWeekday: number;
  dateByIndex: string;
  updateSchedules: (updatedSchedules: any) => void;
}

export const Diary: FC<IDiary> = ({
  theme,
  date,
  slot1,
  slot2,
  weekday,
  deleteState,
  todayWeekday,
  dateByIndex,
  updateSchedules,
}) => {
  const { t } = useTranslation('global');
  const [slot, setSlot] = useState<1 | 2>(1);
  const daysOfWeek = [
    'mainPage.days.monday',
    'mainPage.days.tuesday',
    'mainPage.days.wednesday',
    'mainPage.days.thursday',
    'mainPage.days.friday',
    'mainPage.days.saturday',
  ];
  const currentDayOfWeek = daysOfWeek[weekday - 1];

  const handleChange = (updatedSchedules: any[]) => {
    updateSchedules(updatedSchedules);
  };

  return (
    <DiaryWrap active={weekday === todayWeekday} direction={'column'}>
      <Header
        theme={theme}
        active={weekday === todayWeekday}
        date={date}
        dayOfWeek={currentDayOfWeek}
        slot={slot}
        setSlot={setSlot}
      />

      <TableContent
        tableId={weekday - 1}
        deleteState={deleteState}
        theme={theme}
        slot={slot === 1 ? slot1 : slot2}
        lessons={slot === 1 ? slot1 : slot2}
        dateByIndex={dateByIndex}
        updateSchedules={handleChange}
      />

      <Bottom theme={theme}>
        <Typography.paragraphSmall color={theme === 'dark' ? 'grey-400' : 'typography-muted'}>
          {t('mainPage.mainTable.header.total')}: {slot === 1 ? slot1.length : slot2.length}{' '}
          {t('mainPage.mainTable.header.time')}
        </Typography.paragraphSmall>
      </Bottom>
    </DiaryWrap>
  );
};
