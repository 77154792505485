/* eslint-disable react/jsx-pascal-case */
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useConfirmPhoneMutation,
} from '../../../../../../store/services/updatePasswordApi';
import { useDispatch } from 'react-redux';
import { setResetPasswordToken } from '../../../../../../store/slices/authSlice';
import { VerificationForm } from '../../../../components/Verification-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

interface ILoginFormProps {}

const validationSchema = Yup.object().shape({
  code: Yup.string().required('auth.passVer.errors.code.required'),
});

export const ResetPasswordVerificationForm: FC<ILoginFormProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation('global');
  const [confirmPhone, { isError, isLoading }] = useConfirmPhoneMutation();

  const handleSubmit = async (code: string, phoneNumber: string) => {
    try {
      const response = await confirmPhone({
        phone: phoneNumber,
        code: Number(code),
        lang: i18n.language,
      }).unwrap();
      dispatch(
        setResetPasswordToken({
          reset_password_token: response.token,
        })
      );
      navigate('/author/new-password');
    } catch (error: any) {
      throw error;
    }
  };

  return (
    <VerificationForm
      back={'/reset-password'}
      initialValues={{ code: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onSuccess={() => navigate('/author/new-password')}
      onError={(error: any) => console.log(error)}
    />
  );
};
