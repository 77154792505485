/* eslint-disable react/jsx-pascal-case */
import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Pagination from '@mui/material/Pagination';
import { Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Icons from '../../../assets/icons';
import { useGetResourceTypesQuery } from '../../../store/services/resourcesApi';
import Styles, { GlobalStyles, Typography } from '../../../styles';
import colors from '../../../constants';

import {
  StyledTable,
  StyledSelect,
  TableWrapper,
  ResourcesWrap,
  ButtonsContainer,
  StyledTableContainer,
  EmptyTitleWrapper,
  StyledMenu,
} from './resourcesType.s';
import CommonList from '../../../components/common';
import { useAppTheme } from '../../../hooks';
import { handleDownloadClick } from '../../../helpers/fileDownload';

interface IFormData {
  resourceType: string;
}

type SetIsDownloading = (id: number | null) => void;

export const ResourcesType: FC = () => {
  const { theme } = useAppTheme();
  const { id } = useParams();
  const [isDownloading, setIsDownloading] = useState<number | null>(null);
  const [t, i18n] = useTranslation('global');
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IFormData>({
    resourceType: '',
  });
  const [subtypes, setSubtypes] = useState<any[]>([]);

  const {
    data: resourceData,
    isLoading,
    refetch,
  } = useGetResourceTypesQuery({ id: id || '', lang: i18n.language });

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (resourceData?.results) {
      const uniqueSubtypes = Array.from(
        new Set(resourceData.results.map((item: any) => item.subtype?.name))
      ).map((name) => ({ name }));

      setSubtypes(uniqueSubtypes);
    }
  }, [resourceData]);

  useEffect(() => {
    refetch();
  }, []);

  const filteredResources =
    resourceData?.results.filter((item: any) =>
      formData.resourceType ? item.subtype?.name === formData.resourceType : true
    ) || [];

  const onDownloadClick = (id: number, name: string, setIsDownloading: SetIsDownloading) => {
    handleDownloadClick(
      id,
      name,
      i18n.language,
      setIsDownloading,
      setSnackbarMessage,
      setSnackbarOpen
    );
  };

  return (
    <ResourcesWrap>
      <Styles.Row gap={16}>
        <Styles.Row>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('resources.header.resources')}
          </Typography.h4>
        </Styles.Row>
        <Styles.Row>
          <CommonList.Container>
            <ButtonsContainer gap={16}>
              <Styles.Row>
                <Typography.h5 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {filteredResources[0]?.type?.name}
                </Typography.h5>
              </Styles.Row>
              {filteredResources &&
                filteredResources.length > 0 &&
                filteredResources[0]?.subtype !== null && (
                  <Styles.Row wrap={'nowrap'} gap={16} content={'space-between'}>
                    <StyledSelect
                      value={formData.resourceType}
                      onChange={(event) =>
                        setFormData({ resourceType: event.target.value as string })
                      }
                      displayEmpty
                      name='resourceType'
                      MenuProps={{
                        disableScrollLock: true,
                        sx: {
                          height: '280px',
                          '& .MuiPaper-root': {
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#bdbdbd transparent',
                          },
                        },
                      }}>
                      <StyledMenu value=''>{t('resources.all')}</StyledMenu>
                      {subtypes.map((subtype, index) => (
                        <StyledMenu key={index} value={subtype.name}>
                          {subtype.name}
                        </StyledMenu>
                      ))}
                    </StyledSelect>
                  </Styles.Row>
                )}
            </ButtonsContainer>
            {isLoading ? (
              <EmptyTitleWrapper content={'center'} align_items={'center'}>
                <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('plans.main.loadingTitle')}
                </Typography.h4>
              </EmptyTitleWrapper>
            ) : (
              <>
                {filteredResources.length > 0 ? (
                  <Styles.Row>
                    <TableWrapper>
                      <StyledTableContainer>
                        <StyledTable stickyHeader aria-label='sticky table'>
                          <TableHead>
                            <TableRow>
                              <GlobalStyles.IdTableCell align='center'>
                                <GlobalStyles.TableHeaderTitle content={'center'}>
                                  {t('resources.table.number')}
                                </GlobalStyles.TableHeaderTitle>
                              </GlobalStyles.IdTableCell>
                              <TableCell align='center'>
                                <GlobalStyles.TableHeaderTitle
                                  width='170px'
                                  align_items={'center'}
                                  content={'space-between'}
                                  wrap={'nowrap'}>
                                  {t('resources.table.name')}
                                </GlobalStyles.TableHeaderTitle>
                              </TableCell>
                              {filteredResources[0]?.subtype !== null && (
                                <TableCell align='center'>
                                  <Styles.Row width='170px'>
                                    <Styles.Row
                                      align_items={'center'}
                                      content={'space-between'}
                                      wrap={'nowrap'}>
                                      {t('resources.table.for')}
                                    </Styles.Row>
                                  </Styles.Row>
                                </TableCell>
                              )}
                              <TableCell align='center'>{t('resources.table.necessary')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredResources.map((row: any, index: number) => (
                              <TableRow key={index}>
                                <TableCell align='center'>
                                  {(page - 1) * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell align='left'>{row?.name}</TableCell>
                                {row.subtype !== null && (
                                  <TableCell align='left'>{row?.subtype?.name}</TableCell>
                                )}
                                <TableCell align='center'>
                                  <Styles.Row align_items={'center'} content={'center'}>
                                    <Button onClick={() => navigate(`/resource-view/${row?.id}/`)}>
                                      <Icons.Eye
                                        color={
                                          theme === 'dark'
                                            ? colors['grey-300']
                                            : colors['typography-body']
                                        }
                                      />
                                    </Button>
                                  </Styles.Row>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </StyledTable>
                      </StyledTableContainer>
                    </TableWrapper>
                    <Styles.Row content={'flex-end'}>
                      <Pagination
                        count={Math.ceil(resourceData?.count / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color='primary'
                        shape='rounded'
                        style={{ marginTop: '20px' }}
                      />
                    </Styles.Row>
                  </Styles.Row>
                ) : (
                  <EmptyTitleWrapper content={'center'} align_items={'center'}>
                    <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t('Resources empty')}
                    </Typography.h4>
                  </EmptyTitleWrapper>
                )}
              </>
            )}
          </CommonList.Container>
        </Styles.Row>
      </Styles.Row>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </ResourcesWrap>
  );
};
