import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const Arrow: FC<IIconProps> = ({ className, width = 18, height = 32 }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 18 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5 11.25L9 6.75L4.5 11.25'
        stroke='#4B465C'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13.5 11.25L9 6.75L4.5 11.25'
        stroke='white'
        stroke-opacity='0.5'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.5 20.75L9 25.25L13.5 20.75'
        stroke='#4B465C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.5 20.75L9 25.25L13.5 20.75'
        stroke='white'
        stroke-opacity='0.5'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
