import styled from 'styled-components';
import Styles from '../../../styles';

export const ContainerWrap = styled(Styles.Row)`
  && {
    padding: 24px 0px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0px 4px 18px 0px rgba(75, 70, 92, 0.1);
  }
`;

export const FileUploaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  border: 3px dashed #e9e9e9;
  padding: 6px;
  @media (min-width: 1024px) {
    padding: 24px;
  }
  gap: 16px;
`;

export const FileUploaderInput = styled.input`
  cursor: pointer;
`;

export const FileUploaderImageWrap = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  padding: 20px;
  @media (min-width: 1024px) {
    padding: 40px;
  }
`;

export const FileUploaderImage = styled.img`
  height: 100px;
  width: 100%;
  border-radius: 24px;
  object-fit: contain;
  object-position: center;
`;

export const FileUploaderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 7px;
  height: 171px;
  gap: 10px;
`;

export const IconWrap = styled.div`
  width: 46px;
  height: 46px;
  background-color: #4b465c14;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RequiredTextWrap = styled(Styles.Row)`
  && {
    @media (max-width: 576px) {
      display: none;
    }
  }
`;
