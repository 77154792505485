import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import Styles, { Typography } from '../../../../../styles';
import CommonList from '../../../../../components/common';
import Icons from '../../../../../assets/icons';
import { FirstFormWrap } from './first-form.s';
import colors from '../../../../../constants';
import { FileWithPath } from 'react-dropzone';

interface IFirstFormProps {
  setPlanStep: (status: boolean) => void;
  planStep: boolean;
}

interface IFormData {
  userName: string;
  userSurname: string;
  userPassport: string;
  userINN: string;
  userINPS: string;
  userRegion: string;
  userDistrict: string;
  userAddress: string;
}

export const FirstForm: FC<IFirstFormProps> = ({ planStep, setPlanStep }) => {
  const [t] = useTranslation('global');
  const [formData, setFormData] = useState<IFormData>({
    userName: '',
    userSurname: '',
    userPassport: '',
    userINN: '',
    userINPS: '',
    userRegion: '',
    userDistrict: '',
    userAddress: '',
  });
  const [imageFiles, setImageFiles] = useState<FileWithPath[]>([]);

  const handleImageFilesChange = (newFiles: FileWithPath[]) => {
    setImageFiles(newFiles);
  };

  return (
    <FirstFormWrap>
      <Styles.Row gap={16}>
        <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
          <Styles.Column size={6} difference={0} gap={4}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.name')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <TextField
                placeholder='John'
                name='userName'
                value={formData.userName}
                // onChange={handleChange}
                inputProps={{
                  style: {
                    height: '5px',
                  },
                }}
                sx={{
                  width: '192px',
                  color: colors['secondary-500'],
                }}
              />
            </Styles.Row>
          </Styles.Column>
          <Styles.Column size={6} difference={0} gap={4}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.surname')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <TextField
                placeholder='John'
                name='userSurname'
                value={formData.userSurname}
                // onChange={handleChange}
                inputProps={{
                  style: {
                    height: '5px',
                  },
                }}
                sx={{
                  width: '192px',
                  color: colors['secondary-500'],
                }}
              />
            </Styles.Row>
          </Styles.Column>
        </Styles.Row>
        <Styles.Row gap={23} direction={{ xs: 'column', sm: 'row' }}>
          <Styles.Column size={{ xs: 12, sm: 6, lg: 4 }} difference={16}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.passport')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <TextField
                placeholder='John'
                name='userPassport'
                value={formData.userPassport}
                // onChange={handleChange}
                inputProps={{
                  style: {
                    height: '5px',
                  },
                }}
                sx={{
                  width: '192px',
                  color: colors['secondary-500'],
                }}
              />
            </Styles.Row>
          </Styles.Column>
          <Styles.Column size={{ xs: 12, sm: 6, lg: 4 }} difference={16}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.inn')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <TextField
                placeholder='John'
                name='userINN'
                value={formData.userINN}
                // onChange={handleChange}
                inputProps={{
                  style: {
                    height: '5px',
                  },
                }}
                sx={{
                  width: '192px',
                  color: colors['secondary-500'],
                }}
              />
            </Styles.Row>
          </Styles.Column>
          <Styles.Column size={{ xs: 12, lg: 4 }} difference={16}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.inps')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <TextField
                placeholder='John'
                name='userINPS'
                value={formData.userINPS}
                // onChange={handleChange}
                inputProps={{
                  style: {
                    height: '5px',
                  },
                }}
                sx={{
                  width: '192px',
                  color: colors['secondary-500'],
                }}
              />
            </Styles.Row>
          </Styles.Column>
        </Styles.Row>
        <Styles.Row>
          <CommonList.Uploader
            icon={<Icons.Upload />}
            type='image'
            title={t('plans.payment.addPayment.inputs.first.uploader.header')}
            requiredText={t('plans.payment.addPayment.inputs.first.uploader.title')}
            // onFilesChange={handleImageFilesChange}
            files={imageFiles}
            setFiles={setImageFiles}
          />
        </Styles.Row>
        <Styles.Row gap={23} direction={{ xs: 'column', sm: 'row' }}>
          <Styles.Column size={{ xs: 12, sm: 6, lg: 4 }} difference={16}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.region')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Select
                value={formData.userRegion}
                // onChange={handleSelectChange}
                displayEmpty
                name='userRegion'
                sx={{
                  width: '192px',
                  height: '38px',
                  color: colors['secondary-500'],
                }}
              >
                <MenuItem value=''>Yunosobod</MenuItem>
                <MenuItem value='option1'>Опция 1</MenuItem>
                <MenuItem value='option2'>Опция 2</MenuItem>
                <MenuItem value='option3'>Опция 3</MenuItem>
              </Select>
            </Styles.Row>
          </Styles.Column>
          <Styles.Column size={{ xs: 12, sm: 6, lg: 4 }} difference={16}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.district')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Select
                value={formData.userDistrict}
                // onChange={handleSelectChange}
                displayEmpty
                name='userDistrict'
                sx={{
                  width: '192px',
                  height: '38px',
                  color: colors['secondary-500'],
                }}
              >
                <MenuItem value=''>Yunosobod</MenuItem>
                <MenuItem value='option1'>Опция 1</MenuItem>
                <MenuItem value='option2'>Опция 2</MenuItem>
                <MenuItem value='option3'>Опция 3</MenuItem>
              </Select>
            </Styles.Row>
          </Styles.Column>
          <Styles.Column size={{ xs: 12, lg: 4 }} difference={16}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.address')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Select
                value={formData.userAddress}
                // onChange={handleSelectChange}
                displayEmpty
                name='userAddress'
                sx={{
                  width: '192px',
                  height: '38px',
                  color: colors['secondary-500'],
                }}
              >
                <MenuItem value=''>Yunosobod</MenuItem>
                <MenuItem value='option1'>Опция 1</MenuItem>
                <MenuItem value='option2'>Опция 2</MenuItem>
                <MenuItem value='option3'>Опция 3</MenuItem>
              </Select>
            </Styles.Row>
          </Styles.Column>
        </Styles.Row>
        <Styles.Row gap={16} content={'space-between'} wrap={'nowrap'}>
          <Styles.Row>
            <Button
              // onClick={handleAdd}
              disabled={true}
              variant='contained'
              sx={{
                gap: '4px',
                width: '130px',
                height: '33px',
                padding: '6px 10px',
                backgroundColor: colors['secondary-100'],
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: colors['secondary-300'],
                },
              }}
            >
              <Styles.Row width='18'>
                <Icons.ArrowLeft color={colors['secondary-500']} />
              </Styles.Row>
              <Typography.h6 color='secondary-500'>
                {t('plans.payment.addPayment.inputs.first.buttons.cancel')}
              </Typography.h6>
            </Button>
          </Styles.Row>
          <Styles.Row content={'flex-end'} gap={16}>
            <Button
              onClick={() => setPlanStep(!planStep)}
              variant='contained'
              sx={{
                gap: '4px',
                padding: '6px 10px',
                backgroundColor: colors['primary-500'],
                textTransform: 'capitalize',
              }}
            >
              <Typography.h6 color='white'>
                {t('plans.payment.addPayment.inputs.first.buttons.next')}
              </Typography.h6>
              <Styles.Row width='18'>
                <Icons.ArrowRight color={colors.white} />
              </Styles.Row>
            </Button>
          </Styles.Row>
        </Styles.Row>
      </Styles.Row>
    </FirstFormWrap>
  );
};
