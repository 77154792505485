import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const Settings: FC<IIconProps> = ({
  className,
  width = 25,
  height = 24,
  color = colors.white,
  ...props
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.825 4.317C11.251 2.561 13.749 2.561 14.175 4.317C14.3046 4.85206 14.682 5.29302 15.1907 5.50375C15.6993 5.71447 16.2779 5.6696 16.748 5.383C18.291 4.443 20.058 6.209 19.118 7.753C18.8318 8.22285 18.7871 8.80108 18.9975 9.30938C19.208 9.81768 19.6484 10.195 20.183 10.325C21.939 10.751 21.939 13.249 20.183 13.675C19.6479 13.8046 19.207 14.182 18.9963 14.6907C18.7855 15.1993 18.8304 15.7779 19.117 16.248C20.057 17.791 18.291 19.558 16.747 18.618C16.2771 18.3318 15.6989 18.2871 15.1906 18.4975C14.6823 18.708 14.305 19.1484 14.175 19.683C13.749 21.439 11.251 21.439 10.825 19.683C10.6954 19.1479 10.318 18.707 9.80935 18.4963C9.30073 18.2855 8.72206 18.3304 8.252 18.617C6.709 19.557 4.942 17.791 5.882 16.247C6.16819 15.7771 6.21295 15.1989 6.00247 14.6906C5.792 14.1823 5.35157 13.805 4.817 13.675C3.061 13.249 3.061 10.751 4.817 10.325C5.35206 10.1954 5.79302 9.81797 6.00375 9.30935C6.21447 8.80073 6.1696 8.22206 5.883 7.752C4.943 6.209 6.709 4.442 8.253 5.382C9.253 5.99 10.549 5.452 10.825 4.317Z'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.825 4.317C11.251 2.561 13.749 2.561 14.175 4.317C14.3046 4.85206 14.682 5.29302 15.1907 5.50375C15.6993 5.71447 16.2779 5.6696 16.748 5.383C18.291 4.443 20.058 6.209 19.118 7.753C18.8318 8.22285 18.7871 8.80108 18.9975 9.30938C19.208 9.81768 19.6484 10.195 20.183 10.325C21.939 10.751 21.939 13.249 20.183 13.675C19.6479 13.8046 19.207 14.182 18.9963 14.6907C18.7855 15.1993 18.8304 15.7779 19.117 16.248C20.057 17.791 18.291 19.558 16.747 18.618C16.2771 18.3318 15.6989 18.2871 15.1906 18.4975C14.6823 18.708 14.305 19.1484 14.175 19.683C13.749 21.439 11.251 21.439 10.825 19.683C10.6954 19.1479 10.318 18.707 9.80935 18.4963C9.30073 18.2855 8.72206 18.3304 8.252 18.617C6.709 19.557 4.942 17.791 5.882 16.247C6.16819 15.7771 6.21295 15.1989 6.00247 14.6906C5.792 14.1823 5.35157 13.805 4.817 13.675C3.061 13.249 3.061 10.751 4.817 10.325C5.35206 10.1954 5.79302 9.81797 6.00375 9.30935C6.21447 8.80073 6.1696 8.22206 5.883 7.752C4.943 6.209 6.709 4.442 8.253 5.382C9.253 5.99 10.549 5.452 10.825 4.317Z'
        stroke={props.stroke || color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='12.5'
        cy='12'
        r='3'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='12.5'
        cy='12'
        r='3'
        stroke={props.stroke || color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
