import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const EyeOff: FC<IIconProps> = ({ className, width = 24, height = 24, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 3L21 21'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3 3L21 21'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.584 10.587C9.80248 11.3679 9.80203 12.6345 10.583 13.416C11.3639 14.1975 12.6305 14.1979 13.412 13.417'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.584 10.587C9.80248 11.3679 9.80203 12.6345 10.583 13.416C11.3639 14.1975 12.6305 14.1979 13.412 13.417'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.15676 4.64392C8.75852 4.75784 8.52804 5.17303 8.64196 5.57127C8.75588 5.96951 9.17107 6.2 9.56931 6.08607L9.15676 4.64392ZM12 5L11.9974 5.75H12V5ZM22 12L22.6512 12.3722C22.783 12.1416 22.783 11.8585 22.6512 11.6279L22 12ZM18.9463 14.9789C18.6651 15.2831 18.6838 15.7576 18.988 16.0388C19.2922 16.3199 19.7667 16.3012 20.0478 15.9971L18.9463 14.9789ZM17.7764 17.9708C18.1198 17.7392 18.2104 17.273 17.9788 16.9296C17.7472 16.5862 17.281 16.4956 16.9376 16.7272L17.7764 17.9708ZM2 12L1.34887 11.6278C1.21706 11.8584 1.21704 12.1415 1.34883 12.3721L2 12ZM7.05301 7.27968C7.39581 7.04716 7.4852 6.58078 7.25268 6.23799C7.02017 5.89519 6.55378 5.80579 6.21099 6.03831L7.05301 7.27968ZM9.56931 6.08607C10.3588 5.86023 11.1762 5.74708 11.9974 5.74999L12.0027 4.25C11.0402 4.24659 10.0821 4.37921 9.15676 4.64392L9.56931 6.08607ZM12 5.75C15.64 5.75 18.7632 7.84743 21.3489 12.3721L22.6512 11.6279C19.9029 6.81857 16.36 4.25 12 4.25V5.75ZM21.3489 11.6278C20.5937 12.949 19.792 14.0639 18.9463 14.9789L20.0478 15.9971C20.9841 14.9841 21.8504 13.773 22.6512 12.3722L21.3489 11.6278ZM16.9376 16.7272C15.4284 17.7451 13.79 18.25 12 18.25V19.75C14.094 19.75 16.0236 19.1529 17.7764 17.9708L16.9376 16.7272ZM12 18.25C8.36001 18.25 5.23685 16.1526 2.65117 11.6279L1.34883 12.3721C4.09715 17.1814 7.63999 19.75 12 19.75V18.25ZM2.65113 12.3722C3.98114 10.0454 5.45439 8.36403 7.05301 7.27968L6.21099 6.03831C4.37161 7.28597 2.75686 9.16458 1.34887 11.6278L2.65113 12.3722Z'
        fill={color}
      />
      <path
        d='M9.15676 4.64392C8.75852 4.75784 8.52804 5.17303 8.64196 5.57127C8.75588 5.96951 9.17107 6.2 9.56931 6.08607L9.15676 4.64392ZM12 5L11.9974 5.75H12V5ZM22 12L22.6512 12.3722C22.783 12.1416 22.783 11.8585 22.6512 11.6279L22 12ZM18.9463 14.9789C18.6651 15.2831 18.6838 15.7576 18.988 16.0388C19.2922 16.3199 19.7667 16.3012 20.0478 15.9971L18.9463 14.9789ZM17.7764 17.9708C18.1198 17.7392 18.2104 17.273 17.9788 16.9296C17.7472 16.5862 17.281 16.4956 16.9376 16.7272L17.7764 17.9708ZM2 12L1.34887 11.6278C1.21706 11.8584 1.21704 12.1415 1.34883 12.3721L2 12ZM7.05301 7.27968C7.39581 7.04716 7.4852 6.58078 7.25268 6.23799C7.02017 5.89519 6.55378 5.80579 6.21099 6.03831L7.05301 7.27968ZM9.56931 6.08607C10.3588 5.86023 11.1762 5.74708 11.9974 5.74999L12.0027 4.25C11.0402 4.24659 10.0821 4.37921 9.15676 4.64392L9.56931 6.08607ZM12 5.75C15.64 5.75 18.7632 7.84743 21.3489 12.3721L22.6512 11.6279C19.9029 6.81857 16.36 4.25 12 4.25V5.75ZM21.3489 11.6278C20.5937 12.949 19.792 14.0639 18.9463 14.9789L20.0478 15.9971C20.9841 14.9841 21.8504 13.773 22.6512 12.3722L21.3489 11.6278ZM16.9376 16.7272C15.4284 17.7451 13.79 18.25 12 18.25V19.75C14.094 19.75 16.0236 19.1529 17.7764 17.9708L16.9376 16.7272ZM12 18.25C8.36001 18.25 5.23685 16.1526 2.65117 11.6279L1.34883 12.3721C4.09715 17.1814 7.63999 19.75 12 19.75V18.25ZM2.65113 12.3722C3.98114 10.0454 5.45439 8.36403 7.05301 7.27968L6.21099 6.03831C4.37161 7.28597 2.75686 9.16458 1.34887 11.6278L2.65113 12.3722Z'
        fill='white'
        fill-opacity='0.1'
      />
    </svg>
  );
};
