import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../hooks';

interface IScheduleState {
    lesson_time?: string;
    start_time?: string;
    end_time?: string;
    shift?: string;
    weekday?: string;
    classes?: string;
    science?: string;
}

const initialState: IScheduleState = {
    lesson_time: '',
    start_time: '',
    end_time: '',
    shift: '',
    weekday: '',
    classes: '',
    science: '',
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setSchedule: (state, action: PayloadAction<IScheduleState>) => {
      state.lesson_time = action.payload.lesson_time;
      state.start_time = action.payload.start_time;
      state.science = action.payload.science;
      state.end_time = action.payload.end_time;
      state.shift = action.payload.shift;
      state.weekday = action.payload.weekday;
      state.classes = action.payload.classes;
      state.science = action.payload.science;
    },
    clearSchedule: (state) => {
      state.lesson_time = initialState.lesson_time;
      state.start_time = initialState.start_time;
      state.end_time = initialState.end_time;
      state.shift = initialState.shift;
      state.weekday = initialState.weekday;
      state.classes = initialState.classes;
      state.science = initialState.science;
    },
  },
});

export const { setSchedule, clearSchedule } = scheduleSlice.actions;

export default scheduleSlice.reducer;

export const selectSchedule = (state: RootState) => state.schedule;
