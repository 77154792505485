/* eslint-disable react/jsx-pascal-case */
import { useTranslation } from 'react-i18next';
import CommonList from '../../../components/common';
import Styles, { Typography } from '../../../styles';
import { FirstForm } from './components';

import { StepsWrap, AddPlanBody, NewPlanWrap } from './edit-topic.s';
import { useAppTheme } from '../../../hooks';

export const EditTopic = () => {
  const { theme } = useAppTheme();
  const [t] = useTranslation('global');

  return (
    <NewPlanWrap>
      <Styles.Row gap={26}>
        <Styles.Row>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('plans.viewAddItem.body.topicAdd.editTitle')}
          </Typography.h4>
        </Styles.Row>
        <Styles.Row>
          <CommonList.Container>
            <StepsWrap>
              <Typography.h5 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('plans.viewAddItem.body.topicAdd.containerTitle')}
              </Typography.h5>
              <Typography.paragraph color='typography-muted'>
                {t('plans.viewAddItem.body.topicAdd.containerSubTitle')}
              </Typography.paragraph>
            </StepsWrap>
            <AddPlanBody gap={26}>
              <FirstForm theme={theme} />
            </AddPlanBody>
          </CommonList.Container>
        </Styles.Row>
      </Styles.Row>
    </NewPlanWrap>
  );
};
