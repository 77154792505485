import { FC } from "react";
import { IIconProps } from "./interfaces/icon-interface";

export const Comet: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color,
}) => {
  return (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 18.5L12.5 20L13 16.5L11 14.5L14 14L15.5 11L17 14L20 14.5L18 16.5L18.5 20L15.5 18.5Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 18.5L12.5 20L13 16.5L11 14.5L14 14L15.5 11L17 14L20 14.5L18 16.5L18.5 20L15.5 18.5Z" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 4L11 11" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 4L11 11" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 4L12.5 7.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 4L12.5 7.5" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 9L7.5 12.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 9L7.5 12.5" stroke="white" stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};
