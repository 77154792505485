import { FC, ReactNode, useState } from 'react';
import { LayoutWrap, ContentWrapper } from './layout.s';
import { Navbar } from './navbar';
import { Footer } from './footer';
import { Sidebar } from './sidebar';
import { RouteLinkProps } from '../../interfaces/global-interface';
import Styles from '../../styles';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { AuthNavBar } from './authNavbar/AuthNavbar';
import { Search } from './search';

interface ILayoutProps {
  main?: boolean;
  children: ReactNode;
  menus: RouteLinkProps[];
}

export const Layout: FC<ILayoutProps> = ({ main, children, menus }) => {
  const [menuStatus, setMenuStatus] = useState(true);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [language, setLanguage] = useState<string>(localStorage.getItem('lang') || 'uz');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { role } = useSelector(selectAuth);
  const [searchData, setSearchData] = useState<any>(null);
  const [searchInput, setSearchInput] = useState('');
  

  // Check if any of the arrays in searchData contain items
  const hasSearchResults = (data: any) => {
    return (
      (data?.resources && data.resources.length > 0) ||
      (data?.plans && data.plans.length > 0) ||
      (data?.schedules && data.schedules.length > 0)
    );
  };


  if (main) {
    return (
      <LayoutWrap wrap={'nowrap'}>
        <Sidebar
          theme={theme}
          menuStatus={menuStatus}
          setMenuStatus={setMenuStatus}
          menus={menus}
          open={sidebarOpen}
          setOpen={setSidebarOpen}
        />
        <ContentWrapper role={role} direction={'column'} gap={26}>
          <Navbar
            theme={theme}
            setTheme={setTheme}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            setLanguage={setLanguage}
            language={language}
            openSidebar={sidebarOpen}
            setSearchData={setSearchData}
            closeSidebar={() => setSidebarOpen(!sidebarOpen)}
          />
          { hasSearchResults(searchData) ? (
            <Styles.Row>
              <Search
                setSearchInput={setSearchInput}
                searchData={searchData}
                setSearchData={setSearchData}
              />
            </Styles.Row>
          ) : (
            <Styles.Row>{children}</Styles.Row>
          )}
          <Footer theme={theme}/>
        </ContentWrapper>
      </LayoutWrap>
    );
  } else {
    return (
      <LayoutWrap gap={12} wrap={'nowrap'} direction={'column'}>
        <AuthNavBar setLanguage={setLanguage} language={language} />
        <ContentWrapper role={role} content={'center'} direction={'column'} gap={26}>
          <Styles.Row>{children}</Styles.Row>
        </ContentWrapper>
      </LayoutWrap>
    );
  }
};
