/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import {
  ActiveIconWrap,
  ButtonContentWrap,
  ButtonIconWrap,
  PaymentHeaderWrap,
  PaymentWrap,
  SelectContainer,
  StyledSelect,
  StyledTable,
  StyledTableContainer,
  TableContentWrap,
} from './payment.s';
import Styles, { Typography } from '../../../styles';
import { useTranslation } from 'react-i18next';
import {
  Button,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import colors from '../../../constants';
import Icons from '../../../assets/icons';
import mock from '../../../mock';
import { Common } from '../../../components';

interface IPaymentProps {}

interface IFormData {
  resourceStatus: string;
  resourceState: string;
  cost: string;
}

export const Payment: FC<IPaymentProps> = (props) => {
  const [t] = useTranslation('global');
  const [selectedButtonIndex, setSelectedButtonIndex] = useState<number | null>(null);
  const [modalStatus, setModalStatus] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    resourceStatus: '',
    resourceState: '',
    cost: '',
  });

  const [users, setUsers] = useState([
    {
      id: 1,
      resource: 'John Doe',
      type: 'asdxczxc',
      downloaded: 12,
      paid: '78.000 sum',
      status: 'Hisoblangan',
    },
    {
      id: 2,
      resource: 'John Doe',
      type: 'asdxczxc',
      downloaded: 40,
      paid: '60.500 sum',
      status: 'Hisoblanmagan',
    },
    {
      id: 3,
      resource: 'John Doe',
      type: 'asdxczxc',
      downloaded: 22,
      paid: '40.000 sum',
      status: 'Hisoblanmagan',
    },
  ]);

  return (
    <PaymentWrap>
      <Styles.Row gap={16}>
        <Styles.Row>
          <Typography.h4 color='typography-body'>{t('payment.main.header')}</Typography.h4>
        </Styles.Row>
        <Styles.Row>
          <Common.Container>
            <PaymentHeaderWrap gap={16}>
              <Styles.Row gap={16}>
                <Styles.Row>
                  <Typography.h5 color='typography-body'>
                    {t('payment.main.method.header')}
                  </Typography.h5>
                </Styles.Row>
                <Styles.Row gap={16}>
                  <Styles.Row gap={4}>
                    <Styles.Row>
                      <Typography.paragraph color='typography-body'>
                        {t('payment.main.method.first.header')}
                      </Typography.paragraph>
                    </Styles.Row>
                    <Styles.Row>
                      <Button
                        // onClick={() => handleButtonClick(index)}
                        variant='outlined'
                        fullWidth
                        sx={{
                          backgroundColor: colors['opacity-secondary32'],
                          height: '72px',
                          padding: '0px',
                          width: {
                            md: '50%',
                          },
                        }}
                      >
                        <ButtonContentWrap gap={16} wrap={'nowrap'}>
                          <ButtonIconWrap width='62px' content={'center'} align_items={'center'}>
                            <Icons.Uzum />
                          </ButtonIconWrap>
                          <Styles.Row direction={'column'} gap={4}>
                            <Styles.Row>
                              <Typography.h5 color='typography-body'>
                                {t('payment.main.method.first.number')}
                              </Typography.h5>
                            </Styles.Row>
                            <Styles.Row>
                              <Typography.paragraphSmall color='typography-muted'>
                                {t('payment.main.method.first.name')}
                              </Typography.paragraphSmall>
                            </Styles.Row>
                          </Styles.Row>
                        </ButtonContentWrap>
                      </Button>
                    </Styles.Row>
                  </Styles.Row>
                  {mock.Payment.method.map((item, index) => (
                    <Styles.Row gap={4} direction={'column'}>
                      <Styles.Row>
                        <Typography.paragraph color='typography-body'>
                          {t(item.label)}
                        </Typography.paragraph>
                      </Styles.Row>
                      <Styles.Row>
                        <Button
                          fullWidth
                          onClick={() =>
                            setSelectedButtonIndex(selectedButtonIndex === index ? null : index)
                          }
                          variant='outlined'
                          sx={{
                            backgroundColor: colors['opacity-secondary32'],
                            height: '72px',
                            width: {
                              md: '50%',
                            },
                            padding: '0px',
                          }}
                        >
                          <ButtonContentWrap gap={16} wrap={'nowrap'}>
                            <ButtonIconWrap width='62px' content={'center'} align_items={'center'}>
                              <item.icons />
                            </ButtonIconWrap>
                            <Styles.Row direction={'column'} gap={4}>
                              <Styles.Row>
                                <Typography.h5 color='typography-body'>
                                  {t(item.number)}
                                </Typography.h5>
                              </Styles.Row>
                              <Styles.Row>
                                <Typography.paragraphSmall color='typography-muted'>
                                  {t(item.name)}
                                </Typography.paragraphSmall>
                              </Styles.Row>
                            </Styles.Row>
                            <Styles.Row width='17px'>
                              {selectedButtonIndex === index && (
                                <ActiveIconWrap
                                  align_content={'center'}
                                  content={'center'}
                                  width='14px'
                                >
                                  <Icons.Check color={colors.white} />
                                </ActiveIconWrap>
                              )}
                            </Styles.Row>
                          </ButtonContentWrap>
                        </Button>
                      </Styles.Row>
                    </Styles.Row>
                  ))}
                </Styles.Row>
              </Styles.Row>
              <Styles.Row content={'flex-end'}>
                <Button
                  onClick={() => setModalStatus(!modalStatus)}
                  variant='contained'
                  sx={{
                    backgroundColor: colors['primary-500'],
                    textTransform: 'capitalize',
                  }}
                >
                  <Typography.h6 color='white'>
                    {t('payment.main.method.button.title')}
                  </Typography.h6>
                </Button>
              </Styles.Row>
            </PaymentHeaderWrap>
          </Common.Container>
        </Styles.Row>
        <Styles.Row>
          <Common.Container>
            {/* <SelectContainer>
              <Styles.Row gap={8} wrap={{ xs: 'wrap', sm: 'nowrap' }} content={'space-between'}>
                <Styles.Row align_items={'center'}>
                  <Typography.paragraph color='typography-body'>
                    {t('viewItem.header.title.theme')}:
                  </Typography.paragraph>
                </Styles.Row>
                <Styles.Row wrap={'nowrap'}>
                  <Styles.Row>
                    <Styles.Row gap={6} content={'flex-end'} wrap={'nowrap'}>
                      <StyledSelect
                        value={formData.resourceStatus}
                        // onChange={handleSelectChange}
                        displayEmpty
                        name='select3'
                        MenuProps={{
                          disableScrollLock: true,
                          sx: {
                            height: '250px',
                            width: '250px',
                          },
                        }}
                      >
                        <MenuItem value=''>Yunosobod</MenuItem>
                        <MenuItem value='option1'>Опция 1</MenuItem>
                        <MenuItem value='option2'>Опция 2</MenuItem>
                        <MenuItem value='option3'>Опция 3</MenuItem>
                      </StyledSelect>
                      <StyledSelect
                        value={formData.resourceState}
                        // onChange={handleSelectChange}
                        displayEmpty
                        name='select3'
                        MenuProps={{
                          disableScrollLock: true,
                          sx: {
                            height: '250px',
                            width: '250px',
                          },
                        }}
                      >
                        <MenuItem value=''>Yunosobod</MenuItem>
                        <MenuItem value='option1'>Опция 1</MenuItem>
                        <MenuItem value='option2'>Опция 2</MenuItem>
                        <MenuItem value='option3'>Опция 3</MenuItem>
                      </StyledSelect>
                    </Styles.Row>
                  </Styles.Row>
                </Styles.Row>
              </Styles.Row>
            </SelectContainer> */}
            <Styles.Row>
              <TableContentWrap>
                <StyledTableContainer>
                  <StyledTable aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>{t('payment.main.table.number')}</TableCell>
                        <TableCell align='center'>{t('payment.main.table.resource')}</TableCell>
                        <TableCell align='center'>{t('payment.main.table.type')}</TableCell>
                        <TableCell align='center'>{t('payment.main.table.downloaded')}</TableCell>
                        <TableCell align='center'>{t('payment.main.table.payed')}</TableCell>
                        <TableCell align='center'>{t('payment.main.table.status')}</TableCell>
                        <TableCell align='center'>{t('payment.main.table.necessary')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell align='center'>{row.id}</TableCell>
                          <TableCell align='center'>{row.resource}</TableCell>
                          <TableCell align='center'>{row.type}</TableCell>
                          <TableCell align='center'>{row.downloaded}</TableCell>
                          <TableCell align='center'>{row.paid}</TableCell>
                          <TableCell align='center'>{row.status}</TableCell>
                          <TableCell align='center'>
                            <Button
                            // onClick={() => {
                            //   formik.setValues({
                            //     ...formik.values,
                            //     imageFiles: [],
                            //   });
                            // }}
                            >
                              <Icons.Eye color={colors['typography-heading']} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </StyledTableContainer>
              </TableContentWrap>
            </Styles.Row>
          </Common.Container>
        </Styles.Row>
        {modalStatus && (
          <Common.Modal modalStatus={modalStatus} setModalStatus={setModalStatus}>
            <Styles.Row gap={16}>
              <Styles.Row content={'center'}>
                <Typography.h3 color='typography-body'>
                  {t('payment.main.modal.header')}
                </Typography.h3>
              </Styles.Row>
              <Styles.Row gap={16}>
                <Styles.Row gap={4}>
                  <Styles.Row>
                    <Typography.paragraph color='typography-body'>
                      {t('payment.main.method.first.header')}
                    </Typography.paragraph>
                  </Styles.Row>
                  <Styles.Row>
                    <Button
                      // onClick={() => handleButtonClick(index)}
                      variant='outlined'
                      sx={{
                        backgroundColor: colors['opacity-secondary32'],
                        height: '72px',
                        width: '100%',
                        padding: '0px',
                      }}
                    >
                      <ButtonContentWrap gap={16} wrap={'nowrap'}>
                        <ButtonIconWrap width='62px' content={'center'} align_items={'center'}>
                          <Icons.Uzum />
                        </ButtonIconWrap>
                        <Styles.Row direction={'column'} gap={4}>
                          <Styles.Row>
                            <Typography.h5 color='typography-body'>
                              {t('payment.main.method.first.number')}
                            </Typography.h5>
                          </Styles.Row>
                          <Styles.Row>
                            <Typography.paragraphSmall color='typography-muted'>
                              {t('payment.main.method.first.name')}
                            </Typography.paragraphSmall>
                          </Styles.Row>
                        </Styles.Row>
                      </ButtonContentWrap>
                    </Button>
                  </Styles.Row>
                </Styles.Row>
                {mock.Payment.method.map((item, index) => (
                  <Styles.Row gap={4}>
                    <Styles.Row>
                      <Typography.paragraph color='typography-body'>
                        {t(item.label)}
                      </Typography.paragraph>
                    </Styles.Row>
                    <Styles.Row>
                      <Button
                        onClick={() =>
                          setSelectedButtonIndex(selectedButtonIndex === index ? null : index)
                        }
                        variant='outlined'
                        sx={{
                          backgroundColor: colors['opacity-secondary32'],
                          height: '72px',
                          width: '100%',
                          padding: '0px',
                        }}
                      >
                        <ButtonContentWrap gap={16} wrap={'nowrap'}>
                          <ButtonIconWrap width='62px' content={'center'} align_items={'center'}>
                            <item.icons />
                          </ButtonIconWrap>
                          <Styles.Row direction={'column'} gap={4}>
                            <Styles.Row>
                              <Typography.h5 color='typography-body'>
                                {t(item.number)}
                              </Typography.h5>
                            </Styles.Row>
                            <Styles.Row>
                              <Typography.paragraphSmall color='typography-muted'>
                                {t(item.name)}
                              </Typography.paragraphSmall>
                            </Styles.Row>
                          </Styles.Row>
                        </ButtonContentWrap>
                      </Button>
                    </Styles.Row>
                  </Styles.Row>
                ))}
              </Styles.Row>
              <Styles.Row gap={4}>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <Typography.paragraphSmall>
                    {t('payment.main.modal.inputTitle')}
                  </Typography.paragraphSmall>
                </Styles.Row>
                <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                  <TextField
                    fullWidth
                    placeholder='1,000,000 sum'
                    name='input1'
                    value={formData.cost}
                    // onChange={handleChange}
                    InputProps={{
                      classes: {
                        root: 'MuiInputBase-root-MuiOutlinedInput-root',
                      },
                      style: {
                        alignItems: 'flex-start',
                      },
                    }}
                    sx={{
                      color: colors['secondary-500'],
                    }}
                  />
                </Styles.Row>
              </Styles.Row>
              <Styles.Row>
                <Button
                  onClick={() => setModalStatus(!modalStatus)}
                  variant='contained'
                  fullWidth
                  sx={{
                    backgroundColor: colors['primary-500'],
                    textTransform: 'capitalize',
                  }}
                >
                  <Typography.h6 color='white'>
                    {t('payment.main.modal.button')}
                  </Typography.h6>
                </Button>
              </Styles.Row>
            </Styles.Row>
          </Common.Modal>
        )}
      </Styles.Row>
    </PaymentWrap>
  );
};
