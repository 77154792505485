/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { FC } from 'react';
import {
  EmptyTitleWrapper,
  Line,
  PlanInfoWrapper,
  PlansViewWrap,
  StyledTable,
  StyledTableContainer,
  TableContentWrap,
} from './plans-resource-view.s';
import Styles, { GlobalStyles, Typography } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { Common } from '../../../components';
import { Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import colors from '../../../constants';
import Icons from '../../../assets/icons';
import { formatBytes } from '../../../helpers/formatBytes';
import { useGetTopicMediaByIdQuery } from '../../../store/services/mediaApi';
import { useAppTheme } from '../../../hooks';
import { handleDownloadClick } from '../../../helpers/fileDownload';

interface IPlansResourceViewProps {}

type SetIsDownloading = (id: number | null) => void;

export const PlansResourceView: FC<IPlansResourceViewProps> = (props) => {
  const [t, i18n] = useTranslation('global');
  const { theme } = useAppTheme();
  const [isDownloading, setIsDownloading] = useState<number | null>(null);
  const currentUrl = window.location.href;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const parts = currentUrl.split('/');
  const id = parts[parts.length - 1];
  const { data: mediaData, isLoading: mediaLoading } = useGetTopicMediaByIdQuery({
    id,
    lang: i18n.language,
  });

  const onDownloadClick = (id: number, name: string, setIsDownloading: SetIsDownloading) => {
    handleDownloadClick(
      id,
      name,
      i18n.language,
      setIsDownloading,
      setSnackbarMessage,
      setSnackbarOpen
    );
  };

  return (
    <PlansViewWrap>
      {mediaLoading ? (
        <Common.Container>
          <EmptyTitleWrapper content={'center'} align_items={'center'}>
            <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {t('plans.main.loadingTitle')}
            </Typography.h4>
          </EmptyTitleWrapper>
        </Common.Container>
      ) : (
        <Styles.Row gap={16}>
          <Styles.Row gap={4}>
            <Typography.h4 color='typography-muted'>{t('plans.main.title')} /</Typography.h4>
            <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {mediaData?.name}
            </Typography.h4>
          </Styles.Row>
          <Common.Container>
            <TableContentWrap>
              <StyledTableContainer>
                <StyledTable aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>
                        {t('resources.add.table.content.number')}
                      </TableCell>
                      <TableCell align='left'>
                        <GlobalStyles.TableHeaderTitle
                          align_items={'center'}
                          content={'space-between'}
                          wrap={'nowrap'}>
                          {t('resources.add.table.content.name')}
                        </GlobalStyles.TableHeaderTitle>
                      </TableCell>
                      <TableCell align='left'>
                        <GlobalStyles.TableHeaderTitle>
                          {t('resources.add.table.content.type')}
                        </GlobalStyles.TableHeaderTitle>
                      </TableCell>
                      <TableCell align='left'>
                        <GlobalStyles.TableHeaderTitle>
                          {t('resources.add.table.content.size')}
                        </GlobalStyles.TableHeaderTitle>
                      </TableCell>
                      <TableCell align='center'>
                        {t('resources.add.table.content.download')}
                      </TableCell>
                      {/* {mediaData?.is_author && (
                        <TableCell align='center'>
                          {t('resources.add.table.content.download')}
                        </TableCell>
                      )} */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell align='center'>{1}</TableCell>
                      <TableCell align='left'>{mediaData?.name}</TableCell>
                      <TableCell align='left'>1</TableCell>
                      <TableCell align='left'>{formatBytes(mediaData?.size)}</TableCell>
                      <TableCell align='center'>
                        <Button
                          onClick={() =>
                            onDownloadClick(mediaData.id, mediaData.name, setIsDownloading)
                          }>
                          <Icons.Download
                            color={
                              theme === 'dark' ? colors['grey-300'] : colors['typography-body']
                            }
                          />
                        </Button>
                      </TableCell>

                      {/* {mediaData?.is_author && (
                        <TableCell align='center'>
                          <Styles.Row content={'center'}>
                            {isDownloading !== mediaData.id ? (
                              <Button
                                onClick={() =>
                                  onDownloadClick(mediaData.id, mediaData.name, setIsDownloading)
                                }>
                                <Icons.Download
                                  color={
                                    theme === 'dark'
                                      ? colors['grey-300']
                                      : colors['typography-body']
                                  }
                                />
                              </Button>
                            ) : (
                              <Button disabled>
                                <Icons.Loader
                                  color={
                                    theme === 'dark'
                                      ? colors['grey-100']
                                      : colors['typography-muted']
                                  }
                                />
                              </Button>
                            )}
                          </Styles.Row>
                        </TableCell>
                      )} */}
                    </TableRow>
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
            </TableContentWrap>
          </Common.Container>
        </Styles.Row>
      )}
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </PlansViewWrap>
  );
};
