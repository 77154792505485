import styled from 'styled-components';

export const LeftContentWrap = styled.div`
  && {
    height: 100vh;
    border-radius: 20px;

    background-image: url('/images/auth-illustration/auth-left-bg.png');
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 992px) {
      display: none;
    }
  }
`;
