import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import styled from 'styled-components';

interface IBellProps extends IIconProps {
  notificationCount: number;
}

const NotificationBubble = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: red;
  color: white;
  display: flex;
  align-items: 'end';
  justify-content: center;
  font-size: 10px;
  font-weight: bold;

  @media (max-width: 576px) {
    align-items: flex-start;
  }
`;

export const Bell: FC<IBellProps> = ({
  className,
  width = 25,
  height = 24,
  color,
  notificationCount,
}) => {
  return (
    <div style={{ position: 'relative', display: 'flex' }}>
      <svg
        className={className}
        width={width}
        height={height}
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.5 5C10.5 3.89543 11.3954 3 12.5 3C13.6046 3 14.5 3.89543 14.5 5C16.8402 6.10655 18.3786 8.41425 18.5 11V14C18.6526 15.2608 19.3949 16.3742 20.5 17H4.5C5.60511 16.3742 6.34739 15.2608 6.5 14V11C6.62137 8.41425 8.15983 6.10655 10.5 5'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.5 5C10.5 3.89543 11.3954 3 12.5 3C13.6046 3 14.5 3.89543 14.5 5C16.8402 6.10655 18.3786 8.41425 18.5 11V14C18.6526 15.2608 19.3949 16.3742 20.5 17H4.5C5.60511 16.3742 6.34739 15.2608 6.5 14V11C6.62137 8.41425 8.15983 6.10655 10.5 5'
          stroke='white'
          strokeOpacity='0.1'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.5 17V18C9.5 19.6569 10.8431 21 12.5 21C14.1569 21 15.5 19.6569 15.5 18V17'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.5 17V18C9.5 19.6569 10.8431 21 12.5 21C14.1569 21 15.5 19.6569 15.5 18V17'
          stroke='white'
          strokeOpacity='0.1'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>

      {/* Кружок с числом оповещений */}
      {notificationCount > 0 && <NotificationBubble>{notificationCount}</NotificationBubble>}
    </div>
  );
};
