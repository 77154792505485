import { SCIENCE, SCIENCE_GROUP, SCIENCE_INFO, SCIENCE_TYPE } from '../URLs';
import { api } from './api';

export const scienceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllScience: builder.query<any, string>({
      query: (lang) => ({
        url: SCIENCE,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getAllScience'],
    }),
    getPaidScience: builder.query<any, string>({
      query: (lang) => ({
        url: SCIENCE,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getPaidScience'],
    }),
    getScienceByClass: builder.query<any, {id: number, lang: string}>({
      query: ({id, lang}) => ({
        url: `${SCIENCE}?class_group=${id}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getScienceByClass'],
    }),
    getAllScienceByClass: builder.query<any, {id: number, lang: string}>({
      query: ({id, lang}) => ({
        url: `${SCIENCE}?class_group=${id}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getAllScienceByClass'],
    }),
    getScienceInfo: builder.query<any, { id: number; lang: string }>({
      query: ({ id, lang }) => ({
        url: `${SCIENCE_INFO}${id}/`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getScienceInfo'],
    }),
    getScienceById: builder.query<any, { id: number; lang: string }>({
      query: ({ id, lang }) => ({
        url: `${SCIENCE}${id}/`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getScienceById'],
    }),
    getScienceGroup: builder.query<any, { id: number; lang: string }>({
      query: ({ id, lang }) => ({
        url: `${SCIENCE_GROUP}=${id}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getScienceGroup'],
    }),
    getScienceType: builder.query<any, { class_group: number; science: number; lang: string }>({
      query: ({ class_group, science, lang }) => ({
        url: `${SCIENCE_TYPE}science=${science}&class_group=${class_group}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getScienceType'],
    }),
    getAllScienceType: builder.query<any, { class_group: number; science: number; lang: string }>({
      query: ({ class_group, science, lang }) => ({
        url: `${SCIENCE_TYPE}science=${science}&class_group=${class_group}`,
        method: 'GET',
        headers: {
          'accept-language': lang,
        },
      }),
      providesTags: ['getAllScienceType'],
    }),
  }),
});

export const {
  useGetAllScienceQuery,
  useGetScienceInfoQuery,
  useGetScienceGroupQuery,
  useGetScienceByIdQuery,
  useGetScienceTypeQuery,
  useGetScienceByClassQuery,
  useGetPaidScienceQuery,
  useGetAllScienceByClassQuery,
  useGetAllScienceTypeQuery,
} = scienceApi;
