import { Button, Snackbar, TextField } from '@mui/material';
import styled from 'styled-components';
import colors from '../../../../constants';
import { motion } from 'framer-motion';
import Styles from '../../../../styles';

const wrapperStart = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.6, ease: 'circOut' } },
};

const wrapperTitle = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'circOut' } },
};

const wrapperCode = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'circOut' } },
};

const wrapperBtn = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1.2, ease: 'circOut' } },
};

const wrapperLink = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1.4, ease: 'circOut' } },
};

export const PasswordVerificationFormWrap = styled(motion.div).attrs(() => ({
  variants: wrapperStart,
  initial: 'hidden',
  animate: 'visible',
}))`
  width: 400px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleWrapper = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperTitle,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const CodeWrapper = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperCode,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const BtnWrapper = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperBtn,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const LinkWrapper = styled(motion(Styles.Row)).attrs(() => ({
  variants: wrapperLink,
  initial: 'hidden',
  animate: 'visible',
}))``;

export const StyledTextField = styled(TextField)`
  && {
    width: 91px;
    .MuiOutlinedInput-root {
      border-radius: 7px;
    }
    @media (max-width: 576px) {
      width: 79px;
    }
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 192px;
    padding: 6px 13px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const SecondaryButton = styled(Button)`
  && {
    width: 192px;
    background-color: ${colors['opacity-primary40']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;
