/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import Styles, { GlobalStyles, Typography } from '../../../../../../../../styles';
import Icons from '../../../../../../../../assets/icons';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  ContractFormWrap,
  FirstRows,
  IconWrapper,
  PrimaryButton,
  SecondaryButton,
  SecondRows,
  StepsWrap,
  ThirdRows,
} from './contract-form';
import mock from '../../../../../../../../mock';
import colors from '../../../../../../../../constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useModeratorRegisterMutation } from '../../../../../../../../store/services/authApi';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../../../../../../store/slices/authSlice';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

interface IContractFormProps {
  setPageState: (state: string) => void;
  pageState: string;
  mainFile: File[] | null;
  accountForm: boolean;
  ratingForm: boolean;
}

export const ContractForm: FC<IContractFormProps> = ({
  setPageState,
  mainFile,
  accountForm,
  ratingForm,
}) => {
  console.log(mainFile);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [t, i18n] = useTranslation('global');
  const [moderatorRegister, { isLoading }] = useModeratorRegisterMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const {
    first_name,
    last_name,
    region,
    district,
    institution,
    institution_number,
    science,
    science_types,
    phone,
    password,
    degree,
    school,
    class_group,
  } = useSelector(selectAuth);

  console.log(mainFile);
  const formik = useFormik({
    initialValues: {
      selectedOption: '',
    },
    validationSchema: Yup.object().shape({
      selectedOption: Yup.string().required('Iltimos, variantlardan birini tanlang'),
    }),
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        formData.append('phone', phone);
        formData.append('password', password);
        formData.append('school_type', school.toString());
        formData.append('region', region.toString());
        formData.append('class_group', class_group.toString());
        formData.append('district', district.toString());
        formData.append('institution', institution.toString());
        formData.append('institution_number', institution_number.toString());
        formData.append('science', science.toString());
        science_types.forEach((item: string, index: number) => {
          formData.append(`science_types[${index}]`, item);
        });
        formData.append('degree', degree);
        if (mainFile) {
          mainFile.forEach((file, index) => {
            formData.append(`docs[${index}]`, file);
            formData.append(`description[${index}]`, 'static');
          });
        }
        await moderatorRegister({ formData, lang: i18n.language })
          .unwrap()
          .then(() => {
            navigate('/author/moderator-verification');
          })
          .catch((error) => {
            console.log(error);
            console.log(error.data.phone);
            setSnackbarMessage(error.data.phone);
            setSnackbarOpen(true);
          });
      } catch (error: any) {
        console.log(error);
      }
    },
    validateOnChange: true,
  });

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (accountForm && ratingForm) {
      formik.setFieldValue('selectedOption', value);
      setIsSubmitEnabled(value === '0');
    } else {
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (isLoading) {
      setIsSubmitEnabled(!isLoading);
    }
  }, [isLoading]);

  const animationVariants = {
    hidden: { opacity: 0, x: -30 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <>
      <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
        <ContractFormWrap>
          <Styles.Row gap={26}>
            <StepsWrap>
              <Styles.Row gap={24} wrap={'nowrap'}>
                {mock.Steps.registration.map((item, index) => (
                  <motion.div
                    key={index}
                    initial='hidden'
                    animate='visible'
                    variants={animationVariants}
                    transition={{ duration: 0.5 * index }}>
                    <Styles.Row key={index} wrap={'nowrap'}>
                      <Styles.Row gap={16} wrap={'nowrap'}>
                        <IconWrapper
                          onClick={() => setPageState(item.navigate)}
                          index={index}
                          content={'center'}
                          align_items={'center'}>
                          <item.icons
                            color={index === 2 ? colors.white : colors['typography-body']}
                          />
                        </IconWrapper>
                        <Styles.Row direction={'column'} width='123px'>
                          <Typography.h6 color='typography-body'>{t(item.label)}</Typography.h6>
                          <Typography.paragraphSmall color='typography-muted'>
                            {t(item.title)}
                          </Typography.paragraphSmall>
                        </Styles.Row>
                      </Styles.Row>
                      {item.chevron && (
                        <Styles.Row align_items={'center'}>
                          <Icons.ChevronRight />
                        </Styles.Row>
                      )}
                    </Styles.Row>
                  </motion.div>
                ))}
              </Styles.Row>
            </StepsWrap>
            <Styles.Row gap={26}>
              <FirstRows gap={6} direction={'column'}>
                <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                  <Typography.h3 color='typography-body'>
                    {t('auth.authorReg.contract.title')}
                  </Typography.h3>
                </Styles.Row>
                <Styles.Row
                  gap={4}
                  content={{ xs: 'center', md: 'flex-start' }}
                  align_items={'center'}>
                  <Typography.paragraph color='typography-muted'>
                    {t('auth.authorReg.contract.subTitle')}
                  </Typography.paragraph>
                  <Link to={`/terms?returnTo=${encodeURIComponent(location.pathname)}`}>
                    <Typography.paragraph
                      color='primary-500'
                      style={{ textDecoration: 'underline' }}>
                      {t('auth.authorReg.contract.select.link')}
                    </Typography.paragraph>
                  </Link>
                </Styles.Row>
              </FirstRows>
              <SecondRows gap={20}>
                <Styles.Row direction={'column'}>
                  <Styles.Row
                    align_items={'center'}
                    gap={6}
                    content={{ xs: 'center', md: 'flex-start' }}>
                    <Typography.paragraphSmallSemiBold style={{ display: 'contents' }}>
                      {t('auth.authorReg.contract.select.title')}
                    </Typography.paragraphSmallSemiBold>
                  </Styles.Row>
                  <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      value={formik.values.selectedOption}
                      onChange={handleRadioChange}
                      name='selectedOption'>
                      <FormControlLabel
                        value='0'
                        control={<Radio />}
                        label={
                          <Typography.paragraph color='typography-muted'>
                            {t('auth.authorReg.contract.select.yes')}
                          </Typography.paragraph>
                        }
                      />
                      <FormControlLabel
                        value='1'
                        control={<Radio />}
                        label={
                          <Typography.paragraph color='typography-muted'>
                            {t('auth.authorReg.contract.select.no')}
                          </Typography.paragraph>
                        }
                      />
                    </RadioGroup>
                  </Styles.Row>
                  {formik.touched.selectedOption && formik.errors.selectedOption && (
                    <Typography.paragraph color='danger-500'>
                      {formik.errors.selectedOption}
                    </Typography.paragraph>
                  )}
                </Styles.Row>
              </SecondRows>
              <ThirdRows wrap={'nowrap'} gap={16} content={{ xs: 'center', md: 'space-between' }}>
                <SecondaryButton variant='text' onClick={() => setPageState('rating')}>
                  <Icons.ArrowLeft color={colors['primary-500']} />
                  <Typography.paragraph color='primary-500'>
                    {t('auth.authorReg.contract.cancelBtn')}
                  </Typography.paragraph>
                </SecondaryButton>
                <PrimaryButton type='submit' variant='contained' disabled={!isSubmitEnabled}>
                  {isLoading ? (
                    <Icons.Loader />
                  ) : (
                    <>
                      <Typography.paragraph color='white'>
                        {t('auth.authorReg.contract.nextBtn')}
                      </Typography.paragraph>
                      <Icons.ArrowRight />
                    </>
                  )}
                </PrimaryButton>
              </ThirdRows>
              <ThirdRows gap={6} content={{ xs: 'center', md: 'flex-start' }}>
                <Typography.paragraphMedium color='typography-muted'>
                  {t('auth.authorReg.contract.ratingTitle')}
                </Typography.paragraphMedium>
                <Link to={'/login'}>
                  <Typography.paragraphMedium
                    color='primary-500'
                    style={{ textDecoration: 'underline' }}>
                    {t('auth.authorReg.contract.ratingLink')}
                  </Typography.paragraphMedium>
                </Link>
              </ThirdRows>
            </Styles.Row>
          </Styles.Row>
        </ContractFormWrap>
      </form>
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={
          accountForm && ratingForm
            ? snackbarMessage
            : t(
                !accountForm
                  ? 'auth.userReg.errors.accountState'
                  : !ratingForm
                  ? 'auth.userReg.errors.ratingState'
                  : "Xatolik yuz berdi, takroran urinib ko'ring"
              )
        }
      />
    </>
  );
};
