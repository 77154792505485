import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const Eye: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color = colors.white,
  ...props
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle
        cx='10.4999'
        cy='10.5'
        r='1.66667'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='10.4999'
        cy='10.5'
        r='1.66667'
        stroke={props.stroke || color}
        stroke-opacity='0.5'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M18.8334 10.5C16.6109 14.3892 13.8334 16.3334 10.5001 16.3334C7.16675 16.3334 4.38925 14.3892 2.16675 10.5C4.38925 6.61085 7.16675 4.66669 10.5001 4.66669C13.8334 4.66669 16.6109 6.61085 18.8334 10.5'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M18.8334 10.5C16.6109 14.3892 13.8334 16.3334 10.5001 16.3334C7.16675 16.3334 4.38925 14.3892 2.16675 10.5C4.38925 6.61085 7.16675 4.66669 10.5001 4.66669C13.8334 4.66669 16.6109 6.61085 18.8334 10.5'
        stroke={props.stroke || color}
        stroke-opacity='0.5'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
