import styled from 'styled-components';
import Styles from '../../../styles';
import { Button, MenuItem, Select, Table, TableContainer, TextField } from '@mui/material';
import colors from '../../../constants';

export const NewResourcesWrap = styled.div`
  width: 100%;
`;

export const Tatt = styled.div`
  overflow-x: auto;
`;

export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 250px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
    @media (max-width: 1000px) {
      width: 192px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
`;

export const StyledMenu = styled(MenuItem)`
  && {
    color: ${colors['grey-500']} !important;
    font-size: 15px;
    &.Mui-selected {
      color: ${colors['white']} !important;
    }
    &:focus {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important;
    }
    &:hover {
      background-color: ${colors['primary-500']} !important;
      color: ${colors['white']} !important;
    }
  }
`;

export const EmptyTitleWrapper = styled(Styles.Row)`
  && {
    height: 50vh;
  }
`;

export const StyledBigTextField = styled(TextField)`
  && {
    color: ${colors['secondary-500']};
    input {
      height: 84px;
    }
    .MuiOutlinedInput-root {
      height: 84px;
      align-items: flex-end;
    }
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 250px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
    @media (max-width: 1000px) {
      width: 192px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
`;

export const PrimaryButton = styled(Button)`
  && {
    width: 192px;
    gap: 8px;
    background-color: ${colors['primary-500']};
    @media (max-width: 768px) {
      width: 150px;
    }
  }
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
    width: 100%;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${colors['grey-300']};
    max-width: 100%;
    overflow-x: auto;
  }
`;
