import { useState, useEffect } from 'react';

export function useAppTheme() {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  
    useEffect(() => {
      const handleStorageChange = () => {
        setTheme(localStorage.getItem('theme') || 'light');
      };
  
      window.addEventListener('storage', handleStorageChange);
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }, []);
  
    return { theme, setTheme };
  }