import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { AUTH_TOKEN_REFRESH, BASE_URL } from '../URLs';
import { RootState } from '../store';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import i18n from 'i18next';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const state = getState() as RootState;
    const language = i18n.language;
    let token = state.auth.token;
    headers.set('accept-language', language);

    if (!token) {
      token = localStorage.getItem('accessToken');
    }
    const noAuthEndpoints = [
      'getAllScience',
      'getAllClassGroup',
      'getAllScienceByClass',
      'getAllScienceType',
    ];

    if (!noAuthEndpoints.includes(endpoint) && token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    // send refresh token to get new access token
    const refreshToken = localStorage.getItem('refreshToken');
    const refreshResult: QueryReturnValue<any> = await baseQuery(
      {
        url: AUTH_TOKEN_REFRESH,
        method: 'POST',
        body: { refresh: refreshToken },
      },
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      const newAccessToken = refreshResult.data.access;
      if (localStorage.getItem('accessToken') === null) {
        localStorage.setItem('waiting_accessToken', newAccessToken);
      } else {
        localStorage.setItem('accessToken', newAccessToken);
      }

      // retry original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      // handle refresh token failure
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    }
  }

  return result;
};

export const api = createApi({
  reducerPath: 'classcomAPI',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'auth',
    'cart',
    'login',
    'getMe',
    'register',
    'class',
    'daysOff',
    'downloads',
    'media',
    'moderator',
    'resetPasswrod',
    'confirmPhone',
    'resendPassword',
    'resetSetPasswrod',
    'moderatorRegister',
    'userRegister',
    'getRegion',
    'getDistrict',
    'authConfirm',
    'getResources',
    'getPlans',
    'getAllTopic',
    'getAllScience',
    'getAllClass',
    'getAllQuarters',
    'Schedule',
    'getResourceTypes',
    'quaters',
    'getQuestions',
    'addQuestion',
    'authUpdateMe',
    'getRelatedPlanById',
    'getTopicByParams',
    'getTopicCalculationByParams',
    'getMediaById',
    'getPlansByQuarter',
    'getPlansByGrade',
    'getPlansByScience',
    'getScienceInfo',
    'getScienceGroup',
    'search',
    'changeRole',
    'getScienceById',
    'getOrders',
    'deleteOrder',
    'addOrder',
    'getResource',
    'paymentCreate',
    'uploadHistory',
    'getSchoolType',
    'getModeratorResources',
    'getWeeks',
    'getClassGroup',
    'getScienceType',
    'getScienceByClass',
    'getClassType',
    'connectSchedule',
    'getAllSchedules',
    'getScheduleById',
    'getModeratorLang',
    'getModeratorQuarter',
    'getModeratorScienceType',
    'getModeratorScience',
    'getModeratorClass',
    'getModeratorClassGrp',
    'getTopicById',
    'getAllNotification',
    'addResourceToTopic',
    'getPaidScience',
    'getAllClassGroup',
    'getAllScienceByClass',
    'getAllScienceType',
  ],
  endpoints: () => ({}),
});

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({}),
});
