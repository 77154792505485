import { CubeSphere3d } from './3d-cube-sphere';
import { Arrow } from './arrow';
import { ArrowLeft } from './arrow-left';
import { ArrowRight } from './arrow-right';
import { Bell } from './bell';
import { Book2 } from './book-2';
import { Brush } from './brush';
import { Calendar } from './calendar';
import { ChartBat } from './chart-bar';
import { Check } from './check';
import { ChevronLeft } from './chevron-left';
import { ChevronRight } from './chevron-right';
import { CircleDot } from './circle-dot';
import { CurrencyDolar } from './currency-dollar';
import { Lightbulb, User } from './custom';
import { Eye } from './eye';
import { EyeOff } from './eye-off';
import { FileText } from './file-text';
import { Help } from './help';
import { History } from './history';
import { Lock } from './lock';
import { Logout } from './logout';
import { Menu2 } from './menu-2';
import { MoonStars } from './moon-stars';
import { Pencil } from './pencil';
import { PhoneCall } from './phone-call';
import { Plus } from './plus';
import { Puzzle } from './puzzle';
import { Search } from './search';
import { Separator } from './separator';
import { Settings } from './settings';
import { SmartHome } from './smart-home';
import { Stars } from './stars';
import { Sun } from './sun';
import { Trash } from './trash';
import { Upload } from './upload';
import { Users } from './users';
import { Uzcard } from './uzcard';
import { Uzum } from './uzum';
import { X } from './x';
import { Add } from './add';
import { Edit } from './edit';
import { Graduate } from './graduate';
import { StarFly } from './star-fly';
import { Time } from './time';
import { Function } from './function';
import { Profile } from './profile';
import { Update } from './update';
import { Download } from './download';
import { EmptyUser } from './empty-user';
import { RotateClockwise } from './rotate-clockwise';
import { School } from './school';
import { MathFunction } from './math-function';
import { Comet } from './comet';
import { Clock } from './clock';
import { ChevronDown } from './chevron-down';
import { LockOpen } from './lock-open';
import { Logo } from './logo';
import { ShoppingCart } from './shopping-cart';
import { Link } from './link';
import { StairsUp } from './stairs-up';
import { Loader } from './loader';
import { Point } from './point';

/* ---------------- global icons ---------------- */


const Icons = {
  Download,
  Time,
  Update,
  Profile,
  Function,
  StarFly,
  Graduate,
  Plus,
  Edit,
  Add,
  CubeSphere3d,
  Lock,
  ChevronLeft,
  Eye,
  EyeOff,
  Stars,
  SmartHome,
  FileText,
  ChevronRight,
  ArrowLeft,
  ArrowRight,
  Upload,
  CircleDot,
  Calendar,
  Book2,
  Puzzle,
  Separator,
  CurrencyDolar,
  Help,
  History,
  Logout,
  PhoneCall,
  Settings,
  Sun,
  MoonStars,
  Bell,
  Menu2,
  Search,
  X,
  Brush,
  Users,
  ChartBat,
  Uzcard,
  Uzum,
  Check,
  Arrow,
  Pencil,
  Trash,
  EmptyUser,
  RotateClockwise,
  School, 
  MathFunction, 
  Comet, 
  Clock,
  ChevronDown,
  LockOpen,
  Logo,
  ShoppingCart,
  Link,
  StairsUp,
  Loader,
  Point,
};

// 3d-cube-sphere => cubeSphere3d
// user => EmptyUser
export default Icons;

/* ---------------- custom icons ---------------- */

export const Custom = {
  User,
  Lightbulb,
};
