import { FC, ReactNode } from 'react';
import { LeftContentWrap } from './left-content-moderator.s';

interface ILeftContentModeratorProps {
  children: ReactNode;
}

export const LeftContentModerator: FC<ILeftContentModeratorProps> = ({ children }) => {
  return <LeftContentWrap>{children}</LeftContentWrap>;
};
