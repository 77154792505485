/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect } from 'react';
import { PageNotFoundWrap } from './page-not-found.s';
import { Typography } from '../../styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IPageNotFoundProps {}

export const PageNotFound: FC<IPageNotFoundProps> = ({}) => {
  const [t] = useTranslation('global');
  const navigate = useNavigate();

  useEffect(() => {
    if(localStorage.getItem('accessToken')) {
      navigate('/main')
    }
  }, []);

  return (
    <PageNotFoundWrap>
      <Typography.display2 color='typography-body'>{t('plans.main.loadingTitle')}</Typography.display2>
    </PageNotFoundWrap>
  );
};
