import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const ChevronDown: FC<IIconProps> = ({ className, width = 25, height = 24, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d="M6 9L12 15L18 9" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 9L12 15L18 9" stroke={color} stroke-opacity="0.1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};
