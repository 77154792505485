import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const ShoppingCart: FC<IIconProps> = ({ className, width = 25, height = 24, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle
        cx='6.5'
        cy='19'
        r='2'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='6.5'
        cy='19'
        r='2'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='17.5'
        cy='19'
        r='2'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <circle
        cx='17.5'
        cy='19'
        r='2'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.5 17H6.5V3H4.5'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.5 17H6.5V3H4.5'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.5 5L20.5 6L19.5 13H6.5'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.5 5L20.5 6L19.5 13H6.5'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
