/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import { SelectChangeEvent } from '@mui/material';
import Styles, { Typography } from '../../../../../../styles';
import Icons from '../../../../../../assets/icons';
import colors from '../../../../../../constants';
import { ArrowBtn, BtnWrapper, NavBtn, StyledTextField, TitleBtn } from './header.s';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

interface IHeader {
  theme: string;
  sampleName: string;
  setSampleName: (sampleName: string) => void;
  deleteState: boolean;
  setDeleteState: (state: boolean) => void;
  setScheduleFormat: (newFormat: string) => void;
  setScheduleDay: (newDay: any | null) => void;
  onSave: (sampleName: string) => void;
}

export const Header: FC<IHeader> = ({
  setScheduleFormat,
  sampleName,
  setSampleName,
  setScheduleDay,
  theme,
  onSave,
  setDeleteState,
  deleteState,
}) => {
  const [t] = useTranslation('global');

  const navigate = useNavigate();

  const handleScheduleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string;
    setScheduleFormat(selectedValue);
  };

  return (
    <Styles.Row direction={'column'} gap={16}>
      <Styles.Row align_items={'center'} content={'space-between'} gap={16}>
        <ArrowBtn onClick={() => navigate('/main')}>
          <Icons.ArrowLeft color='#fff' />
        </ArrowBtn>
        <Styles.Row width='auto' direction={'column'} align_items={'center'}>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('mainPage.sample.title')}
          </Typography.h4>
        </Styles.Row>
        {deleteState ? (
          <TitleBtn
            br={false}
            theme={theme}
            color='extra-white'
            bg={colors['primary-500']}
            onClick={() => setDeleteState(!deleteState)}>
            {t('mainPage.sample.editSave')}
            <Icons.Download color='#fff' />
          </TitleBtn>
        ) : (
          <TitleBtn
            br={false}
            theme={theme}
            color='extra-white'
            bg={colors['primary-500']}
            onClick={() => setDeleteState(!deleteState)}>
            {t('mainPage.sample.edit')}
            <Icons.Settings color='#fff' />
          </TitleBtn>
        )}
      </Styles.Row>

      <Styles.Row content={'space-between'} gap={{ xs: 16 }} wrap={{ xs: 'wrap', sm: 'nowrap' }}>
        <BtnWrapper align_items={'center'} gap={16}>
          {/* Quater Select */}
          <StyledTextField
            placeholder={t('mainPage.sample.placeholder')}
            name='sample'
            value={sampleName}
            onChange={(e) => setSampleName(e.target.value)}
          />
        </BtnWrapper>

        {/* Buttons */}
        <BtnWrapper
          content={{ xs: 'space-between', sm: 'flex-end' }}
          align_items={'center'}
          gap={16}>
          <NavBtn
            br={false}
            theme={theme}
            color='extra-white'
            bg={colors['primary-500']}
            onClick={() => navigate('/sample-connect')}>
            {t('mainPage.sample.connect')}
          </NavBtn>

          <NavBtn
            theme={theme}
            color={sampleName !== '' ? 'extra-white' : 'typography-muted'}
            disabled={sampleName === ''}
            br={sampleName !== '' ? false : true}
            bg={sampleName !== '' ? colors['primary-500'] : 'transparent'}
            onClick={() => onSave(sampleName)}>
            {t('mainPage.sample.save')}
          </NavBtn>
        </BtnWrapper>
      </Styles.Row>
    </Styles.Row>
  );
};
