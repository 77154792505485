/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import Styles, { GlobalStyles, Typography } from '../../../../styles';
import { Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Icons from '../../../../assets/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import colors from '../../../../constants';
import { fullNameGenerator } from '../../../../helpers/fullNameGenerator';
import { Common } from '../../../../components';
import { formatBytes } from '../../../../helpers/formatBytes';
import { useGetResourceQuery } from '../../../../store/services/resourcesApi';
import {
  EmptyTitleWrapper,
  Line,
  ResourceBannerWrapper,
  ResourceInfoWrapper,
  ResourceViewWrapper,
  StyledBox,
  StyledH4,
  StyledParagraph,
  StyledTable,
  StyledTableContainer,
  TableContentWrap,
} from './resource-view.s';
import { useAppTheme } from '../../../../hooks';
import { handleDownloadClick } from '../../../../helpers/fileDownload';

type SetIsDownloading = (id: number | null) => void;

export const ResourcesView = () => {
  const { theme } = useAppTheme();
  const [t, i18n] = useTranslation('global');
  const { id } = useParams();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isDownloading, setIsDownloading] = useState<number | null>(null);
  const { data: resourceData, isLoading } = useGetResourceQuery({
    id: id || '',
    lang: i18n.language,
  });

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${month}.${day}.${year}`;
  };

  const onDownloadClick = (id: number, name: string, setIsDownloading: SetIsDownloading) => {
    handleDownloadClick(id, name, i18n.language, setIsDownloading, setSnackbarMessage, setSnackbarOpen);
  };

  return (
    <ResourceViewWrapper>
      {isLoading ? (
        <Common.Container>
          <EmptyTitleWrapper content={'center'} align_items={'center'}>
            <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {t('plans.main.loadingTitle')}
            </Typography.h4>
          </EmptyTitleWrapper>
        </Common.Container>
      ) : (
        <Styles.Row gap={16}>
          <Styles.Row gap={4}>
            <Typography.h4 color={theme === 'dark' ? 'grey-400' : 'typography-muted'}>
              {t('resources.header.resources')} /
            </Typography.h4>
            <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {resourceData?.user.first_name.length > 30
                ? `${resourceData?.user.first_name.slice(0, 30)}...`
                : resourceData?.user.first_name}
            </Typography.h4>
          </Styles.Row>
          <Common.Container>
            <ResourceInfoWrapper wrap={{ xs: 'wrap', sm: 'nowrap' }} gap={16}>
              <ResourceBannerWrapper>
                <StyledBox selectedImage={resourceData?.banner} />
              </ResourceBannerWrapper>
              <Styles.Row direction={'column'} gap={16}>
                <StyledH4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {resourceData?.name}
                </StyledH4>
                <StyledParagraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {resourceData?.description}
                </StyledParagraph>
                <Line></Line>
                <Styles.Row wrap={'nowrap'} gap={8} align_items={'center'}>
                  <Icons.EmptyUser
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('resources.icons.author')}:
                  </Typography.paragraph>
                  <Typography.paragraphBold
                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {fullNameGenerator(resourceData?.user.first_name, resourceData?.user.last_name)}
                  </Typography.paragraphBold>
                </Styles.Row>
                <Styles.Row gap={8} align_items={'center'}>
                  <Icons.RotateClockwise
                    color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                  />
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('resources.icons.updated')}:
                  </Typography.paragraph>
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {formatDate(resourceData?.media[0]?.updated_at)}
                  </Typography.paragraph>
                </Styles.Row>
              </Styles.Row>
            </ResourceInfoWrapper>
          </Common.Container>
          <Common.Container>
            <TableContentWrap>
              <StyledTableContainer>
                <StyledTable aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>
                        {t('resources.add.table.content.number')}
                      </TableCell>
                      <TableCell align='left'>
                        <GlobalStyles.TableHeaderTitle
                          align_items={'center'}
                          content={'space-between'}
                          wrap={'nowrap'}>
                          {t('resources.add.table.content.name')}
                        </GlobalStyles.TableHeaderTitle>
                      </TableCell>
                      <TableCell align='left'>
                        <GlobalStyles.TableHeaderTitle>
                          {t('resources.add.table.content.type')}
                        </GlobalStyles.TableHeaderTitle>
                      </TableCell>
                      <TableCell align='left'>
                        <GlobalStyles.TableHeaderTitle>
                          {t('resources.add.table.content.size')}
                        </GlobalStyles.TableHeaderTitle>
                      </TableCell>
                      <TableCell align='center'>
                        {t('resources.add.table.content.download')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resourceData?.media.map((item: any, index: number) => (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}>
                        <TableCell align='center'>1</TableCell>
                        <TableCell align='left'>{item.name}</TableCell>
                        <TableCell align='left'>{item.name}</TableCell>
                        <TableCell align='left'>{formatBytes(item.size)}</TableCell>
                        <TableCell align='center'>
                          {isDownloading !== item.id ? (
                            <Button
                              onClick={() =>
                                onDownloadClick(item?.id, item.name, setIsDownloading)
                              }>
                              <Icons.Download
                                color={
                                  theme === 'dark' ? colors['grey-300'] : colors['typography-body']
                                }
                              />
                            </Button>
                          ) : (
                            <Button disabled>
                              <Icons.Loader
                                color={
                                  theme === 'dark' ? colors['grey-100'] : colors['typography-muted']
                                }
                              />
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
            </TableContentWrap>
          </Common.Container>
        </Styles.Row>
      )}
      <GlobalStyles.Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </ResourceViewWrapper>
  );
};
