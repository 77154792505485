/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import { LogoutWrap } from './logout.s';
import CommonList from '../../../components/common';
import Styles, { Typography } from '../../../styles';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setLogout } from '../../../store/slices/authSlice';
import { removeToken } from '../../../helpers/token';
import { useAppTheme } from '../../../hooks';

interface ILogoutProps {
  modalStatus: boolean;
  setModalStatus: (value: boolean) => void;
}

export const Logout: FC<ILogoutProps> = ({ modalStatus, setModalStatus }) => {
  const [t] = useTranslation('global');
  const { theme } = useAppTheme();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setLogout());
    removeToken();
  };

  return (
    <LogoutWrap>
      <CommonList.Modal modalStatus={modalStatus} setModalStatus={setModalStatus}>
        <Styles.Row gap={26}>
          <Styles.Row gap={6}>
            <Styles.Column size={12} difference={0}>
              <Typography.h3 color={ theme === 'dark' ? 'grey-200' : 'typography-body'}>{t('logout.header')}</Typography.h3>
            </Styles.Column>
            <Styles.Row gap={6}>
              <Typography.paragraph color={ theme === 'dark' ? 'grey-200' : 'typography-body'}>
                {t('logout.title')}
              </Typography.paragraph>
            </Styles.Row>
          </Styles.Row>
          <Styles.Row gap={10} wrap={'nowrap'} content={'space-between'}>
            <Button
              variant='text'
              onClick={() => setModalStatus(!modalStatus)}
              sx={{
                width: '192px',
                padding: '10px',
                backgroundColor: '#3B82F629',
                textTransform: 'capitalize',
                '@media (max-width: 768px)': {
                  width: '150px',
                },
              }}
            >
              <Typography.paragraphSmallSemiBold color='primary-500'>
                {t('logout.cancel')}
              </Typography.paragraphSmallSemiBold>
            </Button>
            <Button
              onClick={handleLogout}
              variant='contained'
              color='error'
              sx={{
                width: '192px',
                padding: '10px',
                textTransform: 'capitalize',
                '@media (max-width: 768px)': {
                  width: '150px',
                },
              }}
            >
              <Typography.paragraphSmallSemiBold color='white'>
                {t('logout.exit')}
              </Typography.paragraphSmallSemiBold>
            </Button>
          </Styles.Row>
        </Styles.Row>
      </CommonList.Modal>
    </LogoutWrap>
  );
};
