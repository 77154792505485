/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import { Bar, FormWrap, ModeratorFormWrap, UserButtons, UserLink } from './moderator-form.s';
import Styles, { Typography } from '../../../../../styles';
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Icons from '../../../../../assets/icons';
import colors from '../../../../../constants';
import { useChangeRoleMutation } from '../../../../../store/services/changeRole';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Common } from '../../../../../components';
import { useSelector } from 'react-redux';
import {
  useGetAllScienceQuery,
  useGetScienceByIdQuery,
} from '../../../../../store/services/scienceApi';

interface IModeratorFormProps {
  setFormStatus: (status: string) => void;
  theme: string;
}

const validationSchema = Yup.object().shape({
  degree: Yup.string().required('auth.userReg.errors.required'),
  docs: Yup.array().min(1, 'auth.authorReg.rating.errors.file'),
  // subject: Yup.number().required('auth.userReg.errors.required'),
  // classes: Yup.array()
  //   .min(1, 'auth.userReg.errors.required')
  //   .required('auth.userReg.errors.required'),
});

export const ModeratorForm: FC<IModeratorFormProps> = ({ theme }) => {
  const [t, i18n] = useTranslation('global');
  const [file, setFile] = useState<File[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [changeRole, { isLoading }] = useChangeRoleMutation();
  const [contract, setContract] = useState('1');
  const userState = useSelector((state: any) => state.auth);
  const location = useLocation();
  const { data: scienceData } = useGetAllScienceQuery(i18n.language);
  const [scienceInfo, setScienceInfo] = useState(Number(localStorage.getItem('science')) || 2);
  const { data: scienceByIdData, refetch: scienceByIdRefetch } = useGetScienceByIdQuery({
    id: scienceInfo,
    lang: i18n.language,
  });

  const formik = useFormik({
    initialValues: {
      degree: '',
      docs: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append('user_id', userState.id);
        formData.append('degree', values.degree);
        values.docs.forEach((file, index) => {
          formData.append(`docs[${index}]`, file);
          formData.append(`description[${index}]`, 'static');
        });

        await changeRole(formData).unwrap();
        setSnackbarMessage(t('auth.authorReg.waiting.title'));
        setSnackbarOpen(true);
      } catch (error: any) {
        if (error.data.detail === 'Siz ariza topshirgansiz') {
          setSnackbarMessage(t('auth.authorReg.waiting.title'));
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage(t('mainPage.snackbar.sampleErr'));
          setSnackbarOpen(true);
        }
      }
    },
    validateOnChange: true,
  });

  useEffect(() => {
    scienceByIdRefetch();
  }, [scienceInfo]);

  return (
    <ModeratorFormWrap onSubmit={formik.handleSubmit}>
      <Styles.Row>
        <FormWrap gap={20}>
          <Styles.Row gap={6} direction={'column'}>
            <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
              <Typography.h3 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('settings.moderator.title.header')}
              </Typography.h3>
            </Styles.Row>
            <Styles.Row gap={6} content={{ xs: 'center', md: 'flex-start' }}>
              <Typography.paragraph color='typography-muted'>
                {t('settings.moderator.title.titleMain')}
              </Typography.paragraph>
              <UserLink to={`/terms?returnTo=${encodeURIComponent(location.pathname)}`}>
                <Typography.paragraph color='primary-500'>
                  {t('settings.moderator.title.link')}
                </Typography.paragraph>
              </UserLink>
              <Typography.paragraph color='typography-muted'>
                {t('settings.moderator.title.titleSecondary')}
              </Typography.paragraph>
            </Styles.Row>
          </Styles.Row>
          <Styles.Row gap={20}>
            <Styles.Row direction={'column'}>
              <Styles.Row
                align_items={'center'}
                gap={6}
                content={{ xs: 'center', md: 'flex-start' }}>
                <Typography.paragraphSmallSemiBold
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('settings.moderator.radioGroup.first.titleMain')}
                </Typography.paragraphSmallSemiBold>
                <UserLink to={`/terms?returnTo=${encodeURIComponent(location.pathname)}`}>
                  <Typography.paragraphSmallSemiBold color='primary-500'>
                    {t('settings.moderator.radioGroup.first.link')}
                  </Typography.paragraphSmallSemiBold>
                </UserLink>
                <Typography.paragraphSmallSemiBold
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('settings.moderator.radioGroup.first.titleSecondary')}
                </Typography.paragraphSmallSemiBold>
              </Styles.Row>
              <Styles.Row>
                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  defaultValue='female'
                  value={contract}
                  name='contract'>
                  <FormControlLabel
                    value='0'
                    onClick={() => setContract('0')}
                    control={<Radio />}
                    label={
                      <Typography.placeholderDefault color='typography-muted'>
                        {t('settings.moderator.radioGroupItems.first.yes')}
                      </Typography.placeholderDefault>
                    }
                  />
                  <FormControlLabel
                    onClick={() => setContract('1')}
                    value='1'
                    control={<Radio />}
                    label={
                      <Typography.placeholderDefault color='typography-muted'>
                        {t('settings.moderator.radioGroupItems.first.no')}
                      </Typography.placeholderDefault>
                    }
                  />
                  {formik.touched.degree && formik.errors.degree && (
                    <Typography.paragraph color='danger-500'>
                      {t(formik.errors.degree)}
                    </Typography.paragraph>
                  )}
                </RadioGroup>
              </Styles.Row>
            </Styles.Row>
          </Styles.Row>
          <Styles.Row gap={20}>
            <Styles.Row direction={'column'}>
              <Styles.Row content={{ xs: 'center', md: 'flex-start' }}>
                <Typography.paragraphSmallSemiBold
                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}
                  style={{ display: 'contents' }}>
                  {t('settings.moderator.radioGroup.second.title')}{' '}
                  <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
                </Typography.paragraphSmallSemiBold>
              </Styles.Row>
              <Styles.Row>
                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  defaultValue='female'
                  value={formik.values.degree}
                  onChange={(e) => formik.setFieldValue('degree', e.target.value)}
                  name='degree'>
                  <FormControlLabel
                    value='HIGHER'
                    onClick={() => console.log('worked')}
                    control={<Radio />}
                    label={
                      <Typography.placeholderDefault color='typography-muted'>
                        {t('settings.moderator.radioGroupItems.second.high')}
                      </Typography.placeholderDefault>
                    }
                  />
                  <FormControlLabel
                    value='EXCELLENT'
                    control={<Radio />}
                    label={
                      <Typography.placeholderDefault color='typography-muted'>
                        {t('settings.moderator.radioGroupItems.second.people')}
                      </Typography.placeholderDefault>
                    }
                  />
                  <FormControlLabel
                    value='GOOD_TEACHER'
                    control={<Radio />}
                    label={
                      <Typography.placeholderDefault color='typography-muted'>
                        {t('settings.moderator.radioGroupItems.second.best')}
                      </Typography.placeholderDefault>
                    }
                  />
                  <FormControlLabel
                    value='AUTHOR'
                    control={<Radio />}
                    label={
                      <Typography.placeholderDefault color='typography-muted'>
                        {t('settings.moderator.radioGroupItems.second.moderator')}
                      </Typography.placeholderDefault>
                    }
                  />
                  {formik.touched.degree && formik.errors.degree && (
                    <Typography.paragraph color='danger-500'>
                      {t(formik.errors.degree)}
                    </Typography.paragraph>
                  )}
                </RadioGroup>
              </Styles.Row>
            </Styles.Row>
          </Styles.Row>
          <Styles.Row content={'center'}>
            <Common.Uploader
              icon={
                <Icons.Upload
                  color={theme === 'dark' ? colors['grey-300'] : colors['typography-body']}
                />
              }
              type='docs'
              title='auth.authorReg.rating.uploader.title'
              requiredText='auth.authorReg.rating.uploader.subtitle'
              files={formik.values.docs}
              setFiles={(files) => {
                formik.setFieldValue('docs', [...formik.values.docs, ...Array.from(files)]);
              }}
              // setFiles={(files) => formik.setFieldValue('docs', files)}
            />
            {formik.touched.docs && formik.errors.docs && (
              <Typography.paragraph color='danger-500'>
                {t(formik.errors.docs)}
              </Typography.paragraph>
            )}
          </Styles.Row>
        </FormWrap>
        <UserButtons gap={16} wrap={'nowrap'}>
          <Styles.Row width='130px'>
            <Button
              variant='text'
              onClick={() => {
                formik.resetForm();
              }}
              sx={{
                width: '150px',
                padding: '10px',
                backgroundColor: `${colors['opacity-primary16']}`,
              }}>
              <Typography.paragraphSmallSemiBold color='primary-400'>
                {t('settings.buttons.cancel')}
              </Typography.paragraphSmallSemiBold>
            </Button>
          </Styles.Row>
          <Styles.Row width='130px'>
            <Button
              type='submit'
              disabled={isLoading || contract === '1'}
              variant='contained'
              sx={{
                width: '150px',
                padding: '10px',
              }}>
              <Typography.paragraphSmallSemiBold color='white'>
                {t('settings.buttons.save')}
              </Typography.paragraphSmallSemiBold>
            </Button>
          </Styles.Row>
        </UserButtons>
      </Styles.Row>
      <Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </ModeratorFormWrap>
  );
};
