import { FC } from 'react';
import { Img, UserRegisterWrap } from './user-register.s';
import AuthComp from '../../components';
import Styles from '../../../../styles';
import { UserRegisterForm } from './components';

interface IUserRegisterProps {}

export const UserRegister: FC<IUserRegisterProps> = (props) => {
  return (
    <UserRegisterWrap>
      <AuthComp.LogInLeftContent>
        <Img src='/images/auth-illustration/loginImage.png' alt='register' />
      </AuthComp.LogInLeftContent>
      <Styles.Row content={'center'}>
        <UserRegisterForm />
      </Styles.Row>
    </UserRegisterWrap>
  );
};
