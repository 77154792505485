import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useRoutes } from '../hooks/useRoutes';
import Pages from '../pages';
import Components from '../components';
import { Roles } from '../types/roles';

export const Router: FC = () => {
  const { routes, role } = useRoutes();
  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.path}
          index={route.index}
          path={route.path}
          element={
            <Components.Layout
              main={role === Roles.USER || role === Roles.MODERATOR}
              menus={routes}>
              <route.component />
            </Components.Layout>
          }
        />
      ))}
      <Route path='/' element={<Navigate to={routes[0].path ? routes[0].path : '/'} />} />
      <Route
        path='*'
        element={
          role === Roles.AUTH ? (
            <Navigate to='/login' />
          ) : (
            <Components.Layout main={false} menus={routes}>
              <Pages.PageNotFound />
            </Components.Layout>
          )
        }
      />
    </Routes>
  );
};
