import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const ChevronRight: FC<IIconProps> = ({ className, width = 25, height = 24, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.97119 6L15.9712 12L9.97119 18'
        stroke='#4B465C'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.97119 6L15.9712 12L9.97119 18'
        stroke='white'
        stroke-opacity='0.5'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
