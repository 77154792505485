/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Styles, { Typography } from '../../../styles';
import mock from '../../../mock';
import { FooterMockWrap, FooterWrap } from './footer.s';

interface IFooterProps {
  theme: string;
}

export const Footer: FC<IFooterProps> = ({ theme }) => {
  const [t] = useTranslation('global');
  const [fs, setFs] = useState(localStorage.getItem('fontSize') || '16')


  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if(event.key === 'fontSize') {
        setFs(event.newValue || '16');
      }
    }

    setFs(localStorage.getItem('fontSize') || '16');
  
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    }
  }, [])

  return (
    <FooterWrap>
      <Styles.Row
        gap={10}
        content={'space-between'}
        direction={{ xs: 'column', md: 'row' }}
        wrap={'nowrap'}>
        <Styles.Row gap={6} content={{ xs: 'center', md: 'flex-start' }}>
          <Typography.paragraph color={theme === 'dark' ? 'grey-500' : 'typography-body'}>
            © 2024, made with ❤️ by
          </Typography.paragraph>
          <Typography.paragraphSemiBold color='primary-500'>Classcom</Typography.paragraphSemiBold>
        </Styles.Row>
        <FooterMockWrap fs={fs} gap={20} wrap={'nowrap'} content={{ xs: 'center', md: 'flex-end' }}>
          {mock.FooterMock.footer.map((footer, index) => (
            <Styles.Row key={index} content={'center'} width='70px'>
              <Typography.paragraph color={theme === 'dark' ? 'grey-500' : 'typography-body'}>
                {t(footer.label)}
              </Typography.paragraph>
            </Styles.Row>
          ))}
        </FooterMockWrap>
      </Styles.Row>
    </FooterWrap>
  );
};
