import { FC } from 'react';
import { IIconProps } from '../interfaces/icon-interface';

export const User: FC<IIconProps> = ({ className, width = 24, height = 24, color = '#4B465C'}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.2'>
        <path
          d='M20 5C16.9648 4.99911 14.0008 5.91901 11.4994 7.63817C8.99807 9.35733 7.0771 11.7949 5.99026 14.6288C4.90342 17.4627 4.70185 20.5596 5.41218 23.5105C6.12252 26.4614 7.71133 29.1274 9.96874 31.1562C10.9094 29.304 12.3445 27.7483 14.1151 26.6617C15.8857 25.5751 17.9226 24.9999 20 25C18.7639 25 17.5555 24.6334 16.5277 23.9467C15.4999 23.2599 14.6988 22.2838 14.2257 21.1418C13.7527 19.9997 13.6289 18.7431 13.8701 17.5307C14.1112 16.3183 14.7065 15.2047 15.5806 14.3306C16.4547 13.4565 17.5683 12.8612 18.7807 12.6201C19.9931 12.3789 21.2497 12.5027 22.3918 12.9758C23.5338 13.4488 24.5099 14.2499 25.1967 15.2777C25.8834 16.3055 26.25 17.5139 26.25 18.75C26.25 20.4076 25.5915 21.9973 24.4194 23.1694C23.2473 24.3415 21.6576 25 20 25C22.0774 24.9999 24.1143 25.5751 25.8849 26.6617C27.6555 27.7483 29.0906 29.304 30.0312 31.1562C32.2887 29.1274 33.8775 26.4614 34.5878 23.5105C35.2981 20.5596 35.0966 17.4627 34.0097 14.6288C32.9229 11.7949 31.0019 9.35733 28.5006 7.63817C25.9992 5.91901 23.0352 4.99911 20 5Z'
          fill={color}
        />
        <path
          d='M20 5C16.9648 4.99911 14.0008 5.91901 11.4994 7.63817C8.99807 9.35733 7.0771 11.7949 5.99026 14.6288C4.90342 17.4627 4.70185 20.5596 5.41218 23.5105C6.12252 26.4614 7.71133 29.1274 9.96874 31.1562C10.9094 29.304 12.3445 27.7483 14.1151 26.6617C15.8857 25.5751 17.9226 24.9999 20 25C18.7639 25 17.5555 24.6334 16.5277 23.9467C15.4999 23.2599 14.6988 22.2838 14.2257 21.1418C13.7527 19.9997 13.6289 18.7431 13.8701 17.5307C14.1112 16.3183 14.7065 15.2047 15.5806 14.3306C16.4547 13.4565 17.5683 12.8612 18.7807 12.6201C19.9931 12.3789 21.2497 12.5027 22.3918 12.9758C23.5338 13.4488 24.5099 14.2499 25.1967 15.2777C25.8834 16.3055 26.25 17.5139 26.25 18.75C26.25 20.4076 25.5915 21.9973 24.4194 23.1694C23.2473 24.3415 21.6576 25 20 25C22.0774 24.9999 24.1143 25.5751 25.8849 26.6617C27.6555 27.7483 29.0906 29.304 30.0312 31.1562C32.2887 29.1274 33.8775 26.4614 34.5878 23.5105C35.2981 20.5596 35.0966 17.4627 34.0097 14.6288C32.9229 11.7949 31.0019 9.35733 28.5006 7.63817C25.9992 5.91901 23.0352 4.99911 20 5Z'
          fill='white'
          fill-opacity='0.2'
        />
      </g>
      <path
        d='M20 25C23.4518 25 26.25 22.2018 26.25 18.75C26.25 15.2982 23.4518 12.5 20 12.5C16.5482 12.5 13.75 15.2982 13.75 18.75C13.75 22.2018 16.5482 25 20 25ZM20 25C17.9225 25 15.8855 25.5743 14.1149 26.6611C12.3443 27.7479 10.9092 29.3038 9.96875 31.1562M20 25C22.0775 25 24.1145 25.5743 25.8851 26.6611C27.6557 27.7479 29.0908 29.3038 30.0312 31.1562M35 20C35 28.2843 28.2843 35 20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5C28.2843 5 35 11.7157 35 20Z'
        stroke={color}
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M20 25C23.4518 25 26.25 22.2018 26.25 18.75C26.25 15.2982 23.4518 12.5 20 12.5C16.5482 12.5 13.75 15.2982 13.75 18.75C13.75 22.2018 16.5482 25 20 25ZM20 25C17.9225 25 15.8855 25.5743 14.1149 26.6611C12.3443 27.7479 10.9092 29.3038 9.96875 31.1562M20 25C22.0775 25 24.1145 25.5743 25.8851 26.6611C27.6557 27.7479 29.0908 29.3038 30.0312 31.1562M35 20C35 28.2843 28.2843 35 20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5C28.2843 5 35 11.7157 35 20Z'
        stroke='white'
        stroke-opacity='0.2'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
