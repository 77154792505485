/* eslint-disable react/jsx-pascal-case */
import { FC, useState } from 'react';
import { HistoryWrap } from './history.s';
import Styles, { Typography } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Icons from '../../../assets/icons';
import colors from '../../../constants';
import { DownloadHistory, PaymentHistory } from './components';
import CommonList from '../../../components/common';
import { useAppTheme } from '../../../hooks';
import { UploadHistory } from './components/uploadHistory';

interface IHistory {}

export const History: FC<IHistory> = () => {
  const { theme } = useAppTheme();
  const [historyState, setHistoryState] = useState('payment');
  const [t] = useTranslation('global');

  return (
    <HistoryWrap>
      <Styles.Row gap={26}>
        <Styles.Row gap={4}>
          <Typography.h4 color='typography-muted'>{t('history.main.title')} /</Typography.h4>
          <Typography.h4 color={theme === 'dark' ? 'typography-placeholder' : 'typography-body'}>
            {historyState === 'payment'
              ? t('history.main.paymentTitle')
              : historyState === 'download'
              ? t('history.main.downloadTitle')
              : t('history.main.uploadTitle')}
          </Typography.h4>
        </Styles.Row>
        <Styles.Row wrap={'nowrap'} gap={4}>
          <Button
            variant='contained'
            startIcon={
              <Icons.CurrencyDolar
                color={historyState === 'payment' ? colors.white : colors['typography-muted']}
              />
            }
            onClick={() => setHistoryState('payment')}
            sx={{
              boxShadow: 'none',
              backgroundColor:
                historyState === 'payment'
                  ? colors['primary-500']
                  : theme === 'dark'
                  ? colors['dark-theme-secondary']
                  : colors.white,
            }}>
            <Typography.paragraphSemiBold
              color={historyState === 'payment' ? 'white' : 'typography-muted'}>
              {t('history.main.paymentBtn')}
            </Typography.paragraphSemiBold>
          </Button>
          <Button
            variant='contained'
            startIcon={
              <Icons.Download
                color={historyState !== 'download' ? colors['typography-muted'] : colors.white}
              />
            }
            onClick={() => setHistoryState('download')}
            sx={{
              boxShadow: 'none',
              backgroundColor:
                historyState !== 'download'
                  ? theme === 'dark'
                    ? colors['dark-theme-secondary']
                    : colors.white
                  : colors['primary-500'],
            }}>
            <Typography.paragraphSemiBold
              color={historyState !== 'download' ? 'typography-muted' : 'white'}>
              {t('history.main.downloadBtn')}
            </Typography.paragraphSemiBold>
          </Button>
          <Button
            variant='contained'
            startIcon={
              <Icons.Upload
                color={historyState !== 'upload' ? colors['typography-muted'] : colors.white}
              />
            }
            onClick={() => setHistoryState('upload')}
            sx={{
              boxShadow: 'none',
              backgroundColor:
                historyState !== 'upload'
                  ? theme === 'dark'
                    ? colors['dark-theme-secondary']
                    : colors.white
                  : colors['primary-500'],
            }}>
            <Typography.paragraphSemiBold
              color={historyState !== 'upload' ? 'typography-muted' : 'white'}>
              {t('history.main.uploadBtn')}
            </Typography.paragraphSemiBold>
          </Button>
        </Styles.Row>
        <Styles.Row>
          <CommonList.Container>
            {historyState === 'payment' ? (
              <PaymentHistory />
            ) : historyState === 'download' ? (
              <DownloadHistory />
            ) : (
              <UploadHistory />
            )}
          </CommonList.Container>
        </Styles.Row>
      </Styles.Row>
    </HistoryWrap>
  );
};
