import Icons from '../assets/icons';

export const Settings = {
  userBtns: [
    {
      label: 'settings.title.account',
      icons: Icons.Users,
      formChange: 'account',
    },
    {
      label: 'settings.title.theme',
      icons: Icons.Brush,
      formChange: 'theme',
    },
    {
      label: 'settings.title.moderator',
      icons: Icons.ChartBat,
      formChange: 'moderator',
    },
  ],
  authorBtns: [
    {
      label: 'settings.title.account',
      icons: Icons.Users,
      formChange: 'account',
    },
    {
      label: 'settings.title.theme',
      icons: Icons.Brush,
      formChange: 'theme',
    },
  ],
  fontSize: [
    {
      size: 14,
    },
    {
      size: 16,
    },
    {
      size: 18,
    },
    {
      size: 20,
    },
  ],
};
