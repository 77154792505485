export const FooterMock = {
  footer: [
    {
      label: 'footer.license',
    },
    {
      label: 'footer.help',
    },
    {
      label: 'footer.communicate',
    },
  ],
};
