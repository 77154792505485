/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import {
  EmptyTitleWrapper,
  Line,
  PlanInfoWrapper,
  PlansViewWrap,
  StyledTable,
  StyledTableContainer,
  TableContentWrap,
} from './plan-resource-view.s';
import Styles, { GlobalStyles, Typography } from '../../../../styles';
import { useTranslation } from 'react-i18next';
import { Common } from '../../../../components';
import { Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import colors from '../../../../constants';
import Icons from '../../../../assets/icons';
import { formatBytes } from '../../../../helpers/formatBytes';
import { useGetMediaByIdQuery, useGetTopicMediaByIdQuery } from '../../../../store/services/mediaApi';
import { useAppTheme } from '../../../../hooks';

interface IPlansResourceViewProps {}

export const PlansResourceView: FC<IPlansResourceViewProps> = (props) => {
  const { theme } = useAppTheme();
  const [t, i18n] = useTranslation('global');
  const currentUrl = window.location.href;

  const parts = currentUrl.split('/');

  const id = parts[parts.length - 1];
  const { data: mediaData, isLoading: mediaLoading } = useGetTopicMediaByIdQuery({
    id,
    lang: i18n.language,
  });

  return (
    <PlansViewWrap>
      {mediaLoading ? (
        <Common.Container>
          <EmptyTitleWrapper content={'center'} align_items={'center'}>
            <Typography.h4 color='typography-body'>{t('plans.main.loadingTitle')}</Typography.h4>
          </EmptyTitleWrapper>
        </Common.Container>
      ) : (
        <Styles.Row gap={16}>
          <Styles.Row gap={4}>
            <Typography.h4 color='typography-muted'>{t('plans.main.title')} /</Typography.h4>
            <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
              {mediaData?.name}
            </Typography.h4>
          </Styles.Row>
          <Common.Container>
            <TableContentWrap>
              <StyledTableContainer>
                <StyledTable aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>
                        {t('resources.add.table.content.number')}
                      </TableCell>
                      <TableCell align='left'>
                        <GlobalStyles.TableHeaderTitle
                          align_items={'center'}
                          content={'space-between'}
                          wrap={'nowrap'}>
                          {t('resources.add.table.content.name')}
                        </GlobalStyles.TableHeaderTitle>
                      </TableCell>
                      <TableCell align='left'>
                        <GlobalStyles.TableHeaderTitle>
                          {t('resources.add.table.content.type')}
                        </GlobalStyles.TableHeaderTitle>
                      </TableCell>
                      <TableCell align='left'>
                        <GlobalStyles.TableHeaderTitle>
                          {t('resources.add.table.content.size')}
                        </GlobalStyles.TableHeaderTitle>
                      </TableCell>
                      <TableCell align='center'>
                        {t('resources.add.table.content.download')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell align='center'>{1}</TableCell>
                      <TableCell align='left'>{mediaData?.name}</TableCell>
                      <TableCell align='left'>1</TableCell>
                      <TableCell align='left'>{formatBytes(mediaData?.size)}</TableCell>
                      <TableCell align='center'>
                        <Styles.Row content={'center'}>
                          <Button disabled href={mediaData?.file} download={mediaData?.name}>
                            <Icons.Download color={colors['secondary-300']} />
                          </Button>
                        </Styles.Row>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </StyledTable>
              </StyledTableContainer>
            </TableContentWrap>
          </Common.Container>
        </Styles.Row>
      )}
    </PlansViewWrap>
  );
};
