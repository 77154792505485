
export const Month = {
  name: [
    'mainPage.months.January',
    'mainPage.months.February',
    'mainPage.months.March',
    'mainPage.months.April',
    'mainPage.months.May',
    'mainPage.months.June',
    'mainPage.months.July',
    'mainPage.months.August',
    'mainPage.months.September',
    'mainPage.months.October',
    'mainPage.months.November',
    'mainPage.months.December',
  ],
};
