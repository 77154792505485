import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@mui/material';
import Styles, { Typography } from '../../../../../styles';
import Icons from '../../../../../assets/icons';
import { SecondFormWrap } from './second-form.s';
import colors from '../../../../../constants';

interface ISecondFormProps {
  setPlanStep: (status: boolean) => void;
  planStep: boolean;
}

interface IFormData {
  userBank: string;
  userMFO: string;
  userINN: string;
  userAccountNumber: string;
  userCardNumber: string;
}

export const SecondForm: FC<ISecondFormProps> = ({ planStep, setPlanStep }) => {
  const [t] = useTranslation('global');
  const [formData, setFormData] = useState<IFormData>({
    userBank: '',
    userMFO: '',
    userINN: '',
    userAccountNumber: '',
    userCardNumber: '',
  });

  return (
    <SecondFormWrap>
      <Styles.Row gap={16}>
        <Styles.Row gap={16} direction={{ xs: 'column', sm: 'row' }}>
          <Styles.Column size={{ xs: 12, sm: 6, lg: 4 }} difference={16}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.passport')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <TextField
                placeholder='John'
                name='userBank'
                value={formData.userBank}
                // onChange={handleChange}
                inputProps={{
                  style: {
                    height: '5px',
                  },
                }}
                sx={{
                  width: '192px',
                  color: colors['secondary-500'],
                }}
              />
            </Styles.Row>
          </Styles.Column>
          <Styles.Column size={{ xs: 12, sm: 6, lg: 4 }} difference={16}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.inn')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <TextField
                placeholder='John'
                name='userMFO'
                value={formData.userMFO}
                // onChange={handleChange}
                inputProps={{
                  style: {
                    height: '5px',
                  },
                }}
                sx={{
                  width: '192px',
                  color: colors['secondary-500'],
                }}
              />
            </Styles.Row>
          </Styles.Column>
          <Styles.Column size={{ xs: 12, lg: 4 }} difference={16}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.inps')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <TextField
                placeholder='John'
                name='userINN'
                value={formData.userINN}
                // onChange={handleChange}
                inputProps={{
                  style: {
                    height: '5px',
                  },
                }}
                sx={{
                  width: '192px',
                  color: colors['secondary-500'],
                }}
              />
            </Styles.Row>
          </Styles.Column>
        </Styles.Row>
        <Styles.Row gap={16} wrap={'nowrap'} direction={{ xs: 'column', sm: 'row' }}>
          <Styles.Column size={6} difference={0} gap={4}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.name')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <TextField
                placeholder='John'
                name='userAccountNumber'
                value={formData.userAccountNumber}
                // onChange={handleChange}
                inputProps={{
                  style: {
                    height: '5px',
                  },
                }}
                sx={{
                  width: '192px',
                  color: colors['secondary-500'],
                }}
              />
            </Styles.Row>
          </Styles.Column>
          <Styles.Column size={6} difference={0} gap={4}>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <Typography.paragraphSmall>
                {t('plans.payment.addPayment.inputs.first.surname')}
              </Typography.paragraphSmall>
              <Typography.paragraphSmall color='danger-600'>*</Typography.paragraphSmall>
            </Styles.Row>
            <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
              <TextField
                placeholder='John'
                name='userCardNumber'
                value={formData.userCardNumber}
                // onChange={handleChange}
                inputProps={{
                  style: {
                    height: '5px',
                  },
                }}
                sx={{
                  width: '192px',
                  color: colors['secondary-500'],
                }}
              />
            </Styles.Row>
          </Styles.Column>
        </Styles.Row>
        <Styles.Row gap={16} content={'space-between'} wrap={'nowrap'}>
          <Styles.Row>
            <Button
              onClick={() => setPlanStep(!planStep)}
              variant='contained'
              sx={{
                gap: '4px',
                width: '130px',
                height: '33px',
                backgroundColor: colors['secondary-100'],
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: colors['secondary-300'],
                },
              }}
            >
              <Styles.Row width='18'>
                <Icons.ArrowLeft color={colors['secondary-500']} />
              </Styles.Row>
              <Typography.h6 color='secondary-500'>
                {t('plans.payment.addPayment.inputs.first.buttons.cancel')}
              </Typography.h6>
            </Button>
          </Styles.Row>
          <Styles.Row content={'flex-end'} gap={16}>
            <Button
              // onClick={() => setPlanStep(!planStep)}
              variant='contained'
              sx={{
                gap: '4px',
                width: '130px',
                backgroundColor: colors['primary-500'],
                textTransform: 'capitalize',
              }}
            >
              <Typography.h6 color='white'>
                {t('plans.payment.addPayment.inputs.second.buttons.save')}
              </Typography.h6>
            </Button>
          </Styles.Row>
        </Styles.Row>
      </Styles.Row>
    </SecondFormWrap>
  );
};
