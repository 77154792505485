import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const Logout: FC<IIconProps> = ({
  className,
  width = 25,
  height = 24,
  color = colors.white,
  ...props
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.5 8V6C14.5 4.89543 13.6046 4 12.5 4H5.5C4.39543 4 3.5 4.89543 3.5 6V18C3.5 19.1046 4.39543 20 5.5 20H12.5C13.6046 20 14.5 19.1046 14.5 18V16'
        stroke={props.stroke || color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.5 8V6C14.5 4.89543 13.6046 4 12.5 4H5.5C4.39543 4 3.5 4.89543 3.5 6V18C3.5 19.1046 4.39543 20 5.5 20H12.5C13.6046 20 14.5 19.1046 14.5 18V16'
        stroke={props.stroke || color}
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.5 11.25C7.08579 11.25 6.75 11.5858 6.75 12C6.75 12.4142 7.08579 12.75 7.5 12.75V11.25ZM21.5 12V12.75C21.8033 12.75 22.0768 12.5673 22.1929 12.287C22.309 12.0068 22.2448 11.6842 22.0303 11.4697L21.5 12ZM19.0303 8.46967C18.7374 8.17678 18.2626 8.17678 17.9697 8.46967C17.6768 8.76256 17.6768 9.23744 17.9697 9.53033L19.0303 8.46967ZM17.9697 14.4697C17.6768 14.7626 17.6768 15.2374 17.9697 15.5303C18.2626 15.8232 18.7374 15.8232 19.0303 15.5303L17.9697 14.4697ZM22.0303 12.5303C22.3232 12.2374 22.3232 11.7626 22.0303 11.4697C21.7374 11.1768 21.2626 11.1768 20.9697 11.4697L22.0303 12.5303ZM7.5 12.75H21.5V11.25H7.5V12.75ZM22.0303 11.4697L19.0303 8.46967L17.9697 9.53033L20.9697 12.5303L22.0303 11.4697ZM19.0303 15.5303L22.0303 12.5303L20.9697 11.4697L17.9697 14.4697L19.0303 15.5303Z'
        fill={props.stroke || color}
      />
      <path
        d='M7.5 11.25C7.08579 11.25 6.75 11.5858 6.75 12C6.75 12.4142 7.08579 12.75 7.5 12.75V11.25ZM21.5 12V12.75C21.8033 12.75 22.0768 12.5673 22.1929 12.287C22.309 12.0068 22.2448 11.6842 22.0303 11.4697L21.5 12ZM19.0303 8.46967C18.7374 8.17678 18.2626 8.17678 17.9697 8.46967C17.6768 8.76256 17.6768 9.23744 17.9697 9.53033L19.0303 8.46967ZM17.9697 14.4697C17.6768 14.7626 17.6768 15.2374 17.9697 15.5303C18.2626 15.8232 18.7374 15.8232 19.0303 15.5303L17.9697 14.4697ZM22.0303 12.5303C22.3232 12.2374 22.3232 11.7626 22.0303 11.4697C21.7374 11.1768 21.2626 11.1768 20.9697 11.4697L22.0303 12.5303ZM7.5 12.75H21.5V11.25H7.5V12.75ZM22.0303 11.4697L19.0303 8.46967L17.9697 9.53033L20.9697 12.5303L22.0303 11.4697ZM19.0303 15.5303L22.0303 12.5303L20.9697 11.4697L17.9697 14.4697L19.0303 15.5303Z'
        fill={props.stroke || color}
        fill-opacity='0.1'
      />
    </svg>
  );
};
