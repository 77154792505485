import {
  Row,
  Column,
  Subtext,
  display1,
  display2,
  display3,
  display4,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  paragraphLead,
  paragraph,
  paragraphMedium,
  paragraphSemiBold,
  paragraphBold,
  paragraphSmall,
  paragraphSmallSemiBold,
  paragraphSmallBold,
  deleteText,
  italicized,
  underline,
  caption,
  buttonLG,
  buttonDefault,
  buttonSmall,
  labelLg,
  labelDefault,
  labelSm,
  inputLg,
  inputDefault,
  inputSm,
  placeholderLg,
  placeholderDefault,
  placeholderSm,
  TableHeaderTitle,
  Bar,
  ErrorTypography,
  Img,
  StyledTextField,
  IdTableCell,
  StyledPhoneFormat,
} from './style-components';

export const GlobalStyles = {
  TableHeaderTitle,
  Bar,
  ErrorTypography,
  Img,
  StyledTextField,
  StyledPhoneFormat,
  IdTableCell,
};

const Styles = {
  Column,
  Row,
  Subtext,
};
export default Styles;

export const Typography = {
  display1,
  display2,
  display3,
  display4,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  paragraphLead,
  paragraph,
  paragraphMedium,
  paragraphSemiBold,
  paragraphBold,
  paragraphSmall,
  paragraphSmallSemiBold,
  paragraphSmallBold,
  deleteText,
  italicized,
  underline,
  caption,
  buttonLG,
  buttonDefault,
  buttonSmall,
  labelLg,
  labelDefault,
  labelSm,
  inputLg,
  inputDefault,
  inputSm,
  placeholderLg,
  placeholderDefault,
  placeholderSm,
};
