import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const Brush: FC<IIconProps> = ({ className, width = 18, height = 18, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.25 15.75V12.75C2.25 11.0931 3.59315 9.75 5.25 9.75C6.90685 9.75 8.25 11.0931 8.25 12.75C8.25 14.4069 6.90685 15.75 5.25 15.75H2.25'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.7504 2.25C11.3896 2.84648 7.70529 5.78241 6.15039 9.9'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.7496 2.25C15.1531 6.61079 12.2172 10.2951 8.09961 11.85'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.9502 6.75C9.40757 7.42265 10.5775 8.59263 11.2502 10.05'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
