import { BASE_URL, DOWNLOAD_MEDIA } from '../store/URLs';

type SetIsDownloading = (id: number | null) => void;
type SetErrorMsg = (msg: string) => void;
type setSnackbarOpen = (state: boolean) => void;

export const handleDownloadClick = async (
  id: number,
  name: string,
  i18n: string,
  setIsDownloading: SetIsDownloading,
  SetErrorMsg?: SetErrorMsg,
  setSnackbarOpen?: setSnackbarOpen,
) => {
  setIsDownloading(id);

  try {
    const url = `${BASE_URL}${DOWNLOAD_MEDIA}${id}/`;
    const mediaResponse = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'accept-language': i18n,
      },
    });

    if (!mediaResponse.ok) {
      const errorResponse = await mediaResponse.json();
      if (SetErrorMsg && setSnackbarOpen) {
        SetErrorMsg(errorResponse.detail);
        setSnackbarOpen(true);
      }
      throw new Error('Ошибка при запросе данных медиа');
    }

    const mediaData = await mediaResponse.json();

    if (mediaData.download_token) {
      const fileToken = mediaData.download_token;
      const fileUrl = `${BASE_URL}/download/file/${fileToken}/`;

      const fileResponse = await fetch(fileUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!fileResponse.ok) {
        throw new Error('Ошибка при скачивании файла');
      }

      const blob = await fileResponse.blob();

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(link.href);
    } else {
      throw new Error('Токен для скачивания отсутствует');
    }
  } catch (error) {
    console.error('Ошибка при скачивании файла:', error);
  } finally {
    setIsDownloading(null);
  }
};
