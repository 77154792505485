import styled from 'styled-components';
import Styles from '../../../styles';
import colors from '../../../constants';

export const ContainerWrap = styled(Styles.Row)<{theme: string}>`
  && {
    padding: 24px 0px;
    border-radius: 6px;
    background-color: ${((props) => props.theme === 'light' ? colors['extra-white'] : colors['dark-theme-secondary'])};
    box-shadow:  ${((props) => props.theme === 'light' ? '0px 2px 4px 0px rgba(165, 163, 174, 0.3)' : '0px 2px 4px 0px rgba(15, 20, 34, 0.4)')};
  }
`;
