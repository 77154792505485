/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import {
  PaymentHeaderWrap,
  PaymentWrap,
} from './payment.s';
import Styles, { Typography } from '../../../styles';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from '@mui/material';
import colors from '../../../constants';
import { Common } from '../../../components';
import { useAppTheme } from '../../../hooks';
import { Order } from './components';
import { useNavigate } from 'react-router-dom';

interface IPaymentProps {}

export const Payment: FC<IPaymentProps> = (props) => {
  const { theme } = useAppTheme();
  const [t] = useTranslation('global');
  const navigate = useNavigate();

  return (
    <PaymentWrap>
      <Styles.Row gap={16}>
        <Styles.Row>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('payment.main.header')}
          </Typography.h4>
        </Styles.Row>
        <Styles.Row>
          <Common.Container>
            <PaymentHeaderWrap gap={16}>
              <Styles.Row gap={16}>
                <Styles.Row>
                  <Order theme={theme} />
                </Styles.Row>
              </Styles.Row>
              <Styles.Row content={'flex-end'}>
                <Button
                  onClick={() => navigate('/add-order')}
                  variant='contained'
                  sx={{
                    backgroundColor: colors['primary-500'],
                    textTransform: 'capitalize',
                  }}>
                  <Typography.h6 color='white'>{t('payment.order.btn')}</Typography.h6>
                </Button>
              </Styles.Row>
            </PaymentHeaderWrap>
          </Common.Container>
        </Styles.Row>
      </Styles.Row>
    </PaymentWrap>
  );
};
