/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from 'react';
import {
  Button,
  Pagination,
  Select,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  PlansWrap,
  ButtonsContainer,
  TableWrapper,
  StyledTableContainer,
  StyledTable,
  ScienceChip,
  StateChip,
  PrimaryButton,
  SecondaryButton,
  HeaderBtnWrapper,
  SeletsWrapper,
  EmptyTitleWrapper,
  StyledMenu,
  DownloadBtnWrapper,
} from './plans.s';
import Icons from '../../../../assets/icons';
import Styles, { GlobalStyles, Typography } from '../../../../styles';
import colors from '../../../../constants';
import {
  useGetPlansByGradeQuery,
  useGetPlansByQuarterQuery,
  useGetPlansByScienceQuery,
  useGetPlansQuery,
} from '../../../../store/services/plansApi';
import { Common } from '../../../../components';
import { useGetAllScienceQuery } from '../../../../store/services/scienceApi';
import { useGetAllQuartersQuery } from '../../../../store/services/quarterApi';
import { useGetAllClassQuery } from '../../../../store/services/classApi';
import { useAppTheme } from '../../../../hooks';

export const Plans = () => {
  const { theme } = useAppTheme();
  const [selectedQuarterId, setSelectedQuarterId] = useState(0);
  const [selectedClassId, setSelectedClassId] = useState(0);
  const [selectedScienceId, setSelectedScienceId] = useState(0);
  const navigate = useNavigate();
  const [t, i18n] = useTranslation('global');
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const [buttonState, setButtonState] = useState(true);
  const { data: plansData, isLoading: plansLoading } = useGetPlansQuery({
    page,
    lang: i18n.language,
  });
  const { data: quarters } = useGetAllQuartersQuery();
  const { data: sciences } = useGetAllScienceQuery(i18n.language);
  const { data: classes } = useGetAllClassQuery();
  const {
    data: plansByQuarter,
    refetch: quarterRefetch,
    isLoading: quarterLoading,
  } = useGetPlansByQuarterQuery(selectedQuarterId);
  const {
    data: plansByGrade,
    refetch: gradeRefetch,
    isLoading: gradeLoading,
  } = useGetPlansByGradeQuery(selectedClassId);
  const {
    data: plansByScience,
    refetch: scienceRefetch,
    isLoading: scienceLoading,
  } = useGetPlansByScienceQuery(selectedScienceId);
  const [loadingPlans, setLoadingPlans] = useState(false);

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    // Обновляем данные с учетом выбранных параметров
    if (selectedQuarterId) {
      quarterRefetch();
    } else if (selectedClassId) {
      gradeRefetch();
    } else if (selectedScienceId) {
      scienceRefetch();
    }
  };

  const displayPlans = () => {
    if (selectedQuarterId) {
      return plansByQuarter?.results || [];
    } else if (selectedClassId) {
      return plansByGrade?.results || [];
    } else if (selectedScienceId) {
      return plansByScience?.results || [];
    } else {
      return plansData?.results || [];
    }
  };

  const displayedResources = displayPlans();

  const halfFilteredResources = buttonState ? displayedResources.slice(0, rowsPerPage) : [];

  const handleQuarterChange = (event: SelectChangeEvent<number>) => {
    setSelectedQuarterId(event.target.value as number);
    setSelectedClassId(0);
    setSelectedScienceId(0);
  };

  const handleClassChange = (event: SelectChangeEvent<number>) => {
    setSelectedClassId(event.target.value as number);
    setSelectedQuarterId(0);
    setSelectedScienceId(0);
  };

  const handleScienceChange = (event: SelectChangeEvent<number>) => {
    setSelectedScienceId(event.target.value as number);
    setSelectedQuarterId(0);
    setSelectedClassId(0);
  };

  useEffect(() => {
    setLoadingPlans(plansLoading || quarterLoading || gradeLoading || scienceLoading);
  }, [plansLoading, quarterLoading, gradeLoading, scienceLoading]);

  return (
    <PlansWrap>
      <Styles.Row gap={16}>
        <Styles.Row>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('plans.main.title')}
          </Typography.h4>
        </Styles.Row>
        <Styles.Row>
          <Common.Container>
            <ButtonsContainer>
              <Styles.Row wrap={{ xs: 'wrap', sm: 'nowrap' }} gap={10} content={'space-between'}>
                <HeaderBtnWrapper
                  wrap={'nowrap'}
                  gap={6}
                  content={{ xs: 'space-between', md: 'flex-start' }}>
                  <PrimaryButton
                    onClick={() => setButtonState(true)}
                    variant='outlined'
                    buttonState={buttonState}>
                    {t('resources.button.total')}
                  </PrimaryButton>
                  <SecondaryButton
                    onClick={() => setButtonState(false)}
                    variant='outlined'
                    buttonState={buttonState}>
                    {t('resources.button.payed')}
                  </SecondaryButton>
                </HeaderBtnWrapper>
                <SeletsWrapper
                  wrap={'nowrap'}
                  gap={6}
                  content={{ xs: 'space-between', md: 'flex-end' }}>
                  <Select
                    defaultValue={0}
                    onChange={handleScienceChange}
                    value={selectedScienceId as number}
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '280px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    <StyledMenu value={0}>{t('plans.main.table.select.lesson')}</StyledMenu>
                    {sciences?.results.map((item: any) => (
                      <StyledMenu value={item.id}>{item.name}</StyledMenu>
                    ))}
                  </Select>
                  <Select
                    defaultValue={0}
                    value={selectedClassId}
                    onChange={handleClassChange}
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '280px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    <StyledMenu value={0}>{t('plans.main.table.select.grade')}</StyledMenu>
                    {classes?.results.map((item: any) => (
                      <StyledMenu value={item.id}>{item.name}</StyledMenu>
                    ))}
                  </Select>
                  <Select
                    defaultValue={0}
                    value={selectedQuarterId}
                    onChange={handleQuarterChange}
                    MenuProps={{
                      disableScrollLock: true,
                      sx: {
                        height: '280px',
                        '& .MuiPaper-root': {
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          scrollbarColor: '#bdbdbd transparent',
                        },
                      },
                    }}>
                    <StyledMenu value={0}>{t('plans.main.table.select.quater')}</StyledMenu>
                    {quarters?.results.map((item: any) => (
                      <StyledMenu value={item.id}>{item.choices}</StyledMenu>
                    ))}
                  </Select>
                </SeletsWrapper>
              </Styles.Row>
            </ButtonsContainer>

            {loadingPlans ? (
              <EmptyTitleWrapper content={'center'} align_items={'center'}>
                <Typography.h4 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                  {t('plans.main.loadingTitle')}
                </Typography.h4>
              </EmptyTitleWrapper>
            ) : (
              <>
                {halfFilteredResources.length > 0 ? (
                  <Styles.Row>
                    <TableWrapper>
                      <StyledTableContainer>
                        <StyledTable stickyHeader aria-label='sticky table'>
                          <TableHead>
                            <TableRow>
                              <TableCell align='center'>
                                <GlobalStyles.TableHeaderTitle width='90px'>
                                  {t('plans.main.table.title.number')}
                                </GlobalStyles.TableHeaderTitle>
                              </TableCell>
                              <TableCell align='center'>
                                <GlobalStyles.TableHeaderTitle
                                  width='170px'
                                  align_items={'center'}
                                  content={'space-between'}
                                  wrap={'nowrap'}>
                                  {t('plans.main.table.title.quater')}
                                </GlobalStyles.TableHeaderTitle>
                              </TableCell>
                              <TableCell align='center'>
                                <GlobalStyles.TableHeaderTitle
                                  width='170px'
                                  align_items={'center'}
                                  content={'space-between'}
                                  wrap={'nowrap'}>
                                  {t('plans.main.table.title.grade')}
                                </GlobalStyles.TableHeaderTitle>
                              </TableCell>
                              <TableCell align='left'>
                                <GlobalStyles.TableHeaderTitle
                                  width='170px'
                                  align_items={'center'}
                                  content={'space-between'}
                                  wrap={'nowrap'}>
                                  {t('plans.main.table.title.lesson')}
                                </GlobalStyles.TableHeaderTitle>
                              </TableCell>
                              <TableCell align='center'>
                                {t('plans.main.table.title.necessary')}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {halfFilteredResources.map((row: any, index: number) => (
                              <TableRow
                                key={index}
                                onClick={() => navigate(`/subject-topics/${row?.id}/`)}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                  cursor: 'pointer',
                                }}>
                                <TableCell align='left'>
                                  {(page - 1) * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell align='left'>{row?.quarter.choices}</TableCell>
                                <TableCell align='left'>{row?.classes.name}</TableCell>
                                <TableCell align='left'>
                                  <ScienceChip
                                    label={row?.science.name}
                                    variant='outlined'
                                    color='primary'
                                  />
                                </TableCell>
                                <TableCell align='center'>
                                  <DownloadBtnWrapper gap={2} wrap={'nowrap'}>
                                    <Button
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                      disabled>
                                      <Icons.Download
                                        color={
                                          theme === 'dark'
                                            ? colors['grey-500']
                                            : colors['secondary-200']
                                        }
                                      />
                                    </Button>
                                  </DownloadBtnWrapper>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </StyledTable>
                      </StyledTableContainer>
                    </TableWrapper>
                    <Styles.Row content={'flex-end'}>
                      <Pagination
                        count={Math.ceil(plansData?.count / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color='primary'
                        shape='rounded'
                        style={{ marginTop: '20px' }}
                      />
                    </Styles.Row>
                  </Styles.Row>
                ) : (
                  <EmptyTitleWrapper content={'center'} align_items={'center'}>
                    <Typography.h4 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                      {t('plans.main.emptyTitle')}
                    </Typography.h4>
                  </EmptyTitleWrapper>
                )}
              </>
            )}
          </Common.Container>
        </Styles.Row>
      </Styles.Row>
    </PlansWrap>
  );
};
