import { FC, ReactNode } from 'react';
import { LeftContentWrap } from './logIn-left-content.s';

interface ILogInLeftContentProps {
  children: ReactNode;
}

export const LogInLeftContent: FC<ILogInLeftContentProps> = ({ children }) => {
  return <LeftContentWrap>{children}</LeftContentWrap>;
};
