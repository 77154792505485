/* eslint-disable react/jsx-pascal-case */
import { FC, useEffect, useState } from 'react';
import {
  Bar,
  ButtonContentWrap,
  ButtonIconWrap,
  ClassWrap,
  EmptyTitleWrapper,
  OrderConfirm,
  OrderDelete,
  OrderWrapper,
  PaymentWrap,
} from './order.s';

import { useTranslation } from 'react-i18next';
import mock from '../../../../../mock';
import Styles, { Typography } from '../../../../../styles';
import { Button, TextField } from '@mui/material';
import colors from '../../../../../constants';
import Icons from '../../../../../assets/icons';
import {
  useDeleteOrderMutation,
  useGetOrdersQuery,
  usePaymentCreateMutation,
} from '../../../../../store/services/order';
import { Common } from '../../../../../components';

interface IPaymentProps {
  theme: string;
}

export const Order: FC<IPaymentProps> = ({ theme }) => {
  const [t, i18n] = useTranslation('global');
  const { data: userOrders, refetch: userOrdersRefetch, isLoading } = useGetOrdersQuery();
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [deleteId, setDeleteId] = useState(0);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [userOrderDelete, { isLoading: deleteLoading }] = useDeleteOrderMutation();
  const [paymentCreate, { isLoading: paymentLoading }] = usePaymentCreateMutation();

  const handleDelete = async (id: number) => {
    try {
      await userOrderDelete({ id }).unwrap();
      setSnackbarMessage('payment.order.delete');
      setSnackbarOpen(true);
      userOrdersRefetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirm = async (id: number) => {
    try {
      const formData = new FormData();
      formData.append('order', id.toString());
      const payload = await paymentCreate(formData).unwrap();
      const redirectUrl = payload.data.url;

      if (redirectUrl && redirectUrl.startsWith('http')) {
        window.open(redirectUrl, '_blank');
      } else {
        console.error('Invalid URL:', redirectUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hasActiveOrders = userOrders?.results?.some((item: any) => !item.status);

  useEffect(() => {
    userOrdersRefetch();
  }, []);

  return (
    <PaymentWrap>
      <Styles.Row>
        <Typography.h5 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
          {t('payment.order.header')}
        </Typography.h5>
      </Styles.Row>
      {isLoading ? (
        <EmptyTitleWrapper content={'center'} align_items={'center'}>
          <Typography.h4 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
            {t('plans.main.loadingTitle')}
          </Typography.h4>
        </EmptyTitleWrapper>
      ) : (
        <>
          {userOrders?.results.length > 0 ? (
            hasActiveOrders ? (
              <Styles.Row gap={32}>
                {userOrders.results
                  .filter((item: any) => !item.status) // Фильтрация по статусу
                  .map((item: any, index: number) => {
                    const pricePerItem = item.price / item.types.length;
                    return (
                      <Styles.Row key={index} gap={4} direction={'column'}>
                        <Styles.Row>
                          <Typography.paragraph
                            color={
                              theme === 'dark' ? 'grey-300' : 'typography-body'
                            }></Typography.paragraph>
                        </Styles.Row>
                        <Styles.Row>
                          <OrderWrapper>
                            <OrderDelete
                              disabled={deleteLoading}
                              onClick={() => {
                                setDeleteStatus(true);
                                setDeleteId(item.id);
                              }}
                              variant='contained'
                              color='warning'>
                              <Icons.X
                                width={20}
                                color={theme === 'dark' ? colors['grey-300'] : colors['grey-100']}
                              />
                            </OrderDelete>

                            <Styles.Row wrap={{ xs: 'wrap', md: 'nowrap' }}>
                              <Styles.Row wrap={'nowrap'}>
                                <ClassWrap lang={i18n.language}>
                                  <Typography.paragraph
                                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                                    {t('auth.userReg.inputs.class_group')}:
                                  </Typography.paragraph>
                                </ClassWrap>
                                <Styles.Row>
                                  <Typography.paragraph
                                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                                    {item.class_type}
                                  </Typography.paragraph>
                                </Styles.Row>
                              </Styles.Row>
                              <Styles.Row gap={8} wrap={'nowrap'}>
                                <Typography.paragraph
                                  color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                                  {t('auth.userReg.inputs.lesson')}:
                                </Typography.paragraph>
                                <Styles.Row gap={8}>
                                  <Typography.paragraph
                                    color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                                    {item.science.name}
                                  </Typography.paragraph>
                                </Styles.Row>
                              </Styles.Row>
                            </Styles.Row>

                            <Styles.Row>
                              <Typography.paragraph
                                color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                                {t('auth.userReg.inputs.grade')}:
                              </Typography.paragraph>
                              <Styles.Row>
                                {item.types.map((science: any, index: number) => (
                                  <Styles.Row key={index}>
                                    <Typography.paragraph
                                      color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                                      {science.name} - {pricePerItem}
                                    </Typography.paragraph>
                                  </Styles.Row>
                                ))}
                              </Styles.Row>
                            </Styles.Row>

                            <Styles.Row content={'space-between'} align_items={'center'}>
                              <Typography.paragraph
                                color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                                {t('payment.order.price')}: {item.price}
                              </Typography.paragraph>
                              <OrderConfirm
                                disabled={paymentLoading}
                                variant='contained'
                                onClick={() => {
                                  handleConfirm(item.id);
                                }}>
                                <Typography.paragraph color='white'>
                                  {t('payment.order.buy')}
                                </Typography.paragraph>
                              </OrderConfirm>
                            </Styles.Row>
                          </OrderWrapper>
                        </Styles.Row>
                      </Styles.Row>
                    );
                  })}
              </Styles.Row>
            ) : (
              <EmptyTitleWrapper content={'center'} align_items={'center'}>
                <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                  {t('payment.order.empty')}
                </Typography.h4>
              </EmptyTitleWrapper>
            )
          ) : (
            <EmptyTitleWrapper content={'center'} align_items={'center'}>
              <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('payment.order.empty')}
              </Typography.h4>
            </EmptyTitleWrapper>
          )}
        </>
      )}

      {/* Order Delete Modal */}
      {deleteStatus && (
        <Common.Modal modalStatus={deleteStatus} setModalStatus={setDeleteStatus}>
          <Styles.Row gap={16}>
            <Styles.Row content={'center'}>
              <Typography.h3 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('payment.main.modal.deleteHeader')}
              </Typography.h3>
            </Styles.Row>
            <Styles.Row wrap={'nowrap'} gap={16}>
              <Button
                onClick={() => setDeleteStatus(!deleteStatus)}
                variant='outlined'
                fullWidth
                sx={{
                  textTransform: 'capitalize',
                }}>
                <Typography.h6 color='typography-muted'>
                  {t('payment.main.modal.cancelButton')}
                </Typography.h6>
              </Button>
              <Button
                onClick={() => {
                  setDeleteStatus(!deleteStatus);
                  handleDelete(deleteId);
                }}
                variant='contained'
                fullWidth
                sx={{
                  backgroundColor: colors['primary-500'],
                  textTransform: 'capitalize',
                }}>
                <Typography.h6 color='white'>{t('payment.main.modal.deletButton')}</Typography.h6>
              </Button>
            </Styles.Row>
          </Styles.Row>
        </Common.Modal>
      )}

      {/* Order Confirm Modal */}
      {confirmStatus && (
        <Common.Modal modalStatus={confirmStatus} setModalStatus={setConfirmStatus}>
          <Styles.Row gap={16}>
            <Styles.Row content={'center'}>
              <Typography.h3 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('payment.main.modal.header')}
              </Typography.h3>
            </Styles.Row>
            <Styles.Row gap={16}>
              <Styles.Row gap={4}>
                <Styles.Row>
                  <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                    {t('payment.main.method.first.header')}
                  </Typography.paragraph>
                </Styles.Row>
                <Styles.Row>
                  <Button
                    // onClick={() => handleButtonClick(index)}
                    variant='outlined'
                    sx={{
                      backgroundColor: colors['opacity-secondary32'],
                      height: '72px',
                      width: '100%',
                      padding: '0px',
                    }}>
                    <ButtonContentWrap gap={16} wrap={'nowrap'}>
                      <ButtonIconWrap width='62px' content={'center'} align_items={'center'}>
                        <Icons.Uzum />
                      </ButtonIconWrap>
                      <Styles.Row direction={'column'} gap={4}>
                        <Styles.Row>
                          <Typography.h5 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                            {t('payment.main.method.first.number')}
                          </Typography.h5>
                        </Styles.Row>
                        <Styles.Row>
                          <Typography.paragraphSmall color='typography-muted'>
                            {t('payment.main.method.first.name')}
                          </Typography.paragraphSmall>
                        </Styles.Row>
                      </Styles.Row>
                    </ButtonContentWrap>
                  </Button>
                </Styles.Row>
              </Styles.Row>
              {mock.Payment.method.map((item, index) => (
                <Styles.Row gap={4}>
                  <Styles.Row>
                    <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t(item.label)}
                    </Typography.paragraph>
                  </Styles.Row>
                  <Styles.Row>
                    <Button
                      variant='outlined'
                      sx={{
                        backgroundColor: colors['opacity-secondary32'],
                        height: '72px',
                        width: '100%',
                        padding: '0px',
                      }}>
                      <ButtonContentWrap gap={16} wrap={'nowrap'}>
                        <ButtonIconWrap width='62px' content={'center'} align_items={'center'}>
                          <item.icons />
                        </ButtonIconWrap>
                        <Styles.Row direction={'column'} gap={4}>
                          <Styles.Row>
                            <Typography.h5
                              color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                              {t(item.number)}
                            </Typography.h5>
                          </Styles.Row>
                          <Styles.Row>
                            <Typography.paragraphSmall color='typography-muted'>
                              {t(item.name)}
                            </Typography.paragraphSmall>
                          </Styles.Row>
                        </Styles.Row>
                      </ButtonContentWrap>
                    </Button>
                  </Styles.Row>
                </Styles.Row>
              ))}
            </Styles.Row>
            <Styles.Row gap={4}>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <Typography.paragraphSmall>
                  {t('payment.main.modal.inputTitle')}
                </Typography.paragraphSmall>
              </Styles.Row>
              <Styles.Row content={{ xs: 'center', sm: 'flex-start' }}>
                <TextField
                  fullWidth
                  placeholder='1,000,000 sum'
                  name='input1'
                  // value={formData.cost}
                  // onChange={handleChange}
                  InputProps={{
                    classes: {
                      root: 'MuiInputBase-root-MuiOutlinedInput-root',
                    },
                    style: {
                      alignItems: 'flex-start',
                    },
                  }}
                  sx={{
                    color: colors['secondary-500'],
                  }}
                />
              </Styles.Row>
            </Styles.Row>
            <Styles.Row>
              <Button
                onClick={() => setConfirmStatus(!confirmStatus)}
                variant='contained'
                fullWidth
                sx={{
                  backgroundColor: colors['primary-500'],
                  textTransform: 'capitalize',
                }}>
                <Typography.h6 color='white'>{t('payment.main.modal.button')}</Typography.h6>
              </Button>
            </Styles.Row>
          </Styles.Row>
        </Common.Modal>
      )}
      <Bar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t(snackbarMessage)}
      />
    </PaymentWrap>
  );
};
