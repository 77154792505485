/* eslint-disable react/jsx-pascal-case */
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import colors from '../../../../../../../../constants';
import {
  Table,
  TableWrapper,
  StyledTableCell,
  StyledTableRow,
  StyledTypographyParagraph,
  StyledTypographyParagraphSm,
} from './table.s';
import Styles, { Typography } from '../../../../../../../../styles';
import { Day } from '../../../../../../../../interfaces/lesson';
import { AddLessonModal, ResourcesModal } from './components';

const titles = [
  { title: 'mainPage.mainTable.header.number', small: true },
  { title: 'mainPage.mainTable.header.lesson', small: false },
  { title: 'mainPage.mainTable.header.class', small: true },
  { title: 'mainPage.mainTable.header.classType', small: false },
  { title: 'mainPage.mainTable.header.start', small: true },
  { title: 'mainPage.mainTable.header.end', small: true },
];

interface ITableContainer {
  theme: string;
  lessons: any[];
  quarter: number;
  weekId: number;
  slot: number;
  day: any[] | null;
  dateByIndex: string;
}

interface Lesson {
  id?: number;
  science?: {
    name?: string;
    id?: number;
  };
  science_type?: {
    name?: string;
    id?: number;
  };
  classes?: {
    name?: string;
    id?: number;
  };
  class_type?: {
    name?: string;
  };
  class_group?: {
    name?: string;
  };
  start_time?: string;
  end_time?: string;
  lesson_time?: string;
}

interface ResourcesData {
  class: number | null;
  science: number | null;
  week: number | null;
  schedule: number | null;
}

export const TableContent: FC<ITableContainer> = ({
  theme,
  day,
  slot,
  lessons,
  quarter,
  weekId,
  dateByIndex,
}) => {
  const { t } = useTranslation('global');
  const [lessonId, setLessonId] = useState(0);
  const [addLessonModal, setAddLessonModal] = useState(false);
  const [resourcesModal, setResourcesModal] = useState(false);
  const [resourcesData, setResourcesData] = useState<ResourcesData>({
    class: null,
    science: null,
    week: null,
    schedule: null,
  });
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    setAnimation(false);
    setTimeout(() => {
      setAnimation(true);
    }, 200);
  }, [slot]);

  const rows: Lesson[] = Array.from({ length: 6 }, () => ({} as Lesson));

  const lessonMap = new Map<number, Lesson>();

  lessons.forEach((lesson) => {
    const index = parseInt(lesson.lesson_time || '0', 10) - 1;
    if (index >= 0 && index < 6) {
      lessonMap.set(index, lesson);
    }
  });

  lessonMap.forEach((lesson, index) => {
    rows[index] = lesson;
  });

  return (
    <TableWrapper theme={theme}>
      <AddLessonModal
        theme={theme}
        lesson={lessonId}
        quarter={quarter}
        modalStatus={addLessonModal}
        setModalStatus={setAddLessonModal}
      />
      <ResourcesModal
        theme={theme}
        resourcesData={resourcesData}
        dateByIndex={dateByIndex}
        modalStatus={resourcesModal}
        setModalStatus={setResourcesModal}
      />
      <Styles.Row>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {titles.map((title, index) => (
                  <StyledTableCell sx={{ maxWidth: title.small ? '110px' : '' }} key={index}>
                    <Typography.paragraphSmallSemiBold
                      color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                      {t(`${title.title}`)}
                    </Typography.paragraphSmallSemiBold>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((lesson, index) => (
                <StyledTableRow
                  active={lesson.science?.name ? true : false}
                  key={index}
                  onClick={() => {
                    if (lesson.science?.name) {
                      setResourcesModal(true);
                      setResourcesData({
                        class: lesson.classes?.id || null,
                        science: lesson.science?.id || null,
                        week: weekId || null,
                        schedule: lesson.id || null,
                      });
                    }
                  }}
                  style={{
                    backgroundColor:
                      Object.keys(lesson).length === 0 ? colors['secondary-100'] : colors.white,
                  }}>
                  <StyledTableCell>
                    <Typography.paragraph color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                      {index + 1}
                    </Typography.paragraph>
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTypographyParagraph
                      color={theme === 'dark' ? 'grey-200' : 'typography-body'}
                      animate={animation}>
                      {lesson.science_type?.name || lesson.science?.name}
                    </StyledTypographyParagraph>
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTypographyParagraphSm
                      color={theme === 'dark' ? 'grey-200' : 'typography-body'}
                      animate={animation}>
                      {lesson.classes?.name !== undefined
                        ? `${lesson.classes?.name || ''} (${lesson.class_type?.name || ''})`
                        : ''}
                    </StyledTypographyParagraphSm>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography.h6 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                      {lesson.class_group?.name}
                    </Typography.h6>
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTypographyParagraph
                      color={theme === 'dark' ? 'grey-200' : 'typography-body'}
                      animate={animation}>
                      {animation && lesson.start_time}
                    </StyledTypographyParagraph>
                  </StyledTableCell>
                  <StyledTableCell>
                    <StyledTypographyParagraph
                      color={theme === 'dark' ? 'grey-200' : 'typography-body'}
                      animate={animation}>
                      {animation && lesson.end_time}
                    </StyledTypographyParagraph>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Styles.Row>
    </TableWrapper>
  );
};
