import styled from 'styled-components';
import Styles, { Typography } from '../../../styles';
import colors from '../../../constants';

export const SearchWrap = styled.div`
  width: 100%;
`;

export const SearchContainer = styled(Styles.Row)`
  && {
    padding: 20px;
  }
`;

export const SearchedItemsWrapper = styled(Styles.Row)`
  && {
    padding: 10px;
  }
`;

export const SearchedItems = styled(Styles.Row)<{ theme: string }>`
  && {
    padding: 10px;
    display: grid;
    cursor: pointer;
    grid-template-columns: repeat(
      auto-fill,
      minmax(300px, 1fr)
    );
    gap: 16px;
    border-radius: 6px;
    gap: 16px;
    box-shadow: ${({ theme }) =>
      theme === 'light'
        ? '0px 0px 10px 5px rgba(165, 163, 174, 0.3)'
        : '0px 0px 10px 3px rgba(199 199 199 / 20%)'};
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition for smooth scaling and box-shadow change */

    &:hover {
      transform: scale(1.02);
      box-shadow: ${
        ({ theme }) =>
          theme === 'light'
            ? '0px 0px 12px 6px rgba(165, 163, 174, 0.4)' /* Slightly larger shadow on hover */
            : '0px 0px 12px 4px rgba(199, 199, 199, 0.3)' /* Slightly larger shadow on hover */
      };
    }
  }
`;

export const StyledTypographyH5 = styled(Typography.h5)`
  && {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

export const IconWrapper = styled.div`
  && {
    cursor: pointer;
  }
`;
