/* eslint-disable react/jsx-pascal-case */
import { FC } from 'react';
import {
  IconWrapper,
  SearchContainer,
  SearchedItems,
  SearchWrap,
  StyledTypographyH5,
} from './search.s';
import CommonList from '../../common';
import Styles, { Typography } from '../../../styles';
import { useTranslation } from 'react-i18next';
import Icons from '../../../assets/icons';
import colors from '../../../constants';
import { useAppTheme } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface ISearch {
  searchData: any;
  setSearchData: (state: null) => void;
  setSearchInput: (state: string) => void;
}

export const Search: FC<ISearch> = ({ searchData, setSearchData, setSearchInput }) => {
  const [t] = useTranslation('global');
  const { theme } = useAppTheme();
  const navigate = useNavigate();
  const userState = useSelector((state: any) => state.auth);

  const navigateToResource = (id: number) => {
    closeSearchComp();
    navigate(`/resource-view/${id}/`);
  };

  const navigateToPlan = (id: number) => {
    closeSearchComp();
    if (userState.role === 'user') {
      navigate(`/plan-view/${id}`);
    } else {
      navigate(`/plan-view/${id}`);
    }
  };

  const closeSearchComp = () => {
    setSearchData(null);
    setSearchInput('');
  };

  return (
    <SearchWrap>
      <CommonList.Container>
        <SearchContainer content={'space-between'} wrap={'nowrap'}>
          <Typography.h4 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
            {t('navbar.searchRes')}
          </Typography.h4>
          <IconWrapper onClick={closeSearchComp}>
            <Icons.X color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']} />
          </IconWrapper>
        </SearchContainer>
        <Styles.Row gap={16}>
          {searchData && searchData.schedules.length > 0 && (
            <SearchContainer gap={16}>
              <StyledTypographyH5 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                <Icons.SmartHome
                  color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
                />
                {t('sidebar.items.home')}:
              </StyledTypographyH5>
              {searchData.schedules.map((item: any, index: number) => (
                <SearchedItems theme={theme} wrap={'nowrap'} content={'space-between'}>
                  <Styles.Row width='50px' gap={4}>
                    <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {item?.id}
                    </Typography.paragraph>
                  </Styles.Row>
                  <Styles.Row gap={4}>
                    <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t('plans.main.table.select.grade')}:
                    </Typography.paragraph>
                    <Typography.paragraph
                      color={theme === 'dark' ? 'grey-400' : 'typography-muted'}>
                      {item?.classes?.name}
                    </Typography.paragraph>
                  </Styles.Row>
                </SearchedItems>
              ))}
            </SearchContainer>
          )}
          {searchData && searchData.resources.length > 0 && (
            <SearchContainer gap={16}>
              <StyledTypographyH5 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                <Icons.Book2
                  color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
                />
                {t('sidebar.items.resources')}:
              </StyledTypographyH5>
              {searchData.resources.map((item: any, index: number) => (
                <SearchedItems theme={theme} onClick={() => navigateToResource(item?.id)}>
                  <Styles.Row gap={4} width='50px'>
                    <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      ID:
                    </Typography.paragraph>
                    <Typography.paragraph
                      color={theme === 'dark' ? 'grey-400' : 'typography-muted'}>
                      {item?.id}
                    </Typography.paragraph>
                  </Styles.Row>
                  <Styles.Row gap={4}>
                    <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t('resources.add.body.inputs.title.type')}:
                    </Typography.paragraph>
                    <Typography.paragraph
                      color={theme === 'dark' ? 'grey-400' : 'typography-muted'}>
                      {item?.type.name}
                    </Typography.paragraph>
                  </Styles.Row>
                  <Styles.Row gap={4}>
                    <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t('resources.add.body.inputs.title.name')}:
                    </Typography.paragraph>
                    <Typography.paragraph
                      color={theme === 'dark' ? 'grey-400' : 'typography-muted'}>
                      {item?.name}
                    </Typography.paragraph>
                  </Styles.Row>
                  <Styles.Row gap={4}>
                    <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t('resources.add.body.inputs.title.source')}:
                    </Typography.paragraph>
                    <Typography.paragraph
                      color={theme === 'dark' ? 'grey-400' : 'typography-muted'}>
                      {item?.source}
                    </Typography.paragraph>
                  </Styles.Row>
                </SearchedItems>
              ))}
            </SearchContainer>
          )}
          {searchData && searchData.topics.length > 0 && (
            <SearchContainer gap={16}>
              <StyledTypographyH5 color={theme === 'dark' ? 'grey-200' : 'typography-body'}>
                <Icons.Calendar
                  color={theme === 'dark' ? colors['grey-200'] : colors['typography-body']}
                />
                {t('sidebar.items.plans')}:
              </StyledTypographyH5>
              {searchData.topics.map((item: any, index: number) => (
                <SearchedItems theme={theme} onClick={() => navigateToPlan(item?.id)}>
                  <Styles.Row width='50px' gap={4}>
                    <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      ID:
                    </Typography.paragraph>
                    <Typography.paragraph
                      color={theme === 'dark' ? 'grey-400' : 'typography-muted'}>
                      {item?.id}
                    </Typography.paragraph>
                  </Styles.Row>
                  <Styles.Row gap={4}>
                    <Typography.paragraph color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                      {t('plans.main.table.select.name')}:
                    </Typography.paragraph>
                    <Typography.paragraph
                      color={theme === 'dark' ? 'grey-400' : 'typography-muted'}>
                      {item?.name}
                    </Typography.paragraph>
                  </Styles.Row>
                </SearchedItems>
              ))}
            </SearchContainer>
          )}
        </Styles.Row>
      </CommonList.Container>
    </SearchWrap>
  );
};
