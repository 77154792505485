import React, { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';
import colors from '../../constants';

export const Book2: FC<IIconProps> = ({
  className,
  width = 24,
  height = 24,
  color = colors.white,
  ...props
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5 4V20H7.5C6.39543 20 5.5 19.1046 5.5 18V6C5.5 4.89543 6.39543 4 7.5 4H19.5Z'
        stroke={props.stroke || color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5 4V20H7.5C6.39543 20 5.5 19.1046 5.5 18V6C5.5 4.89543 6.39543 4 7.5 4H19.5Z'
        stroke='white'
        strokeOpacity='0.1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5 16H7.5C6.39543 16 5.5 16.8954 5.5 18'
        stroke={props.stroke || color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5 16H7.5C6.39543 16 5.5 16.8954 5.5 18'
        stroke='white'
        strokeOpacity='0.1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.5 8H15.5'
        stroke={props.stroke || color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.5 8H15.5'
        stroke='white'
        strokeOpacity='0.1'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
