/* eslint-disable react/jsx-pascal-case */
import { useTranslation } from 'react-i18next';
import CommonList from '../../../components/common';
import Styles, { Typography } from '../../../styles';
import { FirstForm } from './components';

import { StepsWrap, AddPlanBody, NewPlanWrap } from './topicResource.s';
import { useAppTheme } from '../../../hooks';

export const TopicResource = () => {
  const { theme } = useAppTheme();
  const [t] = useTranslation('global');

  return (
    <NewPlanWrap>
      <Styles.Row gap={26}>
        <Styles.Row>
          <Typography.h4 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
            {t('plans.viewAddItem.body.resAdd.title')}
          </Typography.h4>
        </Styles.Row>
        <Styles.Row>
          <CommonList.Container>
            <StepsWrap>
              <Typography.h5 color={theme === 'dark' ? 'grey-300' : 'typography-body'}>
                {t('plans.viewAddItem.body.resAdd.containerSubTitle')}
              </Typography.h5>
            </StepsWrap>
            <AddPlanBody gap={26}>
              <FirstForm theme={theme} />
            </AddPlanBody>
          </CommonList.Container>
        </Styles.Row>
      </Styles.Row>
    </NewPlanWrap>
  );
};
