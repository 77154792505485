import styled from 'styled-components';
import Styles from '../../../styles';
import colors from '../../../constants';
import { Select, Table, TableContainer } from '@mui/material';

export const PaymentWrap = styled.div`
  width: 100%;
`;

export const PaymentHeaderWrap = styled(Styles.Row)`
  padding: 0px 24px;
`;

export const ButtonContentWrap = styled(Styles.Row)`
  padding: 12px;
`;

export const TableContentWrap = styled(Styles.Row)`
  padding: 16px;
`;

export const ButtonIconWrap = styled(Styles.Row)`
  height: 46px;
  background-color: white;
`;

export const ActiveIconWrap = styled(Styles.Row)`
  height: 14px;
  border-radius: 15px;
  background-color: #3b82f6;
`;

export const SelectContainer = styled(Styles.Row)`
  && {
    padding: 0px 24px;
  }
`;

export const StyledSelect = styled(Select)`
  && {
    width: 150px;
    color: ${colors['secondary-500']};
    input {
      height: 5px;
    }
  }
`;

export const StyledTable = styled(Table)`
  && {
    min-width: 650px;
    width: 100%;
  }
`;

export const TableWrapper = styled(Styles.Row)`
  && {
    padding: 16px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    border: 1px solid rgba(67, 73, 104, 1);
    max-width: 100%;
    overflow-x: auto;
  }
`;
