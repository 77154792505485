import styled from 'styled-components';
import Styles from '../../../styles';

export const AddPaymentWrap = styled.div`
  width: 100%;
`;

export const AddPlanBody = styled(Styles.Row)`
  && {
    padding: 24px;
  }
`;

export const StepsWrap = styled(Styles.Row)`
  && {
    padding: 0px 24px 24px 24px;
    border-bottom: 1px solid var(--extra-divider);
  }
`;

export const StepsTextWrap = styled(Styles.Row)`
  && {
    width: 180px;
    flex-direction: column;
    @media (max-width: 576px) {
      display: none;
    }
  }
`;

export const StepContentWrap = styled(Styles.Row)<{ planStep: boolean; index: number }>`
  && {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: ${({ planStep, index }) =>
      planStep
        ? index === 0
          ? 'var(--primary-500)'
          : 'var(--secondary-500)'
        : index === 0
          ? 'var(--secondary-500)'
          : 'var(--primary-500)'};
  }
`;
