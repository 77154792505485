import { FC } from 'react';
import { IIconProps } from './interfaces/icon-interface';

export const CubeSphere3d: FC<IIconProps> = ({ className, width = 24, height = 24 }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.5 17.6L4.5 16.5V14M4.5 10V7.5M4.5 7.5L6.5 6.4M4.5 7.5L6.5 8.6M10.5 4.1L12.5 3L14.5 4.1M18.5 6.4L20.5 7.5M20.5 7.5V10M20.5 7.5L18.5 8.6M20.5 14V16.5L18.5 17.62M14.5 19.9L12.5 21M12.5 21L10.5 19.9M12.5 21V18.5M12.5 12L14.5 10.9M12.5 12V14.5M12.5 12L10.5 10.88'
        stroke='#4B465C'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.5 17.6L4.5 16.5V14M4.5 10V7.5M4.5 7.5L6.5 6.4M4.5 7.5L6.5 8.6M10.5 4.1L12.5 3L14.5 4.1M18.5 6.4L20.5 7.5M20.5 7.5V10M20.5 7.5L18.5 8.6M20.5 14V16.5L18.5 17.62M14.5 19.9L12.5 21M12.5 21L10.5 19.9M12.5 21V18.5M12.5 12L14.5 10.9M12.5 12V14.5M12.5 12L10.5 10.88'
        stroke='white'
        stroke-opacity='0.1'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
