import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface CartType {
  cart: any;
  data: any[];
  clearCart: () => void;
  setCart: (value: any) => void;
  setData: (value: any) => void;
  removeData: (index: number) => void;
}

const initialState: CartType = {
  cart: null,
  data: [],
  setCart: () => {},
  setData: () => {},
  clearCart: () => {},
  removeData: () => {},
};

const useCartStore = create<CartType>(
  persist(
    (set) => ({
      cart: initialState.cart,
      data: initialState.data,
      setCart: (value: any) => set({ cart: value }),
      setData: (value: any) => set((state: any) => ({ data: [...(state.data || []), value] })),
      removeData: (index: number) =>
        set((state: any) => ({
          data: state.data.filter((_: any, i: number) => i !== index),
        })),
      clearCart: () => set({ cart: null, data: [] }),
    }),
    {
      name: 'cart-store',
    }
  ) as any
);

export default useCartStore;
